import React, { Component } from 'react';
import { Route, Redirect, Switch } from 'react-router-dom';
import { createStore, applyMiddleware, compose } from 'redux';
import { Provider } from 'react-redux';
import thunk from 'redux-thunk';
import { ConnectedRouter, routerMiddleware } from 'connected-react-router';
import { createLogger } from 'redux-logger';

// our own components
import API from './components/API';
import Login from './components/Login';
import PageLoader from './components/Loading';
import Content from './components/Content';

import history from "./history";
import createRootReducer from './components/allreducers'

const middleware = routerMiddleware(history);

const logger = createLogger({
  diff: true,
})

/* во время разработки полезно включить redux-logger, раскоментировав applyMiddleware(logger) */
export const store = createStore(
  createRootReducer(history),
  compose(applyMiddleware(middleware), applyMiddleware(thunk)/*, applyMiddleware(logger)*/),
);

const LoginRoute = ({ component: Component, ...rest }) => (
  <Route exact {...rest} render={props => <Component {...props}/>} />
);

const LoadingRoute = ({ component: Component, ...rest }) => (
  <Route exact {...rest} render={props => {
    if (API.isAuth()) {
      return <Component {...props}/>
    } else {
      return <Redirect to={{
        pathname: '/login',
        state: { from: props.location }
      }}/>
    }
  }}/>
);

const PrivateRoute = ({ component: Component, ...rest }) => (
  <Route exact {...rest} render={props => {
    if (API.isAuth()) {
      return <Component {...props}/>
    } else {
      return <Redirect to={{
        pathname: '/login',
        state: { from: props.location }
      }}/>
    }
  }}/>
);

class App extends Component {

  render() {
    return (
      <div className="App">
        <Provider store={store}>
          <ConnectedRouter history={history}>
            <div>
              <Switch>
                <LoginRoute path="/login" component={Login} />
                <LoadingRoute path="/load" component={PageLoader} />
                <PrivateRoute path="/" component={Content} />
              </Switch>
            </div>
          </ConnectedRouter>
        </Provider>
      </div>
    );
  }
}

export default App;
