import React, { Component } from 'react';
import { Segment } from 'semantic-ui-react';

import API from '../API';
import Denstables from '../Denstables';

import { freqDenstables } from '../constants';

class DenstablesContainer extends Component {
    state = {
        tables: []
    };

    componentDidMount() {
        API.GET('/tanks/' + this.props.id + '/denstables', null, res => {
            this.setState({ tables: res.data });
        });
        this.timerDenstables = setInterval(() => {
            API.GET('/tanks/' + this.props.id + '/denstables', null, res => {
                this.setState({ tables: res.data });
            });
        }, freqDenstables);
    }

    componentWillUnmount() {
        clearInterval(this.timerDenstables);
    }

    render() {
        const { tables } = this.state;
        return (
            <Segment className='soon' basic style={{ padding: '0px' }}>
                <Denstables tables={tables} id={this.props.id} />
            </Segment>
        );
    }
}

export default DenstablesContainer;
