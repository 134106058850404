import React from 'react';
import { Segment, Image, Label, Icon } from 'semantic-ui-react';

import { ADMIN } from '../constants';

const StatusOfModules = props => {
    const {
        gsm,
        gps,
        level,
        massflow_in,
        massflow_out,
        utm,
        cert,
        tank_type,
        hoverColor,
        ip,
        role,
        battery,
        charge_level,
        sd,
        proc
    } = props;
    var battery_name;
    var battery_color;

    switch (battery) {
        case 0:
            if (charge_level <= 20) {
                battery_name = 'battery empty';
                battery_color = 'red';
            } else if (charge_level <= 30) {
                battery_name = 'battery one';
                battery_color = 'yellow';
            } else if (charge_level <= 50) {
                battery_name = 'battery two';
                battery_color = 'blue';
            } else if (charge_level <= 75) {
                battery_name = 'battery three';
                battery_color = 'blue';
            } else {
                battery_name = 'battery four';
                battery_color = 'blue';
            }
            break;
        case 1:
            battery_name = 'battery empty';
            battery_color = 'red';
            break;
        case 2:
            battery_name = 'battery one';
            battery_color = 'yellow';
            break;
        default:
            battery_name = 'battery one';
            battery_color = 'yellow';
    }

    return (
        <Segment className='devices' style={{ backgroundColor: hoverColor }}>
            <Image.Group>
                <Image src={gsm} />
                <Image src={gps} />
                {tank_type !== 0 && tank_type !== 3 ? (
                    <Image src={level} />
                ) : null}
                {tank_type !== 0 && tank_type !== 3 ? (
                    <Image src={massflow_in} />
                ) : null}
                {tank_type > 1 && tank_type !== 3 ? (
                    <Image src={massflow_out} />
                ) : null}
                <Image src={utm} />
                <Image src={cert} />
                {tank_type === 3 && <Image src={sd} />}
                {tank_type === 3 && <Image src={proc} />}
                {tank_type === 3 && (
                    <Image>
                        <Label
                            icon={
                                <Icon
                                    name={battery_name}
                                    rotated='counterclockwise'
                                    size='large'
                                    color={battery_color}
                                    style={{ margin: '0px' }}
                                />
                            }
                            content={charge_level + '%'}
                            style={{
                                backgroundColor: hoverColor,
                                padding: '0px'
                            }}
                        />
                    </Image>
                )}
                {role && role === ADMIN && (
                    <Image floated='right'>
                        <Label
                            content={ip}
                            style={{
                                backgroundColor: hoverColor,
                                color: '#3B83C0',
                                height: '100%',
                                marginRight: '10px'
                            }}
                        />
                    </Image>
                )}
            </Image.Group>
        </Segment>
    );
};

export default StatusOfModules;
