import React, { Component } from 'react';
import { connect } from 'react-redux';

import { Menu, Input, Dropdown, Icon } from 'semantic-ui-react';

import API from '../API';
import MapShow from './MapShow';

import {
    setCarrierID,
    showMap,
    setTankID,
    setBadRequest,
    setNoCurrData,
    setCurrLocation,
    showInfoWindow,
    setMapType
} from '../actions/flags';

import { getCurrData } from '../actions/tanks';

import { getTanks } from '../actions/tanks';

import { ERR_BAD_REQUEST } from '../errors';
import { badReqHandler, indexObjects } from '../functions';

import { ADMIN, mapTypes } from '../constants';

class TankFilter extends Component {
    state = {
        initValue: false,
        value: '',
        mapIconWidth: 0
    };

    componentDidMount() {
        /* это для красоты */
        this.setState({
            mapIconWidth: document
                .getElementById('exit')
                .getBoundingClientRect().width
        });
    }

    handleChangeMapType = (e, { value }) => {
        this.props.onSetMapType(value);
    };

    getCarrierID = (arr, value) => {
        var id;
        for (let i = 0; i < arr.length; i++) {
            if (arr[i].value === value) {
                id = arr[i].carrier_id;
            }
        }
        return id;
    };

    handleChange = (e, { value }) => {
        this.props.resetFilter();
        this.setState({ value });
        var selectedID = this.getCarrierID(this.props.flags.carriers, value);
        if (selectedID !== this.props.flags.curr_carrier_id) {
            this.props.onSetCarrierID(selectedID);
            API.GET(
                '/tanks/init',
                { carrier_id: selectedID },
                res => {
                    if (res.data != null) {
                        indexObjects(res.data);
                    }
                    this.props.onGetTanks(res.data);
                    //  если при запросе списка цистерн он оказался пустым, то установить curr_tank_id = 0, что прекратит регулярные по таймеру запросы текущих данных
                    if (!res.data) {
                        this.props.onSetTankID(0, '');
                    } else {
                        this.props.onSetTankID(
                            res.data[0].id,
                            res.data[0].name
                        );
                        //  далее запрашиваем для каждой цистерны данные отображенные на кнопках меню.
                        //  здесь запрос выполняется один раз, при этом таймер продолжает выполняться!
                        //  **даже если curr_data уже пришла, а список цистерн уже другой, то ничего страшного - id цистерн уникальны вне зависимости от перевозчика.
                        API.GET(
                            '/tanks/curr_data',
                            { carrier_id: this.props.flags.curr_carrier_id },
                            res => {
                                if (res.data) {
                                    this.props.onSetNoCurrData(false);
                                    this.props.onGetCurrData(res.data);
                                    this.props.onSetCurrLocation(
                                        res.data[0].lat,
                                        res.data[0].lng
                                    );
                                    this.props.onShowInfoWindow(false);
                                } else {
                                    this.props.onSetNoCurrData(true);
                                }
                            },
                            err =>
                                badReqHandler(
                                    err,
                                    ERR_BAD_REQUEST,
                                    this.props.onSetBadRequest
                                )
                        );
                    }
                },
                err =>
                    badReqHandler(
                        err,
                        ERR_BAD_REQUEST,
                        this.props.onSetBadRequest
                    )
            );
        }
    };

    componentWillReceiveProps(nextProps) {
        //  нужно для того чтобы при загрузке приложения в Dropdown выбора перевозчика отображался текущий выбранный перевозчик
        //  initValue нужен чтобы выполнить это один раз и все
        if (
            nextProps.flags.carriers &&
            nextProps.flags.carriers.length > 0 &&
            !this.state.initValue
        ) {
            this.setState({
                value: nextProps.flags.carriers[0].value,
                initValue: true
            });
        }
    }

    render() {
        const { value, mapIconWidth } = this.state;
        const { filterValue } = this.props;

        const isAllDataReady =
            this.props.carrier.utm_servers &&
            this.props.carrier.utm_servers.length > 0;

        var showMap = this.props.flags.show.map;
        var map_type = this.props.flags.show.map_type;
        var filterTop = showMap ? '38vh' : '5vh';

        return (
            <div className='tank-filter' style={{ top: filterTop,zIndex:2}}>
                {this.props.flags.role === ADMIN ? (
                    <Menu className='tank-filter-menu'>
                        <Menu.Item header>Выбор перевозчика:</Menu.Item>
                        <Menu.Item>
                            <Dropdown
                                onChange={this.handleChange}
                                value={value}
                                options={this.props.flags.carriers}
                                selection
                            />
                        </Menu.Item>
                        <Menu.Item header>Фильтр цистерн:</Menu.Item>
                        <Menu.Item>
                            <Input
                                className='icon'
                                icon='search'
                                placeholder='Поиск...'
                                value={filterValue}
                                onChange={this.props.handleChangeSearch}
                            />
                        </Menu.Item>
                        <Menu.Menu position='right'>
                            {showMap && (
                                <Menu.Item header>
                                    Выберите тип карты:
                                </Menu.Item>
                            )}
                            {showMap && (
                                <Menu.Item>
                                    <Dropdown
                                        onChange={this.handleChangeMapType}
                                        value={map_type}
                                        options={mapTypes}
                                        selection
                                    />
                                </Menu.Item>
                            )}
                            <Menu.Item style={{ width: mapIconWidth }}>
                                <MapShow
                                    showMap={showMap}
                                    onShowMap={this.props.onShowMap}
                                />
                            </Menu.Item>
                        </Menu.Menu>
                    </Menu>
                ) : (
                    <Menu className='tank-filter-menu'>
                        <Menu.Item header>Перевозчик:</Menu.Item>
                        <Menu.Item>{value}</Menu.Item>
                        <Menu.Item header>Фильтр цистерн:</Menu.Item>
                        <Menu.Item>
                            <Input
                                className='icon'
                                icon='search'
                                placeholder='Поиск...'
                                onChange={this.props.handleChangeSearch}
                            />
                        </Menu.Item>
                        {this.props.flags.feature === 1 && (
                            <Menu.Menu>
                                <Menu.Item header>
                                    УТМ перевозчика:&nbsp;&nbsp;&nbsp;
                                    <Icon
                                        id='utm-conn'
                                        name='circle'
                                        color={
                                            !this.props.carrier.utm_tracking
                                                ? 'grey'
                                                : isAllDataReady &&
                                                  this.props.carrier
                                                      .utm_servers[0].status ===
                                                      1
                                                ? 'red'
                                                : 'green'
                                        }
                                        size='large'
                                    />
                                </Menu.Item>
                            </Menu.Menu>
                        )}
                        <Menu.Menu position='right'>
                            {showMap && (
                                <Menu.Item header>
                                    Выберите тип карты:
                                </Menu.Item>
                            )}
                            {showMap && (
                                <Menu.Item>
                                    <Dropdown
                                        onChange={this.handleChangeMapType}
                                        value={map_type}
                                        options={mapTypes}
                                        selection
                                    />
                                </Menu.Item>
                            )}
                            <Menu.Item style={{ width: mapIconWidth }}>
                                <MapShow
                                    showMap={showMap}
                                    onShowMap={this.props.onShowMap}
                                />
                            </Menu.Item>
                        </Menu.Menu>
                    </Menu>
                )}
            </div>
        );
    }
}

const mapStateToProps = state => state;

const mapDispatchToProps = dispatch => {
    return {
        //  flags
        onSetCarrierID: id => {
            dispatch(setCarrierID({ curr_carrier_id: id }));
        },
        onShowMap: flag => {
            dispatch(showMap({ map: flag }));
        },
        onSetTankID: (id, name) => {
            dispatch(setTankID({ curr_tank_id: id, curr_tank_name: name }));
        },
        onSetBadRequest: flag => {
            dispatch(setBadRequest({ bad_req: flag }));
        },
        onSetNoCurrData: flag => {
            dispatch(setNoCurrData({ curr_data: flag }));
        },
        onSetCurrLocation: (lat, lng) => {
            dispatch(setCurrLocation({ lat: lat, lng: lng }));
        },
        onShowInfoWindow: flag => {
            dispatch(showInfoWindow({ info_window: !flag }));
        },
        onSetMapType: type => {
            dispatch(setMapType({ map_type: type }));
        },

        //  tanks
        onGetTanks: data => {
            dispatch(getTanks({ initial_tanks: data, filtered_tanks: data }));
        },
        onGetCurrData: data => {
            dispatch(getCurrData({ curr_data: data }));
        }
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(TankFilter);
