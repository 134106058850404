export const ERR_NO_MONEY = 'ERR_NO_MONEY';
export const ERR_INVALID_REFRESH_TOKEN = 'ERR_INVALID_REFRESH_TOKEN';
export const ERR_UNEXPECTED = 'ERR_UNEXPECTED';
export const ERR_WRONG_LOGIN = 'ERR_WRONG_LOGIN';
export const ERR_WRONG_PSWD = 'ERR_WRONG_PSWD';
export const ERR_BUSY_ACOOUNT = 'ERR_BUSY_ACOOUNT';
export const SUCCESS_AUTH_DATA = 'SUCCESS_AUTH_DATA';
export const ERR_NETWORK = 'Сервер недоступен';
export const ERR_INTERNAL_SERVER = 'Internal Server Error';
export const ERR_UNAUTHORIZED = '401';
export const ERR_LOGOUT = 'ERR_LOGOUT';
export const SUCCESS_LOGOUT = 'SUCCESS_LOGOUT';
export const ERR_SUSPECT_ACTIONS = 'ERR_SUSPECT_ACTIONS';
export const ERR_NETWORK_ERR = 'Network Error';
export const ERR_BAD_REQUEST = '400';
export const ERR_NULL_VAL = 'ERR_NULL_VAL';
