import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Segment } from 'semantic-ui-react';

import RangeDateSelector from '../DatetimeSelectors/RangeDateSelector';

import { date } from '../constants';

import {
    parseMoment,
    deepCopy,
    calculateDiv,
    splitInterval,
    comparingTimestamp,
    produceParkings
} from '../functions';

import { ERR_NULL_VAL } from '../errors';

import MaterialUIWrapper from '../MaterialUIWrapper';

import API from '../API';
import TripsTableData from '../Tables/TripsTableData';

import {
    showMap,
    showTanks,
    showTrack,
    getTrack,
    setFullTrack,
    getCapsule
} from '../actions/flags';

class Trips extends Component {
    _isMounted = false;

    state = {
        tripsData: [],
        popup: false,
        active_btn: undefined,
        loading_btn: undefined,
        disabled_btn: undefined
    };

    /* Данная функция запрашивает часть маршрута для части интервала и возвращает промис. */
    getPartOfTrack = async (id, part) => {
        return new Promise((resolve, reject) => {
            API.GET(
                '/tanks/' + id + '/track',
                { from: part.from, to: part.to },
                res => {
                    if (!res.data || (res.data && res.data === ERR_NULL_VAL)) {
                        reject(null);
                    } else {
                        resolve(res.data);
                    }
                }
            );
        });
    };

    /* Данная функция проверяет запрошенный маршрут, представляющий собой массив из его частей.
    Возвращает ложь, если по всем частям маршрута были ошибки при запросе. */
    checkResults = results => {
        if (results && results.length > 0) {
            //  просматриваем содержимое
            var k = 0;
            for (let i = 0; i < results.length; i++) {
                if (results[i].ok === false) k++;
            }
            if (k === results.length) {
                return false;
            } else {
                return true;
            }
        }
    };

    fail = error => {
        return { ok: false, error };
    };

    success = value => {
        return { ok: true, value };
    };

    handleClickShowTrip = async (idx, row) => {
        /* выставляем флаг признака завершенной перевозки в значение по умолчанию */
        this.props.onSetFullTrack(true);
        /* обнуляем предыдущий маршрут в памяти */
        this.props.onGetTrack(null);
        /* обнуляем предыдущий массив данных по отсекам */
        this.props.onGetCapsule([]);
        /* для безопасности копируем входные параметры функции */
        var param = deepCopy(row);
        /* Если нажали на уже нажатую кнопку "Показать маршрут", то сбрасываем все возможные флаги в значения по умолчанию. */
        if (this.state.active_btn === idx) {
            if (this._isMounted) {
                this.setState({
                    active_btn: undefined,
                    loading_btn: undefined
                });
            }
            this.props.onGetTrack(null);
            this.props.onGetCapsule([]);
            /* отображаем текущие положения цистерн */
            this.props.onShowTanks(true);
            /* а маршрут перестаем отображать */
            this.props.onShowTrack(false);
        } else {
            /* Если кнопка была неактивная, то начинаем проверки и выполнение функции. */
            //  на случай если вдруг начало интервала нулевое, присваиваем началу интервала значение конца интервала.
            //  чтобы результат был нулевым.
            if (param.start === '0001-01-01T00:00:00Z') {
                param.start = parseMoment(date.defaultTo, 'to');
            }
            //  проверка завершенной перевозки. Если конец интервала нулевое значение, то выставляем флаг незавершенной перевозки.
            if (param.end === '0001-01-01T00:00:00Z') {
                param.end = parseMoment(date.defaultTo, 'to');
                this.props.onSetFullTrack(false);
            }

            /* Делим интервал времени на части по 12 часов. Последняя часть как получится. Получаем массив интервалов. */
            var array = splitInterval(param);
            /* Для каждого интервала и массива выполняем функцию getPartOfTrack. Эти функции выполняются почти одновременно.
            Это сделано в целях уменьшения времени ожидания запрошенного маршрута. Когда запрашивали маршрут по всему интервалу,
            маршрут можно было ждать ооочень долго (в основном после 1 мин nginx уже откидывал этот запрос). Теперь при запросе 
            по частям, весь маршрут запрашивается не более чем за 15-20 сек, если общий интервал большой. */
            const promises = array.map(part =>
                this.getPartOfTrack(this.props.id, part)
            );

            if (this._isMounted) {
                this.setState({
                    active_btn: idx,
                    loading_btn: idx,
                    disabled_btn: -1
                });
            }

            /* здесь применяем Promise.all для того чтобы остановить выполнение функции до тех пор пока все части маршрута
            не придут с сервера. Формируем объект results содержащий результат по каждой части маршрута. */
            const results = await Promise.all(
                promises.map(p => p.then(this.success).catch(this.fail))
            );

            if (this._isMounted) {
                this.setState({
                    loading_btn: undefined,
                    disabled_btn: undefined
                });
            }

            /* проверяем результат.
            Если ложь, то отображаем сообщение, что данных по выбранной перевозке нет и т.д.
            Если истина, то обрабатываем результат для отображения. */
            if (this.checkResults(results)) {
                /* Если есть что отображать, то имеет смысл запросить данные по отсекам для данной перевозки. */
                API.GET(
                    '/tanks/' + this.props.id + '/capsule',
                    { from: param.start, to: param.end },
                    res => this.props.onGetCapsule(res),
                    null
                );

                /* собираем маршрут воедино из частей массива. */
                var track = [].concat.apply(
                    [],
                    results.map(t => (t.ok ? t.value : []))
                );
                //  сортируем полученный массив по времени по возрастанию
                track.sort(comparingTimestamp);

                /* Находим в маршруте стоянки: интервалы времени больше 7 мин и со скоростью меньше 5 км/ч.
                producedTrack это только стоянки! */
                var producedTrack = produceParkings(track);

                /* разреживаем маршрут (без стоянок) так, чтобы количество маркеров одновременно отображаемых
                на карте было таким, чтобы ничего не лагало на клиенте. */
                var div = calculateDiv(producedTrack.track.length);
                var trackForShow = [];
                for (let i = 0; i < track.length; i++) {
                    if (i % div === 0) {
                        trackForShow.push(track[i]);
                    }
                }

                /* объединяем с сортировкой по времени разреженный маршрут и стоянки.
                Выставляем флаги и отображаем на карте результирующий маршрут. */
                var data = {};
                data[this.props.id] = trackForShow
                    .concat(producedTrack.parkings)
                    .sort(comparingTimestamp);
                this.props.onGetTrack(data);
                this.props.onShowMap(true);
                this.props.onShowTanks(false);
                this.props.onShowTrack(true);
            } else {
                if (this._isMounted) {
                    this.setState({ popup: true });
                    setTimeout(() => {
                        this.setState({ active_btn: undefined, popup: false });
                    }, 2000);
                }
            }
        }
    };

    /* Данный метод запрашивает список перевозок. Алгоритм формирования списка на сервере довольно непростой. */
    getTripsData = (from, to) => {
        if (!this.props.tripsFetching) {
            API.GET(
                '/tanks/' + this.props.id + '/trips',
                { from: from, to: to },
                res => {
                    if (this._isMounted) {
                        if (!res.data) {
                            this.setState(
                                { tripsData: [] },
                                this.props.isFetching({ tripsFetching: false })
                            );
                        } else {
                            this.setState({ tripsData: res.data });
                            this.props.isFetching({ tripsFetching: false });
                        }
                    }
                }
            );
            this.props.isFetching({ tripsFetching: true });
        }
    };

    componentDidMount() {
        this._isMounted = true;
        this.getTripsData(
            parseMoment(date.defaultFrom, 'from'),
            parseMoment(date.defaultTo, 'to')
        );
    }

    /* При нажатии на "Подтвердить" все флаги обнуляются, ранее отображенный маршрут удаляется. И запрашивается новый список перевозок. */
    submitRangeDateSelector = param => {
        this.getTripsData(param.from, param.to);
        this.props.onGetTrack(null);
        this.props.onGetCapsule([]);
        this.props.onShowTanks(true);
        this.props.onShowTrack(false);
        this.setState({
            active_btn: undefined,
            loading_btn: undefined,
            disabled_btn: undefined,
            popup: false
        });
    };

    componentWillUnmount() {
        this.props.onGetTrack(null);
        this.props.onGetCapsule([]);
        this.props.onShowTanks(true);
        this.props.onShowTrack(false);
        this._isMounted = false;
    }

    render() {
        const {
            tripsData,
            popup,
            active_btn,
            loading_btn,
            disabled_btn
        } = this.state;
        var disabled = this.props.tripsFetching;
        return (
            <Segment basic className='trips' style={{ padding: '0px' }}>
                <MaterialUIWrapper>
                    <RangeDateSelector
                        submitRangeDateSelector={this.submitRangeDateSelector}
                        disabled={disabled}
                        textFrom='trip-from'
                        textTo='trip-to'
                    />
                </MaterialUIWrapper>
                <Segment
                    basic
                    loading={disabled}
                    textAlign='center'
                    className='trips-table'
                >
                    <TripsTableData
                        data={tripsData}
                        handleClickShowTrip={this.handleClickShowTrip}
                        active_btn={active_btn}
                        loading_btn={loading_btn}
                        disabled_btn={disabled_btn}
                        popup={popup}
                    />
                </Segment>
            </Segment>
        );
    }
}

const mapStateToProps = state => {
    return {
        flags: state.flags
    };
};

const mapDispatchToProps = dispatch => {
    return {
        onShowMap: flag => {
            dispatch(showMap({ map: flag }));
        },
        onShowTanks: flag => {
            dispatch(showTanks({ tanks: flag }));
        },
        onShowTrack: flag => {
            dispatch(showTrack({ track: flag }));
        },
        onGetTrack: data => {
            dispatch(getTrack({ track: data }));
        },
        onSetFullTrack: flag => {
            dispatch(setFullTrack({ full_track: flag }));
        },
        onGetCapsule: res => {
            dispatch(getCapsule({ capsule: res.data }));
        }
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Trips);
