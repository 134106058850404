import { create_action_creator } from '../defaultAction';

export const GET_UTM_SERVERS = 'GET_UTM_SERVERS';
export const getUtmServers = create_action_creator(GET_UTM_SERVERS);

export const GET_CARRIER_INFO = 'GET_CARRIER_INFO';
export const getCarrierInfo = create_action_creator(GET_CARRIER_INFO);

export const GET_SHIPPER_INFO = 'GET_SHIPPER_INFO';
export const getShipperInfo = create_action_creator(GET_SHIPPER_INFO);

export const GET_CONSIGNEE_INFO = 'GET_CONSIGNEE_INFO';
export const getConsigneeInfo = create_action_creator(GET_CONSIGNEE_INFO);

export const GET_SYNC_HISTORY = 'GET_SYNC_HISTORY';
export const getSyncHistory = create_action_creator(GET_SYNC_HISTORY);

export const ADD_ORG_ID_LOADING = 'ADD_ORG_ID_LOADING';
export const addOrgIDLoading = create_action_creator(ADD_ORG_ID_LOADING);

export const DEL_ORG_ID_LOADING = 'DEL_ORG_ID_LOADING';
export const delOrgIDLoading = create_action_creator(DEL_ORG_ID_LOADING);

export const WRITE_RESPONSE = 'WRITE_RESPONSE';
export const writeResponse = create_action_creator(WRITE_RESPONSE);

export const CLOSE_RESPONSE = 'CLOSE_RESPONSE';
export const closeResponse = create_action_creator(CLOSE_RESPONSE);

export const CHANGE_ORG_WAITING = 'CHANGE_ORG_WAITING';
export const changeOrgWaiting = create_action_creator(CHANGE_ORG_WAITING);

export const CHANGE_FIRST_QUERY = 'CHANGE_FIRST_QUERY';
export const changeFirstQuery = create_action_creator(CHANGE_FIRST_QUERY);

export const CHANGE_UTM_TRACK = 'CHANGE_UTM_TRACK';
export const changeUtmTrack = create_action_creator(CHANGE_UTM_TRACK);

export const SET_SSH_PASS = 'SET_SSH_PASS';
export const setSSHPass = create_action_creator(SET_SSH_PASS);

export const RESET_CARRIER = 'RESET_CARRIER';
export const resetCarrier = create_action_creator(RESET_CARRIER);

export const GET_PRODUCTS = 'GET_PRODUCTS';
export const getProducts = create_action_creator(GET_PRODUCTS);

export const GET_NOTICE_HISTORY = 'GET_NOTICE_HISTORY';
export const getNoticeHistory = create_action_creator(GET_NOTICE_HISTORY);

export const SET_NOTICE_PROCESSING = 'SET_NOTICE_PROCESSING';
export const setNoticeProcessing = create_action_creator(SET_NOTICE_PROCESSING);

export const UNSET_NOTICE_PROCESSING = 'UNSET_NOTICE_PROCESSING';
export const unsetNoticeProcessing = create_action_creator(
    UNSET_NOTICE_PROCESSING
);

export const ADD_TANK_ID_SYNC_LOADING = 'ADD_TANK_ID_SYNC_LOADING';
export const addTankIDSyncLoading = create_action_creator(
    ADD_TANK_ID_SYNC_LOADING
);

export const DEL_TANK_ID_SYNC_LOADING = 'DEL_TANK_ID_SYNC_LOADING';
export const delTankIDSyncLoading = create_action_creator(
    DEL_TANK_ID_SYNC_LOADING
);

export const GET_PRODUCER_INFO = 'GET_PRODUCER_INFO';
export const getProducerInfo = create_action_creator(GET_PRODUCER_INFO);
