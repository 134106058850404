import React, { Component } from 'react';
import { Segment } from 'semantic-ui-react';

import DateSelector from '../DatetimeSelectors/DateSelector';

import { date } from '../constants';

import { parseMoment } from '../functions';

import MaterialUIWrapper from '../MaterialUIWrapper';

import TableAsiiuTimeTasks from '../Tables/TableAsiiuTimeTasks';
import API from '../API';

class FiveMinFiles extends Component {
    state = {
        asiiuTimeData: []
    };

    getAsiiuTimeData = date => {
        if (!this.props.fiveMinFetching) {
            API.GET(
                '/tanks/' + this.props.id + '/asiiutime',
                { timestamp: date },
                res => {
                    if (!res.data) {
                        this.setState(
                            { asiiuTimeData: [] },
                            this.props.isFetching({ fiveMinFetching: false })
                        );
                    } else {
                        this.setState({ asiiuTimeData: res.data });
                        this.props.isFetching({ fiveMinFetching: false });
                    }
                }
            );
            this.props.isFetching({ fiveMinFetching: true });
        }
    };

    componentDidMount() {
        this.getAsiiuTimeData(parseMoment(date.defaultCurrDate, 'date'));
    }

    submitDateSelector = param => {
        this.getAsiiuTimeData(param);
    };

    render() {
        const { asiiuTimeData } = this.state;
        var disabled = this.props.fiveMinFetching;
        return (
            <Segment basic className='files' style={{ padding: '0px' }}>
                <MaterialUIWrapper>
                    <DateSelector
                        submitDateSelector={this.submitDateSelector}
                        disabled={disabled}
                        text='five'
                    />
                </MaterialUIWrapper>
                <Segment
                    basic
                    loading={disabled}
                    textAlign='center'
                    className='files-table'
                >
                    <TableAsiiuTimeTasks
                        data={asiiuTimeData}
                        flag='asiiutime'
                    />
                </Segment>
            </Segment>
        );
    }
}

export default FiveMinFiles;
