import React, { Component } from 'react';
import { Segment } from 'semantic-ui-react';

import RangeDateSelector from '../DatetimeSelectors/RangeDateSelector';

import { date } from '../constants';

import { parseMoment } from '../functions';

import MaterialUIWrapper from '../MaterialUIWrapper';

import TableAsiiuTasks from '../Tables/TableAsiiuTasks';
import API from '../API';

class DayFiles extends Component {
    state = {
        dailyFiles: []
    };

    getDailyFiles = (from, to) => {
        if (!this.props.dayFetching) {
            API.GET(
                '/tanks/' + this.props.id + '/asiiu',
                { from: from, to: to },
                res => {
                    if (!res.data) {
                        this.setState(
                            { dailyFiles: [] },
                            this.props.isFetching({ dayFetching: false })
                        );
                    } else {
                        this.setState({ dailyFiles: res.data });
                        this.props.isFetching({ dayFetching: false });
                    }
                }
            );
            this.props.isFetching({ dayFetching: true });
        }
    };

    componentDidMount() {
        this.getDailyFiles(
            parseMoment(date.defaultFrom, 'from'),
            parseMoment(date.defaultTo, 'to')
        );
    }

    submitRangeDateSelector = param => {
        this.getDailyFiles(param.from, param.to);
    };

    render() {
        const { dailyFiles } = this.state;
        var disabled = this.props.dayFetching;
        return (
            <Segment basic className='files' style={{ padding: '0px' }}>
                <MaterialUIWrapper>
                    <RangeDateSelector
                        submitRangeDateSelector={this.submitRangeDateSelector}
                        disabled={disabled}
                        textFrom='day-from'
                        textTo='day-to'
                    />
                </MaterialUIWrapper>
                <Segment
                    basic
                    loading={disabled}
                    textAlign='center'
                    className='files-table'
                >
                    <TableAsiiuTasks data={dailyFiles} />
                </Segment>
            </Segment>
        );
    }
}

export default DayFiles;
