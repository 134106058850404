import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Header, Icon, Button, Modal } from 'semantic-ui-react';

import { closeResponse } from '../actions/carrier';

export const WAYBILL_SUCCESS_DOWNLOAD =
    'Печатная форма фиксации уведомления о перевозке в ЕГАИС успешно выгружена';
export const WRONG_PSWD_ON_NOTICE = 'WRONG_PSWD_ON_NOTICE';
export const WRONG_PASSWORD = 'Неверный пароль';

const ERR_LOST_CONNECTION = 'ERR_LOST_CONNECTION';
const ERR_TIMEOUT = 'ERR_TIMEOUT';
const ERR_GET_TANK_CATALOG = 'ERR_GET_TANK_CATALOG';
const ERR_PART_SYNC_DONE = 'ERR_PART_SYNC_DONE';
const SUCCESS_SYNC_PRODUCTS =
    'Синхронизация справочника продуктов ЕГАИС выполнена успешно!';
const ERR_UTM_WORKER = 'ERR_UTM_WORKER';
const SEND_CANCELED = 'Отправка отменена';

const utmWorkerErr = 'Отслеживание связи с УТМ прервано';
const lostConnection = 'Потеряно соединение';
const timeout = 'Истекло время ожидания';

/* 'ReplyID получен успешно' должна быть первой в этом массиве! */
const goodRes = [
    'ReplyID получен успешно',
    'Тикет получен успешно',
    'Успешное получение информации об организации',
    'Путевой лист успешно сгенерирован!',
    'Синхронизация данных о цистерне выполнена успешно!',
    'Синхронизация справочника продуктов ЕГАИС выполнена успешно!',
    WAYBILL_SUCCESS_DOWNLOAD
];

const warnRes = [
    ERR_LOST_CONNECTION,
    ERR_TIMEOUT,
    ERR_GET_TANK_CATALOG,
    ERR_PART_SYNC_DONE
];

/* Данный компонент используется при получении ответов от сервера при работе в Панеле управления перевозчика.
Кривовато конечно все.
goodRes и warnRes нужны для цвета сообщения (зеленый, желтый), иначе красный.

Если мы добавляем новую функцию и хотим отобразить ответ сервера на клиенте, то в этом файле можно создать константу,
представляющую ответ сервера, и ниже в функции render прописать текст сообщения для клиента. */
class ResponseModal extends Component {
    close = () => {
        this.props.onCloseResponse();
    };

    render() {
        const { show } = this.props;
        var msgPos = false;
        var msgNeg = false;
        var msgWarn = false;
        if (
            goodRes.includes(show.response) ||
            show.response.includes('Принято')
        ) {
            msgPos = true;
        } else if (warnRes.includes(show.response)) {
            msgWarn = true;
        } else {
            msgNeg = true;
        }
        return (
            <Modal
                size='tiny'
                open={show.open}
                onClose={this.closeModal}
                dimmer='blurring'
            >
                <Header
                    icon={
                        <Icon
                            color={
                                msgNeg
                                    ? 'red'
                                    : msgPos
                                    ? 'green'
                                    : msgWarn
                                    ? 'yellow'
                                    : 'red'
                            }
                            name={
                                msgNeg
                                    ? 'close'
                                    : msgPos
                                    ? 'check'
                                    : msgWarn
                                    ? 'warning'
                                    : 'close'
                            }
                        />
                    }
                    content={
                        show.response === ERR_UTM_WORKER
                            ? utmWorkerErr
                            : show.response === WRONG_PSWD_ON_NOTICE
                            ? WRONG_PASSWORD
                            : show.response === ERR_LOST_CONNECTION
                            ? lostConnection
                            : show.response === ERR_TIMEOUT
                            ? timeout
                            : show.response === ERR_GET_TANK_CATALOG
                            ? 'Ошибка при синхронизации'
                            : show.response === ERR_PART_SYNC_DONE
                            ? 'Ошибка при синхронизации'
                            : show.response
                    }
                />
                {show.response === goodRes[0] && ( //  если (9, 202)
                    <Modal.Content>
                        <p>
                            Ответ на запрос еще не пришел в УТМ за заданный
                            лимит времени. Для повторного запроса ответа,
                            перейдите во вкладку "История синхронизаций" и
                            нажмите <Icon name='sync alternate' />
                        </p>
                    </Modal.Content>
                )}
                {show.response === WRONG_PSWD_ON_NOTICE && (
                    <Modal.Content>
                        <p>
                            Для того, чтобы переотправить уже созданное
                            уведомление, воспользуйтесь вкладкой "История
                            уведомлений". Найдите в списке созданное уведомление
                            и нажмите <Icon name='telegram plane' />
                        </p>
                    </Modal.Content>
                )}
                {show.response === SEND_CANCELED && (
                    <Modal.Content>
                        <p>
                            Отмененное к отправке уведомление можно отправить
                            позднее, воспользовавшись вкладкой "История
                            уведомлений". Найдите в списке созданное уведомление
                            и нажмите <Icon name='telegram plane' />
                        </p>
                    </Modal.Content>
                )}
                {show.response === ERR_LOST_CONNECTION && (
                    <Modal.Content>
                        <p>
                            Потеряно соединение с цистерной. Если бортовой
                            контроллер не был выключен, то запущенная
                            синхронизация продолжает свое выполнение. При
                            появлении связи с цистерной статус синхронизации
                            обновится. Можете следить за процессом выполнения в
                            истории команд синхронизаций.
                        </p>
                    </Modal.Content>
                )}
                {show.response === ERR_TIMEOUT && (
                    <Modal.Content>
                        <p>
                            Истекло время ожидания окончания синхронизации.
                            Возможно, синхронизация завершится позднее.
                            Рекомендуем запустить синхронизацию заново, если
                            спустя 5 минут статус синхронизации в истории команд
                            синхронизаций не изменится на успешный. P.S. Если
                            проводилась синхронизация продуктов и если после
                            истечения времени ожидания синхронизация выполнилась
                            успешно, то тем не менее для полного завершения
                            операции необходимо нажать на{' '}
                            <Icon name='exchange' />.
                        </p>
                    </Modal.Content>
                )}
                {show.response === ERR_GET_TANK_CATALOG && (
                    <Modal.Content>
                        <p>
                            Не удалось запросить справочник продуктов ЕГАИС с
                            цистерны для дополнения общего справочника. Для
                            завершения синхронизации найдите в истории команд
                            синхронизаций запущенную Вами и нажмите{' '}
                            <Icon name='exchange' />
                        </p>
                    </Modal.Content>
                )}
                {show.response === ERR_PART_SYNC_DONE && (
                    <Modal.Content>
                        <p>
                            Синхронизация продуктов прошла частично. Если
                            нужного Вам продукта после проведенной синхронизации
                            не оказалось в общем справочнике ЕГАИС, то в истории
                            команд синхронизаций можно нажать на значок{' '}
                            <Icon name='exchange' />, что заново запустит
                            операцию объединения справочника продуктов цистерны
                            и общего справочника ЕГАИС.
                        </p>
                    </Modal.Content>
                )}
                {show.response === SUCCESS_SYNC_PRODUCTS && (
                    <Modal.Content>
                        <p>
                            Если нужного Вам продукта после успешно проведенной
                            синхронизации не оказалось в общем справочнике
                            ЕГАИС, то вероятнее всего данный продукт еще не
                            заведен в системе ЕГАИС. В таком случае, следует
                            обратиться в МРУ.
                        </p>
                    </Modal.Content>
                )}
                <Modal.Actions>
                    <Button negative onClick={this.close}>
                        Закрыть
                    </Button>
                </Modal.Actions>
            </Modal>
        );
    }
}

const mapStateToProps = state => {
    return {
        show: state.carrier.show
    };
};

const mapDispatchToProps = dispatch => {
    return {
        onCloseResponse: () => {
            dispatch(closeResponse({ open: false, response: '' }));
        }
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ResponseModal);
