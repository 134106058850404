import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Table, Segment, Icon, Modal, Form, Button } from 'semantic-ui-react';

import API from '../API';
import NoData from './NoData';

import { ADMIN, WAIT_SYNC_TICKET } from '../constants';

import { writeResponse, changeOrgWaiting } from '../actions/carrier';

const TableColumns = {
    cmd_type: 'Тип',
    reg_id: 'ФСРАР ID',
    dateinsert: 'Время создания',
    description_status: 'Статус',
    description_error: 'Ошибка',
    replyid: 'ReplyID'
};

class SyncHistory extends Component {
    constructor() {
        super();
        this.type = WAIT_SYNC_TICKET;
    }

    state = {
        inputPass: false,
        pass: '',
        passErr: false,
        replyid: '',
        type: 0
    };

    handleChange = (e, { name, value }) => this.setState({ [name]: value });

    closeGetAccess = () => this.setState({ inputPass: false, pass: '' });

    checkPasswordIsEmpty = (pass, key) => {
        if (pass === '') {
            this.setState({ [key]: true });
            setTimeout(() => this.setState({ [key]: false }), 1000);
            return false;
        }
        return true;
    };

    getAccess = (str, type) => {
        const replyid = str;
        //  отображаем модал для ввода пароля
        this.setState({ inputPass: true, replyid: replyid, type: type });
    };

    syncOrg = () => {
        if (!this.checkPasswordIsEmpty(this.state.pass, 'passErr')) {
            return;
        }
        const org_id = this.state.org_id;
        const replyid = this.state.replyid;
        const cmd_type = this.state.type;

        var data = {
            org_id: org_id,
            new_pass: this.state.pass,
            replyid: replyid
        };

        var key;
        switch (cmd_type) {
            case 1:
                key = 'carrier_wait';
                break;
            case 2:
                key = 'shipper_wait';
                break;
            case 3:
                key = 'consignee_wait';
                break;
            case 20:
                key = 'producer_wait';
                break;
            default:
                return;
        }

        this.props.onChangeOrgWaiting(key, true);

        API.POST('/carrier/' + this.type + '/sync_org', data, res => {
            this.props.onChangeOrgWaiting(key, false);
            this.props.onWriteResponse(res.data);
        });
        this.setState({ inputPass: false, pass: '' });
    };

    render() {
        const { history, role, show } = this.props;
        const { inputPass, pass, passErr } = this.state;
        const isAllDataReady = history && history.length > 0;
        var text = 'Синхронизации еще не проводились';
        const carrier_wait = show.carrier_wait;
        const shipper_wait = show.shipper_wait;
        const consignee_wait = show.consignee_wait;
        const producer_wait = show.producer_wait;
        const carrier_1_query = show.carrier_1_query;
        const shipper_1_query = show.shipper_1_query;
        const consignee_1_query = show.consignee_1_query;
        const producer_1_query = show.producer_1_query;
        return (
            <div className='sync-history'>
                {!isAllDataReady ? (
                    <NoData text={text} />
                ) : (
                    <Segment basic style={{ padding: '0px' }}>
                        <Table collapsing celled textAlign='center'>
                            <Table.Header>
                                <Table.Row>
                                    <Table.HeaderCell key={1}>
                                        {TableColumns['cmd_type']}
                                    </Table.HeaderCell>
                                    <Table.HeaderCell key={2}>
                                        {TableColumns['reg_id']}
                                    </Table.HeaderCell>
                                    <Table.HeaderCell key={3}>
                                        {TableColumns['dateinsert']}
                                    </Table.HeaderCell>
                                    <Table.HeaderCell key={4}>
                                        {TableColumns['description_status']}
                                    </Table.HeaderCell>
                                    <Table.HeaderCell key={5}>
                                        {TableColumns['description_error']}
                                    </Table.HeaderCell>
                                    <Table.HeaderCell key={6}>
                                        {TableColumns['replyid']}
                                    </Table.HeaderCell>
                                    <Table.HeaderCell key={7}>
                                        Ответ
                                    </Table.HeaderCell>
                                </Table.Row>
                            </Table.Header>
                            <Table.Body>
                                {history.map((row, i) => {
                                    let wait =
                                        row.cmd_type === 1
                                            ? carrier_wait
                                            : row.cmd_type === 2
                                            ? shipper_wait
                                            : row.cmd_type === 3
                                            ? consignee_wait
                                            : producer_wait;
                                    let first_query =
                                        row.cmd_type === 1
                                            ? carrier_1_query
                                            : row.cmd_type === 2
                                            ? shipper_1_query
                                            : row.cmd_type === 3
                                            ? consignee_1_query
                                            : producer_1_query;
                                    return (
                                        <Table.Row key={i}>
                                            <Table.Cell key={1}>
                                                {row['cmd_type'] === 1
                                                    ? 'Перевозчик'
                                                    : row['cmd_type'] === 2
                                                    ? 'Отправитель'
                                                    : row['cmd_type'] === 3
                                                    ? 'Получатель'
                                                    : 'Производитель'}
                                            </Table.Cell>
                                            <Table.Cell key={2}>
                                                {row['reg_id']}
                                            </Table.Cell>
                                            <Table.Cell key={3}>
                                                {row['dateinsert']
                                                    .replace('T', ' ')
                                                    .replace('Z', '')}
                                            </Table.Cell>
                                            <Table.Cell key={4}>
                                                {row['description_status']}
                                            </Table.Cell>
                                            <Table.Cell key={5}>
                                                {row['description_error']}
                                            </Table.Cell>
                                            <Table.Cell key={6}>
                                                {row['replyid']}
                                            </Table.Cell>
                                            {role !== ADMIN ? (
                                                <Table.Cell key={7}>
                                                    {row['status'] === 10 &&
                                                    row['error'] === 116 ? (
                                                        <Icon
                                                            color='green'
                                                            name='checkmark'
                                                            size='large'
                                                        />
                                                    ) : row['status'] === 9 &&
                                                      row['error'] === 202 ? (
                                                        <Icon
                                                            link={
                                                                !wait &&
                                                                !first_query
                                                            }
                                                            disabled={
                                                                wait ||
                                                                first_query
                                                            }
                                                            loading={wait}
                                                            color='blue'
                                                            name='sync alternate'
                                                            size='large'
                                                            onClick={() =>
                                                                !wait &&
                                                                !first_query &&
                                                                this.getAccess(
                                                                    row.replyid,
                                                                    row.cmd_type
                                                                )
                                                            }
                                                        />
                                                    ) : row['status'] === 8 &&
                                                      (row['error'] === 0 ||
                                                          row['error'] ===
                                                              106) ? null : (
                                                        <Icon
                                                            color='red'
                                                            name='close'
                                                            size='large'
                                                        />
                                                    )}
                                                </Table.Cell>
                                            ) : (
                                                <Table.Cell key={7}>
                                                    {row['status'] === 10 &&
                                                    row['error'] === 116 ? (
                                                        <Icon
                                                            color='green'
                                                            name='checkmark'
                                                            size='large'
                                                        />
                                                    ) : row['status'] === 9 &&
                                                      row['error'] === 202 ? (
                                                        <Icon
                                                            color='blue'
                                                            name='sync alternate'
                                                            size='large'
                                                        />
                                                    ) : row['status'] === 8 &&
                                                      (row['error'] === 0 ||
                                                          row['error'] ===
                                                              106) ? null : (
                                                        <Icon
                                                            color='red'
                                                            name='close'
                                                            size='large'
                                                        />
                                                    )}
                                                </Table.Cell>
                                            )}
                                        </Table.Row>
                                    );
                                })}
                            </Table.Body>
                        </Table>
                    </Segment>
                )}
                <Modal
                    size='tiny'
                    open={inputPass}
                    onClose={this.close}
                    closeOnEscape={false}
                    closeOnDimmerClick={false}
                >
                    <Modal.Header>Введите пароль доступа к УТМ</Modal.Header>
                    <Modal.Content>
                        <h4>Данная операция может занять от 3 до 5 минут.</h4>
                        <Form>
                            <Form.Group>
                                <Form.Input
                                    type='password'
                                    placeholder='Введите пароль'
                                    name='pass'
                                    value={pass}
                                    onChange={this.handleChange}
                                    error={passErr}
                                />
                            </Form.Group>
                        </Form>
                    </Modal.Content>
                    <Modal.Actions>
                        <Button
                            positive
                            content='Подтвердить'
                            onClick={this.syncOrg}
                        />
                        <Button negative onClick={this.closeGetAccess}>
                            Закрыть
                        </Button>
                    </Modal.Actions>
                </Modal>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        show: state.carrier.show
    };
};

const mapDispatchToProps = dispatch => {
    return {
        onWriteResponse: res => {
            dispatch(writeResponse({ open: true, response: res }));
        },
        onChangeOrgWaiting: (key, flag) =>
            dispatch(changeOrgWaiting({ [key]: flag }))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(SyncHistory);
