import React, { Component } from 'react';
import { Tab, Segment } from 'semantic-ui-react';

import { ADMIN, CARRIER, SHIPPER, CONSIGNEE, PRODUCER } from '../constants';

import InfoUtm from './InfoUtm';
import OrgsInfo from './OrgsInfo';
import SyncHistory from './SyncHistory';
import SendNotices from './SendNotices';
import NoticeHistory from './NoticeHistory';

/* константы CARRIER, SHIPPER, CONSIGNEE, PRODUCER и др. объявленные в файле constants.js влияют на многое дальше.
Например, на компонент OrgsInfo (на его содержимое).
но самое ВАЖНОЕ!!! эти константы совпадают с константами объявленными в сервисе utm-worker. По сути это команды к этому сервису. */
const Data = ({ c, role }) => {
    const blockUtmInfo = (
        <Segment basic className='data-block-carrier'>
            <InfoUtm servers={c.utm_servers} role={role} />
        </Segment>
    );
    const blockCarrierInfo = (
        <Segment basic className='data-block-carrier'>
            <OrgsInfo orgs={c.carriers} role={role} type={CARRIER} />
        </Segment>
    );
    const blockShipperInfo = (
        <Segment basic className='data-block-carrier'>
            <OrgsInfo orgs={c.shippers} role={role} type={SHIPPER} />
        </Segment>
    );
    const blockConsigneeInfo = (
        <Segment basic className='data-block-carrier'>
            <OrgsInfo orgs={c.consignees} role={role} type={CONSIGNEE} />
        </Segment>
    );
    const blockProducerInfo = (
        <Segment basic className='data-block-carrier'>
            <OrgsInfo orgs={c.producers} role={role} type={PRODUCER} />
        </Segment>
    );
    const blockSyncHistory = (
        <Segment basic className='data-block-carrier'>
            <SyncHistory history={c.sync_history} role={role} />
        </Segment>
    );
    const blockSendNotices = (
        <Segment basic className='data-block-carrier'>
            <SendNotices />
        </Segment>
    );
    const blockNoticeHistory = (
        <Segment basic className='data-block-notice-history'>
            <NoticeHistory history={c.notice_history} role={role} />
        </Segment>
    );

    const carrierNotices = [
        {
            menuItem: 'История уведомлений',
            render: () => (
                <Tab.Pane className='notice-history'>
                    {blockNoticeHistory}
                </Tab.Pane>
            )
        },
        {
            menuItem: 'Отправка уведомления',
            render: () => (
                <Tab.Pane className='send-notice'>{blockSendNotices}</Tab.Pane>
            )
        }
    ];

    const contents = [
        {
            menuItem: 'Информация об УТМ',
            render: () => (
                <Tab.Pane className='utm-info'>{blockUtmInfo}</Tab.Pane>
            )
        },
        {
            menuItem: 'Перевозчик',
            render: () => (
                <Tab.Pane className='carrier-info'>{blockCarrierInfo}</Tab.Pane>
            )
        },
        {
            menuItem: 'Отправители',
            render: () => (
                <Tab.Pane className='shipper-info'>{blockShipperInfo}</Tab.Pane>
            )
        },
        {
            menuItem: 'Получатели',
            render: () => (
                <Tab.Pane className='consignee-info'>
                    {blockConsigneeInfo}
                </Tab.Pane>
            )
        },
        {
            menuItem: 'Производители',
            render: () => (
                <Tab.Pane className='producer-info'>
                    {blockProducerInfo}
                </Tab.Pane>
            )
        },
        {
            menuItem: 'История синхронизаций',
            render: () => (
                <Tab.Pane className='sync-history'>{blockSyncHistory}</Tab.Pane>
            )
        },
        {
            menuItem: 'Уведомления о перевозках',
            render: () => (
                <Tab.Pane className='carrier-notices'>
                    <Tab
                        className='carrier-notices-tab'
                        panes={carrierNotices}
                    />
                </Tab.Pane>
            )
        }
    ];

    const contentsAdmin = [
        {
            menuItem: 'Информация об УТМ',
            render: () => (
                <Tab.Pane className='utm-info'>{blockUtmInfo}</Tab.Pane>
            )
        },
        {
            menuItem: 'Перевозчики',
            render: () => (
                <Tab.Pane className='carrier-info'>{blockCarrierInfo}</Tab.Pane>
            )
        },
        {
            menuItem: 'История синхронизаций',
            render: () => (
                <Tab.Pane className='sync-history'>{blockSyncHistory}</Tab.Pane>
            )
        },
        {
            menuItem: 'История уведомлений',
            render: () => (
                <Tab.Pane style={{ border: '0' }} className='notice-history'>
                    {blockNoticeHistory}
                </Tab.Pane>
            )
        }
    ];

    var panes = role === ADMIN ? contentsAdmin : contents;

    return <Tab className='carrier-tab' panes={panes} />;
};

/* Данный компонент почти аналогичен компоненту components\TankList\AllData.js */
class AllData extends Component {
    render() {
        const { carrier, role } = this.props;
        return (
            <div className='carrier-all-data'>
                <Data c={carrier} role={role} />
            </div>
        );
    }
}

export default AllData;
