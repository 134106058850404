import React, { Component } from 'react';
import { Tab, Segment } from 'semantic-ui-react';

import Users from './Users';

const Data = ({ data }) => {
    const blockUsers = (
        <Segment basic className='data-block-admin'>
            <Users users={data.users} carriers={data.carriers} />
        </Segment>
    );
    const blockCarriers = (
        <Segment basic className='data-block-admin'>
            {/* <Carriers orgs={c.carriers}/> */}
        </Segment>
    );
    const blockTanks = (
        <Segment basic className='data-block-admin'>
            {/* <Tanks orgs={c.shippers}/> */}
        </Segment>
    );

    const contents = [
        {
            menuItem: 'Пользователи',
            render: () => (
                <Tab.Pane className='users-info'>{blockUsers}</Tab.Pane>
            )
        },
        {
            menuItem: 'Перевозчики',
            render: () => (
                <Tab.Pane className='carriers-info'>{blockCarriers}</Tab.Pane>
            )
        },
        {
            menuItem: 'Цистерны',
            render: () => (
                <Tab.Pane className='tanks-info'>{blockTanks}</Tab.Pane>
            )
        }
    ];

    return <Tab className='admin-tab' panes={contents} />;
};

class AllData extends Component {
    render() {
        const { data } = this.props;
        return (
            <div className='admin-all-data'>
                <Data data={data} />
            </div>
        );
    }
}

export default AllData;
