import React from 'react';

import { Table, Segment } from 'semantic-ui-react';

import { lineNameBoth, lineNameIN, lineNameOUT } from '../constants';

const StateIO = ({ state, modeName, mode }) => {
    if (!state) return <Segment basic loading></Segment>;

    var border =
        mode === 5
            ? ''
            : mode === 7 && modeName === lineNameIN
            ? '3px solid #3B83C0'
            : mode === 9 && modeName === lineNameOUT
            ? '3px solid #3B83C0'
            : modeName === lineNameBoth
            ? '3px solid #3B83C0'
            : '';
    return (
        <Table compact size='14px' style={{ border: border }}>
            <Table.Header>
                <Table.Row>
                    <Table.HeaderCell textAlign='center' colSpan='2'>
                        {modeName}
                    </Table.HeaderCell>
                </Table.Row>
            </Table.Header>
            <Table.Body>
                <Table.Row>
                    <Table.Cell collapsing>Начало сессии:</Table.Cell>
                    <Table.Cell collapsing>
                        {state.session_start.replace('T', ' ').replace('Z', '')}
                    </Table.Cell>
                </Table.Row>
                <Table.Row>
                    <Table.Cell collapsing>Код АП:</Table.Cell>
                    <Table.Cell collapsing>
                        {state.product_id.String === ''
                            ? '—'
                            : state.product_id.String}
                    </Table.Cell>
                </Table.Row>
                <Table.Row>
                    <Table.Cell collapsing>Расход, кг/ч * 1000:</Table.Cell>
                    <Table.Cell collapsing>
                        {state.mass_flow !== 0
                            ? state.mass_flow.toFixed(3)
                            : state.mass_flow}
                    </Table.Cell>
                </Table.Row>
                <Table.Row>
                    <Table.Cell collapsing>Крепость, %:</Table.Cell>
                    <Table.Cell collapsing>
                        {state.alcohol !== 0
                            ? state.alcohol.toFixed(2)
                            : state.alcohol}
                    </Table.Cell>
                </Table.Row>
                <Table.Row>
                    <Table.Cell collapsing>Плотность, кг/м3:</Table.Cell>
                    <Table.Cell collapsing>
                        {state.density !== 0 && state.density !== 1
                            ? state.density.toFixed(3)
                            : state.density}
                    </Table.Cell>
                </Table.Row>
                <Table.Row>
                    <Table.Cell collapsing>Температура, °C:</Table.Cell>
                    <Table.Cell collapsing>
                        {state.temperature.toFixed(2)}
                    </Table.Cell>
                </Table.Row>
                <Table.Row>
                    <Table.Cell collapsing>Объем, дал:</Table.Cell>
                    <Table.Cell collapsing>
                        {state.abs_volume !== 0
                            ? state.abs_volume.toFixed(2)
                            : state.abs_volume}
                    </Table.Cell>
                </Table.Row>
                <Table.Row>
                    <Table.Cell collapsing>
                        Объем водный при 20°C, дал:
                    </Table.Cell>
                    <Table.Cell collapsing>
                        {state.abs_volume_20 !== 0
                            ? state.abs_volume_20.toFixed(2)
                            : state.abs_volume_20}
                    </Table.Cell>
                </Table.Row>
                <Table.Row>
                    <Table.Cell collapsing>
                        Объем безводный при 20°C, дал:
                    </Table.Cell>
                    <Table.Cell collapsing>
                        {state.alco_volume_20 !== 0
                            ? state.alco_volume_20.toFixed(2)
                            : state.alco_volume_20}
                    </Table.Cell>
                </Table.Row>
                <Table.Row>
                    <Table.Cell collapsing>Последнее считывание:</Table.Cell>
                    <Table.Cell collapsing>
                        {state.last_read_massflow
                            .replace('T', ' ')
                            .replace('Z', '')}
                    </Table.Cell>
                </Table.Row>
            </Table.Body>
        </Table>
    );
};

export default StateIO;
