import React, { Component } from 'react';
import { Segment, Label, Grid, Icon } from 'semantic-ui-react';

import StatusOfModules from './StatusOfModules';

import gsm1 from '../../images/devices/network_1.png';
import gsm2 from '../../images/devices/network_2.png';
import gsm3 from '../../images/devices/network_3.png';
import gsmErr from '../../images/devices/network_error.png';
import gsmFull from '../../images/devices/network_full.png';
import gsmWarn from '../../images/devices/network_warning_orange.png';
import gpsOk from '../../images/devices/gps_blue.png';
import gpsErr from '../../images/devices/gps_red.png';
import gpsWarn from '../../images/devices/gps_warning_orange.png';
import levelOk from '../../images/devices/level_blue.png';
import levelErr from '../../images/devices/level_red.png';
import levelWarn from '../../images/devices/level_warning_orange.png';
import massflowOk from '../../images/devices/massflow_blue.png';
import massflowErr from '../../images/devices/massflow_red.png';
import massflowWarn from '../../images/devices/massflow_warning_orange.png';
import utmOk from '../../images/devices/U_ok.png';
import utmNoConn from '../../images/devices/U_black.png';
import utmWarn from '../../images/devices/U_warning_orange.png';
import certOk from '../../images/devices/diploma_blue.png';
import certErr from '../../images/devices/diploma_red.png';
import certWarn from '../../images/devices/diploma_yellow.png';
import sdOk from '../../images/devices/sd-ok.png';
import sdErr from '../../images/devices/sd-err.png';
import sdWarn from '../../images/devices/sd-warn.png';
import procOk from '../../images/devices/proc-ok.png';
import procErr from '../../images/devices/proc-err.png';
import procWarn from '../../images/devices/proc-warn.png';

import { modes, ONLINE, OFFLINE } from '../constants';

const TankName = ({ nameColor, name, hoverColor }) => (
    <Segment
        className='name'
        style={{ border: '0px', backgroundColor: hoverColor }}
    >
        <div className='tank-name' style={{ color: nameColor }}>
            {name}
        </div>
    </Segment>
);

const TankStatusConnect = ({ color, status, time, hoverColor }) => (
    <Segment
        className='ping'
        style={{ border: '0px', backgroundColor: hoverColor }}
    >
        <Label basic color={color} size='small'>
            {status}
            <Label.Detail>{time}</Label.Detail>
        </Label>
    </Segment>
);

const IndicationSpeed = ({ desc, value, quantity }) => {
    return (
        <Grid.Row
            className='indication-row'
            style={{ padding: '0px 0px 10px 0px' }}
        >
            <Grid.Column width={8}>
                <div className='indication-name'>{desc}</div>
            </Grid.Column>
            <Grid.Column width={8}>
                <div className='indication-value'>
                    {value + quantity}&nbsp;&nbsp;&nbsp;
                    {value > 0 && <Icon fitted name='truck' />}
                </div>
            </Grid.Column>
        </Grid.Row>
    );
};

const Indication = ({ desc, value, quantity }) => {
    return (
        <Grid.Row
            className='indication-row'
            style={{ padding: '0px 0px 10px 0px' }}
        >
            <Grid.Column width={8}>
                <div className='indication-name'>{desc}</div>
            </Grid.Column>
            <Grid.Column width={8}>
                <div className='indication-value'>{value + quantity}</div>
            </Grid.Column>
        </Grid.Row>
    );
};

const IndicationWithLabel = ({ desc, value }) => {
    return (
        <Grid.Row
            className='indication-row'
            style={{ padding: '0px 0px 10px 0px' }}
        >
            <Grid.Column width={8}>{desc}</Grid.Column>
            <Grid.Column width={8}>{value}</Grid.Column>
        </Grid.Row>
    );
};

class Tank extends Component {
    state = {
        hover: false
    };

    /* данный метод нужен для того чтобы, при наведении мыши на цистерну в меню, item выглядел так же
    как и активный item (серый цвет) */
    toggleHover = () => {
        this.setState({ hover: !this.state.hover });
    };

    render() {
        const {
            name,
            tank_type,
            status,
            mode,
            product_name,
            last_ping,
            nsat,
            speed,
            session_start,
            csq,
            gsm,
            level,
            promass_in,
            promass_out,
            tracker,
            ustatus,
            cert_status,
            curr_tank_id,
            id,
            ip,
            role,
            battery,
            charge_level,
            sd,
            zhd_track,
            download,
            upload
        } = this.props;

        var product = product_name.Valid ? product_name.String : '—';
        var ping_time = last_ping.Valid ? last_ping.Time : '';
        var nsattelite = nsat.Valid ? nsat.Int64 : '—';
        var tank_speed = speed.Valid ? speed.Float64 : 0;
        var dbm = -113 + csq * 2;

        /* во всех switch конструкциях ниже определяются цвета (статусы) иконок с приборами и т.д. */
        var curr_level;
        switch (level) {
            case 0:
                curr_level = levelOk;
                break;
            case 1:
                curr_level = levelErr;
                break;
            case 2:
                curr_level = levelWarn;
                break;
            default:
                curr_level = levelErr;
        }

        var curr_promass_in;
        switch (promass_in) {
            case 0:
                curr_promass_in = massflowOk;
                break;
            case 1:
                curr_promass_in = massflowErr;
                break;
            case 2:
                curr_promass_in = massflowWarn;
                break;
            default:
                curr_promass_in = massflowErr;
        }

        var curr_promass_out;
        switch (promass_out) {
            case 0:
                curr_promass_out = massflowOk;
                break;
            case 1:
                curr_promass_out = massflowErr;
                break;
            case 2:
                curr_promass_out = massflowWarn;
                break;
            default:
                curr_promass_out = massflowErr;
        }

        var curr_tracker;
        switch (tracker) {
            case 0:
                curr_tracker = gpsOk;
                break;
            case 1:
                curr_tracker = gpsErr;
                break;
            case 2:
                curr_tracker = gpsWarn;
                break;
            default:
                curr_tracker = gpsErr;
        }

        var curr_utm;
        switch (ustatus) {
            case 0:
                curr_utm = utmOk;
                break;
            case 1:
                curr_utm = utmNoConn;
                break;
            case 2:
                curr_utm = utmWarn;
                break;
            default:
                curr_utm = utmWarn;
        }

        var curr_gsm;
        switch (gsm) {
            case 0:
                if (csq <= 7) {
                    curr_gsm = gsm1;
                } else if (csq <= 15) {
                    curr_gsm = gsm2;
                } else if (csq <= 23) {
                    curr_gsm = gsm3;
                } else {
                    curr_gsm = gsmFull;
                }
                break;
            case 1:
                curr_gsm = gsmErr;
                break;
            case 2:
                curr_gsm = gsmWarn;
                break;
            default:
                curr_gsm = gsmErr;
        }

        var curr_cert;
        switch (cert_status) {
            case 0:
                curr_cert = certOk;
                break;
            case 2:
                curr_cert = certWarn;
                break;
            case 1:
                curr_cert = certErr;
                break;
            default:
                curr_cert = certWarn;
        }

        var curr_sd;
        switch (sd) {
            case 0:
                curr_sd = sdOk;
                break;
            case 2:
                curr_sd = sdWarn;
                break;
            case 1:
                curr_sd = sdErr;
                break;
            default:
                curr_sd = sdWarn;
        }

        var curr_proc;
        switch (zhd_track) {
            case 0:
                curr_proc = procOk;
                break;
            case 2:
                curr_proc = procWarn;
                break;
            case 1:
                curr_proc = procErr;
                break;
            default:
                curr_proc = procWarn;
        }

        var statusDesc = status === 1 ? ONLINE : OFFLINE;
        var color = status === 1 ? 'green' : 'red';
        var nameColor = status === 1 ? '#1EBC30' : '#D95C5C';
        var hoverColor =
            id === curr_tank_id || this.state.hover
                ? 'rgba(223, 215, 215, 0.05)'
                : 'white';
        return (
            <div
                className='tank-block'
                onMouseEnter={this.toggleHover}
                onMouseLeave={this.toggleHover}
            >
                {tank_type === 1 || tank_type === 2 ? ( //  обычная цистерна с 1 или 2 расходомерами
                    <Grid className='tank-grid'>
                        <IndicationWithLabel
                            desc={
                                <TankName
                                    nameColor={nameColor}
                                    name={name}
                                    hoverColor={hoverColor}
                                />
                            }
                            value={
                                <TankStatusConnect
                                    color={color}
                                    status={statusDesc}
                                    hoverColor={hoverColor}
                                    time={ping_time
                                        .replace('T', ' ')
                                        .replace('Z', '')}
                                />
                            }
                        />
                        <Indication
                            desc={'Режим'}
                            value={modes[mode]}
                            quantity=''
                        />
                        <Indication
                            desc={'Продукт'}
                            value={product}
                            quantity=''
                        />
                        <Indication
                            desc={'Начало сессии'}
                            value={session_start
                                .replace('T', ' ')
                                .replace('Z', '')}
                            quantity=''
                        />
                        <Indication
                            desc={'Количество спутников'}
                            value={nsattelite}
                            quantity=''
                        />
                        <IndicationSpeed
                            desc={'Скорость'}
                            value={tank_speed.toFixed(1)}
                            quantity=' км/ч'
                        />
                        <Indication
                            desc={'Уровень сигнала'}
                            value={dbm}
                            quantity=' dBm'
                        />
                        <StatusOfModules
                            tank_type={tank_type}
                            gsm={curr_gsm}
                            gps={curr_tracker}
                            level={curr_level}
                            massflow_in={curr_promass_in}
                            massflow_out={curr_promass_out}
                            utm={curr_utm}
                            cert={curr_cert}
                            hoverColor={hoverColor}
                            ip={ip}
                            role={role}
                        />
                    </Grid>
                ) : tank_type === 0 ? ( //  танк-контейнер
                    <Grid className='tank-grid'>
                        <IndicationWithLabel
                            desc={
                                <TankName nameColor={nameColor} name={name} />
                            }
                            value={
                                <TankStatusConnect
                                    color={color}
                                    status={statusDesc}
                                    time={ping_time
                                        .replace('T', ' ')
                                        .replace('Z', '')}
                                />
                            }
                        />
                        <Indication
                            desc={'Количество спутников'}
                            value={nsattelite}
                            quantity=''
                        />
                        <IndicationSpeed
                            desc={'Скорость'}
                            value={tank_speed.toFixed(1)}
                            quantity=' км/ч'
                        />
                        <Indication
                            desc={'Уровень сигнала'}
                            value={dbm}
                            quantity=' dBm'
                        />
                        <StatusOfModules
                            tank_type={tank_type}
                            gsm={curr_gsm}
                            gps={curr_tracker}
                            level={curr_level}
                            massflow_in={curr_promass_in}
                            massflow_out={curr_promass_out}
                            utm={curr_utm}
                            cert={curr_cert}
                            hoverColor={hoverColor}
                            ip={ip}
                            role={role}
                        />
                    </Grid>
                ) : tank_type === 3 ? ( //  жд-цистерна
                    <Grid className='tank-grid'>
                        <IndicationWithLabel
                            desc={
                                <TankName nameColor={nameColor} name={name} />
                            }
                            value={
                                <TankStatusConnect
                                    color={color}
                                    status={statusDesc}
                                    time={ping_time
                                        .replace('T', ' ')
                                        .replace('Z', '')}
                                />
                            }
                        />
                        <Indication
                            desc={'Количество спутников'}
                            value={nsattelite}
                            quantity=''
                        />
                        <IndicationSpeed
                            desc={'Скорость'}
                            value={tank_speed.toFixed(1)}
                            quantity=' км/ч'
                        />
                        <Indication
                            desc={'Уровень сигнала'}
                            value={dbm}
                            quantity=' dBm'
                        />
                        <Indication
                            desc={'Скорость приема'}
                            value={download}
                            quantity=' Мбит/с'
                        />
                        <Indication
                            desc={'Скорость передачи'}
                            value={upload}
                            quantity=' Мбит/с'
                        />
                        <StatusOfModules
                            tank_type={tank_type}
                            gsm={curr_gsm}
                            gps={curr_tracker}
                            level={curr_level}
                            massflow_in={curr_promass_in}
                            massflow_out={curr_promass_out}
                            utm={curr_utm}
                            cert={curr_cert}
                            hoverColor={hoverColor}
                            ip={ip}
                            role={role}
                            battery={battery}
                            charge_level={charge_level}
                            sd={curr_sd}
                            proc={curr_proc}
                        />
                    </Grid>
                ) : null}
            </div>
        );
    }
}

export default Tank;
