import React, { Component } from 'react';
import { Table, Segment, Icon, Popup } from 'semantic-ui-react';

import NoData from '../CarrierPanel/NoData';

const TableColumns = {
    commandType: 'Тип',
    dateinsert: 'Время запуска',
    description_status: 'Статус',
    description_error: 'Ошибка'
};

class TableSyncCommands extends Component {
    render() {
        const { syncs, products_sync } = this.props;
        const isAllDataReady = syncs && syncs.length > 0;
        var text = 'Синхронизации еще не проводились';
        return (
            <div>
                {!isAllDataReady ? (
                    <NoData text={text} />
                ) : (
                    <Segment basic style={{ padding: '0px' }}>
                        <Table collapsing celled textAlign='center'>
                            <Table.Header>
                                <Table.Row>
                                    <Table.HeaderCell key={1}>
                                        {TableColumns['commandType']}
                                    </Table.HeaderCell>
                                    <Table.HeaderCell key={2}>
                                        {TableColumns['dateinsert']}
                                    </Table.HeaderCell>
                                    <Table.HeaderCell key={3}>
                                        {TableColumns['description_status']}
                                    </Table.HeaderCell>
                                    <Table.HeaderCell key={4}>
                                        {TableColumns['description_error']}
                                    </Table.HeaderCell>
                                    <Table.HeaderCell key={5}>
                                        Результат
                                    </Table.HeaderCell>
                                </Table.Row>
                            </Table.Header>
                            <Table.Body>
                                {syncs.map((row, i) => {
                                    return (
                                        <Table.Row key={i}>
                                            <Table.Cell key={1}>
                                                {row['commandType'] === 1
                                                    ? 'Продукты'
                                                    : row['commandType'] === 2
                                                    ? 'Организация'
                                                    : ''}
                                            </Table.Cell>
                                            <Table.Cell key={2}>
                                                {row['dateinsert']
                                                    .replace('T', ' ')
                                                    .replace('Z', '')}
                                            </Table.Cell>
                                            <Table.Cell key={3}>
                                                {
                                                    row['description_status']
                                                        .String
                                                }
                                            </Table.Cell>
                                            <Table.Cell key={4}>
                                                {
                                                    row['description_error']
                                                        .String
                                                }
                                            </Table.Cell>
                                            <Table.Cell key={5}>
                                                {row['commandType'] === 1 ? (
                                                    row['status'] === 10 ? (
                                                        row['error'].Int64 ===
                                                        114 ? (
                                                            row['merged'] ===
                                                            1 ? (
                                                                <Popup
                                                                    trigger={
                                                                        <Icon.Group size='large'>
                                                                            <Icon
                                                                                color='green'
                                                                                name='checkmark'
                                                                            />
                                                                            <Icon
                                                                                corner='bottom right'
                                                                                color='green'
                                                                                name='comment'
                                                                            />
                                                                        </Icon.Group>
                                                                    }
                                                                    content={
                                                                        row[
                                                                            'comments'
                                                                        ].String
                                                                    }
                                                                    position='top center'
                                                                />
                                                            ) : (
                                                                <Icon
                                                                    link={
                                                                        !products_sync
                                                                    }
                                                                    disabled={
                                                                        products_sync
                                                                    }
                                                                    loading={
                                                                        products_sync
                                                                    }
                                                                    color='blue'
                                                                    name='exchange'
                                                                    size='large'
                                                                    onClick={() =>
                                                                        !products_sync &&
                                                                        this.props.finishSyncProducts(
                                                                            row.idx
                                                                        )
                                                                    }
                                                                />
                                                            )
                                                        ) : (
                                                            <Icon
                                                                color='red'
                                                                name='close'
                                                                size='large'
                                                            />
                                                        )
                                                    ) : row['status'] === 2 ? (
                                                        <Icon
                                                            link={
                                                                !products_sync
                                                            }
                                                            disabled={
                                                                products_sync
                                                            }
                                                            loading={
                                                                products_sync
                                                            }
                                                            color='blue'
                                                            name='sync alternate'
                                                            size='large'
                                                            onClick={() =>
                                                                !products_sync &&
                                                                this.props.finishSyncProducts(
                                                                    row.idx
                                                                )
                                                            }
                                                        />
                                                    ) : null
                                                ) : row['status'] === 10 ? (
                                                    row['error'].Int64 ===
                                                    116 ? (
                                                        <Icon
                                                            color='green'
                                                            name='checkmark'
                                                            size='large'
                                                        />
                                                    ) : (
                                                        <Icon
                                                            color='red'
                                                            name='close'
                                                            size='large'
                                                        />
                                                    )
                                                ) : row['status'] === 9 ? (
                                                    row['error'].Int64 ===
                                                    202 ? (
                                                        <Icon
                                                            loading
                                                            color='blue'
                                                            name='sync alternate'
                                                            size='large'
                                                        />
                                                    ) : (
                                                        <Icon
                                                            color='red'
                                                            name='close'
                                                            size='large'
                                                        />
                                                    )
                                                ) : null}
                                            </Table.Cell>
                                        </Table.Row>
                                    );
                                })}
                            </Table.Body>
                        </Table>
                    </Segment>
                )}
            </div>
        );
    }
}

export default TableSyncCommands;
