import { create_action_creator } from '../defaultAction';

/* инициализация списков цистерн (initial_tanks и filtered_tanks) для выбранного перевозчика
сразу после запроса в БД. */
export const GET_TANKS = 'GET_TANKS';
export const getTanks = create_action_creator(GET_TANKS);

/* динамическое обновление списка filtered_tanks после ввода пользователя в поле фильтра цистерн. */
export const UPDATE_TANKS_WITH_FILTER = 'UPDATE_TANKS_WITH_FILTER';
export const updateTanksWithFilter = create_action_creator(
    UPDATE_TANKS_WITH_FILTER
);

/* обновление текущих данных цистерн */
export const GET_CURR_DATA = 'GET_CURR_DATA';
export const getCurrData = create_action_creator(GET_CURR_DATA);

/* обновление текущих измерений отдельно взятой цистерны */
export const GET_TANK_STATE = 'GET_TANK_STATE';
export const getTankState = create_action_creator(GET_TANK_STATE);

/* обновление информации о сертификатах эцп на цистерне */
export const GET_CERTS_INFO = 'GET_CERTS_INFO';
export const getCertsInfo = create_action_creator(GET_CERTS_INFO);

/* сброс редьюсера tanks до значения по умолчанию */
export const RESET_TANKS = 'RESET_TANKS';
export const resetTanks = create_action_creator(RESET_TANKS);

/* обновление текущих данных по отсекам отдельно взятой цистерны */
export const GET_CURR_CAPSULE = 'GET_CURR_CAPSULE';
export const getCurrCapsule = create_action_creator(GET_CURR_CAPSULE);
