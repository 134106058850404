import React, { Component } from 'react';
import DatePicker from 'material-ui/DatePicker';
import TimePicker from 'material-ui/TimePicker';
import { Menu, Button, Popup } from 'semantic-ui-react';

import { date } from '../constants';

import { parseMoment } from '../functions';

class RangeDateTimeSelector extends Component {
    state = {
        fromDate: parseMoment(date.defaultYesterday, 'only_date'),
        toDate: parseMoment(date.defaultCurrDate, 'only_date'),
        fromTime: parseMoment(date.defaultYesterday, 'only_time'),
        toTime: parseMoment(date.defaultCurrDate, 'only_time'),
        startWidth: 0
    };

    componentDidMount() {
        /* для красоты */
        this.setState({
            startWidth: document
                .getElementById('item-start-interval')
                .getBoundingClientRect().width
        });
    }

    handleChangeFromDate = (e, date) => {
        this.setState({ fromDate: parseMoment(date, 'only_date') });
    };
    handleChangeFromTime = (e, time) => {
        this.setState({ fromTime: parseMoment(time, 'only_time') });
    };
    handleChangeToDate = (e, date) => {
        this.setState({ toDate: parseMoment(date, 'only_date') });
    };
    handleChangeToTime = (e, time) => {
        this.setState({ toTime: parseMoment(time, 'only_time') });
    };

    render() {
        const {
            textDateFrom,
            textDateTo,
            textTimeFrom,
            textTimeTo,
            submitRangeDateTimeSelector,
            popup,
            active_btn,
            loading_btn,
            disabled_btn,
            popupMsg
        } = this.props;
        return (
            <div className='range-date-time-selector'>
                <Menu style={{ margin: '0px' }}>
                    <Menu.Item header id='item-start-interval'>
                        Выберите начало интервала:
                    </Menu.Item>
                    <Menu.Item style={{ height: '50px', width: '150px' }}>
                        <DatePicker
                            hintText={textDateFrom}
                            onChange={this.handleChangeFromDate}
                            defaultDate={date.defaultYesterday.toDate()}
                            underlineStyle={{ display: 'none' }}
                        />
                    </Menu.Item>
                    <Menu.Item style={{ height: '50px', width: '150px' }}>
                        <TimePicker
                            format='24hr'
                            hintText={textTimeFrom}
                            onChange={this.handleChangeFromTime}
                            defaultTime={date.defaultYesterday.toDate()}
                            underlineStyle={{ display: 'none' }}
                        />
                    </Menu.Item>
                </Menu>
                <Menu style={{ margin: '0px' }}>
                    <Menu.Item header style={{ width: this.state.startWidth }}>
                        Выберите конец интервала:
                    </Menu.Item>
                    <Menu.Item style={{ height: '50px', width: '150px' }}>
                        <DatePicker
                            hintText={textDateTo}
                            onChange={this.handleChangeToDate}
                            defaultDate={date.defaultCurrDate.toDate()}
                            underlineStyle={{ display: 'none' }}
                        />
                    </Menu.Item>
                    <Menu.Item style={{ height: '50px', width: '150px' }}>
                        <TimePicker
                            format='24hr'
                            hintText={textTimeTo}
                            onChange={this.handleChangeToTime}
                            defaultTime={date.defaultCurrDate.toDate()}
                            underlineStyle={{ display: 'none' }}
                        />
                    </Menu.Item>
                    <Menu.Item>
                        <Popup
                            position='top center'
                            trigger={
                                <Button
                                    color='blue'
                                    basic={active_btn ? false : true}
                                    loading={loading_btn ? true : false}
                                    disabled={disabled_btn ? true : false}
                                    onClick={() => {
                                        submitRangeDateTimeSelector({
                                            start:
                                                this.state.fromDate +
                                                this.state.fromTime,
                                            end:
                                                this.state.toDate +
                                                this.state.toTime
                                        });
                                    }}
                                >
                                    {!active_btn
                                        ? 'Показать перемещения'
                                        : 'Убрать перемещения'}
                                </Button>
                            }
                            content={popupMsg}
                            open={popup}
                        />
                    </Menu.Item>
                    {/* может быть в дальнееейшем эта кнопка понадобится для реалтайм обновления данных, но не сейчас */}
                    {/* <Menu.Item>
                    <Button
                        icon='sync alternate'
                        disabled={disabled_btn ? true : false}
                        color='green'
                        basic
                        onClick={() => {
                            submitRangeDateTimeSelector({ start: this.state.fromDate+this.state.fromTime, end: this.state.toDate+this.state.toTime })
                        }}
                    />
                </Menu.Item> */}
                </Menu>
            </div>
        );
    }
}

export default RangeDateTimeSelector;
