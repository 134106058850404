import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Form, Divider, Segment, Input } from 'semantic-ui-react';

import { ns, ts } from '../constants';

import { reduceDate } from '../functions';

const divider={
    marginTop: '10px',
    marginBottom: '10px',
}

const TicketField = ({ value, label }) => {
    return <Form.Field inline>
        <Segment basic clearing style={{ padding: '0px', margin: '0px' }}>
            <label className='ticket-label'>{label}</label>
            {label === ts.comments ?
                <Form id='comment-viewer'><Form.TextArea
                    id='comment-viewer-area'
                    value={value}
                    readOnly
                /></Form>
            :
                <Input
                    style={{ width: '60%', float: 'right' }}
                    value={value != null ? value : ''}
                    readOnly
                />
            }
        </Segment>
    </Form.Field>
}

class FileFormTmp extends Component {
    render() {
        const { f, t, type } = this.props;
        return <Segment basic className='data-block-carrier'>
            <div className='notice-form'>
                <Segment id='notice-tmp-form' style={{ width: type === 'notice' ? '100%' : '50%' }}>
                    <div>
                        {type === 'notice' ?
                        <Segment basic style={{ padding: '0px' }}>
                            <Form>
                                <Form.Input
                                    label={ns.client_identity}
                                    value={typeof f.Header.ClientIdentity == 'undefined' ? '' : f.Header.ClientIdentity}
                                    readOnly
                                    width={4}
                                />
                                <Form.Group>
                                    <Form.Input
                                        label={ns.notif_number}
                                        value={f.Header.NotifNumber}
                                        readOnly
                                        width={4}
                                    />
                                    <Form.Input
                                        label={ns.serial}
                                        value={typeof f.Header.Serial == 'undefined' ? '' : f.Header.Serial}
                                        readOnly
                                        width={4}
                                    />
                                </Form.Group>
                                <Form.Group>
                                    <Form.Input
                                        label={ns.carrier}
                                        value={
                                            typeof f.Header.Carrier.FL === 'undefined' ?
                                            f.Header.Carrier.UL.ShortName :
                                            f.Header.Carrier.FL.ShortName
                                        }
                                        readOnly
                                        width={8}
                                    />
                                    <Form.Input
                                        label={ns.client_transport}
                                        value={f.Header.ClientTransport.TR.address.description}
                                        readOnly
                                        width={8}
                                    />
                                </Form.Group>
                                <Form.Group>
                                    <Form.Input
                                        label={'ФСРАР ID и ИНН / КПП грузоперевозчика'}
                                        value={
                                            'ФСРАР ID: ' + 
                                            (typeof f.Header.Carrier.FL === 'undefined' ?
                                            f.Header.Carrier.UL.ClientRegId :
                                            f.Header.Carrier.FL.ClientRegId) + 
                                            ' ИНН / КПП: ' + 
                                            (typeof f.Header.Carrier.FL === 'undefined' ?
                                            f.Header.Carrier.UL.INN :
                                            f.Header.Carrier.FL.INN) +
                                            ' / ' + 
                                            (typeof f.Header.Carrier.FL === 'undefined' ?
                                            f.Header.Carrier.UL.KPP :
                                            f.Header.Carrier.FL.KPP)
                                        }
                                        readOnly
                                        width={8}
                                    />
                                    <Form.Input
                                        label={'ФСРАР ID транспортного средства'}
                                        value={f.Header.ClientTransport.TR.ClientRegId}
                                        readOnly
                                        width={8}
                                    />
                                </Form.Group>
                                <Form.Group>
                                    <Form.Input
                                        label={ns.shipper}
                                        value={
                                            typeof f.Header.Shipper.FL !== 'undefined' ?
                                            f.Header.Shipper.FL.ShortName :
                                            typeof f.Header.Shipper.FO !== 'undefined' ?
                                            f.Header.Shipper.FO.ShortName :
                                            typeof f.Header.Shipper.TS !== 'undefined' ?
                                            f.Header.Shipper.TS.ShortName :
                                            f.Header.Shipper.UL.ShortName
                                        }
                                        readOnly
                                        width={8}
                                    />
                                    <Form.Input
                                        label={ns.consignee}
                                        value={
                                            typeof f.Header.Consignee.FL !== 'undefined' ?
                                            f.Header.Consignee.FL.ShortName :
                                            typeof f.Header.Consignee.FO !== 'undefined' ?
                                            f.Header.Consignee.FO.ShortName :
                                            typeof f.Header.Consignee.TS !== 'undefined' ?
                                            f.Header.Consignee.TS.ShortName :
                                            f.Header.Consignee.UL.ShortName
                                        }
                                        readOnly
                                        width={8}
                                    />
                                </Form.Group>
                                <Form.Group>
                                    <Form.Input
                                        label={'ФСРАР ID и ИНН / КПП отправителя'}
                                        value={
                                            'ФСРАР ID: ' + 
                                            (typeof f.Header.Shipper.FL !== 'undefined' ?
                                            f.Header.Shipper.FL.ClientRegId :
                                            typeof f.Header.Shipper.FO !== 'undefined' ?
                                            f.Header.Shipper.FO.ClientRegId :
                                            typeof f.Header.Shipper.TS !== 'undefined' ?
                                            f.Header.Shipper.TS.ClientRegId :
                                            f.Header.Shipper.UL.ClientRegId) + 
                                            ' ИНН / КПП: ' + 
                                            (typeof f.Header.Shipper.FL !== 'undefined' ?
                                            (typeof f.Header.Shipper.FL.INN !== 'undefined' ? f.Header.Shipper.FL.INN : ' - ') :
                                            typeof f.Header.Shipper.FO !== 'undefined' ?
                                            (typeof f.Header.Shipper.FO.INN !== 'undefined' ? f.Header.Shipper.FO.INN : ' - ') :
                                            typeof f.Header.Shipper.TS !== 'undefined' ?
                                            (typeof f.Header.Shipper.TS.INN !== 'undefined' ? f.Header.Shipper.TS.INN : ' - ') :
                                            (typeof f.Header.Shipper.UL.INN !== 'undefined' ? f.Header.Shipper.UL.INN : ' - ')) +
                                            ' / ' + 
                                            (typeof f.Header.Shipper.FL !== 'undefined' ?
                                            (typeof f.Header.Shipper.FL.KPP !== 'undefined' ? f.Header.Shipper.FL.KPP : ' - ') :
                                            typeof f.Header.Shipper.FO !== 'undefined' ?
                                            (typeof f.Header.Shipper.FO.KPP !== 'undefined' ? f.Header.Shipper.FO.KPP : ' - ') :
                                            typeof f.Header.Shipper.TS !== 'undefined' ?
                                            (typeof f.Header.Shipper.TS.KPP !== 'undefined' ? f.Header.Shipper.TS.KPP : ' - ') :
                                            (typeof f.Header.Shipper.UL.KPP !== 'undefined' ? f.Header.Shipper.UL.KPP : ' - '))
                                        }
                                        readOnly
                                        width={8}
                                    />
                                    <Form.Input
                                        label={'ФСРАР ID и ИНН / КПП получателя'}
                                        value={
                                            'ФСРАР ID: ' + 
                                            (typeof f.Header.Consignee.FL !== 'undefined' ?
                                            f.Header.Consignee.FL.ClientRegId :
                                            typeof f.Header.Consignee.FO !== 'undefined' ?
                                            f.Header.Consignee.FO.ClientRegId :
                                            typeof f.Header.Consignee.TS !== 'undefined' ?
                                            f.Header.Consignee.TS.ClientRegId :
                                            f.Header.Consignee.UL.ClientRegId) + 
                                            ' ИНН / КПП: ' + 
                                            (typeof f.Header.Consignee.FL !== 'undefined' ?
                                            (typeof f.Header.Consignee.FL.INN !== 'undefined' ? f.Header.Consignee.FL.INN : ' - ') :
                                            typeof f.Header.Consignee.FO !== 'undefined' ?
                                            (typeof f.Header.Consignee.FO.INN !== 'undefined' ? f.Header.Consignee.FO.INN : ' - ') :
                                            typeof f.Header.Consignee.TS !== 'undefined' ?
                                            (typeof f.Header.Consignee.TS.INN !== 'undefined' ? f.Header.Consignee.TS.INN : ' - ') :
                                            (typeof f.Header.Consignee.UL.INN !== 'undefined' ? f.Header.Consignee.UL.INN : ' - ')) +
                                            ' / ' + 
                                            (typeof f.Header.Consignee.FL !== 'undefined' ?
                                            (typeof f.Header.Consignee.FL.KPP !== 'undefined' ? f.Header.Consignee.FL.KPP : ' - ') :
                                            typeof f.Header.Consignee.FO !== 'undefined' ?
                                            (typeof f.Header.Consignee.FO.KPP !== 'undefined' ? f.Header.Consignee.FO.KPP : ' - ') :
                                            typeof f.Header.Consignee.TS !== 'undefined' ?
                                            (typeof f.Header.Consignee.TS.KPP !== 'undefined' ? f.Header.Consignee.TS.KPP : ' - ') :
                                            (typeof f.Header.Consignee.UL.KPP !== 'undefined' ? f.Header.Consignee.UL.KPP : ' - '))
                                        }
                                        readOnly
                                        width={8}
                                    />
                                </Form.Group>
                                <Form.Group>
                                    <Form.Input
                                        label={ns.ship_out_date}
                                        value={reduceDate(f.Header.ShipmentOutDate, 'from')}
                                        readOnly
                                        width={4}
                                    />
                                    <Form.Input
                                        label={ns.ship_in_date}
                                        value={reduceDate(f.Header.ShipmentInDate, 'from')}
                                        readOnly
                                        width={4}
                                    />
                                </Form.Group>
                                <Form.Input
                                    label={ns.egais_fix_number_ttn}
                                    value={typeof f.Header.EGAISFixNumberTTN == 'undefined' ? '' : f.Header.EGAISFixNumberTTN}
                                    readOnly
                                    width={8}
                                />
                                <Form.Input
                                    label={ns.notif_date}
                                    value={reduceDate(f.Header.NotifDate, 'from')}
                                    readOnly
                                    width={4}
                                />
                            </Form>
                            {
                                (f.Content.Position && Array.isArray(f.Content.Position)) ? (f.Content.Position.length > 0 &&
                                f.Content.Position.map((p, i) => {
                                    return <div key={i}>
                                        <Divider section horizontal style={divider}><h6>{ns.pos_identity + ': ' + p.PosIdentity}</h6></Divider>
                                        <Form>
                                            <Form.Group>
                                                <Form.Input
                                                    label={ns.product}
                                                    value={p.Product.FullName}
                                                    readOnly
                                                    width={8}
                                                />
                                                <Form.Input
                                                    label={ns.producer}
                                                    value={
                                                        typeof p.Product.Producer.FL !== 'undefined' ?
                                                        p.Product.Producer.FL.ShortName :
                                                        typeof p.Product.Producer.FO !== 'undefined' ?
                                                        p.Product.Producer.FO.ShortName :
                                                        typeof p.Product.Producer.TS !== 'undefined' ?
                                                        p.Product.Producer.TS.ShortName :
                                                        p.Product.Producer.UL.ShortName
                                                    }
                                                    readOnly
                                                    width={8}
                                                />
                                            </Form.Group>
                                            <Form.Group>
                                                <Form.Input
                                                    label={'Код АП / Код вида АП'}
                                                    value={p.Product.AlcCode + ' / ' + p.Product.ProductVCode}
                                                    readOnly
                                                    width={8}
                                                />
                                                <Form.Input
                                                    label={'ФСРАР ID и ИНН / КПП производителя'}
                                                    value={
                                                        'ФСРАР ID: ' + 
                                                        (typeof p.Product.Producer.FL !== 'undefined' ?
                                                        p.Product.Producer.FL.ClientRegId :
                                                        typeof p.Product.Producer.FO !== 'undefined' ?
                                                        p.Product.Producer.FO.ClientRegId :
                                                        typeof p.Product.Producer.TS !== 'undefined' ?
                                                        p.Product.Producer.TS.ClientRegId :
                                                        p.Product.Producer.UL.ClientRegId) + 
                                                        ' ИНН / КПП: ' + 
                                                        (typeof p.Product.Producer.FL !== 'undefined' ?
                                                        (typeof p.Product.Producer.FL.INN !== 'undefined' ? p.Product.Producer.FL.INN : ' - ') :
                                                        typeof p.Product.Producer.FO !== 'undefined' ?
                                                        (typeof p.Product.Producer.FO.INN !== 'undefined' ? p.Product.Producer.FO.INN : ' - ') :
                                                        typeof p.Product.Producer.TS !== 'undefined' ?
                                                        (typeof p.Product.Producer.TS.INN !== 'undefined' ? p.Product.Producer.TS.INN : ' - ') :
                                                        (typeof p.Product.Producer.UL.INN !== 'undefined' ? p.Product.Producer.UL.INN : ' - ')) +
                                                        ' / ' + 
                                                        (typeof p.Product.Producer.FL !== 'undefined' ?
                                                        (typeof p.Product.Producer.FL.KPP !== 'undefined' ? p.Product.Producer.FL.KPP : ' - ') :
                                                        typeof p.Product.Producer.FO !== 'undefined' ?
                                                        (typeof p.Product.Producer.FO.KPP !== 'undefined' ? p.Product.Producere.FO.KPP : ' - ') :
                                                        typeof p.Product.Producer.TS !== 'undefined' ?
                                                        (typeof p.Product.Producer.TS.KPP !== 'undefined' ? p.Product.Producer.TS.KPP : ' - ') :
                                                        (typeof p.Product.Producer.UL.KPP !== 'undefined' ? p.Product.Producer.UL.KPP : ' - '))
                                                    }
                                                    readOnly
                                                    width={8}
                                                />
                                            </Form.Group>
                                            <Form.Group>
                                                <Form.Input
                                                    type='number'
                                                    label={ns.quantity_20}
                                                    value={p.Quantity20}
                                                    readOnly
                                                />
                                                <Form.Input
                                                    type='number'
                                                    label={ns.alc_perc_20}
                                                    value={p.AlcPerc20}
                                                    readOnly
                                                />
                                            </Form.Group>
                                        </Form>
                                    </div>
                                }))
                                :
                                <div>
                                    <Divider section horizontal style={divider}><h6>{ns.pos_identity + ': ' + f.Content.Position.PosIdentity}</h6></Divider>
                                    <Form>
                                        <Form.Group>
                                            <Form.Input
                                                label={ns.product}
                                                value={f.Content.Position.Product.FullName}
                                                readOnly
                                                width={8}
                                            />
                                            <Form.Input
                                                label={ns.producer}
                                                value={
                                                    typeof f.Content.Position.Product.Producer.FL !== 'undefined' ?
                                                    f.Content.Position.Product.Producer.FL.ShortName :
                                                    typeof f.Content.Position.Product.Producer.FO !== 'undefined' ?
                                                    f.Content.Position.Product.Producer.FO.ShortName :
                                                    typeof f.Content.Position.Product.Producer.TS !== 'undefined' ?
                                                    f.Content.Position.Product.Producer.TS.ShortName :
                                                    f.Content.Position.Product.Producer.UL.ShortName
                                                }
                                                readOnly
                                                width={8}
                                            />
                                        </Form.Group>
                                        <Form.Group>
                                            <Form.Input
                                                label={'Код АП / Код вида АП'}
                                                value={f.Content.Position.Product.AlcCode + ' / ' + f.Content.Position.Product.ProductVCode}
                                                readOnly
                                                width={8}
                                            />
                                            <Form.Input
                                                label={'ФСРАР ID и ИНН / КПП производителя'}
                                                value={
                                                    'ФСРАР ID: ' + 
                                                    (typeof f.Content.Position.Product.Producer.FL !== 'undefined' ?
                                                    f.Content.Position.Product.Producer.FL.ClientRegId :
                                                    typeof f.Content.Position.Product.Producer.FO !== 'undefined' ?
                                                    f.Content.Position.Product.Producer.FO.ClientRegId :
                                                    typeof f.Content.Position.Product.Producer.TS !== 'undefined' ?
                                                    f.Content.Position.Product.Producer.TS.ClientRegId :
                                                    f.Content.Position.Product.Producer.UL.ClientRegId) + 
                                                    ' ИНН / КПП: ' + 
                                                    (typeof f.Content.Position.Product.Producer.FL !== 'undefined' ?
                                                    (typeof f.Content.Position.Product.Producer.FL.INN !== 'undefined' ? f.Content.Position.Product.Producer.FL.INN : ' - ') :
                                                    typeof f.Content.Position.Product.Producer.FO !== 'undefined' ?
                                                    (typeof f.Content.Position.Product.Producer.FO.INN !== 'undefined' ? f.Content.Position.Product.Producer.FO.INN : ' - ') :
                                                    typeof f.Content.Position.Product.Producer.TS !== 'undefined' ?
                                                    (typeof f.Content.Position.Product.Producer.TS.INN !== 'undefined' ? f.Content.Position.Product.Producer.TS.INN : ' - ') :
                                                    (typeof f.Content.Position.Product.Producer.UL.INN !== 'undefined' ? f.Content.Position.Product.Producer.UL.INN : ' - ')) +
                                                    ' / ' + 
                                                    (typeof f.Content.Position.Product.Producer.FL !== 'undefined' ?
                                                    (typeof f.Content.Position.Product.Producer.FL.KPP !== 'undefined' ? f.Content.Position.Product.Producer.FL.KPP : ' - ') :
                                                    typeof f.Content.Position.Product.Producer.FO !== 'undefined' ?
                                                    (typeof f.Content.Position.Product.Producer.FO.KPP !== 'undefined' ? f.Content.Position.Product.Producere.FO.KPP : ' - ') :
                                                    typeof f.Content.Position.Product.Producer.TS !== 'undefined' ?
                                                    (typeof f.Content.Position.Product.Producer.TS.KPP !== 'undefined' ? f.Content.Position.Product.Producer.TS.KPP : ' - ') :
                                                    (typeof f.Content.Position.Product.Producer.UL.KPP !== 'undefined' ? f.Content.Position.Product.Producer.UL.KPP : ' - '))
                                                }
                                                readOnly
                                                width={8}
                                            />
                                        </Form.Group>
                                        <Form.Group>
                                            <Form.Input
                                                type='number'
                                                label={ns.quantity_20}
                                                value={f.Content.Position.Quantity20}
                                                readOnly
                                            />
                                            <Form.Input
                                                type='number'
                                                label={ns.alc_perc_20}
                                                value={f.Content.Position.AlcPerc20}
                                                readOnly
                                            />
                                        </Form.Group>
                                    </Form>
                                </div>
                            }
                        </Segment>
                        : type === 'ticket' ?
                        <Segment basic style={{ padding: '0px' }}>
                            <Form >
                                <TicketField value={t.TicketDate.replace('T', ' ').substring(0, t.TicketDate.indexOf('.'))} label={ts.ticket_date}/>
                                <TicketField value={t.DocType} label={ts.doc_type}/>
                                <TicketField value={t.TransportId} label={ts.transport_id}/>
                                <TicketField value={t.RegID} label={ts.reg_id}/>
                                <TicketField value={t.Result.Conclusion} label={ts.conclusion}/>
                                <TicketField value={t.Result.ConclusionDate.replace('T', ' ').substring(0, t.Result.ConclusionDate.indexOf('.'))} label={ts.conclusion_date}/>
                                <TicketField value={t.Result.Comments} label={ts.comments}/>
                            </Form>
                        </Segment>
                        :
                        null
                        }
                    </div>
                </Segment>
            </div>
        </Segment>;
    }
}

const mapStateToProps = state => {
    return {
        f: state.notice.file_json.Documents.Document.CarrierNotice,
        t: state.notice.file_json.Documents.Document.Ticket,
        type: state.notice.file_tmp_type,
    }
}

export default connect(mapStateToProps, null)(FileFormTmp);