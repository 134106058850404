import React, { Component } from 'react';
import {
    Segment,
    Button,
    Icon,
    Table,
    Modal,
    Form,
    Header
} from 'semantic-ui-react';

import {
    CARRIER,
    SHIPPER,
    CONSIGNEE,
    PRODUCER,
    ResTypesAddOrgs
} from '../constants';

import API from '../API';

const SUCCESS_ADD_ORG = 'SUCCESS_ADD_ORG';

const TableColumns = {
    reg_id: 'ФСРАР ID',
    full_name: 'Полное наименование',
    short_name: 'Краткое наименование',
    inn: 'ИНН',
    kpp: 'КПП',
    country: 'Код страны',
    region_code: 'Код региона',
    description: 'Адрес'
};

class NoOrgData extends Component {
    state = {
        inputRegID: false,
        inputRegIDErr: false,
        regID: '',
        openModal: false,
        msgNeg: false,
        msgPos: false,
        response: '',
        org_id: null
    };

    open = () => this.setState({ inputRegID: true });
    close = () => this.setState({ inputRegID: false });

    handleChange = (e, { name, value }) => this.setState({ [name]: value });

    closeModal = () =>
        this.setState({
            openModal: false,
            msgNeg: false,
            msgPos: false,
            response: ''
        });

    addOrg = () => {
        if (this.state.regID === '') {
            this.setState({ inputRegIDErr: true });
            setTimeout(() => this.setState({ inputRegIDErr: false }), 1000);
            return;
        }
        const type = this.props.type;
        var data = {
            reg_id: this.state.regID,
            operation: 'add'
        };
        this.setState({ regID: '' });
        API.POST('/carrier/' + type + '/produce_org', data, res => {
            this.setState({ openModal: true, response: res.data });
            if (res.data === SUCCESS_ADD_ORG) {
                this.setState({ msgPos: true });
            } else {
                this.setState({ msgNeg: true });
            }
        });
    };

    render() {
        const {
            inputRegID,
            inputRegIDErr,
            regID,
            openModal,
            msgNeg,
            msgPos,
            response
        } = this.state;
        const { text, type } = this.props;
        var content;
        switch (type) {
            case CARRIER:
                break;
            case SHIPPER:
                content = 'Добавить отправителя';
                break;
            case CONSIGNEE:
                content = 'Добавить получателя';
                break;
            case PRODUCER:
                content = 'Добавить производителя';
                break;
            default:
        }
        return (
            <div>
                <Segment basic style={{ padding: '0px' }}>
                    <Table collapsing celled textAlign='center'>
                        <Table.Header>
                            <Table.Row>
                                <Table.HeaderCell key={1}>
                                    {TableColumns['reg_id']}
                                </Table.HeaderCell>
                                <Table.HeaderCell key={2}>
                                    {TableColumns['full_name']}
                                </Table.HeaderCell>
                                <Table.HeaderCell key={2}>
                                    {TableColumns['short_name']}
                                </Table.HeaderCell>
                                <Table.HeaderCell key={3}>
                                    {TableColumns['inn']}
                                </Table.HeaderCell>
                                <Table.HeaderCell key={4}>
                                    {TableColumns['kpp']}
                                </Table.HeaderCell>
                                <Table.HeaderCell key={5}>
                                    {TableColumns['country']}
                                </Table.HeaderCell>
                                <Table.HeaderCell key={6}>
                                    {TableColumns['region_code']}
                                </Table.HeaderCell>
                                <Table.HeaderCell key={7}>
                                    {TableColumns['description']}
                                </Table.HeaderCell>
                            </Table.Row>
                        </Table.Header>
                        <Table.Body>
                            <Table.Row>
                                <Table.HeaderCell
                                    colSpan='8'
                                    style={{ padding: '14px' }}
                                >
                                    {text}
                                </Table.HeaderCell>
                            </Table.Row>
                        </Table.Body>
                        {type !== CARRIER && (
                            <Table.Footer fullWidth>
                                <Table.Row>
                                    <Table.HeaderCell colSpan='9'>
                                        <Button
                                            floated='left'
                                            content={content}
                                            primary
                                            size='small'
                                            onClick={this.open}
                                        />
                                    </Table.HeaderCell>
                                </Table.Row>
                            </Table.Footer>
                        )}
                    </Table>
                </Segment>
                <Modal
                    size='tiny'
                    open={inputRegID}
                    onClose={this.close}
                    closeOnEscape={false}
                    closeOnDimmerClick={false}
                >
                    <Modal.Header>Введите ФСРАР ID контрагента</Modal.Header>
                    <Modal.Content>
                        <Form>
                            <Form.Group>
                                <Form.Input
                                    placeholder='FSRAR ID'
                                    name='regID'
                                    value={regID}
                                    onChange={this.handleChange}
                                    error={inputRegIDErr}
                                />
                            </Form.Group>
                        </Form>
                    </Modal.Content>
                    <Modal.Actions>
                        <Button
                            positive
                            content='Подтвердить'
                            onClick={this.addOrg}
                        />
                        <Button negative onClick={this.close}>
                            Закрыть
                        </Button>
                    </Modal.Actions>
                </Modal>
                <Modal
                    size='tiny'
                    open={openModal}
                    onClose={this.closeModal}
                    dimmer='blurring'
                >
                    <Header
                        icon={
                            <Icon
                                color={
                                    msgNeg ? 'red' : msgPos ? 'green' : 'red'
                                }
                                name={
                                    msgNeg
                                        ? 'close'
                                        : msgPos
                                        ? 'check'
                                        : 'close'
                                }
                            />
                        }
                        content={ResTypesAddOrgs[response]}
                    />
                    <Modal.Actions>
                        <Button negative onClick={this.closeModal}>
                            Закрыть
                        </Button>
                    </Modal.Actions>
                </Modal>
            </div>
        );
    }
}

export default NoOrgData;
