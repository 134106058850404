import React, { Component } from 'react';
import { Segment } from 'semantic-ui-react';

import DateSelector from '../DatetimeSelectors/DateSelector';

import { date } from '../constants';

import { parseMoment } from '../functions';

import MaterialUIWrapper from '../MaterialUIWrapper';

import TableAsiiuTimeTasks from '../Tables/TableAsiiuTimeTasks';
import API from '../API';

class NavFiles extends Component {
    state = {
        ascpData: []
    };

    getAscpData = date => {
        if (!this.props.NavFetching) {
            API.GET(
                '/tanks/' + this.props.id + '/ascpnavigation',
                { timestamp: date },
                res => {
                    if (!res.data) {
                        this.setState(
                            { ascpData: [] },
                            this.props.isFetching({ NavFetching: false })
                        );
                    } else {
                        this.setState({ ascpData: res.data });
                        this.props.isFetching({ NavFetching: false });
                    }
                }
            );
            this.props.isFetching({ NavFetching: true });
        }
    };

    componentDidMount() {
        this.getAscpData(parseMoment(date.defaultCurrDate, 'date'));
    }

    submitDateSelector = param => {
        this.getAscpData(param);
    };

    render() {
        const { ascpData } = this.state;
        var disabled = this.props.NavFetching;
        return (
            <Segment basic className='files' style={{ padding: '0px' }}>
                <MaterialUIWrapper>
                    <DateSelector
                        submitDateSelector={this.submitDateSelector}
                        disabled={disabled}
                        text='nav'
                    />
                </MaterialUIWrapper>
                <Segment
                    basic
                    loading={disabled}
                    textAlign='center'
                    className='files-table'
                >
                    <TableAsiiuTimeTasks data={ascpData} flag='ascp' />
                </Segment>
            </Segment>
        );
    }
}

export default NavFiles;
