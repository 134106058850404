import React from 'react';

const NoData = ({ text }) => {
    return (
        <div>
            <h4 style={{ color: 'grey', padding: '20px', textAlign: 'center' }}>
                {text}
            </h4>
        </div>
    );
};

export default NoData;
