import React, { Component } from 'react';
import { Button, Menu, Icon, Modal, Dropdown } from 'semantic-ui-react';
import { connect } from 'react-redux';

import logo from '../../images/favicon.ico';

import API from '../API';
import { AuthMessage } from '../../components/Login/AuthMessage';

import CarrierPanel from '../CarrierPanel';
import AdminPanel from '../AdminPanel';

import { getLogoutStatus, resetFlags } from '../actions/flags';

import { resetTanks } from '../actions/tanks';

import { resetCarrier } from '../actions/carrier';

import { resetNotice } from '../actions/notice';

import {
    ERR_NETWORK,
    ERR_INTERNAL_SERVER,
    ERR_UNAUTHORIZED,
    ERR_NO_MONEY,
    ERR_INVALID_REFRESH_TOKEN,
    ERR_UNEXPECTED,
    ERR_SUSPECT_ACTIONS,
    ERR_NETWORK_ERR
} from '../errors';

import {
    INVALID_TOKEN,
    ACCESS_DENIED,
    INVALID_REFRESH_TOKEN,
    SOMETHING_WRONG,
    SUSPECT_ACTIONS,
    SRV_NOT_AVAILABLE
} from '../messages';

import { defaultFlags } from '../defaultFlags';
import { defaultTanks } from '../defaultTanks';
import { defaultCarrier } from '../defaultCarrier';
import { defaultNotice } from '../defaultNotice';

import { freqLogout, ADMIN } from '../constants';

class NavBar extends Component {
    state = {
        errorStatus: '',
        message: '',
        openModal: false,
        openPanel: false,
        openAdmin: false
    };

    closeModal = () =>
        this.setState({ openModal: false, message: '' }, () => {
            sessionStorage.clear();
            this.props.onResetFlags();
            this.props.onResetTanks();
            this.props.onResetCarrier();
            this.props.onResetNotice();
            this.props.history.push('/login');
        });
    openModal = message =>
        this.setState(prevState => {
            if (!prevState.openModal) {
                clearInterval(this.timerLogout);
                return { message: message, openModal: true };
            }
        });

    componentDidMount() {
        /* Эти запросы нужны для обеспечения возможности контролировать одновременную авторизацию
        только одного пользователя под определнной учеткой. Каждый клиент раз в 5 сек спрашивает у
        сервера "Должен ли я выполнить выход?" (также при этом фиксируется время последней активаности
        клиента). Если с сервера приходит ответ, что нужно, то выполняется функция Logout.

        **Попробовать, может быть, когда нибудь реализовать это на websockets..
        
        ВАЖНО!!! Не забыть после выхода вернуть состояние приложения (Redux Store) в изначальное (до Login). */
        API.GET(
            '/must_logout',
            null,
            res => {
                this.setState({ errorStatus: '' });
                this.props.onGetLogoutStatus(res.data.logout);
            },
            error => {
                if (!error.response) {
                    this.setState({ errorStatus: ERR_NETWORK });
                } else {
                    this.setState({ errorStatus: error.response.data.message });
                }
            }
        );
        this.timerLogout = setInterval(() => {
            API.GET(
                '/must_logout',
                null,
                res => {
                    this.setState({ errorStatus: '' });
                    this.props.onGetLogoutStatus(res.data.logout);
                },
                error => {
                    if (error.message) {
                        this.setState({ errorStatus: error.message });
                    } else if (!error.response) {
                        this.setState({ errorStatus: ERR_NETWORK });
                    } else {
                        this.setState({
                            errorStatus: error.response.data.message
                        });
                    }
                }
            );
        }, freqLogout);
    }

    componentWillUnmount() {
        clearInterval(this.timerLogout);
    }

    openCarrierPanel = () => this.setState({ openPanel: true });
    closeCarrierPanel = () => this.setState({ openPanel: false });

    downloadInstruction = () => {
        API.GETFILE('/carrier/instruction', null, res => {
            const url = window.URL.createObjectURL(new Blob([res.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'инструкция.pdf');
            document.body.appendChild(link);
            link.click();
        });
    };

    openAdminPanel = () => this.setState({ openAdmin: true });
    closeAdminPanel = () => this.setState({ openAdmin: false });

    render() {
        var message;
        if (this.props.logout) {
            API.Logout(() => {
                this.props.onResetFlags();
                this.props.onResetTanks();
                this.props.onResetCarrier();
                this.props.onResetNotice();
                this.props.history.push('/login');
            });
        }
        if (
            this.state.errorStatus === ERR_NETWORK ||
            this.state.errorStatus === ERR_INTERNAL_SERVER
        ) {
            sessionStorage.clear();
            this.props.onResetFlags();
            this.props.onResetTanks();
            this.props.onResetCarrier();
            this.props.onResetNotice();
            this.props.history.push('/login');
        }
        if (this.state.errorStatus.includes(ERR_UNAUTHORIZED)) {
            message = INVALID_TOKEN;
            this.openModal(message);
        } else if (this.state.errorStatus === ERR_NO_MONEY) {
            message = ACCESS_DENIED;
            this.openModal(message);
        } else if (this.state.errorStatus === ERR_INVALID_REFRESH_TOKEN) {
            message = INVALID_REFRESH_TOKEN;
            this.openModal(message);
        } else if (this.state.errorStatus === ERR_UNEXPECTED) {
            message = SOMETHING_WRONG;
            this.openModal(message);
        } else if (this.state.errorStatus === ERR_SUSPECT_ACTIONS) {
            message = SUSPECT_ACTIONS;
            this.openModal(message);
        } else if (this.state.errorStatus === ERR_NETWORK_ERR) {
            message = SRV_NOT_AVAILABLE;
            this.openModal(message);
        }
        var carrierPanelH = this.props.noticeOpen ? '90%' : '70%';
        return (
            <div className='nav-bar'>
                <Menu
                    className='nav-bar-menu'
                    stackable
                    style={{ margin: '0px' }}
                >
                    <Menu.Item>
                        <img src={logo} alt='logo' />
                    </Menu.Item>
                    <Menu.Item header style={{ padding: '5px 10px' }}>
                        <div className='app-name'>Мониторинг БАЗИС-Т</div>
                    </Menu.Item>
                    <Menu.Menu position='right'>
                        {/* Если в БД в таблице users в поле feature стоит 1, то пользователю доступна Панель управления перевозчика. */
                        this.props.feature === 1 && (
                            <Dropdown
                                item
                                icon={
                                    <Icon
                                        name='tasks'
                                        size='large'
                                        color='blue'
                                    />
                                }
                                simple
                                direction='left'
                            >
                                <Dropdown.Menu>
                                    {this.props.role === ADMIN && (
                                        <Dropdown.Item
                                            onClick={() =>
                                                this.openAdminPanel()
                                            }
                                        >
                                            Администрирование
                                        </Dropdown.Item>
                                    )}
                                    <Dropdown.Item
                                        onClick={() => this.openCarrierPanel()}
                                    >
                                        Панель управления грузоперевозчика
                                    </Dropdown.Item>
                                    <Dropdown.Item
                                        onClick={() =>
                                            this.downloadInstruction()
                                        }
                                    >
                                        Скачать инструкцию
                                    </Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown>
                        )}
                        <Menu.Item id='exit'>
                            <Button
                                color='blue'
                                onClick={() => {
                                    API.Logout(() => {
                                        this.props.onResetFlags();
                                        this.props.onResetTanks();
                                        this.props.onResetCarrier();
                                        this.props.onResetNotice();
                                        this.props.history.push('/login');
                                    });
                                }}
                            >
                                Выйти
                            </Button>
                        </Menu.Item>
                    </Menu.Menu>
                </Menu>
                <AuthMessage
                    message={this.state.message}
                    openModal={this.state.openModal}
                    closeModal={this.closeModal}
                />
                {/* Панель управления перевозчика */}
                <Modal
                    className='modal-carrier-panel'
                    size='fullscreen'
                    style={{ height: carrierPanelH }}
                    open={this.state.openPanel}
                    onClose={this.closeCarrierPanel}
                    closeOnEscape
                    closeOnDimmerClick
                >
                    <CarrierPanel />
                </Modal>
                {/* Панель администратора */}
                <Modal
                    className='modal-admin-panel'
                    size='fullscreen'
                    style={{ height: '70%' }}
                    open={this.state.openAdmin}
                    onClose={this.closeAdminPanel}
                    closeOnEscape
                    closeOnDimmerClick
                >
                    <AdminPanel />
                </Modal>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        role: state.flags.role,
        logout: state.flags.logout,
        feature: state.flags.feature,
        noticeOpen: state.flags.show.noticeOpen
    };
};

const mapDispatchToProps = dispatch => {
    return {
        //  flags
        onGetLogoutStatus: logout => {
            dispatch(getLogoutStatus({ logout: logout }));
        },
        onResetFlags: () => {
            dispatch(resetFlags(defaultFlags));
        },

        //  tanks
        onResetTanks: () => {
            dispatch(resetTanks(defaultTanks));
        },

        //  carrier
        onResetCarrier: () => {
            dispatch(resetCarrier(defaultCarrier));
        },

        //  notice
        onResetNotice: () => {
            dispatch(resetNotice(defaultNotice));
        }
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(NavBar);
