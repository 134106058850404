import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Button, Modal, Segment, Tab } from 'semantic-ui-react';

import XmlFileView from './XmlFileView';
import FileFormTmp from './FileFormTmp';

import { closeFile } from '../actions/notice';

class FileContainerTmp extends Component {
    close = () => {
        this.props.onCloseFile();
    };

    exportFile = str => {
        var filename = this.props.file_tmp_type + '.xml';
        var link = document.createElement('a');
        link.download = filename;
        var blob = new Blob([str], { type: 'text/plain' });
        link.href = window.URL.createObjectURL(blob);
        link.click();
    };

    render() {
        const { show_file_tmp, file_string } = this.props;
        const panes = [
            {
                menuItem: 'XML',
                render: () => (
                    <Tab.Pane className='xml-view'>
                        <XmlFileView file_string={file_string} />
                    </Tab.Pane>
                )
            },
            {
                menuItem: 'Форма',
                render: () => (
                    <Tab.Pane className='form-view'>
                        <FileFormTmp />
                    </Tab.Pane>
                )
            }
        ];
        return (
            <div>
                <Modal size={'large'} open={show_file_tmp} dimmer='blurring'>
                    <Modal.Content
                        className='file-content'
                        style={{ padding: '0px' }}
                    >
                        <div className='file-content-div'>
                            <Tab className='file-content-tab' panes={panes} />
                        </div>
                    </Modal.Content>
                    <Modal.Actions>
                        <Segment
                            basic
                            clearing
                            style={{ padding: '0px', margin: '0px' }}
                        >
                            <Button
                                floated='left'
                                color='yellow'
                                onClick={() => this.exportFile(file_string)}
                            >
                                Выгрузить
                            </Button>
                            <Button
                                floated='right'
                                negative
                                onClick={this.close}
                            >
                                Закрыть
                            </Button>
                        </Segment>
                    </Modal.Actions>
                </Modal>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        show_file_tmp: state.notice.show_file_tmp,
        file_string: state.notice.file_string,
        file_tmp_type: state.notice.file_tmp_type
    };
};

const mapDispatchToProps = dispatch => {
    return {
        onCloseFile: () => {
            dispatch(
                closeFile({
                    show_file_tmp: false,
                    file_string: '',
                    file_tmp_type: '',
                    file_json: {}
                })
            );
        }
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(FileContainerTmp);
