import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Segment } from 'semantic-ui-react';

import NoData from './NoData';
import NoticeForm from './NoticeForm';

import { setNoticeOpen } from '../actions/flags';

class SendNotices extends Component {
    componentDidMount() {
        this.props.onSetNoticeOpen(!this.props.noticeOpen);
    }

    componentWillUnmount() {
        this.props.onSetNoticeOpen(!this.props.noticeOpen);
    }

    render() {
        var text = 'Данный функционал в процессе доработки';
        return (
            <div className='notice-form'>
                {false ? (
                    <NoData text={text} />
                ) : (
                    <Segment style={{ width: '50%' }}>
                        <NoticeForm />
                    </Segment>
                )}
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        noticeOpen: state.flags.show.noticeOpen
    };
};

const mapDispatchToProps = dispatch => {
    return {
        onSetNoticeOpen: flag => {
            dispatch(setNoticeOpen({ noticeOpen: flag }));
        }
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(SendNotices);
