import React, { Component } from 'react';
import { connect } from 'react-redux';

import API from '../API';
import AllData from './AllData';

import { freqAdmin } from '../constants';

import { getUsersList } from '../actions/flags';

class AdminPanel extends Component {
    componentDidMount() {
        API.GET('/users', null, res => {
            this.props.onGetUsersList(res.data);
        });
        this.timerAdmin = setInterval(() => {
            API.GET('/users', null, res => {
                this.props.onGetUsersList(res.data);
            });
        }, freqAdmin);
    }

    componentWillUnmount() {
        clearInterval(this.timerAdmin);
    }

    render() {
        const { data } = this.props;
        return (
            <div className='admin-panel'>
                <AllData data={data} />
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        data: state.flags
    };
};

const mapDispatchToProps = dispatch => {
    return {
        onGetUsersList: data => {
            dispatch(getUsersList({ users: data }));
        }
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(AdminPanel);
