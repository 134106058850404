import React from 'react';
import { Icon } from 'semantic-ui-react';

const MapShow = ({ showMap, onShowMap }) => {
    return (
        <div>
            <Icon
                link
                name='map marker alternate'
                size='big'
                color={showMap ? 'red' : 'blue'}
                onClick={() => onShowMap(!showMap)}
            />
            <Icon
                link
                name={showMap ? 'chevron up' : 'chevron down'}
                size='large'
                color={showMap ? 'red' : 'blue'}
                onClick={() => onShowMap(!showMap)}
            />
        </div>
    );
};

export default MapShow;
