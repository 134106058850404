/* первоначальное состояние редьюсера carrier по умолчанию */
export const defaultCarrier = {
    utm_servers: [],
    carriers: [],
    shippers: [],
    consignees: [],
    producers: [],
    sync_history: [],
    products: [],
    notice_history: [],
    show: {
        carrier_loading: [],
        shipper_loading: [],
        consignee_loading: [],
        producer_loading: [],
        open: false,
        response: '',
        carrier_wait: false,
        shipper_wait: false,
        consignee_wait: false,
        producer_wait: false,
        carrier_1_query: false,
        shipper_1_query: false,
        consignee_1_query: false,
        producer_1_query: false,
        notices_loading: [],
        sync_product_loading: [],
        sync_tank_org_loading: []
    },
    utm_tracking: false,
    ssh_pass: ''
};
