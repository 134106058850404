import { defaultNotice } from '../defaultNotice';

import {
    CHANGE_FORM_FIELD,
    CHANGE_POSITIONS_NUMBER,
    CHANGE_FORM_FIELD_POSITION,
    RESET_NOTICE,
    SHOW_FILE,
    CLOSE_FILE,
    SAVE_FILE_IDX
} from '../actions/notice';

import {
    reduceChangeInput,
    reduceChangePositionsNumber,
    reduceChangeInputPosition,
    deleteOldAndInsertNew
} from '../defaultReducers';

export const notice = (state = defaultNotice, action) => {
    switch (action.type) {
        case CHANGE_FORM_FIELD:
        case SHOW_FILE:
        case CLOSE_FILE:
        case SAVE_FILE_IDX:
            return reduceChangeInput(state, action.payload);
        case CHANGE_POSITIONS_NUMBER:
            return reduceChangePositionsNumber(state, action.payload);
        case CHANGE_FORM_FIELD_POSITION:
            return reduceChangeInputPosition(state, action.payload);
        case RESET_NOTICE:
            return deleteOldAndInsertNew(state, action.payload);
        default:
            return state;
    }
};
