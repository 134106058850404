import React from 'react';
import { Segment } from 'semantic-ui-react';

const ParkingInfoNew = ({ start, end }) => {
    return (
        <Segment compact>
            {'Стоянка - с ' +
                start.replace('T', ' ').replace('Z', '') +
                ' по ' +
                end.replace('T', ' ').replace('Z', '')}
        </Segment>
    );
};

export default ParkingInfoNew;