import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
    Header,
    Icon,
    Button,
    Modal,
    Form,
    Segment,
    Tab
} from 'semantic-ui-react';

import API from '../API';
import XmlFileView from './XmlFileView';
import FileFormTmp from './FileFormTmp';

import { date, SEND_CARRIER_NOTICE } from '../constants';

import { defaultNotice } from '../defaultNotice';

import {
    closeFile,
    resetNotice,
    changeFormField,
    changePositionsNumber
} from '../actions/notice';

import {
    writeResponse,
    setNoticeProcessing,
    unsetNoticeProcessing
} from '../actions/carrier';

import { parseMoment } from '../functions';

import { WRONG_PSWD_ON_NOTICE, WRONG_PASSWORD } from './ResponseModal';

class FileContainer extends Component {
    state = {
        inputPass: false,
        pass: '',
        passErr: false
    };

    close = () => {
        this.props.onCloseFile();
    };

    handleChangePass = (e, { name, value }) => this.setState({ [name]: value });

    getAccess = () => {
        //  отображаем модал для ввода пароля
        this.setState({ inputPass: true });
    };

    closeGetAccess = () => this.setState({ inputPass: false, pass: '' });

    checkPasswordIsEmpty = (pass, key) => {
        if (pass === '') {
            this.setState({ [key]: true });
            setTimeout(() => this.setState({ [key]: false }), 1000);
            return false;
        }
        return true;
    };

    sendNotice = () => {
        if (!this.checkPasswordIsEmpty(this.state.pass, 'passErr')) {
            return;
        }
        const file_id = this.props.file_idx;

        this.props.onSetNoticeProcessing(SEND_CARRIER_NOTICE, Number(file_id));

        var data = {
            new_pass: this.state.pass,
            notice: file_id
        };

        API.POST(
            '/carrier/' + SEND_CARRIER_NOTICE + '/send_notice',
            data,
            res => {
                if (res.data === WRONG_PASSWORD) {
                    this.props.onWriteResponse(WRONG_PSWD_ON_NOTICE);
                } else {
                    this.props.onWriteResponse(res.data);
                }
                this.props.onUnsetNoticeProcessing(
                    SEND_CARRIER_NOTICE,
                    Number(file_id)
                );
            }
        );
        this.clearForm();
        this.close();
        this.setState({ inputPass: false, pass: '' });
    };

    clearForm = async () => {
        await this.props.onResetNotice();
        this.props.onChangeFormField({
            carrier: this.props.carrier.carriers[0]
        });
        this.props.onChangePositionsNumber(1);
        this.props.onChangeFormField({
            notif_date: parseMoment(date.defaultCurrDate, 'notice_date')
        });
    };

    cancelSend = () => {
        var data = { notice: this.props.file_idx };
        API.POST('/carrier/cancel_send', data, res => {
            this.props.onWriteResponse(res.data);
            setTimeout(() => {
                this.clearForm();
                this.close();
            }, 1000);
        });
    };

    render() {
        const { show_file, file_string } = this.props;
        const { inputPass, pass, passErr } = this.state;
        const panes = [
            {
                menuItem: 'XML',
                render: () => (
                    <Tab.Pane className='xml-view'>
                        <XmlFileView file_string={file_string} />
                    </Tab.Pane>
                )
            },
            {
                menuItem: 'Форма',
                render: () => (
                    <Tab.Pane className='form-view'>
                        <FileFormTmp />
                    </Tab.Pane>
                )
            }
        ];
        return (
            <div>
                <Modal size={'large'} open={show_file} dimmer='blurring'>
                    <Header
                        icon={<Icon color='green' name='check' />}
                        content={
                            'Уведомление о перевозке успешно создано и готово к отправке.'
                        }
                    />
                    <Modal.Content
                        className='send-file-content'
                        style={{ padding: '0px' }}
                    >
                        <div className='send-file-content-div'>
                            <Tab
                                className='send-file-content-tab'
                                panes={panes}
                            />
                        </div>
                    </Modal.Content>
                    <Modal.Actions>
                        <Segment
                            basic
                            clearing
                            style={{ padding: '0px', margin: '0px' }}
                        >
                            <Button
                                floated='left'
                                color='green'
                                onClick={this.getAccess}
                            >
                                Отправить уведомление
                            </Button>
                            <Button
                                floated='right'
                                negative
                                onClick={this.cancelSend}
                            >
                                Не отправлять уведомление
                            </Button>
                        </Segment>
                    </Modal.Actions>
                </Modal>
                <Modal
                    size='tiny'
                    open={inputPass}
                    closeOnEscape={false}
                    closeOnDimmerClick={false}
                >
                    <Modal.Header>Введите пароль доступа к УТМ</Modal.Header>
                    <Modal.Content>
                        <h4>
                            Данная операция может занять от 3 до 5 минут.
                            Текущий статус операции можно будет наблюдать во
                            вкладке "История уведомлений".
                        </h4>
                        <Form>
                            <Form.Group>
                                <Form.Input
                                    type='password'
                                    placeholder='Введите пароль'
                                    name='pass'
                                    value={pass}
                                    onChange={this.handleChangePass}
                                    error={passErr}
                                />
                            </Form.Group>
                        </Form>
                    </Modal.Content>
                    <Modal.Actions>
                        <Button
                            positive
                            content='Подтвердить'
                            onClick={this.sendNotice}
                        />
                        <Button negative onClick={this.closeGetAccess}>
                            Закрыть
                        </Button>
                    </Modal.Actions>
                </Modal>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        show_file: state.notice.show_file,
        file_string: state.notice.file_string,
        file_idx: state.notice.file_idx,
        carrier: state.carrier
    };
};

const mapDispatchToProps = dispatch => {
    return {
        //  notice
        onCloseFile: () => {
            dispatch(
                closeFile({ show_file: false, file_string: '', file_json: {} })
            );
        },
        onResetNotice: () => {
            dispatch(resetNotice(defaultNotice));
        },
        onChangeFormField: data => {
            dispatch(changeFormField(data));
        },
        onChangePositionsNumber: i => {
            dispatch(changePositionsNumber(i));
        },

        //  carrier
        onWriteResponse: res => {
            dispatch(writeResponse({ open: true, response: res }));
        },
        onSetNoticeProcessing: (cmd, id) => {
            dispatch(setNoticeProcessing({ cmd: cmd, id: id }));
        },
        onUnsetNoticeProcessing: (cmd, id) => {
            dispatch(unsetNoticeProcessing({ cmd: cmd, id: id }));
        }
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(FileContainer);
