import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Grid } from 'semantic-ui-react';

import StateIO from './StateIO';
import CapsuleInfo from './CapsuleInfo';

import API from '../API';

import { getTankState, getCurrCapsule } from '../actions/tanks';

import { freqState, lineNameBoth, lineNameIN, lineNameOUT } from '../constants';

class TankState extends Component {
    componentDidMount() {
        API.GET(
            '/tanks/' + this.props.t.id + '/state',
            null,
            this.props.onGetTankState(this.props.t.id)
        );
        API.GET(
            '/tanks/' + this.props.t.id + '/curr_capsule',
            null,
            this.props.onGetCurrCapsule(this.props.t.id)
        );
        //  данный таймер обновляет текущие измерения системы.
        //  таймер выполняется пока пользователь не нажмет на ДРУГУЮ цистерну.
        //  таймер размещен здесь, а не в TankContent т.к. для танк-контейнеров и ЖД запрашиваемые данные не нужны
        this.timerState = setInterval(() => {
            //  доп проверок не нужно, componentDidMount "следит" за выполнением
            API.GET(
                '/tanks/' + this.props.t.id + '/state',
                null,
                this.props.onGetTankState(this.props.t.id)
            );
            API.GET(
                '/tanks/' + this.props.t.id + '/curr_capsule',
                null,
                this.props.onGetCurrCapsule(this.props.t.id)
            );
        }, freqState);
    }

    componentWillUnmount() {
        clearInterval(this.timerState);
    }

    render() {
        const { t, stateDisplay } = this.props;

        var lineName1;
        var linename2;
        var columns;
        if (t.tank_type === 1) {
            lineName1 = lineNameBoth;
            columns = 2;
        } else if (t.tank_type === 2) {
            lineName1 = lineNameIN;
            linename2 = lineNameOUT;
            columns = 3;
        } else {
            return null;
        }

        //  если возвращать null, то так красивее загружается
        if (!t.state) return null;

        return (
            <div className='tank-state' style={{ display: stateDisplay }}>
                <Grid columns={columns}>
                    <Grid.Row className='state-row'>
                        <Grid.Column style={{ paddingRight: '0px' }}>
                            <StateIO
                                modeName={lineName1}
                                state={t.state[0]}
                                mode={t.curr_data.mode}
                            />
                        </Grid.Column>
                        {/* Если 2 расходомера на цистерне, то отображаем два StateIO: приемка и отгрузка отдельно */}
                        {t.tank_type === 2 ? (
                            <Grid.Column
                                style={{
                                    paddingLeft: '0px',
                                    paddingRight: '0px'
                                }}
                            >
                                <StateIO
                                    modeName={linename2}
                                    state={t.state[1]}
                                    mode={t.curr_data.mode}
                                />
                            </Grid.Column>
                        ) : null}
                        <Grid.Column style={{ paddingLeft: '0px' }}>
                            <CapsuleInfo
                                curr_capsule={t.curr_capsule}
                                flag='curr'
                            />
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        tanks: state.tanks
    };
};

const mapDispatchToProps = dispatch => {
    return {
        onGetTankState: id => res => {
            dispatch(getTankState({ id: id, state: res.data }));
        },
        onGetCurrCapsule: id => res => {
            dispatch(getCurrCapsule({ id: id, curr_capsule: res.data }));
        }
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(TankState);
