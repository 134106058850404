import { deepCopy } from './functions';

/* ____________________________________________________ */
/* TANKS -> функции-преобразователи для редьюсера tanks */

/* обновляет списки цистерн (полный и отфильтрованный). */
export const reduceTanks = (state = {}, payload) => {
    return { ...state, ...payload };
};

/* payload в данном случае это текущие данные ВСЕХ цистерн выбранного перевозчика.
С сервера приходит curr_data (payload) для всех цистерн и эта curr_data раскидывается
по цистернам. Сделано это было по причине того что была проблема с "путанием цистерн",
когда curr_data запрашивалась частями для каждой цистерны отдельно. Проблема была на
бэкэнде, после его переписи проблема вроде бы ушла..Но пусть лучше curr_data продолжает
запрашиваться для всех цистерн одним запросом. */
export const reduceUpdateCurrData = (state = {}, payload) => {
    if (!payload.curr_data) return state;
    var newState = deepCopy(state);
    for (let i = 0; i < newState.filtered_tanks.length; i++) {
        for (let j = 0; j < payload.curr_data.length; j++) {
            if (newState.filtered_tanks[i].id === payload.curr_data[j].id) {
                newState.filtered_tanks[i].curr_data = payload.curr_data[j];
                newState.initial_tanks[i].curr_data = payload.curr_data[j];
                break;
            }
        }
    }
    return newState;
};

/* обновляет какие либо данные (payload) отдельно взятой цистерны в обоих списках цистерн */
export const reduceUpdateBoth = (state = {}, payload) => {
    return {
        ...state,
        filtered_tanks:
            state.filtered_tanks &&
            state.filtered_tanks.map(tank =>
                tank.id !== payload.id ? tank : { ...tank, ...payload }
            ),
        initial_tanks:
            state.initial_tanks &&
            state.initial_tanks.map(tank =>
                tank.id !== payload.id ? tank : { ...tank, ...payload }
            )
    };
};
/* ____________________________________________________ */

/* ____________________________________________________ */
/* FLAGS -> функции-преобразователи для редьюсера flags */

/* обновляет свойства редьюсера flags (все кроме тех, что находятся в свойстве show) */
export const reduceFlags = (state = {}, payload) => {
    return { ...state, ...payload };
};

/* обновляет свойства редьюсера flags (те, что находятся в свойстве show) */
export const reduceFlagsShow = (state = {}, payload) => {
    return {
        ...state,
        show: { ...state.show, ...payload }
    };
};
/* ____________________________________________________ */

/* ____________________________________________________ */
/* CARRIER -> функции-преобразователи для редьюсера carrier */
export const reduceCarrier = (state = {}, payload) => {
    return { ...state, ...payload };
};

export const reduceAddId = (state = {}, payload) => {
    var key;
    switch (payload.cmd) {
        case 1:
            key = 'carrier_loading';
            break;
        case 2:
            key = 'shipper_loading';
            break;
        case 3:
            key = 'consignee_loading';
            break;
        case 20:
            key = 'producer_loading';
            break;
        case 4:
        case 6:
            key = 'notices_loading';
            break;
        case 11:
        case 13:
            key = 'sync_product_loading';
            break;
        case 12:
        case 14:
            key = 'sync_tank_org_loading';
            break;
        default:
            return state;
    }
    return {
        ...state,
        show: {
            ...state.show,
            [key]: state.show[key].concat([payload.id])
        }
    };
};

export const reduceDelId = (state = {}, payload) => {
    var key;
    switch (payload.cmd) {
        case 1:
            key = 'carrier_loading';
            break;
        case 2:
            key = 'shipper_loading';
            break;
        case 3:
            key = 'consignee_loading';
            break;
        case 20:
            key = 'producer_loading';
            break;
        case 4:
        case 6:
            key = 'notices_loading';
            break;
        case 11:
        case 13:
            key = 'sync_product_loading';
            break;
        case 12:
        case 14:
            key = 'sync_tank_org_loading';
            break;
        default:
            return state;
    }
    return {
        ...state,
        show: {
            ...state.show,
            [key]: state.show[key].filter(el => el !== payload.id)
        }
    };
};

export const reduceCarrierShow = (state = {}, payload) => {
    return {
        ...state,
        show: { ...state.show, ...payload }
    };
};
/* ____________________________________________________ */

/* ____________________________________________________ */
/* NOTICE -> функции-преобразователи для редьюсера notice */
export const reduceChangeInput = (state = {}, payload) => {
    return { ...state, ...payload };
};

export const reduceChangePositionsNumber = (state = {}, payload) => {
    if (state.positions === payload) return state;
    var newState = deepCopy(state);
    newState.positions = payload;
    newState.position.push({
        pos_identity: payload.toString(),
        product: {},
        producer: {},
        quantity_20: undefined,
        alc_perc_20: undefined
    });

    return newState;
};

export const reduceChangeInputPosition = (state = {}, payload) => {
    return {
        ...state,
        position:
            state.position &&
            state.position.map(p =>
                p.pos_identity !== payload.pos_identity
                    ? p
                    : { ...p, ...payload.object }
            )
    };
};
/* ____________________________________________________ */

/* общая функция-преобразователь для всех редьюсеров; применяется для сброса редьюсера до значения по умолчанию. */
export const deleteOldAndInsertNew = (state = {}, payload) => {
    return payload;
};
