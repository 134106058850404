import { defaultFlags } from '../defaultFlags';

import {
    GET_LOGOUT_STATUS,
    GET_ROLE,
    GET_CARRIERS,
    INIT_CARRIER_ID,
    SET_CARRIER_ID,
    RESET_FLAGS,
    SHOW_MAP,
    INIT_TANK_ID,
    SET_TANK_ID,
    SET_BAD_REQUEST,
    SHOW_STATE,
    SET_NO_CURR_DATA,
    SHOW_INFO_WINDOW,
    SET_CURR_LOCATION,
    SHOW_TANKS,
    SHOW_TRACK,
    GET_TRACK,
    SET_FULL_TRACK,
    SET_MAP_TYPE,
    SHOW_MOVINGS,
    GET_MOVINGS,
    GET_CAPSULE,
    TOGGLE_INFOBOX,
    GET_TMPPRODUCT,
    SET_NOTICE_OPEN,
    TOGGLE_PARKING_INFO_OPEN,
    GET_ALL_TANKS,
    GET_USERS_LIST
} from '../actions/flags';

import {
    reduceFlags,
    reduceFlagsShow,
    deleteOldAndInsertNew
} from '../defaultReducers';

export const flags = (state = defaultFlags, action) => {
    switch (action.type) {
        case GET_LOGOUT_STATUS:
        case GET_ROLE:
        case GET_CARRIERS:
        case INIT_CARRIER_ID:
        case SET_CARRIER_ID:
        case INIT_TANK_ID:
        case SET_TANK_ID:
        case SET_BAD_REQUEST:
        case GET_TRACK:
        case GET_TMPPRODUCT:
        case GET_ALL_TANKS:
        case GET_MOVINGS:
        case GET_CAPSULE:
        case GET_USERS_LIST:
            return reduceFlags(state, action.payload);
        case SHOW_MAP:
        case SHOW_STATE:
        case SET_NO_CURR_DATA:
        case SHOW_INFO_WINDOW:
        case SET_CURR_LOCATION:
        case SHOW_TANKS:
        case SHOW_TRACK:
        case SET_FULL_TRACK:
        case SET_MAP_TYPE:
        case SHOW_MOVINGS:
        case TOGGLE_INFOBOX:
        case SET_NOTICE_OPEN:
        case TOGGLE_PARKING_INFO_OPEN:
            return reduceFlagsShow(state, action.payload);
        case RESET_FLAGS:
            return deleteOldAndInsertNew(state, action.payload);
        default:
            return state;
    }
};
