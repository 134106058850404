/* первоначальное состояние редьюсера notice по умолчанию */
export const defaultNotice = {
    positions: 0,
    client_identity: '',
    serial: '',
    shipper: {},
    consignee: {},
    carrier: {},
    client_transport: {},
    ship_out_date: '',
    ship_in_date: '',
    egais_fix_number_ttn: '',
    notif_number: '',
    notif_date: '',
    position: [],
    show_file: false,
    file_string: '',
    file_idx: undefined,
    show_file_tmp: false,
    file_tmp_type: '',
    file_json: {}
};
