import moment from 'moment';
import 'moment/locale/ru';

export const ADMIN = 'admin';

export const modes = {
    5: 'Остановка',
    7: 'Приемка',
    9: 'Отгрузка'
};

/* Типы карт */
export const mapTypes = [
    { key: 1, text: 'Дорожная', value: 'roadmap' },
    { key: 2, text: 'Гибрид', value: 'hybrid' }
];

//  Поля формы уведомления о перевозке
export const ns = {
    client_identity: 'Идентификатор отчета',
    serial: 'Серия документа',
    shipper: 'Отправитель', //  *
    consignee: 'Получатель', //  *
    carrier: 'Грузоперевозчик', //  заполнено по умолчанию и read-only
    client_transport: 'Транспортное средство', //  *
    ship_out_date: 'Дата отпуска груза', //  *
    ship_in_date: 'Дата получения груза', //  *
    egais_fix_number_ttn: 'Номер подтверждения о фиксации сведений об отгрузке',
    notif_number: 'Номер документа', //  *
    notif_date: 'Дата составления', //  *
    pos_identity: 'Идентификатор позиции внутри уведомления о перевозке', //  это не поле будет а просто текст типа "Позиция 1"
    product: 'Продукция', //  *
    producer: 'Производитель', //  *
    quantity_20: 'Количество Б/С, дал (при 20 ℃)', //  *
    alc_perc_20: 'Содержание ЭС, % (при 20 ℃)' //  *
};

export const ts = {
    ticket_date: 'Время создания',
    doc_type: 'Тип документа',
    transport_id: 'ReplyID',
    reg_id: 'Номер подтверждения',
    conclusion: 'Заключение',
    conclusion_date: 'Время фиксации',
    comments: 'Комментарий'
};

//  типы ответа сервера на манипуляции с пользователями
export const ResTypesEmails = {
    ERR_DB_CONN: 'Нет соединения с цистерной. Попробуйте позже.',
    NOT_DEL_USER_TANK: 'Не удалось удалить пользователя.',
    NOT_DEL_USER_SERVER: 'Не удалось удалить пользователя.',
    NO_SUCH_USER_SERVER: 'Пользователь не существует.',
    NO_SUCH_USER_TANK: 'Пользователь не существует.',
    DEL_USER_SUCCESS: 'Выбранные пользователи были успешно удалены!',
    ERR_DUPL_USER: 'Пользователь уже существует.',
    ERR_ADD_USER: 'Не удалось добавить пользователя.',
    ADD_USER_SUCCESS:
        'Пользователь успешно добавлен! Дождитесь его появления в списке..'
};

//  типы ответа сервера на манипуляции с продуктами
export const ResTypesProducts = {
    ERR_DB_CONN: 'Нет соединения с цистерной. Попробуйте позже.',
    EDIT_PROD_SUCCESS: 'Продукт успешно отредактирован!',
    NOT_EDIT_PROD: 'Не удалось отредактировать продукт',
    NOT_DEL_PROD_TANK: 'Не удалось удалить продукт',
    NOT_DEL_PROD_SERVER: 'Не удалось удалить продукт',
    NO_SUCH_PROD_SERVER: 'Нет такого продукта',
    NO_SUCH_PROD_TANK: 'Нет такого продукта',
    DEL_PROD_SUCCESS: 'Выбранные продукты были успешно удалены!'
};

//  типы ответа сервера на добавление продукта
export const ResTypesAddProduct = {
    ERR_DB_CONN: 'Нет соединения с цистерной. Попробуйте позже.',
    ERR_DUPL_PROD: 'Продукт уже добавлен',
    ERR_ADD_PROD: 'Не удалось добавить продукт',
    ADD_PROD_SUCCESS:
        'Продукт успешно добавлен!\nНе забудьте выбрать таблицу плотностей в разделе редактирования.'
};

//  типы ответа сервера на добавление таблицы плотностей
export const ResTypesDenstables = {
    ERR_SOMETHING_WRONG: 'Что-то пошло не так...',
    ERR_SSH_CONN: 'Нет соединения с цистерной. Попробуйте позже.',
    ERR_DB_CONN: 'Нет соединения с цистерной. Попробуйте позже.',
    ERR_WRONG_FILE_FORMAT: 'Некорректное расширение файла (должно быть ".bin")',
    ERR_CORRUPTED_FILE: 'Ошибка при передаче файла. Попробуйте еще раз.',
    ERR_NO_SUCCESS: 'Не удалось добавить таблицу плотностей.',
    ERR_DUPL_TABLE: 'Таблица плотностей уже существует.',
    SUCCESS: 'Таблица плотностей успешно добавлена!'
};

export const ResTypesAddOrgs = {
    ERR_ADD_ORG: 'Не удалось добавить контрагента',
    SUCCESS_ADD_ORG: 'Контрагент добавлен успешно',
    SUCCESS_ADD_CARRIER: 'Перевозчик успешно добавлен',
    SUCCESS_EDIT_CARRIER: 'ФСРАР ID перевозчика успешно отредактирован',
    ERR_DEL_ORG: 'Не удалось удалить контрагента',
    SUCCESS_DEL_ORG: 'Контрагент успешно удален',
    SOMETHING_WENT_WRONG: 'Что-то пошло не так...',
    NO_SUCH_ORG: 'Контрагент не найден'
};

export const ResTypesContractors = {
    ERR_DB_CONN: 'Нет соединения с цистерной. Попробуйте позже.',
    ERR_ADD_ORG: 'Не удалось добавить контрагента',
    ERR_DUPL_CONTRACTOR: 'Контрагент уже существует',
    SUCCESS_ADD_ORG: 'Контрагент добавлен успешно',
    ERR_DEL_ORG: 'Не удалось удалить контрагента',
    SUCCESS_DEL_ORG: 'Контрагент успешно удален',
    SOMETHING_WENT_WRONG: 'Что-то пошло не так...',
    NO_SUCH_ORG: 'Контрагент не найден'
};

export const ResTypesOnChange = {
    ERR_CONFIRM_PASS: 'Пароль не подтвержден',
    SUCCESS_CHANGE_PASS: 'Пароль успешно изменен',
    ERR_CHANGE_PASS: 'Не удалось сменить пароль',
    PASSWORD_FAIL: 'Неверный пароль',
    SOMETHING_WENT_WRONG: 'Что-то пошло не так...',
    SUCCESS_CHANGE_UTM_DATA: 'Параметры подключения к УТМ успешно изменены',
    ERR_CHANGE_UTM_DATA: 'Не удалось изменить параметры подключения к УТМ',
    INVALID_IP_ADDRESS: 'Некорректный IP адрес'
};

//  типы уведомлений
export const MsgTypes = [
    { type: 'Критические ошибки', i: 0 },
    { type: 'Сообщения о сертификатах', i: 1 },
    { type: 'Сообщения о суточных файлах', i: 2 }
];

export const lineNameBoth = 'Приемка/Отгрузка';
export const lineNameIN = 'Приемка';
export const lineNameOUT = 'Отгрузка';

export const ONLINE = 'ONLINE';
export const OFFLINE = 'OFFLINE';

/* Значения для DateSelector-ов по умолчанию */
export const date = {
    defaultCurrDate: moment(),
    defaultYesterday: moment().subtract(1, 'day'),
    defaultFrom: moment().subtract(1, 'week'),
    defaultTo: moment()
};

export const minParkingTime = 5;

/* ТАЙМЕРЫ */
export const freqLogout = 5000;
export const freqCurrData = 6000;
export const freqState = 6000;
export const freqMarker = 10;
export const freqEmails = 6000;
export const freqProducts = 6000;
export const freqSyncs = 6000;
export const freqDenstables = 6000;
export const freqCarrier = 10000;
export const freqUtmConn = 120000;
export const freqContractors = 6000;
export const freqAdmin = 10000;

/* типы команд синхронизации данных об организации */
export const CARRIER = 1;
export const SHIPPER = 2;
export const CONSIGNEE = 3;
export const PRODUCER = 20;
export const SEND_CARRIER_NOTICE = 4;
export const WAIT_SYNC_TICKET = 5;
export const WAIT_NOTICE_TICKET = 6;
export const CREATE_CARRIER_NOTICE = 7;
export const SHOW_TICKET = 8;
export const SHOW_CARRIER_NOTICE = 9;
export const CHECK_UTM_CONN = 10;
export const PRODUCTS = 11;
export const TANK_ORG = 12;
export const WAIT_PRODUCTS = 13;
export const WAIT_TANK_ORG = 14;
