import React, { Component } from 'react';
import {
  Segment,
  Form,
  Icon,
  Input,
  Grid,
  Header,
  Modal,
  Button,
  Popup
} from 'semantic-ui-react';
import { connect } from 'react-redux';
import { DragDropContext,/*DndProvider, CreateDndContext,*/ DragSource, DropTarget } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';

import API from '../API';
import Pagination from './Pagination';

import { ResTypesAddProduct } from '../constants';

const TmpProductTbl = {
  product_id: { name: 'Код АП', width: 4 },
  spirit_code: { name: 'Код вида АП', width: 2 },
  full_name: { name: 'Полное наименование', width: 8 },
  alc_tu: { name: 'Крепость по ТУ', width: 2 }
};

const TblProduct = {
  product_id: { name: 'Код АП', width: 3 },
  spirit_code: { name: 'Код вида АП', width: 2 },
  full_name: { name: 'Полное наименование', width: 6 },
  alc_tu: { name: 'Крепость по ТУ', width: 2 },
  str_prod: { name: 'Название', width: 3 }
};

//  поле по которому ведется поиск
const fields = [
  {
    key: 'product_id'
  }
];

/* Компонент - название столбца */
const HeaderCell = ({ width, name }) => {
  return (
    <Grid.Column width={width} style={{ padding: '0px' }}>
      <Segment
        inverted
        color='blue'
        secondary
        size='large'
        textAlign='center'
        style={{ height: '50px' }}
      >
        {name}
      </Segment>
    </Grid.Column>
  );
};

/* Компонент - ячейка в таблице */
const TableCell = ({ width, value }) => {
  return (
    <Grid.Column width={width} style={{ padding: '0px' }}>
      <Segment textAlign='center' style={{ height: '50px' }}>
        {value}
      </Segment>
    </Grid.Column>
  );
};

/* Компонент - заголовок таблицы справочника продуктов егаис */
const TmpTableHeader = ({ tbl, columns }) => {
  return (
    <Grid columns={columns} padded>
      <HeaderCell
        key={1}
        width={tbl['product_id'].width}
        name={tbl['product_id'].name}
      />
      <HeaderCell
        key={2}
        width={tbl['spirit_code'].width}
        name={tbl['spirit_code'].name}
      />
      <HeaderCell
        key={4}
        width={tbl['alc_tu'].width}
        name={tbl['alc_tu'].name}
      />
      <HeaderCell
        key={3}
        width={tbl['full_name'].width}
        name={tbl['full_name'].name}
      />
    </Grid>
  );
};

/* Компонент - заголовок таблицы продуктов */
const TableHeader = ({ tbl, columns }) => {
  return (
    <Grid columns={columns} padded>
      <HeaderCell
        key={1}
        width={tbl['product_id'].width}
        name={tbl['product_id'].name}
      />
      <HeaderCell
        key={2}
        width={tbl['spirit_code'].width}
        name={tbl['spirit_code'].name}
      />
      <HeaderCell
        key={4}
        width={tbl['alc_tu'].width}
        name={tbl['alc_tu'].name}
      />
      <HeaderCell
        key={5}
        width={tbl['str_prod'].width}
        name={tbl['str_prod'].name}
      />
      <HeaderCell
        key={3}
        width={tbl['full_name'].width}
        name={tbl['full_name'].name}
      />
    </Grid>
  );
};

/* Компонент - строка в таблице продуктов или справочнике егаис, в зависимости от свойства connectDragSource (подключен ли компонент через
функцию DragSource()) */
class ProductItem extends Component {
  render() {
    const {
      isDragging,
      connectDragSource,
      data,
      tbl,
      columns
    } = this.props;
    if (connectDragSource != null) {
      return connectDragSource(
        <div style={{ opacity: isDragging ? 0.5 : 1 }}>
          <Grid columns={columns} padded>
            <TableCell
              key={1}
              width={tbl['product_id'].width}
              value={data['product_id']}
            />
            <TableCell
              key={2}
              width={tbl['spirit_code'].width}
              value={data['spirit_code']}
            />
            <TableCell
              key={3}
              width={tbl['alc_tu'].width}
              value={data['alc_tu']}
            />
            <TableCell
              key={4}
              width={tbl['full_name'].width}
              value={data['full_name']}
            />
          </Grid>
        </div>,
        { dropEffect: 'copy' }
      );
    } else {
      return (
        <div>
          <Grid columns={columns} padded>
            <TableCell
              key={1}
              width={tbl['product_id'].width}
              value={data['product_id']}
            />
            <TableCell
              key={2}
              width={tbl['spirit_code'].width}
              value={data['spirit_code']}
            />
            <TableCell
              key={3}
              width={tbl['alc_tu'].width}
              value={data['alc_tu']}
            />
            <TableCell
              key={4}
              width={tbl['str_prod'].width}
              value={
                data['str_prod'].Valid
                  ? data['str_prod'].String
                  : ''
              }
            />
            <TableCell
              key={5}
              width={tbl['full_name'].width}
              value={data['full_name']}
            />
          </Grid>
        </div>
      );
    }
  }
}

/* начало описания перетаскиваемого компонента */
/* Делаем компонент ProductItem перетаскиваемым -> ProductItemDraggable. Описываем поведение в перетаскивании, результат, какие данные передаются
при этом.  */
const productSource = {
  beginDrag(props) {
    return {
      product: props
    };
  },
  endDrag(props, monitor) {
    if (!monitor.didDrop()) {
      return;
    }
    const item = monitor.getItem();
    const dropResult = monitor.getDropResult();

    if (dropResult) {
      props.addProduct(item.product.id, { ...item.product.data });
    }
  }
};

const collectDrag = (connect, monitor) => {
  return {
    connectDragSource: connect.dragSource(),
    isDragging: monitor.isDragging(),
    connectDragPreview: connect.dragPreview()
  };
};

const ProductItemDraggable = DragSource(
  'productItem',
  productSource,
  collectDrag
)(ProductItem);
/* конец описания перетаскиваемого компонента */

/* начало описания секции для вложения компонента */
/* Делаем компонент TableProduct целью (зоной) для перетаскивания -> TableProductTarget. */
const productTarget = {
  drop(props) {
    return props;
  }
};

const collectDrop = (connect, monitor) => {
  return {
    connectDropTarget: connect.dropTarget(),
    isOver: monitor.isOver()
  };
};

class TableProduct extends Component {
  render() {
    const { connectDropTarget, isOver, data, tbl, columns } = this.props;
    const styles = isOver ? { opacity: 0.7 } : null;
    return connectDropTarget(
      <div className='tablearea3-body-div'>
        <Segment
          className='tablearea3-body'
          attached
          style={{ padding: '0px', ...styles }}
        >
          <TableHeader tbl={TblProduct} columns={5} />
          <Segment
            className='tablearea3-rows'
            style={{
              marginTop: '0px',
              padding: '0px',
              overflow: 'auto'
            }}
          >
            {data.map((row, i) => (
              <ProductItem
                key={i}
                data={row}
                tbl={tbl}
                columns={columns}
              />
            ))}
          </Segment>
        </Segment>
      </div>
    );
  }
}

const TableProductTarget = DropTarget(
  'productItem',
  productTarget,
  collectDrop
)(TableProduct);
/* конец описания секции для вложения компонента */

/* функция для программного клика на какую нибудь страницу в пагинации (idx). */
const toggleClickOnPageItem = idx => {
  var event = document.createEvent('MouseEvents');
  event.initMouseEvent(
    'click',
    true,
    true,
    window,
    0,
    0,
    0,
    0,
    0,
    false,
    false,
    false,
    false,
    0,
    null
  );
  var elem = document.getElementById('page-menu-item' + idx);
  if (elem) {
    elem.dispatchEvent(event);
    elem.focus();
  }
};


class AddingProduct extends Component {
  constructor(props) {
    super(props);

    let nextFilter;
    nextFilter = new RegExp('.*', 'g');

    this.filtered_data = this.props.tmpproduct;

    this.state = {
      filter: nextFilter,
      openModal: false,
      msgNeg: false,
      msgPos: false,
      response: '',
      loadingAdd: false,
      filtered_data: this.filtered_data,
      curr_filtered_data: [],
      no_pages: false,
      isOpen: false
    };
  }

  /* обрабатывает ввод пользователя и в зависимости от ввода фильтрует список продуктов и еще влияет на пагинацию.
   **на страницу отводится по 100 продуктов. */
  handleChange = e => {
    let newValue = e.target.value;
    let newFilter = this.state.filter;
    newFilter = new RegExp('.*' + newValue + '.*', 'gi');
    this.setState({ filter: newFilter }, () => {
      this.setState({ filtered_data: this.getFilteredData() }, () => {
        if (this.state.filtered_data.length > 0) {
          if (this.state.filtered_data.length > 100) {
            this.setState({ no_pages: false });
            toggleClickOnPageItem(0);
          } else {
            this.setState({ no_pages: true });
          }
          this.setState({ isOpen: false });
        } else {
          this.setState({ isOpen: true });
        }
      });
    });
  };

  handleClose = () => {
    this.setState({ isOpen: false });
  };

  /* возвращает отфильтрованный справочник ЕГАИС в зависимости от значения фильтра. */
  getFilteredData = () => {
    return this.props.tmpproduct.filter(row => {
      let result = fields.reduce((prev, field) => {
        let re = this.state.filter;
        if (typeof row[field.key] === 'string') {
          return prev && row[field.key].search(re) !== -1;
        } else {
          return prev && ('' + row[field.key]).search(re) !== -1;
        }
      }, true);
      return result;
    });
  };

  /* запускает процедуру добавления продукта на цистерну. */
  addProduct = (id, product) => {
    this.setState({ loadingAdd: true });
    var data = {};
    data = { ...product, action: 'add', id: id };
    API.POST('/product', data, res => {
      if (res.data === 'ADD_PROD_SUCCESS') {
        this.setState({
          openModal: true,
          response: res.data,
          msgPos: true,
          loadingAdd: false
        });
      } else {
        this.setState({
          openModal: true,
          response: res.data,
          msgNeg: true,
          loadingAdd: false
        });
      }
    });
  };

  openModal = () => this.setState({ openModal: true });

  closeModal = () =>
    this.setState({ openModal: false, msgNeg: false, msgPos: false });

  componentDidMount() {
    this.setState({ filtered_data: this.props.tmpproduct });
  }

  /* обрабатывает открытие другой страницы в пагинации.
  **
  есть filtered_data - это все продукты, на всех страницах пагинации, из справочника егаис отфильтрованные пользователем через поиск.
  есть curr_filtered_data - это часть filtered_data, помещающиеся на текущей выбранной странице. */
  onPageChanged = data => {
    const { currentPage, pageLimit } = data;

    const offset = (currentPage - 1) * pageLimit;
    const curr_filtered_data = this.state.filtered_data
      ? this.state.filtered_data.slice(offset, offset + pageLimit)
      : [];

    this.setState({ curr_filtered_data: curr_filtered_data });
  };

  render() {
    const { tblproduct, id } = this.props;
    const {
      filtered_data,
      curr_filtered_data,
      no_pages,
      isOpen
    } = this.state;
    const totalProducts = filtered_data ? filtered_data.length : 0;
    return (
      <div className='add-product'>
        <Grid className='add-grid' columns={2} divided padded>
          <Grid.Column
            className='column-tablearea3'
            style={{ paddingBottom: '0px' }}
          >
            <Segment
              className='tablearea3'
              style={{ padding: '0px' }}
            >
              <Header
                color='blue'
                textAlign='center'
                as='h2'
                attached='top'
                style={{ margin: '0px' }}
              >
                Таблица продуктов
                            </Header>
              <TableProductTarget
                data={tblproduct}
                tbl={TblProduct}
                columns={5}
              />
            </Segment>
          </Grid.Column>
          <Grid.Column
            className='column-tmpproduct'
            style={{ paddingBottom: '0px' }}
          >
            <Segment
              className='tmpproduct'
              style={{ padding: '0px' }}
            >
              <Header
                color='blue'
                textAlign='center'
                as='h2'
                attached='top'
                style={{ margin: '0px' }}
              >
                Справочник продуктов ЕГАИС
                            </Header>
              <Segment
                className='tmpproduct-body'
                attached
                style={{ padding: '0px' }}
              >
                <TmpTableHeader
                  tbl={TmpProductTbl}
                  columns={4}
                />
                <Segment
                  className='tmpproduct-rows'
                  style={{ marginTop: '0px', padding: '0px' }}
                  loading={this.state.loadingAdd}
                >
                  {!no_pages &&
                    curr_filtered_data.map((row, i) => (
                      <ProductItemDraggable
                        key={i}
                        data={row}
                        tbl={TmpProductTbl}
                        columns={4}
                        id={id}
                        addProduct={this.addProduct}
                      />
                    ))}
                  {no_pages &&
                    filtered_data.map((row, i) => (
                      <ProductItemDraggable
                        key={i}
                        data={row}
                        tbl={TmpProductTbl}
                        columns={4}
                        id={id}
                        addProduct={this.addProduct}
                      />
                    ))}
                </Segment>
              </Segment>
            </Segment>
          </Grid.Column>
        </Grid>
        <Grid className='add-footer' columns={2} padded>
          <Grid.Column>
            <Segment
              basic
              className='instructions'
              style={{ padding: '0px' }}
            >
              <div className='instructions-text'>
                Для того, чтобы добавить новый продукт, введите
                в поиске код, нужного Вам продукта (можно ввести
                последние 4-5 цифр), и перетащите мышкой
                найденный продукт в левую таблицу. Для выхода
                нажмите ESC.
                            </div>
            </Segment>
          </Grid.Column>
          <Grid.Column>
            <Grid className='footer-filter'>
              <Grid.Column floated='left' width={8}>
                <Segment
                  basic
                  className='filter-products'
                  style={{ padding: '0px' }}
                >
                  <Form>
                    <Form.Field inline>
                      <label>Введите код АП</label>
                      <Popup
                        basic
                        trigger={
                          <Input
                            icon='search'
                            placeholder='Поиск...'
                            onChange={
                              this.handleChange
                            }
                          />
                        }
                        content={
                          <div>
                            <p>
                              Если вдруг, при
                              поиске продукта, его
                              не оказалось в
                              справочнике ЕГАИС,
                              то попробуйте ввести
                              последние 4-5 цифр
                              кода АП. Если вдруг
                              и в этом случае
                              продукта не
                              оказалось, то
                              необходимо будет
                              провести
                              синхронизацию
                              справочника ЕГАИС.
                                                        </p>
                            <p>
                              *Перед проведением
                              синхронизации,
                              возможно, возникнет
                              необходимость
                              добавить
                              контрагента, в
                              случае если нужный
                              Вам продукт в
                              системе ЕГАИС
                              заведен не на Вашу
                              организацию.
                              Добавление можно
                              выполнить по
                              следующему пути:
                              Функционал ->
                              Контрагенты.
                                                        </p>
                            <p>
                              Синхронизацию можно
                              выполнить по
                              следующему пути:
                              Функционал ->
                              Таблица продуктов ->
                              Запустить
                              синхронизацию, нажав
                              на кнопку
                                                            <Button
                                disabled
                                size='mini'
                                icon
                                labelPosition='left'
                                primary
                              >
                                <Icon name='sync alternate' />
                                                                Cинх-ть
                                                                справочник
                                                                продуктов ЕГАИС
                                                            </Button>
                            </p>
                          </div>
                        }
                        open={isOpen}
                        onClose={this.handleClose}
                        onOpen={this.handleChange}
                        on='focus'
                      />
                    </Form.Field>
                  </Form>
                </Segment>
              </Grid.Column>
              <Grid.Column floated='right' width={8}>
                <Pagination
                  totalRecords={totalProducts}
                  pageLimit={100}
                  pageNeighbours={1}
                  onPageChanged={this.onPageChanged}
                />
              </Grid.Column>
            </Grid>
          </Grid.Column>
        </Grid>
        <Modal
          size='tiny'
          open={this.state.openModal}
          onClose={this.closeModal}
          dimmer='blurring'
        >
          <Header
            icon={
              <Icon
                color={
                  this.state.msgNeg
                    ? 'red'
                    : this.state.msgPos
                      ? 'green'
                      : 'red'
                }
                name={
                  this.state.msgNeg
                    ? 'close'
                    : this.state.msgPos
                      ? 'check'
                      : 'close'
                }
              />
            }
            content={ResTypesAddProduct[this.state.response]}
          />
          <Modal.Actions>
            <Button negative onClick={this.closeModal}>
              Закрыть
                        </Button>
          </Modal.Actions>
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    tmpproduct: state.flags.tmpproduct
  };
};

export default connect(
  mapStateToProps,
  null
)(DragDropContext(HTML5Backend)(AddingProduct));
