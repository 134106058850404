import React from 'react';
import { Modal, Button } from 'semantic-ui-react';

/* компонент отображает ответ сервера на попытку аутентификации. */
export const AuthMessage = ({ message, closeModal, openModal }) => {
    return (
        <div>
            <Modal basic className='scrolling' size='mini' open={openModal}>
                <Modal.Header>
                    <h2 style={{ color: 'red', textAlign: 'center' }}>
                        {message}
                    </h2>
                </Modal.Header>
                <Modal.Actions>
                    <Button onClick={closeModal} color='red'>
                        <h5>Закрыть</h5>
                    </Button>
                </Modal.Actions>
            </Modal>
        </div>
    );
};
