import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Segment } from 'semantic-ui-react';

import RangeDateTimeSelector from '../DatetimeSelectors/RangeDateTimeSelector';

import API from '../API';
import MaterialUIWrapper from '../MaterialUIWrapper';

import { ERR_NULL_VAL } from '../errors';

import {
    showMap,
    showTanks,
    showMovings,
    getMovings,
    getCapsule
} from '../actions/flags';

import {
    deepCopy,
    calculateDiv,
    splitInterval,
    checkInterval,
    comparingTimestamp,
    produceParkings
} from '../functions';

/* Данный компонент в части работы методов почти аналогичен компоненту Trips (читаем комменты в Trips). */
class Movings extends Component {
    _isMounted = false;

    state = {
        popup: false,
        popupMsg: '',
        active_btn: false,
        loading_btn: false,
        disabled_btn: false
    };

    getPartOfTrack = async (id, part) => {
        return new Promise((resolve, reject) => {
            API.GET(
                '/tanks/' + id + '/track',
                { from: part.from, to: part.to },
                res => {
                    if (!res.data || (res.data && res.data === ERR_NULL_VAL)) {
                        reject(null);
                    } else {
                        resolve(res.data);
                    }
                }
            );
        });
    };

    fail = error => {
        return { ok: false, error };
    };

    success = value => {
        return { ok: true, value };
    };

    checkResults = results => {
        if (results && results.length > 0) {
            //  просматриваем содержимое
            var k = 0;
            for (let i = 0; i < results.length; i++) {
                if (results[i].ok === false) k++;
            }
            if (k === results.length) {
                return false;
            } else {
                return true;
            }
        }
    };

    submitRangeDateTimeSelector = async row => {
        if (this._isMounted) {
            this.setState({ popupMsg: '' });
        }
        var param = deepCopy(row);
        if (this.state.active_btn) {
            if (this._isMounted) {
                this.setState({ active_btn: false, loading_btn: false });
            }
            this.props.onGetMovings(null);
            this.props.onShowTanks(true);
            this.props.onShowMovings(false);
        } else {
            if (checkInterval(param.start, param.end)) {
                API.GET(
                    '/tanks/' + this.props.id + '/capsule',
                    { from: param.start, to: param.end },
                    res => this.props.onGetCapsule(res),
                    null
                );

                var array = splitInterval(param);
                const promises = array.map(part =>
                    this.getPartOfTrack(this.props.id, part)
                );
                if (this._isMounted) {
                    this.setState({
                        active_btn: true,
                        loading_btn: true,
                        disabled_btn: true
                    });
                }
                const results = await Promise.all(
                    promises.map(p => p.then(this.success).catch(this.fail))
                );
                if (this._isMounted) {
                    this.setState({ loading_btn: false, disabled_btn: false });
                }
                if (this.checkResults(results)) {
                    var movings = [].concat.apply(
                        [],
                        results.map(t => (t.ok ? t.value : []))
                    );

                    movings.sort(comparingTimestamp);
                    var producedMovings = produceParkings(movings);

                    var div = calculateDiv(producedMovings.track.length);
                    var movingsForShow = [];
                    for (let i = 0; i < movings.length; i++) {
                        if (i % div === 0) {
                            movingsForShow.push(movings[i]);
                        }
                    }
                    var data = {};
                    data[this.props.id] = movingsForShow
                        .concat(producedMovings.parkings)
                        .sort(comparingTimestamp);
                    this.props.onGetMovings(data);
                    this.props.onShowMap(true);
                    this.props.onShowTanks(false);
                    this.props.onShowMovings(true);
                } else {
                    if (this._isMounted) {
                        this.setState({
                            popup: true,
                            popupMsg:
                                'Нет навигационных данных по выбранному интервалу'
                        });
                        setTimeout(() => {
                            this.setState({ active_btn: false, popup: false });
                        }, 2000);
                    }
                }
            } else {
                if (this._isMounted) {
                    this.setState({
                        popup: true,
                        popupMsg:
                            'Превышен лимит временного интервала (1 неделя)'
                    });
                    setTimeout(() => {
                        this.setState({ popup: false });
                    }, 2000);
                }
            }
        }
    };

    componentDidMount() {
        this._isMounted = true;
    }

    componentWillUnmount() {
        this.props.onGetMovings(null);
        this.props.onShowTanks(true);
        this.props.onShowMovings(false);
        this._isMounted = false;
    }

    render() {
        const {
            popup,
            active_btn,
            loading_btn,
            disabled_btn,
            popupMsg
        } = this.state;
        return (
            <Segment basic className='moving' style={{ padding: '0px' }}>
                <MaterialUIWrapper>
                    <RangeDateTimeSelector
                        submitRangeDateTimeSelector={
                            this.submitRangeDateTimeSelector
                        }
                        active_btn={active_btn}
                        loading_btn={loading_btn}
                        disabled_btn={disabled_btn}
                        popup={popup}
                        popupMsg={popupMsg}
                        textDateFrom='moving-date-from'
                        textDateTo='moving-date-to'
                        textTimeFrom='moving-time-from'
                        textTimeTo='moving-time-from'
                    />
                </MaterialUIWrapper>
            </Segment>
        );
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onShowMap: flag => {
            dispatch(showMap({ map: flag }));
        },
        onShowTanks: flag => {
            dispatch(showTanks({ tanks: flag }));
        },
        onShowMovings: flag => {
            dispatch(showMovings({ movings: flag }));
        },
        onGetMovings: data => {
            dispatch(getMovings({ movings: data }));
        },
        onGetCapsule: res => {
            dispatch(getCapsule({ capsule: res.data }));
        }
    };
};

export default connect(null, mapDispatchToProps)(Movings);
