import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Segment, Menu, Button, Confirm } from 'semantic-ui-react';

import API from '../API';
import TableSyncCommands from '../Tables/TableSyncCommands';

import { freqSyncs, PRODUCTS, TANK_ORG, WAIT_PRODUCTS } from '../constants';

import {
    writeResponse,
    addTankIDSyncLoading,
    delTankIDSyncLoading
} from '../actions/carrier';

class SyncCommandsContainer extends Component {
    state = {
        syncs: []
    };

    componentDidMount() {
        API.GET('/tanks/' + this.props.id + '/syncs', null, res => {
            this.setState({ syncs: res.data });
        });
        this.timerSyncs = setInterval(() => {
            API.GET('/tanks/' + this.props.id + '/syncs', null, res => {
                this.setState({ syncs: res.data });
            });
        }, freqSyncs);
    }

    componentWillUnmount() {
        clearInterval(this.timerSyncs);
    }

    /* константы PRODUCTS, WAIT_PRODUCTS, TANK_ORG, WAIT_TANK_ORG, использующиеся в методах ниже, лучше не менять!
    Потому что они связаны с константами в сервисе utm-worker. Это по сути типы команд для этого сервиса. Если и нужно
    поменять, то в utm-worker также нужно поменять! */
    syncProducts = () => {
        this.setState({ sync_confirm: true });
    };

    confirmSyncProducts = () => {
        this.props.onAddTankIDSyncLoading(PRODUCTS, this.props.id);
        this.setState({ sync_confirm: false });
        API.POST(
            '/tanks/sync_on_tank/' + this.props.id + '/' + PRODUCTS,
            null,
            res => {
                this.props.onDelTankIDSyncLoading(PRODUCTS, this.props.id);
                this.props.onWriteResponse(res.data);
            }
        );
    };

    сancelSyncProducts = () => this.setState({ sync_confirm: false });

    finishSyncProducts = idx => {
        this.props.onAddTankIDSyncLoading(WAIT_PRODUCTS, this.props.id);
        API.POST(
            '/tanks/sync_on_tank/' + this.props.id + '/' + WAIT_PRODUCTS,
            { sync_id: idx },
            res => {
                this.props.onDelTankIDSyncLoading(WAIT_PRODUCTS, this.props.id);
                this.props.onWriteResponse(res.data);
            }
        );
    };

    syncTankOrg = () => {
        this.setState({ sync_org_confirm: true });
    };

    confirmSyncTankOrg = () => {
        this.props.onAddTankIDSyncLoading(TANK_ORG, this.props.id);
        this.setState({ sync_org_confirm: false });
        API.POST(
            '/tanks/sync_on_tank/' + this.props.id + '/' + TANK_ORG,
            null,
            res => {
                this.props.onDelTankIDSyncLoading(TANK_ORG, this.props.id);
                this.props.onWriteResponse(res.data);
            }
        );
    };

    сancelSyncTankOrg = () => this.setState({ sync_org_confirm: false });

    render() {
        const { syncs } = this.state;
        const { show, id, tank_name } = this.props;
        return (
            <Segment className='files' basic style={{ padding: '0px' }}>
                <div className='date-selector'>
                    <Menu>
                        <Menu.Item>
                            <Button
                                primary
                                size='small'
                                style={{ width: '300px', height: '50px' }}
                                onClick={this.syncProducts}
                                disabled={
                                    show.sync_product_loading.includes(id)
                                        ? true
                                        : false
                                }
                                loading={
                                    show.sync_product_loading.includes(id)
                                        ? true
                                        : false
                                }
                            >
                                Cинхронизировать справочник продуктов ЕГАИС
                            </Button>
                        </Menu.Item>
                        <Menu.Item>
                            <Button
                                primary
                                size='small'
                                style={{ width: '300px', height: '50px' }}
                                onClick={this.syncTankOrg}
                                disabled={
                                    show.sync_tank_org_loading.includes(id)
                                        ? true
                                        : false
                                }
                                loading={
                                    show.sync_tank_org_loading.includes(id)
                                        ? true
                                        : false
                                }
                            >
                                Cинхронизировать данные о цистерне
                            </Button>
                        </Menu.Item>
                    </Menu>
                </div>
                <Segment basic textAlign='center' className='files-table'>
                    <TableSyncCommands
                        syncs={syncs}
                        products_sync={
                            show.sync_product_loading.includes(id)
                                ? true
                                : false
                        }
                        org_sync={
                            show.sync_tank_org_loading.includes(id)
                                ? true
                                : false
                        }
                        finishSyncProducts={this.finishSyncProducts}
                    />
                </Segment>
                <Confirm
                    open={this.state.sync_confirm}
                    content={
                        'Подтвердите запуск синхронизации справочника продуктов ЕГАИС на цистерне ' +
                        tank_name +
                        '. Время проведения синхронизации может занять до 15 минут. Желательно не перезагружать страницу.'
                    }
                    cancelButton='Закрыть'
                    confirmButton='Подтвердить'
                    onCancel={this.сancelSyncProducts}
                    onConfirm={this.confirmSyncProducts}
                />
                <Confirm
                    open={this.state.sync_org_confirm}
                    content={
                        'Подтвердите запуск синхронизации данных об организации на цистерне ' +
                        tank_name +
                        '. Время проведения синхронизации может занять до 5 минут. Желательно не перезагружать страницу.'
                    }
                    cancelButton='Закрыть'
                    confirmButton='Подтвердить'
                    onCancel={this.сancelSyncTankOrg}
                    onConfirm={this.confirmSyncTankOrg}
                />
            </Segment>
        );
    }
}

const mapStateToProps = state => {
    return {
        tank_name: state.flags.curr_tank_name,
        show: state.carrier.show
    };
};

const mapDispatchToProps = dispatch => {
    return {
        //  carrier
        onWriteResponse: res => {
            dispatch(writeResponse({ open: true, response: res }));
        },
        onAddTankIDSyncLoading: (cmd, id) => {
            dispatch(addTankIDSyncLoading({ cmd: cmd, id: id }));
        },
        onDelTankIDSyncLoading: (cmd, id) => {
            dispatch(delTankIDSyncLoading({ cmd: cmd, id: id }));
        }
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(SyncCommandsContainer);
