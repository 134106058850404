import React, { Component } from 'react';
import { Table, Button, Popup, Icon } from 'semantic-ui-react';
// utils
import dallDifferenceCheck from '../../utils/dallDifferenceCheck'

import NoData from '../Tables/NoData';

const TripsTableColumns = {
    start: 'Время начала перевозки',
    end: 'Время конца перевозки',
    input_volume: 'Принятый объем, дал',
    output_volume: 'Отгруженный объем, дал',
    product_name: 'Продукт',
    status: 'Статус'
};

const errorColor = '#f66'

class TripsTableData extends Component {
    state = {};

    render() {
        const {
            data,
            handleClickShowTrip,
            popup,
            active_btn,
            loading_btn,
            disabled_btn
        } = this.props;

        const isAllDataReady = data && data.length > 0;
        return (
            <div>
                {!isAllDataReady && <NoData />}
                {isAllDataReady && (
                    <div>
                        <Table collapsing compact celled textAlign='center'>
                            <Table.Header>
                                <Table.Row>
                                    <Table.HeaderCell key={1}>
                                        {TripsTableColumns['start']}
                                    </Table.HeaderCell>
                                    <Table.HeaderCell key={2}>
                                        {TripsTableColumns['end']}
                                    </Table.HeaderCell>
                                    <Table.HeaderCell key={3}>
                                        {TripsTableColumns['input_volume']}
                                    </Table.HeaderCell>
                                    <Table.HeaderCell key={4}>
                                        {TripsTableColumns['output_volume']}
                                    </Table.HeaderCell>
                                    <Table.HeaderCell key={5}>
                                        {TripsTableColumns['product_name']}
                                    </Table.HeaderCell>
                                    <Table.HeaderCell key={6}>
                                        Подробнее
                                    </Table.HeaderCell>
                                    <Table.HeaderCell key={7}>
                                        {TripsTableColumns['status']}
                                    </Table.HeaderCell>
                                </Table.Row>
                            </Table.Header>
                            <Table.Body>
                                {data.map((row, j) => {

                                const dallDifference = dallDifferenceCheck(
                                    row['input_volume'], 
                                    row['output_volume'], 
                                    row['status']
                                )

                                    return (
                                        <Table.Row key={j}>
                                            <Table.Cell key={1}>
                                                {row['start'] ===
                                                '0001-01-01T00:00:00Z'
                                                    ? '---'
                                                    : row['start']
                                                          .replace('T', ' ')
                                                          .replace('Z', '')}
                                            </Table.Cell>
                                            <Table.Cell key={2}>
                                                {row['end'] ===
                                                '0001-01-01T00:00:00Z'
                                                    ? '---'
                                                    : row['end']
                                                          .replace('T', ' ')
                                                          .replace('Z', '')}
                                            </Table.Cell>

                                            {/* ПРИНЯТЫЙ */}
                                            <Table.Cell key={3} style={dallDifference ? {backgroundColor: errorColor} : {}}>
                                                {row['input_volume'].toFixed(3)}
                                            </Table.Cell>

                                            {/* ОТГРУЖЕННЫЙ */}
                                            <Table.Cell key={4} style={dallDifference ? {backgroundColor: errorColor} : {}}>
                                                {row['output_volume'].toFixed(3)}
                                            </Table.Cell>

                                            {/* НАЗВАНИЕ ПРОДУКТА */}
                                            <Table.Cell key={5}>
                                                {row['product_name']}
                                            </Table.Cell>

                                            {/* ПОКАЗАТЬ МАРШРУТ */}
                                            <Table.Cell key={6}>
                                                <Popup
                                                    position='top center'
                                                    trigger={
                                                        <Button
                                                            basic={
                                                                active_btn ===
                                                                undefined
                                                                    ? true
                                                                    : active_btn ===
                                                                      j
                                                                    ? false
                                                                    : true
                                                            }
                                                            loading={
                                                                loading_btn ===
                                                                undefined
                                                                    ? false
                                                                    : loading_btn ===
                                                                      j
                                                                    ? true
                                                                    : false
                                                            }
                                                            disabled={
                                                                disabled_btn ===
                                                                undefined
                                                                    ? false
                                                                    : true
                                                            }
                                                            color={'blue'}
                                                            onClick={() => {
                                                                handleClickShowTrip(
                                                                    j,
                                                                    row
                                                                );
                                                            }}
                                                        >
                                                            {active_btn ===
                                                                undefined ||
                                                            active_btn !== j
                                                                ? 'Показать маршрут'
                                                                : 'Убрать маршрут'}
                                                        </Button>
                                                    }
                                                    content='Нет навигационных данных по выбранной перевозке'
                                                    open={
                                                        popup &&
                                                        active_btn === j &&
                                                        true
                                                    }
                                                />
                                            </Table.Cell>

                                            {/* СТАТУС */}
                                            <Table.Cell key={7}>
                                                {row['status'] ===
                                                'Завершена' ? (
                                                    <Icon
                                                        color='green'
                                                        name='checkmark'
                                                        size='large'
                                                    />
                                                ) : (
                                                    row['status']
                                                )}
                                            </Table.Cell>
                                        </Table.Row>
                                    );
                                })}
                            </Table.Body>
                        </Table>
                    </div>
                )}
            </div>
        );
    }
}

export default TripsTableData;
