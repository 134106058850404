import React, { Component } from 'react';
import {
    Segment,
    Table,
    Divider,
    Icon,
    Button,
    Modal,
    Header
} from 'semantic-ui-react';
import { FilePond } from 'react-filepond';

import API from '../API';

import { ResTypesDenstables } from '../constants';

const segment_out = {
    width: '650px',
    padding: '0px'
};

const divider = {
    marginTop: '20px',
    marginBottom: '15px'
};

const TableDensTables = {
    t_name_table: 'Название',
    t_dscr_table: 'Описание'
};

class Denstables extends Component {
    state = {
        files: [],
        buttonLoading: false,
        buttonDisable: false,
        openModal: false,
        response: '',
        success: false
    };

    //  отправляет файл (таблицу плотностей) на сервер для добавления ее на цисетрну
    sendDenstableToTank = file => {
        const formData = new FormData();
        formData.append('file', file);
        this.setState({ buttonLoading: true });
        API.POST('/tanks/' + this.props.id + '/denstables', formData, res => {
            this.setState({ buttonLoading: false });
            if (res.data === 'SUCCESS') {
                this.setState(
                    { openModal: true, response: res.data, success: true },
                    () => this.pond.removeFiles()
                );
            } else {
                this.setState({
                    openModal: true,
                    response: res.data,
                    success: false
                });
            }
        });
    };

    close = () => this.setState({ openModal: false });

    render() {
        const { tables } = this.props;
        const {
            files,
            buttonLoading,
            openModal,
            response,
            success
        } = this.state;
        const isAllDataReady = tables && tables.length > 0;
        const buttonDisable = files && files.length === 0;
        return (
            <div>
                <Segment basic style={segment_out}>
                    <Segment
                        basic
                        style={{
                            height: 'auto',
                            margin: '0px',
                            padding: '0px'
                        }}
                    >
                        <Table celled textAlign='center'>
                            <Table.Header>
                                <Table.Row>
                                    <Table.HeaderCell>
                                        {TableDensTables['t_name_table']}
                                    </Table.HeaderCell>
                                    <Table.HeaderCell>
                                        {TableDensTables['t_dscr_table']}
                                    </Table.HeaderCell>
                                </Table.Row>
                            </Table.Header>
                            <Table.Body>
                                {!isAllDataReady && (
                                    <Table.Row>
                                        <Table.Cell colSpan='2'>
                                            Нет добавленных таблиц плотностей
                                        </Table.Cell>
                                    </Table.Row>
                                )}
                                {isAllDataReady &&
                                    tables.map((row, i) => {
                                        return (
                                            <Table.Row key={i}>
                                                {Object.keys(row).map(
                                                    (k, j) => {
                                                        return (
                                                            <Table.Cell key={j}>
                                                                {k ===
                                                                    't_name_table' && (
                                                                    <Icon name='file outline' />
                                                                )}
                                                                {row[k]}
                                                            </Table.Cell>
                                                        );
                                                    }
                                                )}
                                            </Table.Row>
                                        );
                                    })}
                            </Table.Body>
                        </Table>
                    </Segment>
                    <Divider section style={divider} />
                    <Segment basic style={{ padding: '0px', margin: '0px' }}>
                        {/* библиотечный компонент для загрузки файлов */}
                        <FilePond
                            labelIdle='Для добавления таблицы плотностей, перетащите файл сюда или <a href="#">Выберите файл</a> и нажмите Отправить'
                            ref={ref => (this.pond = ref)}
                            files={files}
                            //  onupdatefiles нужно для занесения добавленного файла в state компонента
                            onupdatefiles={fileItems => {
                                this.setState({
                                    files: fileItems.map(
                                        fileItem => fileItem.file
                                    )
                                });
                            }}
                        />
                    </Segment>
                </Segment>
                <Button
                    color='green'
                    disabled={buttonDisable}
                    loading={buttonLoading}
                    onClick={() => this.sendDenstableToTank(files[0])}
                >
                    Отправить таблицу
                </Button>
                <Modal
                    size='tiny'
                    open={openModal}
                    onClose={this.close}
                    dimmer='blurring'
                >
                    <Header
                        icon={
                            <Icon
                                color={success ? 'green' : 'red'}
                                name={success ? 'check' : 'close'}
                            />
                        }
                        content={ResTypesDenstables[response]}
                    />
                    <Modal.Actions>
                        <Button negative onClick={this.close}>
                            Закрыть
                        </Button>
                    </Modal.Actions>
                </Modal>
            </div>
        );
    }
}

export default Denstables;
