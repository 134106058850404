import React, { Component } from 'react';
import moment from 'moment';
import { connect } from 'react-redux';
import {
    Table,
    Segment,
    Icon,
    Popup,
    Modal,
    Form,
    Button,
    Confirm,
    Menu,
    Header,
    Dropdown
} from 'semantic-ui-react';
import { DateInput } from 'semantic-ui-calendar-react';

import API from '../API';
import FileContainerTmp from './FileContainerTmp';
import NoData from './NoData';

import {
    ADMIN,
    SEND_CARRIER_NOTICE,
    WAIT_NOTICE_TICKET,
    SHOW_TICKET,
    SHOW_CARRIER_NOTICE
} from '../constants';

import {
    getDocInJSON,
    makeCarriersOptions,
    makeTanksOptions,
    reduceDate,
    parseStringToMomentDate,
    isSameOrAfterThatDay,
    isSameOrBeforeThatDay
} from '../functions';

import {
    writeResponse,
    setNoticeProcessing,
    unsetNoticeProcessing
} from '../actions/carrier';

import { showFile } from '../actions/notice';

import { WAYBILL_SUCCESS_DOWNLOAD } from './ResponseModal';

const conclusions = [
    { text: 'Не выбрано', value: 'Не выбрано' },
    { text: 'Принято', value: 'Accepted' },
    { text: 'Отказано', value: 'Rejected' },
    { text: 'В процессе', value: 'Processing' }
];

const TableColumns = {
    notif_number: '№',
    tank_name: 'Транспортное средство',
    dateinsert: 'Время создания',
    conclusion: 'Заключение',
    description_status: 'Статус',
    description_error: 'Ошибка',
    replyid: 'ReplyID',
    conclusionDate: 'Время ответа'
};

class NoticeHistory extends Component {
    state = {
        filtered_history: [],

        inputPass: false,
        pass: '',
        passErr: false,
        replyid: '',
        file_id: undefined,
        cmd_type: '',
        checked:
            this.props.history != null
                ? [
                      ...this.props.history.map(row => {
                          return {
                              id: row.id,
                              isOpen: false
                          };
                      })
                  ]
                : [{}],
        checkedId: undefined,
        openConfirm: false,
        carrier: '',
        carrier_id: 0,
        tank: '',
        from: moment().subtract(1,'month').format('YYYY-MM-DD'),
        to: '',
        conclusion: ''
    };

    handleOpen = id => {
        const payload = {
            id: id,
            isOpen: true
        };
        this.setState({
            checkedId: id,
            checked: this.state.checked.map(current =>
                current.id !== id
                    ? { ...current, id: current.id, isOpen: false }
                    : { ...current, ...payload }
            )
        });
    };

    handleClose = id => {
        const payload = {
            id: id,
            isOpen: false
        };
        this.setState({
            activeItem: '',
            checkedId: undefined,
            checked: this.state.checked.map(current =>
                current.id !== id ? current : { ...current, ...payload }
            )
        });
    };

    handleChangePass = (e, { name, value }) => this.setState({ [name]: value });

    getAccess = (id, str, name) => {
        const replyid = str;
        const file_id = id;

        //  отображаем модал для ввода пароля
        this.setState({
            inputPass: true,
            replyid: replyid,
            file_id: file_id,
            cmd_type: name
        });
    };

    closeGetAccess = () => this.setState({ inputPass: false, pass: '' });

    checkPasswordIsEmpty = (pass, key) => {
        if (pass === '') {
            this.setState({ [key]: true });
            setTimeout(() => this.setState({ [key]: false }), 1000);
            return false;
        }
        return true;
    };

    sendNotice = () => {
        const file_id = this.state.file_id.toString();
        this.props.onSetNoticeProcessing(SEND_CARRIER_NOTICE, Number(file_id));
        var data = {
            new_pass: this.state.pass,
            notice: file_id
        };

        API.POST(
            '/carrier/' + SEND_CARRIER_NOTICE + '/send_notice',
            data,
            res => {
                this.props.onWriteResponse(res.data);
                this.props.onUnsetNoticeProcessing(
                    SEND_CARRIER_NOTICE,
                    Number(file_id)
                );
            }
        );
        this.setState({ inputPass: false, pass: '' });
    };

    waitTicket = () => {
        const file_id = this.state.file_id.toString();
        this.props.onSetNoticeProcessing(WAIT_NOTICE_TICKET, Number(file_id));
        const replyid = this.state.replyid;
        var data = {
            new_pass: this.state.pass,
            replyid: replyid,
            notice: file_id
        };
        API.POST(
            '/carrier/' + WAIT_NOTICE_TICKET + '/wait_ticket',
            data,
            res => {
                this.props.onWriteResponse(res.data);
                this.props.onUnsetNoticeProcessing(
                    WAIT_NOTICE_TICKET,
                    Number(file_id)
                );
            }
        );
        this.setState({ inputPass: false, pass: '' });
    };

    produceNotice = cmd_type => {
        if (!this.checkPasswordIsEmpty(this.state.pass, 'passErr')) {
            return;
        }

        switch (cmd_type) {
            case 'telegram plane':
                this.sendNotice();
                break;
            case 'wait':
                this.waitTicket();
                break;
            default:
                return;
        }
    };

    showTicket = row => {
        const id = row.id.toString();
        const name = row.tank_name;
        var data = {
            notice: id,
            tank_name: name
        };
        API.POST('/carrier/' + SHOW_TICKET + '/show_ticket', data, res => {
            if (res.data.includes('<?xml')) {
                this.props.onShowFile(
                    res.data,
                    'ticket',
                    getDocInJSON(res.data)
                );
            } else {
                this.props.onWriteResponse(res.data);
            }
        });
    };

    showNotice = row => {
        const id = row.id.toString();
        const name = row.tank_name;
        var data = {
            notice: id,
            tank_name: name
        };
        API.POST(
            '/carrier/' + SHOW_CARRIER_NOTICE + '/show_notice',
            data,
            res => {
                if (res.data.includes('<?xml')) {
                    this.props.onShowFile(
                        res.data,
                        'notice',
                        getDocInJSON(res.data)
                    );
                } else {
                    this.props.onWriteResponse(res.data);
                }
            }
        );
    };

    downloadWaybill = notice_id => {
        const id = notice_id;
        var data = {
            waybill_id: id
        };
        API.GETFILE(
            '/reports/waybill',
            data,
            res => {
                if (res.status === 200) {
                    const url = window.URL.createObjectURL(
                        new Blob([res.data])
                    );
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', 'waybill.pdf');
                    document.body.appendChild(link);
                    link.click();
                    this.props.onWriteResponse(WAYBILL_SUCCESS_DOWNLOAD);
                }
            },
            err => this.props.onWriteResponse('Что-то пошло не так')
        );
    };

    deleteNotice = row => {
        this.setState({
            openConfirm: true,
            notif_number: row.notif_number,
            notif_id: row.id
        });
    };

    confirmDelete = () => {
        const notif_id = this.state.notif_id.toString();
        var data = {
            notice: notif_id
        };
        API.POST('/carrier/delete_notice', data, res => {
            this.props.onWriteResponse(res.data);
            this.setState({
                openConfirm: false,
                notif_number: '',
                notif_id: undefined
            });
        });
    };

    сancelDelete = () =>
        this.setState({
            openConfirm: false,
            notif_number: '',
            notif_id: undefined
        });

    editNotice = () => {
        this.props.onWriteResponse('Данная функция пока недоступна');
    };

    handleItemClick = row => (e, { name }) => {
        this.setState({ activeItem: name });
        switch (name) {
            case 'watch':
                this.showNotice(row);
                break;
            case 'edit':
                this.editNotice();
                break;
            case 'delete':
                this.deleteNotice(row);
                break;
            case 'watch-ticket':
                this.showTicket(row);
                break;
            case 'print':
                this.downloadWaybill(row.id);
                break;
            default:
                return;
        }
        this.handleClose(this.state.checkedId);
    };

    getCarrierID = name => {
        for (let i = 0; i < this.props.carriers.length; i++) {
            if (this.props.carriers[i].value === name)
                return this.props.carriers[i].carrier_id;
        }
    };

    /* ----------------------------------------------------------------------------------------- */
    filterHistoryByCarrierID = () => {
        return (
            this.props.history &&
            this.props.history.filter(
                row => row.carrier_id === this.state.carrier_id
            ) &&
            this.props.history.filter(
                row =>
            isSameOrAfterThatDay(
                parseStringToMomentDate(row.dateinsert.replace(/T[\s\S]+/, '')),
                parseStringToMomentDate(this.state.from)
            )
            )
        );
    };

    filterHistoryByTankID = () => {
        return this.state.filtered_history.filter(
            row => row.tank_name === this.state.tank
        );
    };

    filterHistoryByDateFrom = () => {
        return this.state.filtered_history.filter(row =>
            isSameOrAfterThatDay(
                parseStringToMomentDate(row.dateinsert.replace(/T[\s\S]+/, '')),
                parseStringToMomentDate(this.state.from)
            )
        );
    };

    filterHistoryByDateTo = () => {
        return this.state.filtered_history.filter(row =>
            isSameOrBeforeThatDay(
                parseStringToMomentDate(row.dateinsert.replace(/T[\s\S]+/, '')),
                parseStringToMomentDate(this.state.to)
            )
        );
    };

    /* возможно следует добавить более подробную фильтрацию по статусам и ошибкам */
    filterHistoryByConclusion = () => {
        return this.state.filtered_history.filter(
            row =>
                row.conclusion ===
                (this.state.conclusion === 'Processing'
                    ? ''
                    : this.state.conclusion)
        );
    };

    filter = () => {
        return new Promise(resolve => {
            return this.setState(
                {
                    filtered_history: this.filterHistoryByCarrierID()
                },
                () => {
                    this.setState(
                        {
                            filtered_history:
                                this.state.tank !== 'Не выбрано'
                                    ? this.filterHistoryByTankID()
                                    : this.state.filtered_history
                        },
                        () => {
                            this.setState(
                                {
                                    filtered_history:
                                        this.state.from !== ''
                                            ? this.filterHistoryByDateFrom()
                                            : this.state.filtered_history
                                },
                                () => {
                                    this.setState(
                                        {
                                            filtered_history:
                                                this.state.to !== ''
                                                    ? this.filterHistoryByDateTo()
                                                    : this.state
                                                          .filtered_history
                                        },
                                        () => {
                                            this.setState(
                                                {
                                                    filtered_history:
                                                        this.state
                                                            .conclusion !==
                                                        'Не выбрано'
                                                            ? this.filterHistoryByConclusion()
                                                            : this.state
                                                                  .filtered_history
                                                },
                                                () =>
                                                    resolve(
                                                        this.state
                                                            .filtered_history
                                                    )
                                            );
                                        }
                                    );
                                }
                            );
                        }
                    );
                }
            );
        });
    };

    handleChangeCarrier = (e, { name, value }) => {
        this.setState(
            {
                [name]: value,
                carrier_id: this.getCarrierID(value),
                tank: 'Не выбрано',
                conclusion: conclusions[0].value,
                from: '',
                to: ''
            },
            () => {
                this.setState({
                    filtered_history: this.filterHistoryByCarrierID()
                });
            }
        );
    };

    handleChangeTank = (e, { name, value }) => {
        this.setState(
            {
                [name]: value,
                from: '',
                to: '',
                conclusion: conclusions[0].value
            },
            () => this.filter()
        );
    };

    handleChangeDateFrom = (e, { name, value }) => {
        this.setState({ [name]: reduceDate(value, 'into') }, () =>
            this.filter()
        );
    };

    handleChangeDateTo = (e, { name, value }) => {
        this.setState({ [name]: reduceDate(value, 'into') }, () =>
            this.filter()
        );
    };

    handleChangeConclusion = (e, { name, value }) => {
        this.setState({ [name]: value }, () => this.filter());
    };
    /* ----------------------------------------------------------------------------------------- */

    componentDidMount() {
        this.setState(
            {
                filtered_history: this.props.history,
                carrier: this.props.carriers[0].value,
                carrier_id: this.props.carriers[0].carrier_id,
                tank: 'Не выбрано',
                conclusion: conclusions[0].value
            },
            () => {
                this.setState({
                    filtered_history: this.filterHistoryByCarrierID()
                });
            }
        );
    }

    componentWillReceiveProps(nextProps) {
        if (
            nextProps.history &&
            this.props.history &&
            nextProps.history.length !== this.props.history.length
        ) {
            this.setState({ filtered_history: nextProps.history }, () =>
                this.filter()
            );
        }
    }

    render() {
        const {
            filtered_history,
            carrier_id,
            tank,
            from,
            to,
            conclusion,
            inputPass,
            pass,
            passErr,
            cmd_type,
            activeItem,
            openConfirm,
            notif_number
        } = this.state;
        const { history, role, show, carriers, all_tanks, tanks } = this.props;
        const isAllDataReady = history && history.length > 0;
        var text = 'Уведомления о перевозках еще не отправлялись';
        const isThereFilteredData =
            history && filtered_history && filtered_history.length > 0;
        var noFilteredHistory = 'Нет уведомлений по заданным параметрам';
        return (
            <div className='div-notice-history'>
                {!isAllDataReady ? (
                    <NoData text={text} />
                ) : (
                    <Segment
                        basic
                        className='notice-history'
                        style={{ padding: '0px' }}
                    >
                        <div className='notice-history-filters'>
                            <Menu size='small'>
                                {role === ADMIN && (
                                    <Menu.Menu>
                                        <Menu.Item header>
                                            Перевозчик:
                                        </Menu.Item>
                                        <Menu.Item>
                                            <Dropdown
                                                name='carrier'
                                                onChange={
                                                    this.handleChangeCarrier
                                                }
                                                options={makeCarriersOptions(
                                                    carriers
                                                )}
                                                defaultValue={carriers[0].value}
                                                selection
                                            />
                                        </Menu.Item>
                                    </Menu.Menu>
                                )}
                                <Menu.Item header>Цистерна:</Menu.Item>
                                <Menu.Item>
                                    <Dropdown
                                        name='tank'
                                        onChange={this.handleChangeTank}
                                        value={tank}
                                        options={makeTanksOptions(
                                            role === ADMIN ? all_tanks : tanks,
                                            carrier_id
                                        )}
                                        selection
                                    />
                                </Menu.Item>
                                <Menu.Item header>Интервал:</Menu.Item>
                                <Menu.Item>
                                    <DateInput
                                        name='from'
                                        placeholder={'с'}
                                        value={reduceDate(from, 'from')}
                                        iconPosition='left'
                                        onChange={this.handleChangeDateFrom}
                                        clearable
                                    />
                                </Menu.Item>
                                <Menu.Item>
                                    <DateInput
                                        name='to'
                                        placeholder={'по'}
                                        value={reduceDate(to, 'from')}
                                        iconPosition='left'
                                        onChange={this.handleChangeDateTo}
                                        clearable
                                    />
                                </Menu.Item>
                                <Menu.Item header>Заключение:</Menu.Item>
                                <Menu.Item>
                                    <Dropdown
                                        compact
                                        name='conclusion'
                                        onChange={this.handleChangeConclusion}
                                        value={conclusion}
                                        options={conclusions}
                                        selection
                                    />
                                </Menu.Item>
                            </Menu>
                        </div>
                        <Segment
                            basic
                            textAlign='center'
                            className='notice-history-table'
                        >
                            {!isThereFilteredData ? (
                                <NoData text={noFilteredHistory} />
                            ) : (
                                <Table collapsing celled textAlign='center'>
                                    <Table.Header>
                                        <Table.Row>
                                            <Table.HeaderCell key={1}>
                                                {TableColumns['notif_number']}
                                            </Table.HeaderCell>
                                            <Table.HeaderCell key={2}>
                                                {TableColumns['tank_name']}
                                            </Table.HeaderCell>
                                            <Table.HeaderCell key={3}>
                                                {TableColumns['dateinsert']}
                                            </Table.HeaderCell>
                                            <Table.HeaderCell key={4}>
                                                {TableColumns['conclusion']}
                                            </Table.HeaderCell>
                                            <Table.HeaderCell key={5}>
                                                {
                                                    TableColumns[
                                                        'description_status'
                                                    ]
                                                }
                                            </Table.HeaderCell>
                                            <Table.HeaderCell key={6}>
                                                {
                                                    TableColumns[
                                                        'description_error'
                                                    ]
                                                }
                                            </Table.HeaderCell>
                                            <Table.HeaderCell key={7}>
                                                {TableColumns['replyid']}
                                            </Table.HeaderCell>
                                            <Table.HeaderCell key={8}>
                                                {TableColumns['conclusionDate']}
                                            </Table.HeaderCell>
                                            <Table.HeaderCell key={9}>
                                                Ответ
                                            </Table.HeaderCell>
                                            <Table.HeaderCell key={10}>
                                                Действия
                                            </Table.HeaderCell>
                                        </Table.Row>
                                    </Table.Header>
                                    <Table.Body>
                                        {filtered_history.map((row, i) => {
                                            var isOpen;
                                            for (
                                                let i = 0;
                                                i < this.state.checked.length;
                                                ++i
                                            ) {
                                                if (
                                                    this.state.checked[i].id ===
                                                    row.id
                                                ) {
                                                    isOpen = this.state.checked[
                                                        i
                                                    ].isOpen;
                                                    break;
                                                }
                                            }
                                            return (
                                                <Table.Row key={i}>
                                                    <Table.Cell key={1}>
                                                        {row['notif_number']}
                                                    </Table.Cell>
                                                    <Table.Cell key={2}>
                                                        {row['tank_name']}
                                                    </Table.Cell>
                                                    <Table.Cell key={3}>
                                                        {row['dateinsert']
                                                            .replace('T', ' ')
                                                            .replace('Z', '')}
                                                    </Table.Cell>
                                                    <Table.Cell key={4}>
                                                        {row['conclusion'] ===
                                                        'Accepted'
                                                            ? 'Принято'
                                                            : row[
                                                                  'conclusion'
                                                              ] === 'Rejected'
                                                            ? 'Отказано'
                                                            : ''}
                                                    </Table.Cell>
                                                    <Table.Cell key={5}>
                                                        {
                                                            row[
                                                                'description_status'
                                                            ]
                                                        }
                                                    </Table.Cell>
                                                    <Table.Cell key={6}>
                                                        {
                                                            row[
                                                                'description_error'
                                                            ]
                                                        }
                                                    </Table.Cell>
                                                    <Table.Cell key={7}>
                                                        {row['replyid']}
                                                    </Table.Cell>
                                                    <Table.Cell key={8}>
                                                        {row[
                                                            'conclusionDate'
                                                        ] &&
                                                            row[
                                                                'conclusionDate'
                                                            ]
                                                                .replace(
                                                                    'T',
                                                                    ' '
                                                                )
                                                                .substring(
                                                                    0,
                                                                    row[
                                                                        'conclusionDate'
                                                                    ].indexOf(
                                                                        '.'
                                                                    )
                                                                )}
                                                    </Table.Cell>
                                                    <Table.Cell key={9}>
                                                        <StatusIcon
                                                            row={row}
                                                            role={role}
                                                            getAccess={
                                                                this.getAccess
                                                            }
                                                            state={
                                                                show.notices_loading
                                                            }
                                                        />
                                                    </Table.Cell>
                                                    <Popup
                                                        trigger={
                                                            <Table.Cell
                                                                key={10}
                                                                collapsing
                                                                selectable
                                                            >
                                                                <Icon
                                                                    name='unordered list'
                                                                    size='large'
                                                                    color='blue'
                                                                    link
                                                                />
                                                            </Table.Cell>
                                                        }
                                                        content={
                                                            <Menu vertical>
                                                                <Menu.Item
                                                                    key={1}
                                                                    name='watch'
                                                                    active={
                                                                        activeItem ===
                                                                        'watch'
                                                                    }
                                                                    onClick={this.handleItemClick(
                                                                        row
                                                                    )}
                                                                >
                                                                    <Header as='h5'>
                                                                        <Icon name='eye' />
                                                                        Просмотреть
                                                                    </Header>
                                                                </Menu.Item>
                                                                {row.status ===
                                                                    8 && (
                                                                    <Menu.Item
                                                                        key={2}
                                                                        name='edit'
                                                                        active={
                                                                            activeItem ===
                                                                            'edit'
                                                                        }
                                                                        onClick={this.handleItemClick(
                                                                            row
                                                                        )}
                                                                    >
                                                                        <Header as='h5'>
                                                                            <Icon name='edit' />
                                                                            Редактировать
                                                                        </Header>
                                                                    </Menu.Item>
                                                                )}
                                                                {!(
                                                                    row.status ===
                                                                        9 &&
                                                                    row.error ===
                                                                        202
                                                                ) && (
                                                                    <Menu.Item
                                                                        key={3}
                                                                        name='delete'
                                                                        active={
                                                                            activeItem ===
                                                                            'delete'
                                                                        }
                                                                        onClick={this.handleItemClick(
                                                                            row
                                                                        )}
                                                                    >
                                                                        <Header as='h5'>
                                                                            <Icon
                                                                                name='trash alternate'
                                                                                color='red'
                                                                            />
                                                                            Удалить
                                                                        </Header>
                                                                    </Menu.Item>
                                                                )}
                                                                {row.status ===
                                                                    10 && (
                                                                    <Menu.Item
                                                                        key={4}
                                                                        name='watch-ticket'
                                                                        active={
                                                                            activeItem ===
                                                                            'watch-ticket'
                                                                        }
                                                                        onClick={this.handleItemClick(
                                                                            row
                                                                        )}
                                                                    >
                                                                        <Header as='h5'>
                                                                            <Icon name='eye' />
                                                                            Просмотреть
                                                                            тикет
                                                                        </Header>
                                                                    </Menu.Item>
                                                                )}
                                                                {row.status ===
                                                                    10 &&
                                                                    row.conclusion ===
                                                                        'Accepted' && (
                                                                        <Menu.Item
                                                                            key={
                                                                                5
                                                                            }
                                                                            name='print'
                                                                            active={
                                                                                activeItem ===
                                                                                'print'
                                                                            }
                                                                            onClick={this.handleItemClick(
                                                                                row
                                                                            )}
                                                                        >
                                                                            <Header as='h5'>
                                                                                <Icon name='download' />
                                                                                Распечатать
                                                                                в
                                                                                pdf
                                                                            </Header>
                                                                        </Menu.Item>
                                                                    )}
                                                            </Menu>
                                                        }
                                                        on='click'
                                                        open={isOpen}
                                                        onClose={() =>
                                                            this.handleClose(
                                                                row.id
                                                            )
                                                        }
                                                        onOpen={() =>
                                                            this.handleOpen(
                                                                row.id
                                                            )
                                                        }
                                                        position='right center'
                                                        style={{
                                                            padding: '0px'
                                                        }}
                                                    />
                                                </Table.Row>
                                            );
                                        })}
                                    </Table.Body>
                                </Table>
                            )}
                        </Segment>
                    </Segment>
                )}
                <Modal
                    size='tiny'
                    open={inputPass}
                    closeOnEscape={false}
                    closeOnDimmerClick={false}
                >
                    <Modal.Header>Введите пароль доступа к УТМ</Modal.Header>
                    <Modal.Content>
                        <h4>Данная операция может занять от 3 до 5 минут.</h4>
                        <Form>
                            <Form.Group>
                                <Form.Input
                                    type='password'
                                    placeholder='Введите пароль'
                                    name='pass'
                                    value={pass}
                                    onChange={this.handleChangePass}
                                    error={passErr}
                                />
                            </Form.Group>
                        </Form>
                    </Modal.Content>
                    <Modal.Actions>
                        <Button
                            positive
                            content='Подтвердить'
                            onClick={() => this.produceNotice(cmd_type)}
                        />
                        <Button negative onClick={this.closeGetAccess}>
                            Закрыть
                        </Button>
                    </Modal.Actions>
                </Modal>
                <Confirm
                    open={openConfirm}
                    content={
                        'Вы уверены, что хотите удалить уведомление о перевозке №' +
                        notif_number +
                        '?'
                    }
                    cancelButton='Нет'
                    confirmButton='Да'
                    onCancel={this.сancelDelete}
                    onConfirm={this.confirmDelete}
                />
                <FileContainerTmp />
            </div>
        );
    }
}

const StatusIcon = ({ row, role, getAccess, state }) => {
    let color, name;
    var link = false;
    switch (row.status) {
        case 8:
            switch (row.error) {
                case 100:
                case 106:
                case 315:
                    color = 'blue';
                    name = 'telegram plane';
                    link = true;
                    break;
                default:
                    color = 'red';
                    name = 'close';
            }
            break;
        case 9:
            switch (row.error) {
                case 202:
                    color = 'yellow';
                    name = 'wait';
                    link = true;
                    break;
                default:
                    color = 'red';
                    name = 'close';
            }
            break;
        case 10:
            switch (row.error) {
                case 207:
                    switch (row.conclusion) {
                        case 'Accepted':
                            color = 'green';
                            name = 'checkmark';
                            break;
                        default:
                            color = 'red';
                            name = 'close';
                    }
                    break;
                default:
                    color = 'red';
                    name = 'close';
            }
            break;
        default:
            color = 'red';
            name = 'close';
    }
    if (role === ADMIN) link = false;
    return (
        <div>
            {row.status === 10 && row.comment !== '' ? (
                <Popup
                    trigger={
                        <Icon
                            color={color}
                            name={name}
                            link={link}
                            size='large'
                        />
                    }
                    content={row.comments}
                    position='top center'
                />
            ) : (
                <Icon
                    color={color}
                    name={name}
                    link={link && !state.includes(row.id) ? true : false}
                    size='large'
                    onClick={() => {
                        if (
                            role !== ADMIN && link && !state.includes(row.id)
                                ? true
                                : false
                        ) {
                            getAccess(row.id, row.replyid, name);
                        }
                    }}
                    loading={state.includes(row.id) ? true : false}
                    disabled={state.includes(row.id) ? true : false}
                />
            )}
        </div>
    );
};

const mapStateToProps = state => {
    return {
        show: state.carrier.show,
        carriers: state.flags.carriers,
        all_tanks: state.flags.all_tanks,
        tanks: state.tanks.initial_tanks
    };
};

const mapDispatchToProps = dispatch => {
    return {
        //  carrier
        onWriteResponse: res => {
            dispatch(writeResponse({ open: true, response: res }));
        },
        onSetNoticeProcessing: (cmd, id) => {
            dispatch(setNoticeProcessing({ cmd: cmd, id: id }));
        },
        onUnsetNoticeProcessing: (cmd, id) => {
            dispatch(unsetNoticeProcessing({ cmd: cmd, id: id }));
        },

        //  notice
        onShowFile: (file, type, file_json) => {
            dispatch(
                showFile({
                    show_file_tmp: true,
                    file_tmp_type: type,
                    file_string: file,
                    file_json: file_json
                })
            );
        }
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(NoticeHistory);
