import React from 'react';
import { Table } from 'semantic-ui-react';

const NoCapsuleInfo = ({ flag, time_stamp }) => {
    return (
        <Table size='14px' compact celled>
            <Table.Header>
                {flag === 'marker' && (
                    <Table.Row textAlign='center'>
                        <Table.HeaderCell colSpan='4'>
                            {time_stamp != null
                                ? time_stamp.replace('T', ' ').replace('Z', '')
                                : ''}
                        </Table.HeaderCell>
                    </Table.Row>
                )}
                <Table.Row textAlign='center'>
                    <Table.HeaderCell colSpan='4'>
                        Информация по отсекам
                    </Table.HeaderCell>
                </Table.Row>
                <Table.Row textAlign='center'>
                    <Table.HeaderCell>№ отсека</Table.HeaderCell>
                    <Table.HeaderCell>ρ, кг/м3</Table.HeaderCell>
                    <Table.HeaderCell>t, °C</Table.HeaderCell>
                    <Table.HeaderCell>H, дал/мм</Table.HeaderCell>
                </Table.Row>
            </Table.Header>
            <Table.Body>
                <Table.Row textAlign='center'>
                    <Table.Cell colSpan='4'>Нет данных по отсекам</Table.Cell>
                </Table.Row>
            </Table.Body>
        </Table>
    );
};

/* Данный компонент используется и в TankState и на карте при наведении на маркер из массива перевозки.
Отображение для них разное, зависит от flag === 'marker/curr'. */
const CapsuleInfo = ({ curr_capsule, flag, time_stamp }) => {
    const isAllDataReady = curr_capsule && curr_capsule.length > 0;
    return (
        <div>
            {!isAllDataReady && (
                <NoCapsuleInfo time_stamp={time_stamp} flag={flag} />
            )}
            {isAllDataReady && (
                <div>
                    <Table size='14px' compact celled textAlign='center'>
                        <Table.Header>
                            {flag === 'marker' && (
                                <Table.Row>
                                    <Table.HeaderCell colSpan='4'>
                                        {curr_capsule[0].time_stamp
                                            .replace('T', ' ')
                                            .replace('Z', '')}
                                    </Table.HeaderCell>
                                </Table.Row>
                            )}
                            <Table.Row>
                                <Table.HeaderCell colSpan='4'>
                                    Информация по отсекам
                                </Table.HeaderCell>
                            </Table.Row>
                            <Table.Row>
                                <Table.HeaderCell>№ отсека</Table.HeaderCell>
                                <Table.HeaderCell>ρ, кг/м3</Table.HeaderCell>
                                <Table.HeaderCell>t, °C</Table.HeaderCell>
                                <Table.HeaderCell>
                                    {flag === 'curr' ? 'H, дал/мм' : 'H, дал'}
                                </Table.HeaderCell>
                            </Table.Row>
                        </Table.Header>
                        <Table.Body>
                            {curr_capsule.map((row, i) => {
                                return (
                                    <Table.Row key={i} textAlign='center'>
                                        <Table.Cell key={1}>
                                            {row['capsule_id']}
                                        </Table.Cell>
                                        <Table.Cell key={2}>
                                            {row['density'].Valid
                                                ? row[
                                                      'density'
                                                  ].Float64.toFixed(3)
                                                : ''}
                                        </Table.Cell>
                                        <Table.Cell key={4}>
                                            {row['temperature'].Valid
                                                ? row[
                                                      'temperature'
                                                  ].Float64.toFixed(2)
                                                : ''}
                                        </Table.Cell>
                                        {flag === 'curr' && (
                                            <Table.Cell key={3}>
                                                {row['level'].Valid &&
                                                row['level_mm'].Valid
                                                    ? row[
                                                          'level'
                                                      ].Float64.toFixed(2) +
                                                      '/' +
                                                      row[
                                                          'level_mm'
                                                      ].Float64.toFixed(0)
                                                    : ''}
                                            </Table.Cell>
                                        )}
                                        {flag === 'marker' && (
                                            <Table.Cell key={3}>
                                                {row['level'].Valid
                                                    ? row[
                                                          'level'
                                                      ].Float64.toFixed(2)
                                                    : ''}
                                            </Table.Cell>
                                        )}
                                    </Table.Row>
                                );
                            })}
                        </Table.Body>
                    </Table>
                </div>
            )}
        </div>
    );
};

export default CapsuleInfo;
