import React, { useEffect } from 'react';
import { Marker, useMap, Tooltip } from 'react-leaflet';
import L from 'leaflet';

import tank from '../../images/tanker48.png';

import { toggleClickOnTankItem } from '../functions';

/* компонент, представляющий собой цистерну на карте (icon). */
const CurrLocationNew = ({ tanks, id, open }) => {
    //  здесь tanks это filtered_tanks!
    const map = useMap()
    // фокус мапы на выбранную тачку
    useEffect(()=>{
            if (tanks!=null) {
                let tank = tanks.find(t=>t.id===id)
                if (tank) {
                    if (tank.curr_data) {
                        map.setView([tank.curr_data.lat,tank.curr_data.lng],map.getZoom())
                    }
                }
            }
    })
    return (
        <div>
            {tanks != null &&
                tanks.map(t => {
                    if (t.curr_data) {
                        return (
                            <Marker
                                key={t.id}
                                draggable={false}
                                position={{lat: t.curr_data.lat ? t.curr_data.lat : 0,lng: t.curr_data.lng ? t.curr_data.lng : 0}}
                                icon={L.icon({ iconUrl: tank }) }
                                title={t.name}
                                eventHandlers={{
                                    click: () => {
                                        toggleClickOnTankItem(t.idx);
                                    },
                                }}
                            >
                                {id === t.id && open ? (
                                    <Tooltip direction="top" offset={[20, 20]} opacity={1} permanent>
                                        <div>{t.name}</div>
                                    </Tooltip>
                                ) : null}
                            </Marker>
                        );
                    }
                })}
        </div>
    );
};

export default CurrLocationNew;