import React, { Component } from 'react';
import { Segment } from 'semantic-ui-react';

import API from '../API';
import Products from '../Products';

import { freqProducts } from '../constants';

class ProductsContainer extends Component {
    state = {
        products: [],
        tables: []
    };

    /* запускается таймер обновления таблицы продуктов и таблиц плотностей. Нужно для обратной связи при добавлении их добавлении. */
    componentDidMount() {
        API.GET('/tanks/' + this.props.id + '/products', null, res => {
            this.setState({ products: res.data });
        });
        API.GET('/tanks/' + this.props.id + '/denstables', null, res => {
            this.setState({ tables: res.data });
        });
        this.timerProducts = setInterval(() => {
            API.GET('/tanks/' + this.props.id + '/products', null, res => {
                this.setState({ products: res.data });
            });
            API.GET('/tanks/' + this.props.id + '/denstables', null, res => {
                this.setState({ tables: res.data });
            });
        }, freqProducts);
    }

    componentWillUnmount() {
        clearInterval(this.timerProducts);
    }

    render() {
        const { products, tables } = this.state;
        const { tank_type } = this.props;
        return (
            <Segment className='soon' basic style={{ padding: '0px' }}>
                <Products
                    data={products}
                    tables={tables}
                    id={this.props.id}
                    tank_type={tank_type}
                />
            </Segment>
        );
    }
}

export default ProductsContainer;
