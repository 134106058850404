import React, { Component } from 'react';
import { Segment } from 'semantic-ui-react';

import API from '../API';
import Contractors from '../Contractors';

import { freqContractors } from '../constants';

class ContractorsContainer extends Component {
    state = {
        contractors: []
    };

    componentDidMount() {
        API.GET('/tanks/' + this.props.id + '/contractors', null, res => {
            this.setState({ contractors: res.data });
        });
        this.timerContractors = setInterval(() => {
            API.GET('/tanks/' + this.props.id + '/contractors', null, res => {
                this.setState({ contractors: res.data });
            });
        }, freqContractors);
    }

    componentWillUnmount() {
        clearInterval(this.timerContractors);
    }

    render() {
        const { contractors } = this.state;
        return (
            <Segment className='soon' basic style={{ padding: '0px' }}>
                <Contractors contractors={contractors} id={this.props.id} />
            </Segment>
        );
    }
}

export default ContractorsContainer;
