import moment from 'moment';
import 'moment/locale/ru';

import { date, minParkingTime } from './constants';

//  эта функция нужна для обработки ошибки 400, которая возникает при обновлении accessToken.
//  если разобраться как делать повторный запрос при ошибке 400 в axios, то эту функцию можно будет не использовать!!!
export const badReqHandler = (err, errType, handler) => {
    var error = err.toString();
    if (error.includes(errType)) {
        handler(true);
    }
};

//  Решение для правильной связки MenuItem и TabPane компонента Tab.
//  эта функция производит программный клик на элемент меню цистерн (по индексу в меню)
//  эту функция используется для связи маркеров на карте и элементов меню
export const toggleClickOnTankItem = idx => {
    var event = document.createEvent('MouseEvents');
    event.initMouseEvent(
        'click',
        true,
        true,
        window,
        0,
        0,
        0,
        0,
        0,
        false,
        false,
        false,
        false,
        0,
        null
    );
    var elem = document.getElementById('tank-menu-item' + idx);
    if (elem) {
        elem.dispatchEvent(event);
        elem.focus();
    }
};

//  имеется массив объектов. добавить во все объекты индекс от 0
export const indexObjects = arr => {
    var i = 0;
    arr.forEach(el => {
        el.idx = i++;
    });
};

//  парсит объект типа moment в дату-строку заданного формата в зависимости от flag
export const parseMoment = (time, flag) => {
    if (flag === 'from') {
        return moment(time).format('YYYY-MM-DDT00:00:00') + 'Z';
    }
    if (flag === 'to') {
        return moment(time).format('YYYY-MM-DDT23:59:59') + 'Z';
    }
    if (flag === 'date') {
        return moment(time).format('YYYY-MM-DDT00:00:00') + 'Z';
    }
    if (flag === 'only_date') {
        return moment(time).format('YYYY-MM-DDT');
    }
    if (flag === 'only_time') {
        return moment(time).format('HH:mm:00') + 'Z';
    }
    if (flag === 'exact') {
        return moment(time).format('YYYY-MM-DDTHH:mm:ss') + 'Z';
    }
    if (flag === 'notice_date') {
        return moment(time).format('YYYY-MM-DD');
    }
    if (flag === 'notice_date_reverse') {
        return moment(time).format('DD-MM-YYYY');
    }
};

//  парсит дату-строку в объект типа moment
export const parseStringToMoment = str => {
    return moment(str, 'YYYY-MM-DDTHH:mm:ss');
};

export const parseStringToMomentDateReverse = str => {
    return moment(str, 'DD-MM-YYYY');
};

export const parseStringToMomentDate = str => {
    return moment(str, 'YYYY-MM-DD');
};

export const reduceDate = (str, flag) => {
    if (str === '') return '';
    if (flag === 'into') {
        return parseMoment(parseStringToMomentDateReverse(str), 'notice_date');
    }
    if (flag === 'from') {
        return parseMoment(parseStringToMomentDate(str), 'notice_date_reverse');
    }
};

export const isBeforeToday = selectedDate => {
    return moment(selectedDate).isBefore(
        parseMoment(date.defaultCurrDate, 'notice_date'),
        'day'
    );
};

export const isBeforeThatDay = (thisDay, thatDay) => {
    return moment(thisDay).isBefore(moment(thatDay));
};

export const isSameOrAfterThatDay = (thisDay, thatDay) => {
    return moment(thisDay).isSameOrAfter(moment(thatDay));
};

export const isSameOrBeforeThatDay = (thisDay, thatDay) => {
    return moment(thisDay).isSameOrBefore(moment(thatDay));
};

//  для расчета делителя для целесообразного количетсва отображаемых маркеров
export const calculateDiv = len => {
    var div1, div2;
    const number = 350;
    div1 = Math.ceil(len / number);
    div2 = Math.floor(len / number);
    var calcNumber1 = parseInt(len / div1);
    var calcNumber2 = parseInt(len / div2);
    if (Math.abs(calcNumber1 - number) < Math.abs(calcNumber2 - number)) {
        return div1;
    } else {
        return div2 === 0 ? 1 : div2;
    }
};

//  разделяет заданный интервал времени на части по 12 часов
export const splitInterval = interval => {
    var fromTime = parseStringToMoment(interval.start);
    var toTime = parseStringToMoment(interval.end);
    var array = [];
    var from = fromTime;
    while (true) {
        var end = from.clone().add(12, 'hour');
        if (end.isSameOrAfter(toTime)) {
            array.push({
                from: parseMoment(from, 'exact'),
                to: parseMoment(toTime, 'exact')
            });
            break;
        } else {
            array.push({
                from: parseMoment(from, 'exact'),
                to: parseMoment(end, 'exact')
            });
        }
        from = end;
    }
    return array;
};

/* сранивает поля-времена двух объектов */
export const comparingTimestamp = (obj1, obj2) => {
    var ts1 = parseStringToMoment(obj1.time_stamp);
    var ts2 = parseStringToMoment(obj2.time_stamp);
    return ts1 - ts2;
};

/* проверяет больше ли интервал чем неделя. Если больше возвращает false */
export const checkInterval = (start, end) => {
    var ts1 = parseStringToMoment(start);
    var ts2 = parseStringToMoment(end);
    if (ts2 - ts1 > moment.duration(7, 'd')) {
        return false;
    } else {
        return true;
    }
};

//  функция для создания копий объектов
export const deepCopy = origin => {
    let cp;
    switch (typeof origin) {
        case 'object':
            if (origin === null) {
                cp = null;
            } else {
                switch (toString.call(origin)) {
                    case '[object Array]':
                        cp = origin.map(deepCopy);
                        break;
                    case '[object Date]':
                        cp = new Date(origin);
                        break;
                    case '[object ReqExp]':
                        cp = new RegExp(origin);
                        break;
                    default:
                        cp = Object.keys(origin).reduce((prev, key) => {
                            prev[key] = deepCopy(origin[key]);
                            return prev;
                        }, {});
                        break;
                }
            }
            break;
        default:
            cp = origin;
    }
    return cp;
};

//  преобразует строковое представление двочного числа в массив из 0 и 1
export const fromStringToArray = str => {
    var arr = [];
    for (var i = 0; i < str.length; i++) {
        arr.unshift(Number(str[i]));
    }
    return arr;
};

//  преобразует выбранные настройки уведомлений в строку из 0 и 1
export const fromArrayToString = arr => {
    var clone = arr.slice(0);
    var str = '';
    clone.reverse();
    for (var i = 0; i < clone.length; i++) {
        str += (clone[i] === true ? 1 : 0).toString();
    }
    return str;
};

//  вставляет в объект элемент с ключом и значением
export const pushElem = (obj, key, value) => {
    obj[key] = value;
    return obj;
};

//  удаляет из объекта элемент по его ключу
export const delElem = (obj, key) => {
    delete obj[key];
    return obj;
};

export const makeCarriersOptions = originArr => {
    var arr = deepCopy(originArr);
    var options = [];
    var j = -1;
    for (let i = 0; i < arr.length; i++) {
        j++;
        if (arr[i].mru_name === '') {
            j--;
            continue;
        }
        options.push({});
        options[j].carrier_id = arr[i].carrier_id;
        options[j].text = arr[i].text;
        options[j].value = arr[i].value;
    }
    return options;
};

export const makeTanksOptions = (originArr, carrier_id) => {
    var arr = deepCopy(originArr);
    var options = [];
    var j = 0;
    options[0] = { text: 'Не выбрано', value: 'Не выбрано' };
    for (let i = 0; i < arr.length; i++) {
        j++;
        if (arr[i].name === '' || arr[i].carrier_id !== carrier_id) {
            j--;
            continue;
        }
        options.push({});
        options[j].carrier_id = arr[i].carrier_id;
        options[j].tank_id = arr[i].tank_id;
        options[j].text = arr[i].name;
        options[j].value = arr[i].name;
    }
    return options;
};

export const makeOptions = (originArr, name) => {
    var arr = deepCopy(originArr);
    var options = [];
    var j = -1;
    for (let i = 0; i < arr.length; i++) {
        j++;
        if (typeof arr[i] == 'undefined' || arr[i][name] === '') {
            j--;
            continue;
        }
        options.push({});
        options[j].key = j;
        if (name === 'name') {
            options[j].text = arr[i][name];
        } else if (name === 'full_name') {
            options[j].text =
                arr[i][name] +
                ' ' +
                arr[i].alc_tu +
                '% Код АП - ' +
                arr[i].product_id +
                ' (' +
                arr[i].type +
                ')';
        } else {
            switch (arr[i].type) {
                case 'FL':
                    options[j].text = arr[i][name] + ' ИНН - ' + arr[i].inn;
                    break;
                case 'FO':
                case 'TS':
                    options[j].text = arr[i][name];
                    break;
                default:
                    options[j].text =
                        arr[i][name] +
                        ' ИНН/КПП - ' +
                        arr[i].inn +
                        '/' +
                        arr[i].kpp;
            }
        }
        options[j].value = arr[i];
    }
    return options;
};

export const makeOptionsWithConcat = (arr1, arr2, name) => {
    var arr = arr1.concat(arr2);
    var set = new Set(arr.map(a => a.reg_id));
    arr = Array.from(set).map(reg_id => {
        return arr.find(a => a.reg_id === reg_id && a[name] !== '');
    });

    return makeOptions(arr, name);
};

export const isEmpty = obj => {
    return Object.keys(obj).length === 0;
};

/* ПРОВЕРКА СТОЯНОК НА МАРШРУТЕ */
export const produceParkings = arr => {
    var parkings = [];
    var flag = false;
    var parking = {};
    var startIndex = 0,
        endIndex = 0;
    arr[0].type = 0;
    arr[arr.length - 1].type = 0;
    for (let i = 1; i < arr.length - 1; i++) {
        arr[i].type = 1;
        if (arr[i].speed < 5) {
            if (!flag) {
                parking.start = arr[i].time_stamp;
                startIndex = i;
                parking.time_stamp = arr[i].time_stamp;
                parking.lat = arr[i].lat;
                parking.lng = arr[i].lng;
                parking.type = 2;
                flag = true;
            } else {
                parking.end = arr[i].time_stamp;
                if (i === arr.length - 2) {
                    if (isItLongEnough(parking, minParkingTime)) {
                        parkings.push(parking);
                        endIndex = i;
                        //  удаляем стоянки из маршрута
                        arr.splice(startIndex, endIndex - startIndex + 1);
                    }
                }
            }
        } else {
            parking.end = arr[i - 1].time_stamp;
            if (flag) {
                if (isItLongEnough(parking)) {
                    parkings.push(parking);
                    endIndex = i - 1;
                    //  удаляем стоянки из маршрута
                    arr.splice(startIndex, endIndex - startIndex + 1);
                    parking = {};
                }
            }
            flag = false;
        }
    }

    return { track: arr, parkings: parkings };
};

const isItLongEnough = parking => {
    if (
        parseStringToMoment(parking.end).diff(
            parseStringToMoment(parking.start),
            'minute',
            true
        ) > minParkingTime
    ) {
        return true;
    }
    return false;
};

export const validateIPAddress = text => {
    var ipformat = /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/;
    if (text.match(ipformat)) {
        return true;
    }
    return false;
};

export const validateNumsOrDots = s => {
    var rgx = /^[0-9.]*$/;
    return s.match(rgx);
};

export const validateNums = s => {
    var rgx = /^[0-9]*$/;
    return s.match(rgx);
};

export const validateUtmIP = s => {
    var rgx = /^[0-9.localhost]*$/;
    return s.match(rgx);
};

export const getDocInJSON = str => {
    var parser = new DOMParser();
    var xmlDoc = parser.parseFromString(str, 'text/xml');
    var jsonString = xml2json(xmlDoc);
    var jsonDoc = JSON.parse(
        jsonString.replace(/undefined|ns:|rpp:|oref:|pref:/g, '')
    );
    return jsonDoc;
};

const xml2json = (xml, tab) => {
    var X = {
        toObj: function(xml) {
            var o = {};
            if (xml.nodeType == 1) {
                // element node ..
                if (xml.attributes.length)
                    // element with attributes  ..
                    for (var i = 0; i < xml.attributes.length; i++)
                        o['@' + xml.attributes[i].nodeName] = (
                            xml.attributes[i].nodeValue || ''
                        ).toString();
                if (xml.firstChild) {
                    // element has child nodes ..
                    var textChild = 0,
                        cdataChild = 0,
                        hasElementChild = false;
                    for (var n = xml.firstChild; n; n = n.nextSibling) {
                        if (n.nodeType == 1) hasElementChild = true;
                        else if (
                            n.nodeType == 3 &&
                            n.nodeValue.match(/[^ \f\n\r\t\v]/)
                        )
                            textChild++;
                        // non-whitespace text
                        else if (n.nodeType == 4) cdataChild++; // cdata section node
                    }
                    if (hasElementChild) {
                        if (textChild < 2 && cdataChild < 2) {
                            // structured element with evtl. a single text or/and cdata node ..
                            X.removeWhite(xml);
                            for (var n = xml.firstChild; n; n = n.nextSibling) {
                                if (n.nodeType == 3)
                                    // text node
                                    o['#text'] = X.escape(n.nodeValue);
                                else if (n.nodeType == 4)
                                    // cdata node
                                    o['#cdata'] = X.escape(n.nodeValue);
                                else if (o[n.nodeName]) {
                                    // multiple occurence of element ..
                                    if (o[n.nodeName] instanceof Array)
                                        o[n.nodeName][
                                            o[n.nodeName].length
                                        ] = X.toObj(n);
                                    else
                                        o[n.nodeName] = [
                                            o[n.nodeName],
                                            X.toObj(n)
                                        ];
                                } // first occurence of element..
                                else o[n.nodeName] = X.toObj(n);
                            }
                        } else {
                            // mixed content
                            if (!xml.attributes.length)
                                o = X.escape(X.innerXml(xml));
                            else o['#text'] = X.escape(X.innerXml(xml));
                        }
                    } else if (textChild) {
                        // pure text
                        if (!xml.attributes.length)
                            o = X.escape(X.innerXml(xml));
                        else o['#text'] = X.escape(X.innerXml(xml));
                    } else if (cdataChild) {
                        // cdata
                        if (cdataChild > 1) o = X.escape(X.innerXml(xml));
                        else
                            for (var n = xml.firstChild; n; n = n.nextSibling)
                                o['#cdata'] = X.escape(n.nodeValue);
                    }
                }
                if (!xml.attributes.length && !xml.firstChild) o = null;
            } else if (xml.nodeType == 9) {
                // document.node
                o = X.toObj(xml.documentElement);
            } else alert('unhandled node type: ' + xml.nodeType);
            return o;
        },
        toJson: function(o, name, ind) {
            var json = name ? '"' + name + '"' : '';
            if (o instanceof Array) {
                for (var i = 0, n = o.length; i < n; i++)
                    o[i] = X.toJson(o[i], '', ind + '\t');
                json +=
                    (name ? ':[' : '[') +
                    (o.length > 1
                        ? '\n' +
                          ind +
                          '\t' +
                          o.join(',\n' + ind + '\t') +
                          '\n' +
                          ind
                        : o.join('')) +
                    ']';
            } else if (o == null) json += (name && ':') + 'null';
            else if (typeof o == 'object') {
                var arr = [];
                for (var m in o)
                    arr[arr.length] = X.toJson(o[m], m, ind + '\t');
                json +=
                    (name ? ':{' : '{') +
                    (arr.length > 1
                        ? '\n' +
                          ind +
                          '\t' +
                          arr.join(',\n' + ind + '\t') +
                          '\n' +
                          ind
                        : arr.join('')) +
                    '}';
            } else if (typeof o == 'string')
                json += (name && ':') + '"' + o.toString() + '"';
            else json += (name && ':') + o.toString();
            return json;
        },
        innerXml: function(node) {
            var s = '';
            if ('innerHTML' in node) s = node.innerHTML;
            else {
                var asXml = function(n) {
                    var s = '';
                    if (n.nodeType == 1) {
                        s += '<' + n.nodeName;
                        for (var i = 0; i < n.attributes.length; i++)
                            s +=
                                ' ' +
                                n.attributes[i].nodeName +
                                '="' +
                                (n.attributes[i].nodeValue || '').toString() +
                                '"';
                        if (n.firstChild) {
                            s += '>';
                            for (var c = n.firstChild; c; c = c.nextSibling)
                                s += asXml(c);
                            s += '</' + n.nodeName + '>';
                        } else s += '/>';
                    } else if (n.nodeType == 3) s += n.nodeValue;
                    else if (n.nodeType == 4)
                        s += '<![CDATA[' + n.nodeValue + ']]>';
                    return s;
                };
                for (var c = node.firstChild; c; c = c.nextSibling)
                    s += asXml(c);
            }
            return s;
        },
        escape: function(txt) {
            return txt
                .replace(/[\\]/g, '\\\\')
                .replace(/[\"]/g, '\\"')
                .replace(/[\n]/g, '\\n')
                .replace(/[\r]/g, '\\r');
        },
        removeWhite: function(e) {
            e.normalize();
            for (var n = e.firstChild; n; ) {
                if (n.nodeType == 3) {
                    // text node
                    if (!n.nodeValue.match(/[^ \f\n\r\t\v]/)) {
                        // pure whitespace text node
                        var nxt = n.nextSibling;
                        e.removeChild(n);
                        n = nxt;
                    } else n = n.nextSibling;
                } else if (n.nodeType == 1) {
                    // element node
                    X.removeWhite(n);
                    n = n.nextSibling;
                } // any other node
                else n = n.nextSibling;
            }
            return e;
        }
    };
    if (xml.nodeType == 9)
        // document node
        xml = xml.documentElement;
    var json = X.toJson(X.toObj(X.removeWhite(xml)), xml.nodeName, '\t');
    return (
        '{\n' +
        tab +
        (tab ? json.replace(/\t/g, tab) : json.replace(/\t|\n/g, '')) +
        '\n}'
    );
};
