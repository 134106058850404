import React, { Component } from 'react';
import LoadingScreen from 'react-loading-screen';
import logo from '../../images/logo.png';

class PageLoader extends Component {
    render() {
        setTimeout(() => this.props.history.push('/'), 3000);
        return (
            <div>
                <LoadingScreen
                    loading={true}
                    bgColor='#000000' //  black
                    spinnerColor='#008000'
                    logoSrc={logo}
                ></LoadingScreen>
            </div>
        );
    }
}

export default PageLoader;
