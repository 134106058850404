import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Form, Divider, Segment, Button, Icon, Popup } from 'semantic-ui-react';
import { DateInput } from 'semantic-ui-calendar-react';

import API from '../API';
import FileContainer from './FileContainer';

import {
    changeFormField,
    changePositionsNumber,
    changeFormFieldPos,
    resetNotice,
    saveFileIdx
} from '../actions/notice';

import { writeResponse } from '../actions/carrier';

import { showFile } from '../actions/notice';

import { defaultNotice } from '../defaultNotice';

import { date, ns, CREATE_CARRIER_NOTICE } from '../constants';

import {
    parseMoment,
    reduceDate,
    makeOptions,
    isEmpty,
    isBeforeToday,
    isBeforeThatDay,
    getDocInJSON
} from '../functions';

const divider = {
    marginTop: '10px',
    marginBottom: '10px'
};

class NoticeForm extends Component {
    state = {
        loadingForm: false
    };

    componentDidMount() {
        this.props.onChangeFormField({
            carrier: this.props.carrier.carriers[0]
        });
        this.props.onChangePositionsNumber(1);
        this.props.onChangeFormField({
            notif_date: parseMoment(date.defaultCurrDate, 'notice_date')
        });
    }

    handleChange = position => (e, { name, value }) => {
        if (name === 'client_transport') {
            var obj = {};
            obj.client_reg_id = value.client_reg_id.String;
            obj.description = value.name;
            this.props.onChangeFormField({ [name]: obj });
            return;
        }
        if (
            ['product', 'producer', 'quantity_20', 'alc_perc_20'].includes(name)
        ) {
            this.props.onChangeFormFieldPos(position, name, value);
            return;
        }
        if (['notif_date', 'ship_out_date', 'ship_in_date'].includes(name)) {
            this.props.onChangeFormField({ [name]: reduceDate(value, 'into') });
            return;
        }
        this.props.onChangeFormField({ [name]: value });
    };

    checkProduct = p => {
        if (
            p.full_name === '' ||
            p.spirit_code === '' ||
            p.type === '' ||
            p.unit_type === ''
        ) {
            return true;
        }
        return false;
    };

    checkOrg = org => {
        if (
            org.reg_id === '' ||
            org.full_name === '' ||
            org.short_name === '' ||
            ((org.type === 'UL' || org.type === 'FL') && org.inn === '') ||
            (org.type === 'UL' && org.kpp === '') ||
            org.country === '' ||
            ((org.type === 'UL' || org.type === 'FL') &&
                org.region_code === '') ||
            org.description === ''
        ) {
            return true;
        }
        return false;
    };

    checkPositions = arr => {
        for (let i = 0; i < arr.length; i++) {
            if (
                !arr[i].quantity_20 ||
                !arr[i].alc_perc_20 ||
                !arr[i].pos_identity ||
                arr[i].pos_identity < 1 ||
                isEmpty(arr[i].product) ||
                isEmpty(arr[i].producer) ||
                arr[i].quantity_20 < 0 ||
                (arr[i].quantity_20 != null &&
                    (arr[i].quantity_20.toString() === '' ||
                        ['.', ','].includes(
                            arr[i].quantity_20.toString().charAt(0)
                        ))) ||
                (arr[i].alc_perc_20 != null &&
                    (arr[i].alc_perc_20 < 0 ||
                        arr[i].alc_perc_20 > 100 ||
                        arr[i].alc_perc_20.toString() === '' ||
                        ['.', ','].includes(
                            arr[i].alc_perc_20.toString().charAt(0)
                        ) ||
                        arr[i].alc_perc_20.toString().length > 5)) ||
                this.checkOrg(arr[i].producer) ||
                this.checkProduct(arr[i].product)
            ) {
                return true;
            }
        }
        return false;
    };

    checkSpaces = str => {
        return String(str).indexOf(' ') !== -1;
    };

    validate = () => {
        if (
            isEmpty(this.props.notice.carrier) ||
            isEmpty(this.props.notice.consignee) ||
            isEmpty(this.props.notice.shipper) ||
            !this.props.notice.notif_number ||
            isEmpty(this.props.notice.client_transport) ||
            (!isEmpty(this.props.notice.client_transport) &&
                !this.props.notice.client_transport.client_reg_id) ||
            !this.props.notice.notif_date ||
            !this.props.notice.ship_in_date ||
            !this.props.notice.ship_out_date ||
            isBeforeThatDay(
                this.props.notice.ship_in_date,
                this.props.notice.ship_out_date
            ) ||
            isBeforeToday(this.props.notice.ship_in_date) ||
            isBeforeToday(this.props.notice.ship_out_date) ||
            this.checkPositions(this.props.notice.position) ||
            this.checkOrg(this.props.notice.shipper) ||
            this.checkOrg(this.props.notice.consignee) ||
            this.checkOrg(this.props.notice.carrier) ||
            this.checkSpaces(this.props.notice.egais_fix_number_ttn) ||
            this.checkSpaces(this.props.notice.client_identity) ||
            this.checkSpaces(this.props.notice.notif_number) ||
            this.checkSpaces(this.props.notice.serial)
        ) {
            return true;
        }
        return false;
    };

    clearForm = async () => {
        await this.props.onResetNotice();
        this.props.onChangeFormField({
            carrier: this.props.carrier.carriers[0]
        });
        this.props.onChangePositionsNumber(1);
        this.props.onChangeFormField({
            notif_date: parseMoment(date.defaultCurrDate, 'notice_date')
        });
    };

    createNotice = () => {
        this.setState({ loadingForm: true });

        var data = {
            notice: JSON.stringify(this.props.notice)
        };

        API.POST(
            '/carrier/' + CREATE_CARRIER_NOTICE + '/create_notice',
            data,
            res => {
                this.setState({ loadingForm: false });
                if (res.data.includes('<?xml')) {
                    this.props.onShowFile(
                        res.data.substring(20),
                        'notice',
                        getDocInJSON(res.data.substring(20))
                    );
                    this.props.onSaveFileIdx(res.data.substring(0, 20));
                } else {
                    this.props.onWriteResponse(res.data);
                }
            }
        );
    };

    render() {
        const { carrier, notice, tanks } = this.props;
        return (
            <div>
                <Segment
                    basic
                    style={{ padding: '0px' }}
                    loading={this.state.loadingForm}
                >
                    <Form>
                        <Form.Input
                            id='notice-form-input'
                            name='client_identity'
                            label={
                                <p
                                    style={{
                                        marginBottom: '4px',
                                        fontSize: '12px'
                                    }}
                                >
                                    <b>{ns.client_identity}</b>
                                </p>
                            }
                            placeholder={ns.client_identity}
                            text={notice.client_identity}
                            value={notice.client_identity}
                            onChange={this.handleChange()}
                            error={this.checkSpaces(notice.client_identity)}
                            width={4}
                        />
                        <Form.Group>
                            <Form.Input
                                id='notice-form-input'
                                name='notif_number'
                                label={
                                    <p
                                        style={{
                                            marginBottom: '4px',
                                            fontSize: '12px'
                                        }}
                                    >
                                        <b>{ns.notif_number}</b>
                                        <Icon name='exclamation' color='blue' />
                                    </p>
                                }
                                placeholder={ns.notif_number}
                                text={notice.notif_number}
                                value={notice.notif_number}
                                onChange={this.handleChange()}
                                error={this.checkSpaces(notice.notif_number)}
                                required
                                width={4}
                            />
                            <Form.Input
                                id='notice-form-input'
                                name='serial'
                                label={
                                    <p
                                        style={{
                                            marginBottom: '4px',
                                            fontSize: '12px'
                                        }}
                                    >
                                        <b>{ns.serial}</b>
                                    </p>
                                }
                                placeholder={ns.serial}
                                text={notice.serial}
                                value={notice.serial}
                                onChange={this.handleChange()}
                                error={this.checkSpaces(notice.serial)}
                                width={4}
                            />
                        </Form.Group>
                        <Form.Group>
                            <Form.Input
                                id='notice-form-input'
                                name='carrier'
                                label={
                                    <p
                                        style={{
                                            marginBottom: '4px',
                                            fontSize: '12px'
                                        }}
                                    >
                                        <b>{ns.carrier}</b>
                                    </p>
                                }
                                placeholder={ns.carrier}
                                text={notice.carrier.short_name}
                                value={notice.carrier.short_name}
                                error={this.checkOrg(notice.carrier)}
                                readOnly
                                width={8}
                            />
                            <Form.Select
                                id='notice-form-input'
                                name='client_transport'
                                label={
                                    <p
                                        style={{
                                            marginBottom: '4px',
                                            fontSize: '12px'
                                        }}
                                    >
                                        <b>{ns.client_transport}</b>
                                        <Icon name='exclamation' color='blue' />
                                    </p>
                                }
                                placeholder={ns.client_transport}
                                text={notice.client_transport.description}
                                value={
                                    notice.client_transport.description
                                        ? notice.client_transport.description
                                        : ''
                                }
                                options={
                                    tanks != null && makeOptions(tanks, 'name')
                                }
                                onChange={this.handleChange()}
                                required
                                error={
                                    notice.client_transport.client_reg_id === ''
                                }
                                width={8}
                            />
                        </Form.Group>
                        <Form.Group>
                            <Form.Select
                                id='notice-form-input'
                                name='shipper'
                                label={
                                    <p
                                        style={{
                                            marginBottom: '4px',
                                            fontSize: '12px'
                                        }}
                                    >
                                        <b>{ns.shipper}</b>
                                        <Icon name='exclamation' color='blue' />
                                    </p>
                                }
                                placeholder={ns.shipper}
                                text={notice.shipper.short_name}
                                value={
                                    notice.shipper.short_name
                                        ? notice.shipper.short_name
                                        : ''
                                }
                                options={
                                    carrier.shippers != null &&
                                    makeOptions(carrier.shippers, 'short_name')
                                }
                                onChange={this.handleChange()}
                                error={this.checkOrg(notice.shipper)}
                                required
                                width={8}
                            />
                            <Form.Select
                                id='notice-form-input'
                                name='consignee'
                                label={
                                    <p
                                        style={{
                                            marginBottom: '4px',
                                            fontSize: '12px'
                                        }}
                                    >
                                        <b>{ns.consignee}</b>
                                        <Icon name='exclamation' color='blue' />
                                    </p>
                                }
                                placeholder={ns.consignee}
                                text={notice.consignee.short_name}
                                value={
                                    notice.consignee.short_name
                                        ? notice.consignee.short_name
                                        : ''
                                }
                                options={
                                    carrier.consignees != null &&
                                    makeOptions(
                                        carrier.consignees,
                                        'short_name'
                                    )
                                }
                                onChange={this.handleChange()}
                                error={this.checkOrg(notice.consignee)}
                                required
                                width={8}
                            />
                        </Form.Group>
                        <Form.Group>
                            <DateInput
                                id='notice-form-input'
                                name='ship_out_date'
                                label={
                                    <p
                                        style={{
                                            marginBottom: '4px',
                                            fontSize: '12px'
                                        }}
                                    >
                                        <b>{ns.ship_out_date}</b>
                                        <Icon name='exclamation' color='blue' />
                                    </p>
                                }
                                placeholder={ns.ship_out_date}
                                value={reduceDate(notice.ship_out_date, 'from')}
                                iconPosition='left'
                                onChange={this.handleChange()}
                                error={
                                    isBeforeToday(notice.ship_out_date) ||
                                    isBeforeThatDay(
                                        notice.ship_in_date,
                                        notice.ship_out_date
                                    )
                                }
                                clearable
                                required
                                width={4}
                            />
                            <DateInput
                                id='notice-form-input'
                                name='ship_in_date'
                                label={
                                    <p
                                        style={{
                                            marginBottom: '4px',
                                            fontSize: '12px'
                                        }}
                                    >
                                        <b>{ns.ship_in_date}</b>
                                        <Icon name='exclamation' color='blue' />
                                    </p>
                                }
                                placeholder={ns.ship_in_date}
                                value={reduceDate(notice.ship_in_date, 'from')}
                                iconPosition='left'
                                onChange={this.handleChange()}
                                error={
                                    isBeforeToday(notice.ship_in_date) ||
                                    isBeforeThatDay(
                                        notice.ship_in_date,
                                        notice.ship_out_date
                                    )
                                }
                                clearable
                                required
                                width={4}
                            />
                        </Form.Group>
                        <Form.Input
                            id='notice-form-input'
                            name='egais_fix_number_ttn'
                            label={
                                <p
                                    style={{
                                        marginBottom: '4px',
                                        fontSize: '12px'
                                    }}
                                >
                                    <b>{ns.egais_fix_number_ttn}</b>
                                </p>
                            }
                            placeholder={ns.egais_fix_number_ttn}
                            text={notice.egais_fix_number_ttn}
                            value={notice.egais_fix_number_ttn}
                            onChange={this.handleChange()}
                            error={this.checkSpaces(
                                notice.egais_fix_number_ttn
                            )}
                            width={8}
                        />
                        <DateInput
                            id='notice-form-input'
                            name='notif_date'
                            label={
                                <p
                                    style={{
                                        marginBottom: '4px',
                                        fontSize: '12px'
                                    }}
                                >
                                    <b>{ns.notif_date}</b>
                                    <Icon name='exclamation' color='blue' />
                                </p>
                            }
                            placeholder={ns.notif_date}
                            value={reduceDate(notice.notif_date, 'from')}
                            iconPosition='left'
                            onChange={this.handleChange()}
                            clearable
                            required
                            width={4}
                        />
                    </Form>
                    {notice.position &&
                        notice.position.length > 0 &&
                        notice.position.map((p, i) => {
                            return (
                                <div key={i}>
                                    <Divider section horizontal style={divider}>
                                        <h6>
                                            {ns.pos_identity +
                                                ': ' +
                                                p.pos_identity}
                                        </h6>
                                    </Divider>
                                    <Form>
                                        <Form.Group>
                                            <Form.Dropdown
                                                id='notice-form-input'
                                                name='product'
                                                label={
                                                    <p
                                                        style={{
                                                            marginBottom: '4px',
                                                            fontSize: '12px'
                                                        }}
                                                    >
                                                        <b>{ns.product}</b>
                                                        <Icon
                                                            name='exclamation'
                                                            color='blue'
                                                        />
                                                    </p>
                                                }
                                                placeholder={ns.product}
                                                text={p.product.full_name}
                                                value={p.product.full_name}
                                                options={
                                                    carrier.products &&
                                                    makeOptions(
                                                        carrier.products,
                                                        'full_name'
                                                    )
                                                }
                                                onChange={this.handleChange(
                                                    p.pos_identity
                                                )}
                                                error={this.checkProduct(
                                                    p.product
                                                )}
                                                required
                                                search
                                                selection
                                                width={8}
                                            />
                                            <Form.Select
                                                id='notice-form-input'
                                                name='producer'
                                                label={
                                                    <p
                                                        style={{
                                                            marginBottom: '4px',
                                                            fontSize: '12px'
                                                        }}
                                                    >
                                                        <b>{ns.producer}</b>
                                                        <Icon
                                                            name='exclamation'
                                                            color='blue'
                                                        />
                                                    </p>
                                                }
                                                placeholder={ns.producer}
                                                text={p.producer.short_name}
                                                value={p.producer.short_name}
                                                options={
                                                    carrier.producers != null &&
                                                    makeOptions(
                                                        carrier.producers,
                                                        'short_name'
                                                    )
                                                }
                                                // options={
                                                //     (carrier.shippers != null && carrier.consignees != null) ? makeOptionsWithConcat(carrier.shippers, carrier.consignees, 'short_name') :
                                                //     (carrier.shippers == null && carrier.consignees) ? makeOptions(carrier.consignees, 'short_name') :
                                                //     (carrier.shippers && carrier.consignees == null) ? makeOptions(carrier.shippers, 'short_name') : null
                                                // }
                                                onChange={this.handleChange(
                                                    p.pos_identity
                                                )}
                                                error={this.checkOrg(
                                                    p.producer
                                                )}
                                                required
                                                width={8}
                                            />
                                        </Form.Group>
                                        <Form.Group>
                                            <Popup
                                                basic
                                                trigger={
                                                    <Form.Input
                                                        id='notice-form-input'
                                                        type='number'
                                                        name='quantity_20'
                                                        label={
                                                            <p
                                                                style={{
                                                                    marginBottom:
                                                                        '4px',
                                                                    fontSize:
                                                                        '12px'
                                                                }}
                                                            >
                                                                <b>
                                                                    {
                                                                        ns.quantity_20
                                                                    }
                                                                </b>
                                                                <Icon
                                                                    name='exclamation'
                                                                    color='blue'
                                                                />
                                                            </p>
                                                        }
                                                        placeholder={
                                                            ns.quantity_20
                                                        }
                                                        value={p.quantity_20}
                                                        onChange={this.handleChange(
                                                            p.pos_identity
                                                        )}
                                                        error={
                                                            p.quantity_20 < 0 ||
                                                            (p.quantity_20 !=
                                                                null &&
                                                                (p.quantity_20.toString() ===
                                                                    '' ||
                                                                    [
                                                                        '.',
                                                                        ','
                                                                    ].includes(
                                                                        p.quantity_20
                                                                            .toString()
                                                                            .charAt(
                                                                                0
                                                                            )
                                                                    )))
                                                        }
                                                    />
                                                }
                                                header='Количество безводного спирта, дал (при 20 ℃)'
                                                content='Внимание!!! Объем безводного спирта, приведенный к 20℃'
                                                on='focus'
                                            />
                                            <Form.Input
                                                id='notice-form-input'
                                                type='number'
                                                name='alc_perc_20'
                                                label={
                                                    <p
                                                        style={{
                                                            marginBottom: '4px',
                                                            fontSize: '12px'
                                                        }}
                                                    >
                                                        <b>{ns.alc_perc_20}</b>
                                                        <Icon
                                                            name='exclamation'
                                                            color='blue'
                                                        />
                                                    </p>
                                                }
                                                placeholder={ns.alc_perc_20}
                                                value={p.alc_perc_20}
                                                onChange={this.handleChange(
                                                    p.pos_identity
                                                )}
                                                error={
                                                    p.alc_perc_20 != null &&
                                                    (p.alc_perc_20 < 0 ||
                                                        p.alc_perc_20 > 100 ||
                                                        p.alc_perc_20.toString() ===
                                                            '' ||
                                                        ['.', ','].includes(
                                                            p.alc_perc_20
                                                                .toString()
                                                                .charAt(0)
                                                        ) ||
                                                        p.alc_perc_20.toString()
                                                            .length > 5)
                                                }
                                            />
                                        </Form.Group>
                                    </Form>
                                </div>
                            );
                        })}
                    {/* с помощью этой кнопки можно добавлять новые позиции в уведомление */}
                    {/* <Button onClick={() => this.props.onChangePositionsNumber(notice.positions + 1)}/> */}
                    <Divider section horizontal style={divider}>
                        <h6>Проверьте введенные данные</h6>
                    </Divider>
                    <Segment
                        basic
                        clearing
                        style={{ padding: '0px', margin: '0px' }}
                    >
                        <Button
                            floated='left'
                            color='blue'
                            disabled={this.validate()}
                            onClick={this.createNotice}
                        >
                            Создать уведомление
                        </Button>
                        <Button
                            floated='right'
                            negative
                            onClick={this.clearForm}
                        >
                            Очистить форму
                        </Button>
                    </Segment>
                </Segment>
                <FileContainer />
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        carrier: state.carrier,
        notice: state.notice,
        tanks: state.tanks.initial_tanks
    };
};

const mapDispatchToProps = dispatch => {
    return {
        //  notice
        onChangeFormField: data => {
            dispatch(changeFormField(data));
        },
        onChangePositionsNumber: i => {
            dispatch(changePositionsNumber(i));
        },
        onChangeFormFieldPos: (id, name, value) => {
            dispatch(
                changeFormFieldPos({
                    pos_identity: id,
                    object: { [name]: value }
                })
            );
        },
        onResetNotice: () => {
            dispatch(resetNotice(defaultNotice));
        },
        onShowFile: (file, type, file_json) => {
            dispatch(
                showFile({
                    show_file: true,
                    file_string: file,
                    file_tmp_type: type,
                    file_json: file_json
                })
            );
        },
        onSaveFileIdx: idx => {
            dispatch(saveFileIdx({ file_idx: idx }));
        },

        //  carrier
        onWriteResponse: res => {
            dispatch(writeResponse({ open: true, response: res }));
        }
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(NoticeForm);
