import React, { Component } from 'react';
import { connect } from 'react-redux';
import { MapContainer, TileLayer, Marker, Tooltip,CircleMarker } from "react-leaflet";

import CurrLocationNew from './CurrLocation';
import TransitNew from './Transit';
import CapsuleInfo from '../TankList/CapsuleInfo';
import ParkingInfoNew from './ParkingInfo';
import "leaflet/dist/leaflet.css";
import "../../styles.css"

const Mapp = props => {
    if (props!==undefined) {
    return (
    <MapContainer  center={{lat: 55.7,lng: 37.6}} zoom={6} scrollWheelZoom={true} style={{zIndex:1,marginBottom:"-2vh",visibility: props.mapVisibility, width: "100%",height: props.h}}>
        <TileLayer
            attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        />
        {/* Отображение данных по отсекам при наведении курсора на маркер из маршрута перевозки. */}
        {props.flags.show.info_box && (
            <CircleMarker
                center={{lat: props.flags.show.lat_info ? props.flags.show.lat_info : 0,lng: props.flags.show.lng_info ? props.flags.show.lng_info : 0}}
                pathOptions={{ color: 'red'}}
                radius={20}
                opacity={0}
                fillOpacity={0}
            >
                <Tooltip direction="bottom" offset={[0, 0]} opacity={1} permanent>
                    <CapsuleInfo
                        curr_capsule={props.flags.show.capsule}
                        flag='marker'
                        time_stamp={props.flags.show.time_stamp}
                    />
                </Tooltip>
            </CircleMarker>
        )}
        {/* Отображение данных по стоянке при наведении курсора на "якорь". */}
        {props.flags.show.parking_info && (
            <CircleMarker
                center={{lat: props.flags.show.lat_info ? props.flags.show.lat_info:0 ,lng: props.flags.show.lng_info?props.flags.show.lng_info:0}}
                pathOptions={{ color: 'red'}}
                radius={20}
                opacity={0}
                fillOpacity={0}
            >
                <Tooltip direction="bottom" offset={[0, 0]} opacity={1} permanent>
                    <ParkingInfoNew
                        start={props.flags.show.start}
                        end={props.flags.show.end}
                    />
                </Tooltip>
            </CircleMarker>
        )}
        {/* Отображение маршрута перевозки в виде массива маркеров. */}
        {props.flags.show.track && props.flags.track && (
            <TransitNew
                data={props.flags.track[props.flags.curr_tank_id]}
                flag='tracks'
                flagEnd={props.flags.show.full_track}
            />
        )}
        {/* Отображение перемещений цистерны за выбранный интервал времени в виде массива маркеров. */}
        {props.flags.show.movings && props.flags.movings && (
            <TransitNew
                data={props.flags.movings[props.flags.curr_tank_id]}
                flag='movings'
            />
        )}
        {/* Отображение местоположения цистерн в виде массива изображений цистерн на карте. */}
        {props.flags.show.tanks && (
            <CurrLocationNew
                tanks={props.tanks}
                id={props.flags.curr_tank_id}
                open={props.flags.show.info_window}
            />
        )}
    </MapContainer >
    ) } else {return (<MapContainer  center={{lat: 55.7,lng: 37.6}} zoom={4} scrollWheelZoom={false} style={{zIndex:1,marginBottom:"-2vh",height: props.h}}>
        <TileLayer
            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        />
        </MapContainer >)}
            }

class NewMap extends Component {
    render() {
        const { tanks, flags } = this.props;
        var mapVisibility = flags.show.map ? 'visible' : 'hidden';
        var mapHeight = flags.show.map ? '35vh' : '35vh';
        if (flags.show.map) {
        return (
            <div className="map" style={{ zIndex:1,marginBottom:"-2vh",visibility: mapVisibility,width: "100%",height: mapHeight }} >
                <Mapp tanks={tanks} flags={flags} h={mapHeight} v={mapVisibility}/>
            </div>
        );
        } else {return (<div></div>)}
    }
}

const mapStateToProps = state => {
    return {
        flags: state.flags
    };
};

export default connect(mapStateToProps, null)(NewMap);