import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Segment, Menu, Dropdown } from 'semantic-ui-react';

import RangeDateSelector from '../DatetimeSelectors/RangeDateSelector';

import { date } from '../constants';

import { parseMoment } from '../functions';

import MaterialUIWrapper from '../MaterialUIWrapper';

import API from '../API';
import EgaisTableData from '../Tables/EgaisTableData';

import { writeResponse } from '../actions/carrier';

class EgaisData extends Component {
    state = {
        egaisData: {}
    };

    getAddrDev = (lines, sensor_number) => {
        var addr_dev;
        for (let i = 0; i < lines.length; i++) {
            if (lines[i].sensor_number === sensor_number) {
                addr_dev = lines[i].addr_dev;
            }
        }
        return addr_dev;
    };

    /* метод запрашивает данные ЕГАИС и в зависимости от типа цистерны сохраняет в нужном формате. */
    getEgaisData = (from, to) => {
        if (!this.props.egaisFetching) {
            API.GET(
                '/tanks/' + this.props.id + '/egais',
                { from: from, to: to },
                res => {
                    if (this.props.tank_type === 1) {
                        if (!res.data['1']) {
                            this.setState(
                                { egaisData: {} },
                                this.props.isFetching({ egaisFetching: false })
                            );
                        } else {
                            this.setState(
                                { egaisData: res.data },
                                this.props.isFetching({ egaisFetching: false })
                            );
                        }
                    } else {
                        this.setState(
                            { egaisData: res.data },
                            this.props.isFetching({ egaisFetching: false })
                        );
                    }
                },
                this.props.isFetching({ egaisFetching: false })
            );
            this.props.isFetching({ egaisFetching: true });
        }
    };

    componentDidMount() {
        /* Не помню почему это прописано здесь, а не на сервере, но это ВАЖНО!
        Если вдруг поменяются адреса расходомеров (48,49), то здесь нужно будет поправить.
        НО вряд ли это когда нибудь случится.. */
        if (this.props.tank_type === 2) {
            this.setState({
                lines: [
                    {
                        sensor_number: this.props.uspd_number + '_01',
                        addr_dev: 48
                    },
                    {
                        sensor_number: this.props.uspd_number + '_02',
                        addr_dev: 49
                    }
                ],
                selectedLine: this.props.uspd_number + '_01'
            });
        }
        this.getEgaisData(
            parseMoment(date.defaultFrom, 'from'),
            parseMoment(date.defaultTo, 'to')
        );
    }

    submitRangeDateSelector = param => {
        this.getEgaisData(param.from, param.to);
    };

    handleItemClick = (e, { name }) => {
        this.setState({ selectedLine: name });
    };

    /* метод выгружает txt файл с данными ЕГАИС за выбранный интервал и по выбранной точке учета. */
    exportTxt = (from, to) => {
        let addr = this.state.lines
            ? this.getAddrDev(this.state.lines, this.state.selectedLine)
            : '1';
        var data = this.state.egaisData[addr];
        if (!data) {
            this.props.onWriteResponse('Нет данных для выгрузки в файл!');
            return;
        }
        var sensor_number = '';
        if (this.state.lines && this.state.lines.length === 2) {
            if (addr === 48) {
                sensor_number = this.state.lines[0].sensor_number;
            } else {
                sensor_number = this.state.lines[1].sensor_number;
            }
        } else {
            sensor_number = this.props.uspd_number + '_01';
        }

        var str = '';
        str +=
            'SensorNumber' +
            '\t' +
            'ProductNameCode' +
            '\t' +
            'Mode' +
            '\t' +
            'StartDate' +
            '\t' +
            'EndDate' +
            '\t' +
            'VbsStart' +
            '\t' +
            'VbsEnd' +
            '\t' +
            'VbsDelta' +
            '\t' +
            'AStart' +
            '\t' +
            'AEnd' +
            '\t' +
            'ADelta' +
            '\t' +
            'PercentageAlc' +
            '\t' +
            'Temp' +
            '\n';
        for (let i = 0; i < data.length; i++) {
            str +=
                sensor_number +
                '\t' +
                (data[i].product_id + '\t') +
                (data[i].mode + '\t') +
                (data[i].start_date
                    .replace(new RegExp('-', 'g'), '')
                    .replace(new RegExp(':', 'g'), '')
                    .replace('T', '_')
                    .replace('Z', '') +
                    '\t') +
                (data[i].end_date
                    .replace(new RegExp('-', 'g'), '')
                    .replace(new RegExp(':', 'g'), '')
                    .replace('T', '_')
                    .replace('Z', '') +
                    '\t') +
                (data[i].vbs_start.toFixed(2).replace('.', ',') + '\t') +
                (data[i].vbs_end.toFixed(2).replace('.', ',') + '\t') +
                ((data[i].vbs_end - data[i].vbs_start)
                    .toFixed(2)
                    .replace('.', ',') +
                    '\t') +
                (data[i].a_start.toFixed(2).replace('.', ',') + '\t') +
                (data[i].a_end.toFixed(2).replace('.', ',') + '\t') +
                ((data[i].a_end - data[i].a_start)
                    .toFixed(2)
                    .replace('.', ',') +
                    '\t') +
                (data[i].aver_alko.toFixed(1).replace('.', ',') + '\t') +
                (data[i].aver_temp.toFixed(1).replace('.', ',') + '\n');
        }
        var filename =
            from
                .replace(new RegExp('-', 'g'), '')
                .replace(new RegExp(':', 'g'), '')
                .replace('T', '_')
                .replace('Z', '') +
            '_' +
            to
                .replace(new RegExp('-', 'g'), '')
                .replace(new RegExp(':', 'g'), '')
                .replace('T', '_')
                .replace('Z', '') +
            '_' +
            sensor_number +
            '.txt';

        var link = document.createElement('a');
        link.download = filename;
        var blob = new Blob([str], { type: 'text/plain' });
        link.href = window.URL.createObjectURL(blob);
        link.click();
    };

    render() {
        const { tank_type } = this.props;
        const { lines, egaisData, selectedLine } = this.state;
        var disabled = this.props.egaisFetching;
        var nameOfClass = tank_type === 1 ? 'trips-table' : 'egais-table';
        let addr = lines ? this.getAddrDev(lines, selectedLine) : '1';
        return (
            <Segment basic className='egais' style={{ padding: '0px' }}>
                <MaterialUIWrapper>
                    <RangeDateSelector
                        submitRangeDateSelector={this.submitRangeDateSelector}
                        exportTxt={this.exportTxt}
                        disabled={disabled}
                        textFrom='egais-from'
                        textTo='egais-to'
                    />
                </MaterialUIWrapper>
                {tank_type === 2 && lines ? (
                    <Menu className='select-line'>
                        <Menu.Item header>Выберите линию: </Menu.Item>
                        <Dropdown item text={selectedLine}>
                            <Dropdown.Menu>
                                <Dropdown.Item
                                    name={lines[0].sensor_number}
                                    onClick={this.handleItemClick}
                                >
                                    {lines[0].sensor_number}
                                </Dropdown.Item>
                                <Dropdown.Item
                                    name={lines[1].sensor_number}
                                    onClick={this.handleItemClick}
                                >
                                    {lines[1].sensor_number}
                                </Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                    </Menu>
                ) : null}
                <Segment
                    basic
                    loading={disabled}
                    textAlign='center'
                    className={nameOfClass}
                >
                    <EgaisTableData data={egaisData[addr]} />
                </Segment>
            </Segment>
        );
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onWriteResponse: res => {
            dispatch(writeResponse({ open: true, response: res }));
        }
    };
};

export default connect(null, mapDispatchToProps)(EgaisData);
