import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
    Segment,
    Table,
    Form,
    Checkbox,
    Popup,
    Button,
    Icon,
    Input,
    Select,
    Modal,
    Header,
    Confirm
} from 'semantic-ui-react';

import API from '../API';
import AddingProduct from './AddingProduct';

import { pushElem, delElem } from '../functions';

import { ResTypesProducts, PRODUCTS } from '../constants';

import {
    writeResponse,
    addTankIDSyncLoading,
    delTankIDSyncLoading
} from '../actions/carrier';

const TableProducts = {
    product_id: 'Код АП',
    str_prod: 'Название',
    full_name: 'Полное наименование'
};

const CheckboxItem = props => {
    return (
        <Checkbox
            name={props.row.product_id}
            checked={props.checked}
            onChange={() => {
                props.selectProduct(props.i, props.row, props.checked);
            }}
        />
    );
};

const More = ({ data, tank_type }) => {
    return (
        <div>
            <Segment basic style={{ padding: '0px' }}>
                <Table basic='very' compact>
                    <Table.Body>
                        <Table.Row>
                            <Table.Cell collapsing>Код вида АП:</Table.Cell>
                            <Table.Cell collapsing>
                                {data.spirit_code}
                            </Table.Cell>
                        </Table.Row>
                        <Table.Row>
                            <Table.Cell collapsing>Крепость по ТУ:</Table.Cell>
                            <Table.Cell collapsing>{data.alc_tu}</Table.Cell>
                        </Table.Row>
                        <Table.Row>
                            <Table.Cell collapsing>Тип:</Table.Cell>
                            <Table.Cell collapsing>{data.type}</Table.Cell>
                        </Table.Row>
                        <Table.Row>
                            <Table.Cell collapsing>
                                Краткое наименование:
                            </Table.Cell>
                            <Table.Cell collapsing>
                                {data.short_name.Valid
                                    ? data.short_name.String
                                    : '---'}
                            </Table.Cell>
                        </Table.Row>
                        <Table.Row>
                            <Table.Cell collapsing>Емкость тары:</Table.Cell>
                            <Table.Cell collapsing>{data.capacity}</Table.Cell>
                        </Table.Row>
                        {tank_type !== 0 && tank_type !== 3 && (
                            <Table.Row>
                                <Table.Cell collapsing>
                                    Таблица плотностей:
                                </Table.Cell>
                                <Table.Cell collapsing>
                                    {data.name_table.Valid &&
                                    data.name_table.String !== ''
                                        ? data.name_table.String + '.bin'
                                        : '---'}
                                </Table.Cell>
                            </Table.Row>
                        )}
                    </Table.Body>
                </Table>
            </Segment>
        </div>
    );
};

class Products extends Component {
    constructor(props) {
        super(props);
        this.state = {
            checkedProducts: {},
            checkedBoxes:
                this.props.data != null
                    ? [
                          ...this.props.data.map(row => {
                              return {
                                  product_id: row.product_id,
                                  checked: false,
                                  isOpen: false
                              };
                          })
                      ]
                    : [{}],
            disableDel: false,
            loadingDel: false,
            disableAdd: false,
            loadingAdd: false,
            openConfirm: false,
            openModal: false,
            response: '',
            msgNeg: false,
            msgPos: false,
            openAddition: false,
            visible: false,
            str_prod: '',
            short_name: '',
            disableEdit: false,
            loadingEdit: false,
            openEditMsg: false,
            editMsg: '',
            openDelMsg: false,
            delMsg: '',
            value: '' /* значение текущей выбранной таблицы плотностей */,
            sync_confirm: false
        };
    }

    syncProducts = () => {
        this.setState({ sync_confirm: true });
    };

    confirmSyncProducts = () => {
        this.props.onAddTankIDSyncLoading(PRODUCTS, this.props.tank_id);
        this.setState({ sync_confirm: false });
        API.POST(
            '/tanks/sync_on_tank/' + this.props.id + '/' + PRODUCTS,
            null,
            res => {
                this.props.onDelTankIDSyncLoading(PRODUCTS, this.props.id);
                this.props.onWriteResponse(res.data);
            }
        );
    };

    сancelSyncProducts = () => this.setState({ sync_confirm: false });

    //  обрабатывает выбор таблицы плотностей
    handleChangeDensTable = (e, { value }) => this.setState({ value });

    handleChange = (e, { name, value }) => this.setState({ [name]: value });

    //  открывает/закрывает модал - компонент AddingProduct
    openAddition = () => {
        this.setState({ disableAdd: true, loadingAdd: true });
        setTimeout(() => this.setState({ openAddition: true }), 500);
    };
    closeAddition = () =>
        this.setState({
            openAddition: false,
            disableAdd: false,
            loadingAdd: false
        });

    /* открывает/закрывает модал с ответом от сервера */
    openModal = () => this.setState({ openModal: true });
    closeModal = () =>
        this.setState({ openModal: false, msgNeg: false, msgPos: false });

    /* открывает/закрывает модал для подтверждения удаления либо сообщение об ошибке */
    open = () => {
        if (Object.keys(this.state.checkedProducts).length !== 0) {
            this.setState({ openConfirm: true });
        } else {
            this.setState({
                openDelMsg: true,
                delMsg: 'Выберите продукты для удаления'
            });
        }
    };
    close = () => this.setState({ openConfirm: false });

    /* открывает/закрывает компонент для редактирования продукта либо сообщение об ошибке */
    handleButtonClick = () => {
        if (!this.state.visible) {
            if (Object.keys(this.state.checkedProducts).length === 1) {
                this.setState({
                    visible: !this.state.visible,
                    str_prod: Object.keys(this.state.checkedProducts).map(p => {
                        return this.state.checkedProducts[p].str_prod.Valid
                            ? this.state.checkedProducts[p].str_prod.String
                            : '';
                    })[0],
                    short_name: Object.keys(this.state.checkedProducts).map(
                        p => {
                            return this.state.checkedProducts[p].short_name
                                .Valid
                                ? this.state.checkedProducts[p].short_name
                                      .String
                                : '';
                        }
                    )[0],
                    value: Object.keys(this.state.checkedProducts).map(p => {
                        return this.state.checkedProducts[p].name_table.Valid
                            ? this.state.checkedProducts[p].name_table.String +
                                  '.bin'
                            : '';
                    })[0]
                });
            } else if (Object.keys(this.state.checkedProducts).length === 0) {
                this.setState({
                    openEditMsg: true,
                    editMsg: 'Выберите один продукт для редактирования'
                });
            } else {
                this.setState({
                    openEditMsg: true,
                    editMsg: 'Выбрано несколько продуктов'
                });
            }
        } else {
            this.setState({ visible: !this.state.visible });
        }
    };

    //  закрывает сообщение popup при редактировании
    handleEditMsgClose = () => {
        this.setState({ openEditMsg: false });
    };

    //  закрывает сообщение popup при удалении
    handleDelMsgClose = () => {
        this.setState({ openDelMsg: false });
    };

    /* открывает/закрывает компонент с подробной информацией о продукте */
    handleOpen = row => {
        const payload = {
            product_id: row.product_id,
            isOpen: true
        };
        this.setState({
            checkedBoxes: this.state.checkedBoxes.map(current =>
                current.product_id !== row.product_id
                    ? {
                          ...current,
                          product_id: current.product_id,
                          isOpen: false
                      }
                    : { ...current, ...payload }
            )
        });
    };
    handleClose = row => {
        const payload = {
            product_id: row.product_id,
            isOpen: false
        };
        this.setState({
            checkedBoxes: this.state.checkedBoxes.map(current =>
                current.product_id !== row.product_id
                    ? current
                    : { ...current, ...payload }
            )
        });
    };

    //  выбирает продукт в таблице с выделением чекбокса или убирает выделение
    selectProduct = (id, row, checked) => {
        if (Object.keys(this.state.checkedProducts).length === 0) {
            this.setState({
                checkedProducts: pushElem(this.state.checkedProducts, id, row)
            });
        } else {
            if (this.state.checkedProducts.hasOwnProperty(id)) {
                this.setState({
                    checkedProducts: delElem(this.state.checkedProducts, id)
                });
            } else {
                this.setState({
                    checkedProducts: pushElem(
                        this.state.checkedProducts,
                        id,
                        row
                    )
                });
            }
        }
        const payload = {
            product_id: row.product_id,
            checked: !checked
        };
        this.setState({
            checkedBoxes: this.state.checkedBoxes.map(current =>
                current.product_id !== row.product_id
                    ? current
                    : { ...current, ...payload }
            )
        });
        if (Object.keys(this.state.checkedProducts).length !== 1) {
            this.setState({
                visible: false,
                str_prod: '',
                short_name: '',
                value: ''
            });
        }
    };

    //  запуск процедуры редактирования выбранного продукта
    editProduct = id => {
        this.setState({ disableEdit: true, loadingEdit: true });
        var data = {};
        var prod_id;
        Object.keys(this.state.checkedProducts).map(product => {
            data['action'] = 'edit';
            data['id'] = id;
            data['product_id'] = this.state.checkedProducts[product].product_id;
            data['str_prod'] = this.state.str_prod;
            data['short_name'] = this.state.short_name;
            data['denstable'] =
                this.state.value == null || this.state.value === ''
                    ? null
                    : this.state.value.replace(/\.[\s\S]+/, '');
            prod_id = this.state.checkedProducts[product].product_id;
        });
        API.POST('/product', data, res => {
            if (res.data === 'EDIT_PROD_SUCCESS') {
                const payload = {
                    checked: false
                };
                this.setState({
                    checkedBoxes: this.state.checkedBoxes.map(current =>
                        current.product_id !== prod_id
                            ? current
                            : { ...current, ...payload }
                    )
                });
                this.setState({
                    disableEdit: false,
                    loadingEdit: false,
                    checkedProducts: {},
                    visible: false,
                    str_prod: '',
                    short_name: '',
                    openModal: true,
                    response: res.data,
                    msgPos: true,
                    value: ''
                });
            } else {
                this.setState({
                    disableEdit: false,
                    loadingEdit: false,
                    openModal: true,
                    response: res.data,
                    msgNeg: true
                });
            }
        });
    };

    //  запуск процедуры удаления выбранных продуктов
    deleteCheckedProducts = id => {
        if (Object.keys(this.state.checkedProducts).length !== 0) {
            this.setState({ disableDel: true, loadingDel: true });
            Object.keys(this.state.checkedProducts).map(product => {
                var data = {};
                data['product_id'] = this.state.checkedProducts[
                    product
                ].product_id;
                data['id'] = id;
                data['action'] = 'del';
                API.POST('/product', data, res => {
                    if (res.data === 'DEL_PROD_SUCCESS') {
                        this.setState({
                            checkedProducts: {},
                            disableDel: false,
                            loadingDel: false,
                            openModal: true,
                            response: res.data,
                            msgPos: true
                        });
                    } else {
                        this.setState({
                            disableDel: false,
                            loadingDel: false,
                            openModal: true,
                            response: res.data,
                            msgNeg: true
                        });
                    }
                });
            });
        }
    };

    //  учитывание и сопряжение новых свойств и текущего состояния компонента
    /* Без этого кода при появлении новых props, все шло в интерфейсе не так (не помню как, но не так). Поэтому лучше это не удалять. */
    componentDidUpdate() {
        if (this.props.data) {
            if (this.state.checkedBoxes.length !== this.props.data.length) {
                this.setState({ checkedBoxes: [] });
                this.setState({
                    checkedBoxes: [
                        ...this.props.data.map(row => {
                            return {
                                product_id: row.product_id,
                                checked: false,
                                isOpen: false
                            };
                        })
                    ]
                });
            }
        }
    }

    render() {
        const {
            data,
            tables,
            id,
            tank_name,
            tank_type,
            show,
            tank_id
        } = this.props;
        let options =
            tables &&
            tables.map(table => {
                return {
                    text: table.t_name_table + ' - ' + table.t_dscr_table,
                    value: table.t_name_table
                };
            });
        if (options && options.length > 0) {
            options.splice(0, 0, { text: 'Не выбрано', value: '' });
        } else {
            options = [];
            options[0] = { text: 'Не выбрано', value: '' };
        }
        var stateDisplay = !this.state.visible ? 'none' : '';
        const isAllDataReady = data && data.length > 0;
        return (
            <div>
                <Segment basic style={{ padding: '0px', margin: '0px' }}>
                    <Table celled textAlign='center'>
                        <Table.Header>
                            <Table.Row>
                                <Table.HeaderCell></Table.HeaderCell>
                                <Table.HeaderCell>
                                    {TableProducts['product_id']}
                                </Table.HeaderCell>
                                <Table.HeaderCell>
                                    {TableProducts['str_prod']}
                                </Table.HeaderCell>
                                <Table.HeaderCell>
                                    {TableProducts['full_name']}
                                </Table.HeaderCell>
                                {isAllDataReady && (
                                    <Table.HeaderCell>
                                        Подробнее
                                    </Table.HeaderCell>
                                )}
                            </Table.Row>
                        </Table.Header>
                        <Table.Body>
                            {!isAllDataReady && (
                                <Table.Row>
                                    <Table.Cell></Table.Cell>
                                    <Table.Cell colSpan='3'>
                                        Нет добавленных пользователей
                                    </Table.Cell>
                                </Table.Row>
                            )}
                            {isAllDataReady &&
                                data.map((row, i) => {
                                    var checked;
                                    var isOpen;
                                    /* Вот эту часть лучше тоже не убирать! она завязана на поведении интерфейса при каких то изменениях. */
                                    for (
                                        let i = 0;
                                        i < this.state.checkedBoxes.length;
                                        ++i
                                    ) {
                                        if (
                                            this.state.checkedBoxes[i]
                                                .product_id === row.product_id
                                        ) {
                                            checked = this.state.checkedBoxes[i]
                                                .checked;
                                            isOpen = this.state.checkedBoxes[i]
                                                .isOpen;
                                            break;
                                        }
                                    }
                                    return (
                                        <Table.Row
                                            key={i}
                                            negative={
                                                !row.name_table.Valid ||
                                                row.name_table.String === ''
                                            }
                                        >
                                            <Table.Cell collapsing>
                                                <CheckboxItem
                                                    selectProduct={
                                                        this.selectProduct
                                                    }
                                                    i={i}
                                                    row={row}
                                                    checked={checked}
                                                />
                                            </Table.Cell>
                                            <Table.Cell collapsing>
                                                {row['product_id']}
                                            </Table.Cell>
                                            <Table.Cell collapsing>
                                                {row['str_prod'].String}
                                            </Table.Cell>
                                            <Table.Cell collapsing>
                                                {row['full_name']}
                                            </Table.Cell>
                                            <Popup
                                                trigger={
                                                    <Table.Cell collapsing>
                                                        <Icon
                                                            name='unordered list'
                                                            size='large'
                                                            color='blue'
                                                            link
                                                        />
                                                    </Table.Cell>
                                                }
                                                content={
                                                    <More
                                                        data={row}
                                                        tank_type={tank_type}
                                                    />
                                                }
                                                on='click'
                                                open={isOpen}
                                                onClose={() =>
                                                    this.handleClose(row)
                                                }
                                                onOpen={() =>
                                                    this.handleOpen(row)
                                                }
                                                position='left center'
                                            />
                                        </Table.Row>
                                    );
                                })}
                        </Table.Body>
                        <Table.Footer fullWidth>
                            <Table.Row>
                                <Table.HeaderCell />
                                <Table.HeaderCell colSpan='4'>
                                    <Button
                                        floated='left'
                                        style={{ marginRight: '14px' }}
                                        size='small'
                                        color='green'
                                        loading={this.state.loadingAdd}
                                        disabled={this.state.disableAdd}
                                        onClick={this.openAddition}
                                    >
                                        Добавить продукт
                                    </Button>
                                    <Popup
                                        trigger={
                                            <Button
                                                floated='left'
                                                style={{ marginRight: '14px' }}
                                                loading={this.state.loadingDel}
                                                size='small'
                                                color='red'
                                                disabled={this.state.disableDel}
                                            >
                                                Удалить продукт
                                            </Button>
                                        }
                                        content={this.state.delMsg}
                                        on='click'
                                        open={this.state.openDelMsg}
                                        onClose={() => this.handleDelMsgClose()}
                                        onOpen={() => this.open()}
                                        position='top left'
                                    />
                                    <Popup
                                        trigger={
                                            <Button
                                                floated='left'
                                                style={{ marginRight: '14px' }}
                                                size='small'
                                                color='yellow'
                                            >
                                                Редактировать продукт
                                            </Button>
                                        }
                                        content={this.state.editMsg}
                                        on='click'
                                        open={this.state.openEditMsg}
                                        onClose={() =>
                                            this.handleEditMsgClose()
                                        }
                                        onOpen={() => this.handleButtonClick()}
                                        position='top left'
                                    />
                                    <Button
                                        floated='right'
                                        icon
                                        labelPosition='left'
                                        primary
                                        size='small'
                                        onClick={this.syncProducts}
                                        disabled={
                                            show.sync_product_loading.includes(
                                                tank_id
                                            )
                                                ? true
                                                : false
                                        }
                                        loading={
                                            show.sync_product_loading.includes(
                                                tank_id
                                            )
                                                ? true
                                                : false
                                        }
                                    >
                                        <Icon name='sync alternate' />
                                        Cинх-ть справочник продуктов ЕГАИС
                                    </Button>
                                </Table.HeaderCell>
                            </Table.Row>
                        </Table.Footer>
                    </Table>
                </Segment>
                <Segment basic style={{ margin: '0px', display: stateDisplay }}>
                    <Form>
                        <Form.Group>
                            <Form.Field
                                control={Input}
                                label='Название продукта'
                                placeholder='Название продукта'
                                name='str_prod'
                                value={this.state.str_prod}
                                onChange={this.handleChange}
                            />
                            <Form.Field
                                control={Input}
                                label='Краткое наименование'
                                placeholder='Краткое наименование'
                                name='short_name'
                                value={this.state.short_name}
                                onChange={this.handleChange}
                            />
                            {tank_type !== 0 && tank_type !== 3 && (
                                <Form.Field
                                    control={Select}
                                    label='Таблица плотностей'
                                    value={this.state.value}
                                    onChange={this.handleChangeDensTable}
                                    options={options}
                                    placeholder='Таблица плотностей'
                                />
                            )}
                        </Form.Group>
                        <Button
                            loading={this.state.loadingEdit}
                            disabled={this.state.disableEdit}
                            size='small'
                            color='yellow'
                            onClick={() => this.editProduct(id)}
                        >
                            Применить
                        </Button>
                    </Form>
                </Segment>
                <Modal
                    basic
                    className='scrolling'
                    size='mini'
                    open={this.state.openConfirm}
                >
                    <Modal.Content>
                        <p>
                            Вы уверены, что хотите удалить выбранные продукты?
                        </p>
                    </Modal.Content>
                    <Modal.Actions>
                        <Button onClick={this.close} negative>
                            Нет
                        </Button>
                        <Button
                            onClick={() => {
                                this.deleteCheckedProducts(id);
                                this.close();
                            }}
                            positive
                            content='Да'
                        />
                    </Modal.Actions>
                </Modal>
                <Modal
                    size='tiny'
                    open={this.state.openModal}
                    onClose={this.closeModal}
                    dimmer='blurring'
                >
                    <Header
                        icon={
                            <Icon
                                color={
                                    this.state.msgNeg
                                        ? 'red'
                                        : this.state.msgPos
                                        ? 'green'
                                        : 'red'
                                }
                                name={
                                    this.state.msgNeg
                                        ? 'close'
                                        : this.state.msgPos
                                        ? 'check'
                                        : 'close'
                                }
                            />
                        }
                        content={ResTypesProducts[this.state.response]}
                    />
                    <Modal.Actions>
                        <Button negative onClick={this.closeModal}>
                            Закрыть
                        </Button>
                    </Modal.Actions>
                </Modal>
                <Modal
                    className='modal-add-product'
                    size='fullscreen'
                    open={this.state.openAddition}
                    onClose={this.closeAddition}
                    closeOnEscape
                    closeOnDimmerClick={false}
                >
                    {<AddingProduct tblproduct={data} id={id} />}
                </Modal>
                <Confirm
                    open={this.state.sync_confirm}
                    content={
                        'Подтвердите запуск синхронизации справочника продуктов ЕГАИС на цистерне ' +
                        tank_name +
                        '. ' +
                        'Время проведения синхронизации может занять до 15 минут. Желательно не перезагружать страницу.' +
                        'Текущий статус синхронизации можно будет отслеживать по следующему пути: Статусы отправки данных -> Команды синхронизации.'
                    }
                    cancelButton='Закрыть'
                    confirmButton='Подтвердить'
                    onCancel={this.сancelSyncProducts}
                    onConfirm={this.confirmSyncProducts}
                />
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        tank_name: state.flags.curr_tank_name,
        tank_id: state.flags.curr_tank_id,
        show: state.carrier.show
    };
};

const mapDispatchToProps = dispatch => {
    return {
        //  carrier
        onWriteResponse: res => {
            dispatch(writeResponse({ open: true, response: res }));
        },
        onAddTankIDSyncLoading: (cmd, id) => {
            dispatch(addTankIDSyncLoading({ cmd: cmd, id: id }));
        },
        onDelTankIDSyncLoading: (cmd, id) => {
            dispatch(delTankIDSyncLoading({ cmd: cmd, id: id }));
        }
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Products);
