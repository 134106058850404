import React, { Component } from 'react';
import { connect } from 'react-redux';

import API from '../API';
import AllData from '../CarrierPanel/AllData';

import {
    freqCarrier,
    ADMIN,
    CARRIER,
    SHIPPER,
    CONSIGNEE,
    PRODUCER
} from '../constants';

import {
    getCarrierInfo,
    getShipperInfo,
    getConsigneeInfo,
    getProducerInfo,
    getSyncHistory,
    getNoticeHistory
} from '../actions/carrier';

/* компонент содержащий весь функционал Панели управления грузоперевозчика.
константы CARRIER, SHIPPER, CONSIGNEE, PRODUCER важны, так как влияют на цель запросов и содержание компонентов. */
class CarrierPanel extends Component {
    /* при монтировании данного компонента совершаются запросы данных и устанавливаются таймеры обновления этих данных. */
    componentDidMount() {
        API.GET('/carrier/' + CARRIER + '/org_info', null, res => {
            this.props.onGetCarrierInfo(res.data);
            if (this.props.role !== ADMIN) {
                API.GET('/carrier/' + SHIPPER + '/org_info', null, res => {
                    this.props.onGetShipperInfo(res.data);
                    API.GET(
                        '/carrier/' + CONSIGNEE + '/org_info',
                        null,
                        res => {
                            this.props.onGetConsigneeInfo(res.data);
                            API.GET(
                                '/carrier/' + PRODUCER + '/org_info',
                                null,
                                res => {
                                    this.props.onGetProducerInfo(res.data);
                                }
                            );
                        }
                    );
                });
            }
        });
        API.GET('/carrier/sync_history', null, res => {
            this.props.onGetSyncHistory(res.data);
        });
        API.GET('/carrier/notice_history', null, res => {
            this.props.onGetNoticeHistory(res.data);
        });
        this.timerCarrier = setInterval(() => {
            API.GET('/carrier/' + CARRIER + '/org_info', null, res => {
                this.props.onGetCarrierInfo(res.data);
                if (this.props.role !== ADMIN) {
                    API.GET('/carrier/' + SHIPPER + '/org_info', null, res => {
                        this.props.onGetShipperInfo(res.data);
                        API.GET(
                            '/carrier/' + CONSIGNEE + '/org_info',
                            null,
                            res => {
                                this.props.onGetConsigneeInfo(res.data);
                                API.GET(
                                    '/carrier/' + PRODUCER + '/org_info',
                                    null,
                                    res => {
                                        this.props.onGetProducerInfo(res.data);
                                    }
                                );
                            }
                        );
                    });
                }
            });
            API.GET('/carrier/sync_history', null, res => {
                this.props.onGetSyncHistory(res.data);
            });
            API.GET('/carrier/notice_history', null, res => {
                this.props.onGetNoticeHistory(res.data);
            });
        }, freqCarrier);
    }

    componentWillUnmount() {
        clearInterval(this.timerCarrier);
    }

    render() {
        const { carrier, role } = this.props;
        return (
            <div className='carrier-panel'>
                <AllData carrier={carrier} role={role} />
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        carrier: state.carrier,
        role: state.flags.role
    };
};

const mapDispatchToProps = dispatch => {
    return {
        onGetCarrierInfo: data => {
            dispatch(getCarrierInfo({ carriers: data }));
        },
        onGetShipperInfo: data => {
            dispatch(getShipperInfo({ shippers: data }));
        },
        onGetConsigneeInfo: data => {
            dispatch(getConsigneeInfo({ consignees: data }));
        },
        onGetProducerInfo: data => {
            dispatch(getProducerInfo({ producers: data }));
        },
        onGetSyncHistory: data => {
            dispatch(getSyncHistory({ sync_history: data }));
        },
        onGetNoticeHistory: data => {
            dispatch(getNoticeHistory({ notice_history: data }));
        }
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(CarrierPanel);
