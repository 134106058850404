import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
    Segment,
    Button,
    Icon,
    Table,
    Modal,
    Form,
    Header
} from 'semantic-ui-react';

import {
    ADMIN,
    CARRIER,
    SHIPPER,
    CONSIGNEE,
    PRODUCER,
    ResTypesAddOrgs
} from '../constants';

import API from '../API';
import NoOrgData from './NoOrgData';

import {
    addOrgIDLoading,
    delOrgIDLoading,
    writeResponse,
    changeFirstQuery
} from '../actions/carrier';

const SUCCESS_ADD_ORG = 'SUCCESS_ADD_ORG';
const SUCCESS_DEL_ORG = 'SUCCESS_DEL_ORG';
const SUCCESS_ADD_CARRIER = 'SUCCESS_ADD_CARRIER';
const SUCCESS_EDIT_CARRIER = 'SUCCESS_EDIT_CARRIER';

const TableColumns = {
    reg_id: 'ФСРАР ID',
    full_name: 'Полное наименование',
    short_name: 'Краткое наименование',
    inn: 'ИНН',
    kpp: 'КПП',
    country: 'Код страны',
    region_code: 'Код региона',
    description: 'Адрес'
};

/* Данный компонент может представлять из себя вкладки Перевозчики, Отправители, Получатели и Производители,
в зависимости от свойства type и роли пользователя. */
class OrgsInfo extends Component {
    state = {
        inputRegIDAdd: false,
        inputRegIDDel: false,
        inputRegIDErr: false,
        regID: '',
        openModal: false,
        msgNeg: false,
        msgPos: false,
        response: '',
        inputPass: false,
        pass: '',
        passErr: false,
        org_id: null
    };

    openAdd = () => this.setState({ inputRegIDAdd: true });
    closeAdd = () => this.setState({ inputRegIDAdd: false });

    openEdit = () => this.setState({ inputRegIDEdit: true });
    closeEdit = () => this.setState({ inputRegIDEdit: false });

    openDel = () => this.setState({ inputRegIDDel: true });
    closeDel = () => this.setState({ inputRegIDDel: false });

    handleChange = (e, { name, value }) => this.setState({ [name]: value });

    closeModal = () =>
        this.setState({
            openModal: false,
            msgNeg: false,
            msgPos: false,
            response: ''
        });

    produceOrg = operation => {
        if (this.state.regID === '') {
            this.setState({ inputRegIDErr: true });
            setTimeout(() => this.setState({ inputRegIDErr: false }), 1000);
            return;
        }
        var data = {
            reg_id: this.state.regID,
            operation: operation
        };
        this.setState({ regID: '' });
        API.POST('/carrier/' + this.props.type + '/produce_org', data, res => {
            this.setState({ openModal: true, response: res.data });
            if (
                res.data === SUCCESS_ADD_ORG ||
                res.data === SUCCESS_DEL_ORG ||
                res.data === SUCCESS_ADD_CARRIER ||
                res.data === SUCCESS_EDIT_CARRIER
            ) {
                this.setState({ msgPos: true });
                this.closeEdit();
                this.closeAdd();
                this.closeDel();
            } else {
                this.setState({ msgNeg: true });
            }
        });
    };

    checkPasswordIsEmpty = (pass, key) => {
        if (pass === '') {
            this.setState({ [key]: true });
            setTimeout(() => this.setState({ [key]: false }), 1000);
            return false;
        }
        return true;
    };

    /* открывает/закрывает окно для ввода пароля доступа к УТМ */
    getAccess = id => {
        const org_id = id; //  на всякий случай делаем копию аргумента
        this.setState({ inputPass: true, org_id: org_id });
    };
    closeGetAccess = () => this.setState({ inputPass: false, pass: '' });

    syncOrg = () => {
        /* проверка ввели ли пароль */
        if (!this.checkPasswordIsEmpty(this.state.pass, 'passErr')) {
            return;
        }
        /* копируем на всякий случай переменные для запроса */
        const type = this.props.type;
        const org_id = this.state.org_id;

        var data = {
            org_id: org_id,
            new_pass: this.state.pass,
            replyid: ''
        };
        //  добавляем org_id в массив в redux store, чтобы нажатая кнопка стала disabled и loading
        this.props.onAddOrgIDLoading(type, org_id);
        var key;
        switch (type) {
            case 1:
                key = 'carrier_1_query';
                break;
            case 2:
                key = 'shipper_1_query';
                break;
            case 3:
                key = 'consignee_1_query';
                break;
            case 20:
                key = 'producer_1_query';
                break;
            default:
                return;
        }
        this.props.onChangeFirstQuery(key, true);

        API.POST('/carrier/' + type + '/sync_org', data, res => {
            this.props.onDelOrgIDLoading(type, org_id);
            this.props.onWriteResponse(res.data);
            this.props.onChangeFirstQuery(key, false);
        });
        this.setState({ inputPass: false, pass: '' });
    };

    render() {
        /* show это свойство из редьюсера carrier, в котором хранятся переменные влияющие на поведение интерфейса */
        const { orgs, type, role, show } = this.props;
        const {
            inputRegIDAdd,
            regID,
            inputRegIDErr,
            openModal,
            msgNeg,
            msgPos,
            response,
            inputPass,
            pass,
            passErr,
            inputRegIDDel,
            inputRegIDEdit
        } = this.state;
        const isAllDataReady = orgs && orgs.length > 0;
        var text;
        var carrier_color; //  цвет кнопки во вкладке Перевозчики
        var contentAdd; //  текст на кнопке
        var contentDel; //  текст на кнопке
        var key; // <- название свойства в redux store, которое влияет на интерфейс - какие кнопки в столбце "Синхронизация" в состоянии loading
        /* инициализация вышеобъявленных переменных в зависимости от type */
        switch (type) {
            case CARRIER:
                text = 'Нет данных';
                key = 'carrier_loading';
                contentAdd =
                    orgs &&
                    (orgs[0].reg_id === ''
                        ? 'Добавить ФСРАР ID перевозчика'
                        : 'Редактировать ФСРАР ID перевозчика');
                carrier_color =
                    orgs && (orgs[0].reg_id === '' ? 'blue' : 'yellow');
                break;
            case SHIPPER:
                text = 'Пока нет добавленных отправителей';
                contentAdd = 'Добавить отправителя';
                contentDel = 'Удалить отправителя';
                key = 'shipper_loading';
                break;
            case CONSIGNEE:
                text = 'Пока нет добавленных получателей';
                contentAdd = 'Добавить получателя';
                contentDel = 'Удалить получателя';
                key = 'consignee_loading';
                break;
            case PRODUCER:
                text = 'Пока нет добавленных производителей';
                contentAdd = 'Добавить производителя';
                contentDel = 'Удалить производителя';
                key = 'producer_loading';
                break;
            default:
                text = 'Нет данных';
        }
        var modalHeader; //  заголовок в открывающемся модальном окне
        modalHeader =
            ((inputRegIDAdd && type === CARRIER) || inputRegIDEdit
                ? 'Введите ФСРАР ID перевозчика'
                : inputRegIDAdd &&
                  'Введите ФСРАР ID контрагента для добавления') ||
            (inputRegIDDel && 'Введите ФСРАР ID контрагента для удаления');
        return (
            <div className='orgs-info'>
                {!isAllDataReady ? (
                    <NoOrgData text={text} type={type} />
                ) : (
                    <Segment basic style={{ padding: '0px' }}>
                        <Table collapsing celled textAlign='center'>
                            <Table.Header>
                                <Table.Row>
                                    <Table.HeaderCell key={1}>
                                        {TableColumns['reg_id']}
                                    </Table.HeaderCell>
                                    <Table.HeaderCell key={2}>
                                        {TableColumns['full_name']}
                                    </Table.HeaderCell>
                                    <Table.HeaderCell key={3}>
                                        {TableColumns['short_name']}
                                    </Table.HeaderCell>
                                    <Table.HeaderCell key={4}>
                                        {TableColumns['inn']}
                                    </Table.HeaderCell>
                                    <Table.HeaderCell key={5}>
                                        {TableColumns['kpp']}
                                    </Table.HeaderCell>
                                    <Table.HeaderCell key={6}>
                                        {TableColumns['country']}
                                    </Table.HeaderCell>
                                    <Table.HeaderCell key={7}>
                                        {TableColumns['region_code']}
                                    </Table.HeaderCell>
                                    <Table.HeaderCell key={8}>
                                        {TableColumns['description']}
                                    </Table.HeaderCell>
                                    {role !== ADMIN && (
                                        <Table.HeaderCell key={9}>
                                            Синхронизация
                                        </Table.HeaderCell>
                                    )}
                                </Table.Row>
                            </Table.Header>
                            <Table.Body>
                                {orgs.map((row, i) => {
                                    return (
                                        <Table.Row key={i}>
                                            <Table.Cell key={1}>
                                                {row['reg_id']}
                                            </Table.Cell>
                                            <Table.Cell key={2}>
                                                {row['full_name']}
                                            </Table.Cell>
                                            <Table.Cell key={3}>
                                                {row['short_name']}
                                            </Table.Cell>
                                            <Table.Cell key={4}>
                                                {row['inn']}
                                            </Table.Cell>
                                            <Table.Cell key={5}>
                                                {row['kpp']}
                                            </Table.Cell>
                                            <Table.Cell key={6}>
                                                {row['country']}
                                            </Table.Cell>
                                            <Table.Cell key={7}>
                                                {row['region_code']}
                                            </Table.Cell>
                                            <Table.Cell key={8}>
                                                {row['description']}
                                            </Table.Cell>
                                            {role !== ADMIN && (
                                                <Table.Cell
                                                    style={{ padding: '5px' }}
                                                >
                                                    <Button
                                                        /* если в свойстве-массиве *_loading есть id организации, то ее кнопка
                                                    синхронизации disabled и loading. Или если у организации нет присвоенного
                                                    ФСРАР ID, то disabled */
                                                        disabled={
                                                            (show[key].includes(
                                                                row.id
                                                            )
                                                                ? true
                                                                : false) ||
                                                            row.reg_id === ''
                                                        }
                                                        loading={
                                                            show[key].includes(
                                                                row.id
                                                            )
                                                                ? true
                                                                : false
                                                        }
                                                        style={{
                                                            margin: '0px'
                                                        }}
                                                        basic
                                                        size='tiny'
                                                        /* при клике открывается окно для ввода пароля доступа */
                                                        onClick={() =>
                                                            this.getAccess(
                                                                row.id
                                                            )
                                                        }
                                                    >
                                                        <Icon
                                                            id='carrier-sync'
                                                            name='sync alternate'
                                                            size='large'
                                                        />
                                                    </Button>
                                                </Table.Cell>
                                            )}
                                        </Table.Row>
                                    );
                                })}
                            </Table.Body>
                            {type !== CARRIER && (
                                <Table.Footer fullWidth>
                                    <Table.Row>
                                        <Table.HeaderCell colSpan='10'>
                                            <Button
                                                floated='left'
                                                content={contentAdd}
                                                primary
                                                size='small'
                                                onClick={this.openAdd}
                                            />
                                            <Button
                                                floated='left'
                                                content={contentDel}
                                                color='red'
                                                size='small'
                                                onClick={this.openDel}
                                            />
                                        </Table.HeaderCell>
                                    </Table.Row>
                                </Table.Footer>
                            )}
                            {type === CARRIER && role !== ADMIN && (
                                <Table.Footer fullWidth>
                                    <Table.Row>
                                        <Table.HeaderCell colSpan='10'>
                                            <Button
                                                floated='left'
                                                content={contentAdd}
                                                color={carrier_color}
                                                size='small'
                                                onClick={this.openEdit}
                                            />
                                        </Table.HeaderCell>
                                    </Table.Row>
                                </Table.Footer>
                            )}
                        </Table>
                    </Segment>
                )}
                <Modal
                    size='tiny'
                    open={inputRegIDAdd || inputRegIDDel || inputRegIDEdit}
                    closeOnEscape={false}
                    closeOnDimmerClick={false}
                >
                    <Modal.Header>{modalHeader}</Modal.Header>
                    <Modal.Content>
                        <Form>
                            <Form.Group>
                                <Form.Input
                                    placeholder='FSRAR ID'
                                    name='regID'
                                    value={regID}
                                    onChange={this.handleChange}
                                    error={inputRegIDErr}
                                />
                            </Form.Group>
                        </Form>
                    </Modal.Content>
                    <Modal.Actions>
                        <Button
                            positive
                            content='Подтвердить'
                            onClick={() => {
                                if (inputRegIDAdd) this.produceOrg('add');
                                if (inputRegIDDel) this.produceOrg('delete');
                                if (inputRegIDEdit) this.produceOrg('edit');
                            }}
                        />
                        <Button
                            negative
                            onClick={
                                (inputRegIDAdd && this.closeAdd) ||
                                (inputRegIDDel && this.closeDel) ||
                                (inputRegIDEdit && this.closeEdit)
                            }
                        >
                            Закрыть
                        </Button>
                    </Modal.Actions>
                </Modal>
                <Modal
                    size='tiny'
                    open={openModal}
                    onClose={this.closeModal}
                    dimmer='blurring'
                >
                    <Header
                        icon={
                            <Icon
                                color={
                                    msgNeg ? 'red' : msgPos ? 'green' : 'red'
                                }
                                name={
                                    msgNeg
                                        ? 'close'
                                        : msgPos
                                        ? 'check'
                                        : 'close'
                                }
                            />
                        }
                        content={ResTypesAddOrgs[response]}
                    />
                    <Modal.Actions>
                        <Button negative onClick={this.closeModal}>
                            Закрыть
                        </Button>
                    </Modal.Actions>
                </Modal>
                {/* окно для ввода пароля доступа к УТМ */}
                <Modal
                    size='tiny'
                    open={inputPass}
                    closeOnEscape={false}
                    closeOnDimmerClick={false}
                >
                    <Modal.Header>Введите пароль доступа к УТМ</Modal.Header>
                    <Modal.Content>
                        <h4>
                            Данная операция может занять от 3 до 5 минут.
                            Текущий статус операции можно будет наблюдать во
                            вкладке "История синхронизаций".
                        </h4>
                        <Form>
                            <Form.Group>
                                <Form.Input
                                    type='password'
                                    placeholder='Введите пароль'
                                    name='pass'
                                    value={pass}
                                    onChange={this.handleChange}
                                    error={passErr}
                                />
                            </Form.Group>
                        </Form>
                    </Modal.Content>
                    <Modal.Actions>
                        <Button
                            positive
                            content='Подтвердить'
                            onClick={this.syncOrg}
                        />
                        <Button negative onClick={this.closeGetAccess}>
                            Закрыть
                        </Button>
                    </Modal.Actions>
                </Modal>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        show: state.carrier.show
    };
};

const mapDispatchToProps = dispatch => {
    return {
        onAddOrgIDLoading: (cmd, id) => {
            dispatch(addOrgIDLoading({ cmd: cmd, id: id }));
        },
        onDelOrgIDLoading: (cmd, id) => {
            dispatch(delOrgIDLoading({ cmd: cmd, id: id }));
        },
        onWriteResponse: res => {
            dispatch(writeResponse({ open: true, response: res }));
        },
        onChangeFirstQuery: (key, flag) =>
            dispatch(changeFirstQuery({ [key]: flag }))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(OrgsInfo);
