import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Tab, Menu } from 'semantic-ui-react';

import API from '../API';
import NoContent from './NoContent';
import TankContent from './TankContent';
import Tank from './Tank';

import { setTankID, showInfoWindow, setCurrLocation } from '../actions/flags';

import {
    getUtmServers,
    changeUtmTrack,
    setSSHPass,
    writeResponse
} from '../actions/carrier';

import { getCurrData } from '../actions/tanks';

import {
    EMPTY_MSG,
    TANK_NOT_FOUND,
    BAD_REQUEST,
    NO_CURR_DATA
} from '../messages';

import {
    freqCurrData,
    freqUtmConn,
    freqCarrier,
    CHECK_UTM_CONN
} from '../constants';

const ERR_UTM_WORKER = 'ERR_UTM_WORKER';

class TankList extends Component {
    componentDidMount() {
        /* Таймеры, объявленные ниже, работают постоянно, пока запущено приложение. */

        //  Данный таймер обновляет текущие данные по всему списку цистерн для перевозчика.
        //  в случае какой либо ошибки, тело таймера просто не выполняется.
        this.timerCurrData = setInterval(() => {
            let status = this.checkIsThereContent(
                this.props.tanks.filtered_tanks,
                this.props.flags
            );
            if (!status.flag) {
                API.GET(
                    '/tanks/curr_data',
                    { carrier_id: this.props.flags.curr_carrier_id },
                    res => {
                        this.props.onGetCurrData(res.data);
                    }
                );
            }
        }, freqCurrData);

        //  Данный таймер обновляет информацию об УТМ. Нужно в частности при редактировании настроек УТМ.
        API.GET('/carrier/utm_servers', null, res => {
            this.props.onGetUtmServers(res.data);
        });
        this.timerCarrier = setInterval(() => {
            API.GET('/carrier/utm_servers', null, res => {
                this.props.onGetUtmServers(res.data);
            });
        }, freqCarrier);

        /* Данный таймер проверяет связь с УТМ грузоперевозчика. Тело таймера работает, если пользователь
        вручную включил отслеживание связи в панеле управления. */
        this.timerCheckConn = setInterval(() => {
            if (
                this.props.carrier.utm_tracking &&
                this.props.carrier.ssh_pass !== ''
            ) {
                API.POST(
                    '/carrier/' + CHECK_UTM_CONN + '/check_utmconn',
                    { new_pass: this.props.carrier.ssh_pass },
                    res => {
                        if (res.data === ERR_UTM_WORKER) {
                            this.props.onWriteResponse(res.data);
                            this.props.onChangeUtmTrack(false);
                            this.props.onSetSSHPass('');
                        }
                    }
                );
            }
        }, freqUtmConn);
    }

    componentWillUnmount() {
        clearInterval(this.timerCurrData);
        clearInterval(this.timerCarrier);
        clearInterval(this.timerCheckConn);
    }

    /* Данный метод проводит различные проверки отфильтрованного списка цистерн для перевозчика.
    От его результата зависит обновление текущих данных цистерн.
    Лучше ничего в этом методе не трогать. Последовательность проверок важна. */
    checkIsThereContent = (filtered_tanks, flags) => {
        if (flags.bad_req) {
            return { flag: true, msg: BAD_REQUEST };
        }

        if (filtered_tanks && filtered_tanks.length === 0) {
            return { flag: true, msg: TANK_NOT_FOUND };
        }

        if (
            filtered_tanks &&
            filtered_tanks.length > 0 &&
            !filtered_tanks[0].curr_data
        ) {
            return { flag: true, msg: EMPTY_MSG };
        }

        //  срабатывает если для перевозчика вообще нет обновляющихся цистерн
        if (flags.show.curr_data) {
            return { flag: true, msg: NO_CURR_DATA };
        }

        if (!filtered_tanks) {
            return { flag: true, msg: TANK_NOT_FOUND };
        }

        if (filtered_tanks.length === 0 || flags.curr_tank_id === 0) {
            return { flag: true, msg: TANK_NOT_FOUND };
        }

        return { flag: false, msg: EMPTY_MSG };
    };

    render() {
        const { filtered_tanks } = this.props.tanks;
        const { flags } = this.props;
        var showMap = flags.show.map;
        var showState = flags.show.state;
        var showInfoWindow = flags.show.info_window;
        var curr_tid = flags.curr_tank_id;
        var i = 0;
        var status = this.checkIsThereContent(filtered_tanks, flags);
        if (status.flag) {
            return <NoContent message={status.msg} showMap={showMap} />;
        }
        var panes = [];
        if (filtered_tanks.length > 0) {
            /* здесь используется обычное меню. компонент Tank - menu-item, а TankContent - контент */
            panes = filtered_tanks.map(t => {
                //  данная проверка не отображает цистерны для которых не был получен curr_data
                if (t.curr_data) {
                    return {
                        menuItem: (
                            <Menu.Item
                                className='tank-menu-item'
                                style={{ padding: '10px' }}
                                key={t.id}
                                /* id нужен для программного клика по нужному элементу списка меню */
                                id={'tank-menu-item' + i++}
                                active={flags.curr_tank_id === t.id}
                                onClick={() => {
                                    this.props.onSetTankID(t.id, t.name);
                                    //  данная проверка нужна для логики поведения интерфейса при нажатии на цистерны на карте или в меню
                                    if (curr_tid === t.id) {
                                        this.props.onShowInfoWindow(
                                            showInfoWindow
                                        );
                                    } else {
                                        this.props.onShowInfoWindow(false);
                                        //  установка текущих координат выбранной цистерны нужно для дальнейшего использования фокуса на цистерну.
                                        //  то есть при клике на цистерну в меню, на карте совершается переход к этой цистерне. Пока это не реализовано!
                                        this.props.onSetCurrLocation(
                                            t.curr_data.lat,
                                            t.curr_data.lng
                                        );
                                    }
                                }}
                            >
                                <Tank
                                    {...t}
                                    {...t.curr_data}
                                    curr_tank_id={flags.curr_tank_id}
                                    role={flags.role}
                                />
                            </Menu.Item>
                        ),
                        render: () => (
                            <Tab.Pane className='tank-tab-pane' key={t.id}>
                                <TankContent
                                    showMap={showMap}
                                    showState={showState}
                                    t={t}
                                />
                            </Tab.Pane>
                        )
                    };
                }
            });
        }
        return (
            <Tab /* наличие key здесь ОЧЕНЬ ВАЖНО! для правильного поведения интерфейса при переключении между перевозчиками */
                key={'tank-list' + flags.curr_carrier_id}
                className='tank-list'
                menu={{ fluid: true, vertical: true, pointing: true }}
                grid={{ paneWidth: 12, tabWidth: 4 }}
                panes={panes}
                /* также ОЧЕНЬ ВАЖНОЕ свойство - при переключении между цистернами TankContent каждый раз 
                    перемонтируется, что поможет контролировать таймеры обновления прописанные в TankContent */
                renderActiveOnly
                /* **renderActiveOnly={true} - монтируются только активный пэйн, при переключении табов меню пэйн перемонтируется; 
                        renderActiveOnly={false} - монтируются все пэйны сразу, а отображается только активный; */
            />
        );
    }
}

const mapStateToProps = state => state;

const mapDispatchToProps = dispatch => {
    return {
        //  flags
        onSetTankID: (id, name) => {
            dispatch(setTankID({ curr_tank_id: id, curr_tank_name: name }));
        },
        onShowInfoWindow: flag => {
            dispatch(showInfoWindow({ info_window: !flag }));
        },
        onSetCurrLocation: (lat, lng) => {
            dispatch(setCurrLocation({ lat: lat, lng: lng }));
        },

        //tanks
        onGetCurrData: data => {
            dispatch(getCurrData({ curr_data: data }));
        },

        // carrier
        onGetUtmServers: data => {
            dispatch(getUtmServers({ utm_servers: data }));
        },
        onChangeUtmTrack: flag => {
            dispatch(changeUtmTrack({ utm_tracking: flag }));
        },
        onSetSSHPass: pass => {
            dispatch(setSSHPass({ ssh_pass: pass }));
        },
        onWriteResponse: res => {
            dispatch(writeResponse({ open: true, response: res }));
        }
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(TankList);
