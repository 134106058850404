import React, { Component } from 'react';
import {
    Segment,
    Table,
    Checkbox,
    Popup,
    Icon,
    Form,
    Input,
    Button,
    Modal,
    Header
} from 'semantic-ui-react';

import API from '../API';

import {
    fromStringToArray,
    fromArrayToString,
    pushElem,
    delElem
} from '../functions';

import { ResTypesEmails, MsgTypes } from '../constants';

const TableColumns = {
    user: 'Пользователь',
    e_mail: 'Почтовый адрес'
};

const CheckboxItem = props => {
    return (
        <Checkbox
            name={props.row.e_mail.String}
            checked={props.checked}
            onChange={() => {
                props.selectEmail(props.i, props.row, props.checked);
            }}
        />
    );
};

class Settings extends Component {
    constructor(props) {
        super(props);
        this.state = {
            checkedTypes:
                this.props.mask == null
                    ? [...MsgTypes.map(t => true)]
                    : this.props.mask.length > 0
                    ? [...this.props.mask.map(m => (m === 0 ? false : true))]
                    : [...MsgTypes.map(t => false)]
        };
    }

    /* Если вдруг в будущем понадобится редактировать типы сообщений, отправляемых получателям, то в эту функцию можно передать
    метод selectType из класса Notifications и все. */

    render() {
        return (
            <div>
                <Segment basic>
                    {
                        <Form>
                            <Form.Group grouped>
                                {MsgTypes.map((t, j) => (
                                    <Form.Field key={j}>
                                        <Checkbox
                                            toggle
                                            disabled
                                            label={t.type}
                                            checked={
                                                this.state.checkedTypes[t.i]
                                            }
                                        />
                                    </Form.Field>
                                ))}
                            </Form.Group>
                        </Form>
                    }
                </Segment>
            </div>
        );
    }
}

class Notifications extends Component {
    constructor(props) {
        super(props);
        this.state = {
            checkedEmails: {},
            checkedBoxes:
                this.props.data != null
                    ? [
                          ...this.props.data.map(row => {
                              /* mask это битовая маска, хранящаяся в БД и описывающая какие типы сообщений отправляются получателям. */
                              var mask;
                              if (row.is_send === 0) {
                                  mask = [];
                              } else {
                                  mask = fromStringToArray(
                                      row.is_send.toString(2)
                                  );
                              }
                              return {
                                  item: row.e_mail.String,
                                  checked: false,
                                  isOpen: false,
                                  mask: mask
                              };
                          })
                      ]
                    : [{}],
            user: '',
            email: '',
            checkedTypesOnAdd: [...MsgTypes.map(t => true)],
            visible: false,
            disableAdd: false,
            disableDel: false,
            loadingAdd: false,
            loadingDel: false,
            errorInput: false,
            openConfirm: false,
            openModal: false,
            response: '',
            msgNeg: false,
            msgPos: false
        };
    }

    /* открывает/закрывает модал с ответом от сервера */
    openModal = () => this.setState({ openModal: true });
    closeModal = () =>
        this.setState({ openModal: false, msgNeg: false, msgPos: false });

    /* открывает/закрывает модал для подтверждения удаления получателей */
    open = () => {
        if (Object.keys(this.state.checkedEmails).length !== 0) {
            this.setState({ openConfirm: true });
        }
    };
    close = () => this.setState({ openConfirm: false });

    //  открывает настройки оповещений при добавлении получателя
    handleButtonClick = () => this.setState({ visible: !this.state.visible });

    //  открывает/закрывает popup с настройками оповещений для получателя
    handleOpen = row => {
        const payload = {
            item: row.e_mail.String,
            isOpen: true
        };
        this.setState({
            checkedBoxes: this.state.checkedBoxes.map(current =>
                current.item !== row.e_mail.String
                    ? { ...current, item: current.item, isOpen: false }
                    : { ...current, ...payload }
            )
        });
    };
    handleClose = row => {
        const payload = {
            item: row.e_mail.String,
            isOpen: false
        };
        this.setState({
            checkedBoxes: this.state.checkedBoxes.map(current =>
                current.item !== row.e_mail.String
                    ? current
                    : { ...current, ...payload }
            )
        });
    };

    //  выбирает получателя в таблице с выделением чекбокса или убирает выделение
    selectEmail = (id, row, checked) => {
        if (Object.keys(this.state.checkedEmails).length === 0) {
            this.setState({
                checkedEmails: pushElem(this.state.checkedEmails, id, row)
            });
        } else {
            if (this.state.checkedEmails.hasOwnProperty(id)) {
                this.setState({
                    checkedEmails: delElem(this.state.checkedEmails, id)
                });
            } else {
                this.setState({
                    checkedEmails: pushElem(this.state.checkedEmails, id, row)
                });
            }
        }
        const payload = {
            item: row.e_mail.String,
            checked: !checked
        };
        this.setState({
            checkedBoxes: this.state.checkedBoxes.map(current =>
                current.item !== row.e_mail.String
                    ? current
                    : { ...current, ...payload }
            )
        });
    };

    //  запуск процедуры удаления выбранных получателей
    deleteCheckedEmails = id => {
        if (Object.keys(this.state.checkedEmails).length !== 0) {
            this.setState({ disableDel: true, loadingDel: true });
            Object.keys(this.state.checkedEmails).map(user => {
                var data = {};
                Object.keys(this.state.checkedEmails[user]).map(key => {
                    if (key !== 'is_send') {
                        data[key] = this.state.checkedEmails[user][key].String;
                    } else {
                        data[key] = this.state.checkedEmails[user][key];
                    }
                });
                data['id'] = id;
                data['action'] = 'del';
                API.POST('/email', data, res => {
                    if (res.data === 'DEL_USER_SUCCESS') {
                        this.setState({
                            checkedEmails: {},
                            disableDel: false,
                            loadingDel: false,
                            openModal: true,
                            response: res.data,
                            msgPos: true
                        });
                    } else {
                        this.setState({
                            disableDel: false,
                            loadingDel: false,
                            openModal: true,
                            response: res.data,
                            msgNeg: true
                        });
                    }
                });
            });
        }
    };

    //  запуск процедуры добавления нового пользователя
    addUser = id => {
        if (this.state.user === '' || this.state.email === '') {
            this.setState({ errorInput: true });
            setTimeout(() => this.setState({ errorInput: false }), 1000);
            return;
        }
        this.setState({ disableAdd: true, loadingAdd: true });
        var str = fromArrayToString(this.state.checkedTypesOnAdd);
        //  конвертация двоичного числа в десятичное
        var is_send = parseInt(str, 2);
        var data = {
            is_send: is_send,
            user: this.state.user,
            e_mail: this.state.email,
            id: id,
            action: 'add'
        };
        API.POST('/email', data, res => {
            this.setState({
                disableAdd: false,
                loadingAdd: false,
                openModal: true,
                response: res.data
            });
            if (res.data === 'ADD_USER_SUCCESS') {
                this.setState({
                    user: '',
                    email: '',
                    checkedTypesOnAdd: [...MsgTypes.map(t => true)],
                    msgPos: true
                });
            } else {
                this.setState({ msgNeg: true });
            }
        });
    };

    //  учитывание и сопряжение новых свойств и текущего состояния компонента
    /* Без этого кода при появлении новых props, все шло в интерфейсе не так (не помню как, но не так). Поэтому лучше это не удалять. */
    componentDidUpdate(prevProps, prevState) {
        if (this.props.data) {
            if (this.state.checkedBoxes.length !== this.props.data.length) {
                this.setState({ checkedBoxes: [] });
                this.setState({
                    checkedBoxes: [
                        ...this.props.data.map(row => {
                            var mask;
                            if (row.is_send === 0) {
                                mask = [];
                            } else {
                                mask = fromStringToArray(
                                    row.is_send.toString(2)
                                );
                            }
                            return {
                                item: row.e_mail.String,
                                checked: false,
                                isOpen: false,
                                mask: mask
                            };
                        })
                    ]
                });
            }
        }
    }

    handleChange = (e, { name, value }) => this.setState({ [name]: value });

    //  выбирает тип сообщений из списка настроек
    selectType = (j, checked) => {
        this.setState({
            checkedTypesOnAdd: this.state.checkedTypesOnAdd.map((m, i) =>
                i === j ? !checked : m
            )
        });
    };

    //  программный скроллинг в самый низ текущего блока при открытии настроек оповещений при добавлении нового получателя.
    /* Убрал за ненадобностью. Ранее использовалось, когда при длинном списке получателей кнопки 'добавить/удалить' могли
    быть пользователем не увидены. */
    scrollToBottom = () => {
        this.messagesEnd.scrollIntoView({ behavior: 'smooth' });
    };

    render() {
        const { data, id } = this.props;
        var stateDisplay = !this.state.visible ? 'none' : '';
        const isAllDataReady = data && data.length > 0;
        return (
            <div>
                <Segment basic style={{ padding: '0px', margin: '0px' }}>
                    <Table celled textAlign='center'>
                        <Table.Header>
                            {!isAllDataReady && (
                                <Table.Row>
                                    <Table.HeaderCell></Table.HeaderCell>
                                    <Table.HeaderCell>
                                        {TableColumns['user']}
                                    </Table.HeaderCell>
                                    <Table.HeaderCell>
                                        {TableColumns['e_mail']}
                                    </Table.HeaderCell>
                                </Table.Row>
                            )}
                            {isAllDataReady && (
                                <Table.Row>
                                    <Table.HeaderCell></Table.HeaderCell>
                                    {Object.keys(data[0]).map((k, i) => {
                                        if (i !== 0)
                                            return (
                                                <Table.HeaderCell key={i}>
                                                    {TableColumns[k]}
                                                </Table.HeaderCell>
                                            );
                                    })}
                                    <Table.HeaderCell>
                                        Подробнее
                                    </Table.HeaderCell>
                                </Table.Row>
                            )}
                        </Table.Header>
                        <Table.Body>
                            {!isAllDataReady && (
                                <Table.Row>
                                    <Table.Cell></Table.Cell>
                                    <Table.Cell colSpan='3'>
                                        Нет добавленных пользователей для
                                        отправки уведомлений
                                    </Table.Cell>
                                </Table.Row>
                            )}
                            {isAllDataReady &&
                                data.map((row, i) => {
                                    var checked;
                                    var isOpen;
                                    var mask;
                                    /* Вот эту часть лучше тоже не убирать! она завязана на поведении интерфейса при каких то изменениях. */
                                    for (
                                        let i = 0;
                                        i < this.state.checkedBoxes.length;
                                        ++i
                                    ) {
                                        if (
                                            this.state.checkedBoxes[i].item ===
                                            row.e_mail.String
                                        ) {
                                            checked = this.state.checkedBoxes[i]
                                                .checked;
                                            isOpen = this.state.checkedBoxes[i]
                                                .isOpen;
                                            mask = this.state.checkedBoxes[i]
                                                .mask;
                                            break;
                                        }
                                    }
                                    return (
                                        <Table.Row key={i}>
                                            <Table.Cell collapsing>
                                                <CheckboxItem
                                                    selectEmail={
                                                        this.selectEmail
                                                    }
                                                    i={i}
                                                    row={row}
                                                    checked={checked}
                                                />
                                            </Table.Cell>
                                            {Object.keys(row).map((k, j) => {
                                                if (j !== 0)
                                                    return (
                                                        <Table.Cell key={j}>
                                                            {row[k].String}
                                                        </Table.Cell>
                                                    );
                                            })}
                                            <Popup
                                                trigger={
                                                    <Table.Cell collapsing>
                                                        <Icon
                                                            name='setting'
                                                            size='large'
                                                            color='blue'
                                                            link
                                                        />
                                                    </Table.Cell>
                                                }
                                                content={
                                                    <Settings mask={mask} />
                                                }
                                                on='click'
                                                open={isOpen}
                                                onClose={() =>
                                                    this.handleClose(row)
                                                }
                                                onOpen={() =>
                                                    this.handleOpen(row)
                                                }
                                                position='left center'
                                            />
                                        </Table.Row>
                                    );
                                })}
                        </Table.Body>
                        <Table.Footer fullWidth>
                            <Table.Row>
                                <Table.HeaderCell />
                                <Table.HeaderCell colSpan='3'>
                                    <Form>
                                        <Form.Group inline>
                                            <Form.Field>
                                                <Input
                                                    placeholder='Пользователь'
                                                    name='user'
                                                    value={this.state.user}
                                                    error={
                                                        this.state.errorInput
                                                    }
                                                    onChange={this.handleChange}
                                                />
                                            </Form.Field>
                                            <Form.Field>
                                                <Input
                                                    placeholder='Почтовый адрес'
                                                    name='email'
                                                    value={this.state.email}
                                                    error={
                                                        this.state.errorInput
                                                    }
                                                    onChange={this.handleChange}
                                                />
                                            </Form.Field>
                                            <Form.Field>
                                                <Icon
                                                    name='setting'
                                                    size='large'
                                                    color='blue'
                                                    link
                                                    onClick={
                                                        this.handleButtonClick
                                                    }
                                                />
                                            </Form.Field>
                                            <Form.Field>
                                                <Button
                                                    icon
                                                    labelPosition='left'
                                                    loading={
                                                        this.state.loadingAdd
                                                    }
                                                    size='small'
                                                    color='green'
                                                    disabled={
                                                        this.state.disableAdd
                                                    }
                                                    onClick={() =>
                                                        this.addUser(id)
                                                    }
                                                >
                                                    <Icon name='user' />{' '}
                                                    Добавить пользователя
                                                </Button>
                                            </Form.Field>
                                            <Form.Field>
                                                <Button
                                                    loading={
                                                        this.state.loadingDel
                                                    }
                                                    size='small'
                                                    color='red'
                                                    disabled={
                                                        this.state.disableDel
                                                    }
                                                    onClick={() => this.open()}
                                                >
                                                    Удалить пользователя
                                                </Button>
                                            </Form.Field>
                                        </Form.Group>
                                    </Form>
                                </Table.HeaderCell>
                            </Table.Row>
                        </Table.Footer>
                    </Table>
                </Segment>
                <Segment basic style={{ margin: '0px', display: stateDisplay }}>
                    <b>
                        Здесь Вы можете выбрать какие типы сообщений сможет
                        получать добавленный пользователь:
                    </b>
                    <Form>
                        <Form.Group
                            grouped
                            style={{ marginLeft: '20px', marginTop: '20px' }}
                        >
                            {MsgTypes.map((t, j) => (
                                <Form.Field key={j}>
                                    <Checkbox
                                        toggle
                                        label={t.type}
                                        checked={
                                            this.state.checkedTypesOnAdd[t.i]
                                        }
                                        onChange={() =>
                                            this.selectType(
                                                j,
                                                this.state.checkedTypesOnAdd[
                                                    t.i
                                                ]
                                            )
                                        }
                                    />
                                </Form.Field>
                            ))}
                        </Form.Group>
                    </Form>
                </Segment>
                {/* невидимый div к которому метод scrollToBottom перематывает скролл */}
                <div
                    style={{ float: 'left', clear: 'both' }}
                    ref={el => {
                        this.messagesEnd = el;
                    }}
                ></div>
                <Modal
                    basic
                    className='scrolling'
                    size='mini'
                    open={this.state.openConfirm}
                >
                    <Modal.Content>
                        <p>
                            Вы уверены, что хотите удалить выбранных
                            пользователей?
                        </p>
                    </Modal.Content>
                    <Modal.Actions>
                        <Button onClick={this.close} negative>
                            Нет
                        </Button>
                        <Button
                            onClick={() => {
                                this.deleteCheckedEmails(id);
                                this.close();
                            }}
                            positive
                            content='Да'
                        />
                    </Modal.Actions>
                </Modal>
                <Modal
                    size='tiny'
                    open={this.state.openModal}
                    onClose={this.closeModal}
                    dimmer='blurring'
                >
                    <Header
                        icon={
                            <Icon
                                color={
                                    this.state.msgNeg
                                        ? 'red'
                                        : this.state.msgPos
                                        ? 'green'
                                        : 'red'
                                }
                                name={
                                    this.state.msgNeg
                                        ? 'close'
                                        : this.state.msgPos
                                        ? 'check'
                                        : 'close'
                                }
                            />
                        }
                        content={ResTypesEmails[this.state.response]}
                    />
                    <Modal.Actions>
                        <Button negative onClick={this.closeModal}>
                            Закрыть
                        </Button>
                    </Modal.Actions>
                </Modal>
            </div>
        );
    }
}

export default Notifications;
