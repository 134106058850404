import axios from 'axios';

import Authenticate from '../Auth';

import { ERR_NO_MONEY, ERR_UNEXPECTED } from '../errors';

/* !!! ДЛЯ ПРОДАКШНА РАБОЧИМ ДОЛЖЕН БЫТЬ - baseURL = '//bazist.zahp.ru' !!!
для разработки - baseURL = 'http://127.0.0.1:1323'.
**Желательно регулировать baseURL через какой нибудь конфиг файл. */
const baseURL = '//bazist.zahp.ru';
//const baseURL = 'http://192.168.0.250:12368'
//const baseURL = '//testzalupa.zahp.ru'
// const baseURL = 'http://185.158.153.173:1323'

const CancelToken = axios.CancelToken;
var cancel;

const baseAPI = axios.create({
    baseURL: baseURL
});

const refreshAPI = axios.create({
    baseURL: baseURL
});

const refreshToken = token => {
    const payload = { token: token };
    return new Promise((resolve, reject) => {
        return refreshAPI
            .post('/auth/refresh_token', payload)
            .then(response => {
                resolve(response);
            })
            .catch(error => {
                reject(error);
            });
    });
};

/* Перед каждым запросом выполняется эта функция проверяющая необходимость обновления токенов. */
baseAPI.interceptors.request.use(
    config => {
        var originalRequest = config;

        var now = new Date();
        var utc = new Date(now.getTime() + now.getTimezoneOffset() * 60000);
        utc.setHours(utc.getHours() + 3);
        var currUnixTime = Math.round(new Date(utc).getTime() / 1000);
        if (currUnixTime > Number(sessionStorage.getItem('exp'))) {
            return refreshToken(Auth.refreshToken()).then(response => {
                if (
                    response.status === 200 &&
                    Auth.checkResponseToken(response.data)
                ) {
                    if (
                        response.data.access_token !== '' &&
                        response.data.refresh_token !== ''
                    ) {
                        sessionStorage.setItem(
                            'access_token',
                            response.data.access_token
                        );
                        sessionStorage.setItem(
                            'refresh_token',
                            response.data.refresh_token
                        );
                        sessionStorage.setItem('exp', response.data.exp);
                    } else if (response.data.error === ERR_NO_MONEY) {
                        cancel(ERR_NO_MONEY);
                    } else if (response.data.error === ERR_UNEXPECTED) {
                        cancel(ERR_UNEXPECTED);
                    }
                }
                originalRequest.headers.Authorization =
                    response.data.access_token;
                return Promise.resolve(originalRequest);
            });
        }
        return config;
    },
    err => {
        return Promise.reject(err);
    }
);

const getConfig = params => {
    return {
        baseURL: baseURL,
        headers: {
            Authorization: Auth.accessToken()
        },
        params: params,
        cancelToken: new CancelToken(function executor(c) {
            cancel = c;
        })
    };
};

const getConfigOnDownload = params => {
    return {
        baseURL: baseURL,
        headers: {
            Authorization: Auth.accessToken()
        },
        params: params,
        cancelToken: new CancelToken(function executor(c) {
            cancel = c;
        }),
        responseType: 'blob'
    };
};

const Auth = new Authenticate(refreshAPI);

const API = {
    Login: (data, handler, errorHandler) => {
        return Auth.authenticate(data, handler, errorHandler);
    },
    isAuth: () => {
        return Auth.isAuthenticated();
    },
    Logout: handler => {
        return Auth.signout(handler);
    },
    GET: (path, params, handler, errorHandler) => {
        baseAPI
            .get(path, getConfig(params))
            .then(handler)
            .catch(errorHandler);
    },
    POST: (path, data, handler) => {
        baseAPI
            .post(path, data, getConfig())
            .then(handler)
            .catch(console.error);
    },
    GETFILE: (path, params, handler, errorHandler) => {
        baseAPI
            .get(path, getConfigOnDownload(params))
            .then(handler)
            .catch(errorHandler);
    }
};

export default API;
