import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';

import { tanks } from './reducers/tanks';
import { flags } from './reducers/flags';
import { carrier } from './reducers/carrier';
import { notice } from './reducers/notice';

// eslint-disable-next-line import/no-anonymous-default-export
export default history =>
    combineReducers({
        router: connectRouter(history),
        tanks,
        flags,
        carrier,
        notice
    });
