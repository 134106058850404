import React from 'react';
import { Segment, Header, Divider } from 'semantic-ui-react';

const segment_out = {
    height: '470px',
    padding: '0px',
}

const segment_in = {
    height: 'auto',
    width: '650px',
}

const segment_section = {
    padding: '0px',
    height: '25px',
}

const divider = {
    marginTop: '10px',
    marginBottom: '10px',
}

const ChooseColor = (status) => {
    switch (status) {
        case 0: 
            return 'blue';
        case 2: 
            return 'yellow';
        case 1: 
            return 'red';
        default:
            return 'yellow';
    }
}

const Info = (props) => {
    const { imei, newmobileuspd, ssl_cert, ssl_status, usermobile, utm, utm_gost, utm_gost_status, utm_rsa, utm_rsa_status, xmlutm, tank_type, pay_date } = props;
    
    let ssl_color = ChooseColor(ssl_status);
    let gost_color = ChooseColor(utm_gost_status);
    let rsa_color = ChooseColor(utm_rsa_status);

    return <div>
        <Segment basic style={segment_out}>
            <Segment style={segment_in}>
                <Segment basic style={segment_section}>
                    <Header as='h4' floated='left'>
                        IMEI:
                    </Header>
                    <Header as='h4' floated='right'>
                        {imei.Valid ? imei.String : '---'}
                    </Header>
                </Segment>
                <Segment basic style={segment_section}>
                    <Header as='h5' floated='left'>
                        Дата отключения доступа:
                    </Header>
                    <Header as='h5' floated='right'>
                        {pay_date.Valid ? new Date(pay_date.Time).toLocaleDateString() : '---'}
                    </Header>
                </Segment>
                <Divider section horizontal style={divider}><h6>Даты окончания сертификатов</h6></Divider>
                {
                    tank_type !== 0 && tank_type !== 3 &&
                    <Segment basic style={segment_section} >
                        <Header as='h4' floated='right' color={ssl_color}>
                            {ssl_cert.Valid ? ssl_cert.Time.replace(/T[\s\S]+/, '') : '---'}
                        </Header>
                        <Header as='h4' floated='left' color={ssl_color}>
                            AGROHIM_GOST:
                        </Header>
                    </Segment>
                }
                <Segment basic style={segment_section} >
                    <Header as='h4' floated='right' color={gost_color}>
                        {utm_gost.Valid ? utm_gost.Time.replace(/T[\s\S]+/, '') : '---'}
                    </Header>
                    <Header as='h4' floated='left' color={gost_color}>
                        UTM_GOST:
                    </Header>
                </Segment>
                <Segment basic style={segment_section} >
                    <Header as='h4' floated='right' color={rsa_color}>
                        {utm_rsa.Valid ? utm_rsa.Time.replace(/T[\s\S]+/, '') : '---'}
                    </Header>
                    <Header as='h4' floated='left' color={rsa_color}>
                        UTM_RSA:
                    </Header>
                </Segment>
                <Divider section horizontal style={divider}><h6>Версии программного обеспечения</h6></Divider>
                <Segment basic style={segment_section}>
                    <Header as='h4' floated='right'>
                        {utm !== '' ? utm : '---'}
                    </Header>
                    <Header as='h4' floated='left'>
                        УТМ:
                    </Header>
                </Segment>
                {/* Давно хотел сюда вывести номер сборки УТМ... Для этого нужно добавить это в tank-service и бэкэнд. */}
                <Divider section style={divider}/>
                <Segment basic style={segment_section}>
                    <Header as='h4' floated='right'>
                        {usermobile !== '' ? usermobile : '---'}
                    </Header>
                    <Header as='h4' floated='left'>
                        Графический интерфейс:
                    </Header>
                </Segment>
                <Segment basic style={segment_section}>
                    <Header as='h4' floated='right'>
                        {newmobileuspd !== '' ? newmobileuspd : '---'}
                    </Header>
                    <Header as='h4' floated='left'>
                        Опрос датчиков:
                    </Header>
                </Segment>
                <Segment basic style={segment_section}>
                    <Header as='h4' floated='right'>
                        1.3.0
                    </Header>
                    <Header as='h4' floated='left'>
                        Метрологически значимая часть:
                    </Header>
                </Segment>
                <Segment basic style={segment_section}>
                    <Header as='h4' floated='right'>
                        {xmlutm !== '' ? xmlutm : '---'}
                    </Header>
                    <Header as='h4' floated='left'>
                        Отправка данных в УТМ:
                    </Header>
                </Segment>
            </Segment>
        </Segment>
    </div>;
};


export default Info;