import { ERR_LOGOUT, SUCCESS_LOGOUT } from '../errors';

class Authenticate {
    constructor(axios) {
        this.axios = axios;
    }

    accessToken = () => {
        return 'Bearer ' + sessionStorage.getItem('access_token');
    };

    refreshToken = () => {
        return 'Bearer ' + sessionStorage.getItem('refresh_token');
    };

    checkResponseToken = data => {
        return (
            data.hasOwnProperty('access_token') &&
            data.hasOwnProperty('refresh_token')
        );
    };

    isAuthenticated = () => {
        var access_token = sessionStorage.getItem('access_token');
        var refresh_token = sessionStorage.getItem('refresh_token');
        if (
            access_token != null &&
            access_token !== '' &&
            refresh_token != null &&
            refresh_token !== ''
        )
            return true;
        return false;
    };

    authenticate = (data, handler, errorHandler) => {
        this.axios
            .post('/auth/login', data)
            .then(res => {
                if (res.status === 200 && this.checkResponseToken(res.data)) {
                    if (
                        res.data.access_token !== '' &&
                        res.data.refresh_token !== ''
                    ) {
                        sessionStorage.setItem(
                            'access_token',
                            res.data.access_token
                        );
                        sessionStorage.setItem(
                            'refresh_token',
                            res.data.refresh_token
                        );
                        sessionStorage.setItem('exp', res.data.exp);
                    }
                }
                handler(res.data);
            })
            .catch(errorHandler);
    };

    signout = handler => {
        this.axios
            .post('/logout', null, {
                headers: { Authorization: this.accessToken() }
            })
            .then(res => {
                if (res.data === SUCCESS_LOGOUT) {
                    sessionStorage.clear();
                    handler();
                } else if (res.data === ERR_LOGOUT) {
                    sessionStorage.clear();
                    handler();
                }
            });
    };
}

export default Authenticate;
