import React, { Component } from 'react';
import {
    Segment,
    Table,
    Icon,
    Form,
    Button,
    Modal,
    Header
} from 'semantic-ui-react';

import API from '../API';

import { ResTypesContractors } from '../constants';

const segment_out = {
    width: '650px',
    padding: '0px'
};

const TableContractors = {
    name: 'Название',
    inn: 'ИНН'
};

const SUCCESS_ADD_ORG = 'SUCCESS_ADD_ORG';
const SUCCESS_DEL_ORG = 'SUCCESS_DEL_ORG';

/* компонент, представляющий собой полное содержимое вкладки "Контрагенты". */
class Contractors extends Component {
    state = {
        onAdd: false,
        onDel: false,
        inn: '',
        name: '',
        inputInnErr: false,
        inputNameErr: false,
        openModal: false,
        response: '',
        msgPos: false,
        msgNeg: false,
        loadingAdd: false,
        loadingDel: false
    };

    //  закрывает модальное окно с ответом от сервера
    closeModal = () =>
        this.setState({
            openModal: false,
            msgNeg: false,
            msgPos: false,
            response: ''
        });

    //  открывает/закрывает окно для добавления/удаления контрагента
    openAdd = () => this.setState({ onAdd: true });
    closeAdd = () => this.setState({ onAdd: false, inn: '', name: '' });
    openDel = () => this.setState({ onDel: true });
    closeDel = () => this.setState({ onDel: false, inn: '', name: '' });

    //  обработка ввода пользователя
    handleChange = (e, { name, value }) => this.setState({ [name]: value });

    //  отправляет запрос с заданной операцией на сервер
    produceContractor = operation => {
        switch (operation) {
            case 'add':
                if (this.state.inn === '') {
                    this.setState({ inputInnErr: true });
                    setTimeout(
                        () => this.setState({ inputInnErr: false }),
                        1000
                    );
                    return;
                }
                if (this.state.name === '') {
                    this.setState({ inputNameErr: true });
                    setTimeout(
                        () => this.setState({ inputNameErr: false }),
                        1000
                    );
                    return;
                }
                this.setState({ loadingAdd: true });
                break;
            case 'delete':
                if (this.state.inn === '') {
                    this.setState({ inputInnErr: true });
                    setTimeout(
                        () => this.setState({ inputInnErr: false }),
                        1000
                    );
                    return;
                }
                this.setState({ loadingDel: true });
                break;
            default:
                return;
        }
        var data = {
            inn: this.state.inn,
            name: this.state.name,
            operation: operation
        };
        API.POST(
            '/tanks/' + this.props.id + '/produce_contractor',
            data,
            res => {
                this.setState({
                    openModal: true,
                    response: res.data,
                    loadingAdd: false,
                    loadingDel: false
                });
                if (
                    res.data === SUCCESS_ADD_ORG ||
                    res.data === SUCCESS_DEL_ORG
                ) {
                    this.setState({ msgPos: true });
                    this.closeAdd();
                    this.closeDel();
                } else {
                    this.setState({ msgNeg: true });
                }
            }
        );
        this.setState({ inn: '', name: '', onAdd: false, onDel: false });
    };

    render() {
        const { contractors } = this.props;
        const {
            onAdd,
            onDel,
            inn,
            name,
            inputInnErr,
            inputNameErr,
            msgPos,
            msgNeg,
            openModal,
            response,
            loadingAdd,
            loadingDel
        } = this.state;
        const isAllDataReady = contractors && contractors.length > 0;
        return (
            <div>
                <Segment basic style={segment_out}>
                    <Segment
                        basic
                        style={{
                            height: 'auto',
                            margin: '0px 0px 10px 0px',
                            padding: '0px'
                        }}
                    >
                        <Table celled textAlign='center'>
                            <Table.Header>
                                <Table.Row>
                                    <Table.HeaderCell>
                                        {TableContractors['name']}
                                    </Table.HeaderCell>
                                    <Table.HeaderCell>
                                        {TableContractors['inn']}
                                    </Table.HeaderCell>
                                </Table.Row>
                            </Table.Header>
                            <Table.Body>
                                {!isAllDataReady && (
                                    <Table.Row>
                                        <Table.Cell colSpan='2'>
                                            Нет добавленных контрагентов
                                        </Table.Cell>
                                    </Table.Row>
                                )}
                                {isAllDataReady &&
                                    contractors.map((row, i) => {
                                        return (
                                            <Table.Row key={i}>
                                                {Object.keys(row).map(
                                                    (k, j) => {
                                                        return (
                                                            <Table.Cell key={j}>
                                                                {row[k]}
                                                            </Table.Cell>
                                                        );
                                                    }
                                                )}
                                            </Table.Row>
                                        );
                                    })}
                            </Table.Body>
                        </Table>
                    </Segment>
                    <Button
                        loading={loadingAdd}
                        disabled={loadingAdd}
                        primary
                        size='small'
                        onClick={this.openAdd}
                    >
                        Добавить контрагента
                    </Button>
                    <Button
                        loading={loadingDel}
                        disabled={loadingDel}
                        color='red'
                        size='small'
                        onClick={this.openDel}
                    >
                        Удалить контрагента
                    </Button>
                </Segment>
                <Modal
                    size='tiny'
                    open={onAdd}
                    closeOnEscape={false}
                    closeOnDimmerClick={false}
                >
                    <Modal.Header>
                        Введите данные контрагента для добавления
                    </Modal.Header>
                    <Modal.Content>
                        <Form>
                            <Form.Group>
                                <Form.Input
                                    width={8}
                                    placeholder='ИНН'
                                    name='inn'
                                    value={inn}
                                    onChange={this.handleChange}
                                    error={inputInnErr}
                                />
                            </Form.Group>
                            <Form.Group>
                                <Form.Input
                                    width={8}
                                    placeholder='Название'
                                    name='name'
                                    value={name}
                                    onChange={this.handleChange}
                                    error={inputNameErr}
                                />
                            </Form.Group>
                        </Form>
                    </Modal.Content>
                    <Modal.Actions>
                        <Button
                            positive
                            content='Подтвердить'
                            onClick={() => {
                                this.produceContractor('add');
                            }}
                        />
                        <Button negative onClick={this.closeAdd}>
                            Закрыть
                        </Button>
                    </Modal.Actions>
                </Modal>
                <Modal
                    size='tiny'
                    open={onDel}
                    closeOnEscape={false}
                    closeOnDimmerClick={false}
                >
                    <Modal.Header>
                        Введите ИНН контрагента для удаления
                    </Modal.Header>
                    <Modal.Content>
                        <Form>
                            <Form.Group>
                                <Form.Input
                                    width={8}
                                    placeholder='ИНН'
                                    name='inn'
                                    value={inn}
                                    onChange={this.handleChange}
                                    error={inputInnErr}
                                />
                            </Form.Group>
                        </Form>
                    </Modal.Content>
                    <Modal.Actions>
                        <Button
                            positive
                            content='Подтвердить'
                            onClick={() => {
                                this.produceContractor('delete');
                            }}
                        />
                        <Button negative onClick={this.closeDel}>
                            Закрыть
                        </Button>
                    </Modal.Actions>
                </Modal>
                <Modal
                    size='tiny'
                    open={openModal}
                    onClose={this.closeModal}
                    dimmer='blurring'
                >
                    <Header
                        icon={
                            <Icon
                                color={
                                    msgNeg ? 'red' : msgPos ? 'green' : 'red'
                                }
                                name={
                                    msgNeg
                                        ? 'close'
                                        : msgPos
                                        ? 'check'
                                        : 'close'
                                }
                            />
                        }
                        content={ResTypesContractors[response]}
                    />
                    <Modal.Actions>
                        <Button negative onClick={this.closeModal}>
                            Закрыть
                        </Button>
                    </Modal.Actions>
                </Modal>
            </div>
        );
    }
}

export default Contractors;
