import React, { Component } from 'react';
import { Segment } from 'semantic-ui-react';

import API from '../API';
import Notifications from '../Notifications';

import { freqEmails } from '../constants';

class NotificationsContainer extends Component {
    state = {
        emails: []
    };

    /* запускается таймер обновления списка получателей оповещений. Нужно для обратной связи при добавлении новых получателей. */
    componentDidMount() {
        API.GET('/tanks/' + this.props.id + '/emails', null, res => {
            this.setState({ emails: res.data });
        });
        this.timerEmails = setInterval(() => {
            API.GET('/tanks/' + this.props.id + '/emails', null, res => {
                this.setState({ emails: res.data });
            });
        }, freqEmails);
    }

    componentWillUnmount() {
        clearInterval(this.timerEmails);
    }

    render() {
        const { emails } = this.state;
        return (
            <Segment className='soon' basic style={{ padding: '0px' }}>
                <Notifications data={emails} id={this.props.id} />
            </Segment>
        );
    }
}

export default NotificationsContainer;
