import { create_action_creator } from '../defaultAction';

/* обновление флага logout, который "слушается" и если он true, то выполняется метод Logout */
export const GET_LOGOUT_STATUS = 'GET_LOGOUT_STATUS';
export const getLogoutStatus = create_action_creator(GET_LOGOUT_STATUS);

/* сохраняет роль пользователя в свойство role (admin или user) */
export const GET_ROLE = 'GET_ROLE';
export const getRole = create_action_creator(GET_ROLE);

/* сохраняет список перевозчиков для admin и перевозчика для user в свойство carriers (в виде массива; если user, то массива из одного элемента) */
export const GET_CARRIERS = 'GET_CARRIERS';
export const getCarriers = create_action_creator(GET_CARRIERS);

/* первоначально выставляет id первого в списке перевозчика как текущего выбранного (curr_carrier_id) */
export const INIT_CARRIER_ID = 'INIT_CARRIER_ID';
export const initCarrierID = create_action_creator(INIT_CARRIER_ID);

/* выставляет id выбранного перевозчика как текущего (curr_carrier_id); только для admin */
export const SET_CARRIER_ID = 'SET_CARRIER_ID';
export const setCarrierID = create_action_creator(SET_CARRIER_ID);

/* сброс редьюсера flags до значения по умолчанию */
export const RESET_FLAGS = 'RESET_FLAGS';
export const resetFlags = create_action_creator(RESET_FLAGS);

/* переключает флаг map (видимость карты) */
export const SHOW_MAP = 'SHOW_MAP';
export const showMap = create_action_creator(SHOW_MAP);

/* первоначально выставляет id и name цистерны как текущую выбарнную (curr_tank_id и curr_tank_name) */
export const INIT_TANK_ID = 'INIT_TANK_ID';
export const initTankID = create_action_creator(INIT_TANK_ID);

/* выставляет id и name выбранной цистерны как текущей */
export const SET_TANK_ID = 'SET_TANK_ID';
export const setTankID = create_action_creator(SET_TANK_ID);

/* в случае неудачного запроса (например, списка цистерн, перевозчиков и т.д.), выставляет свойство bad_req в true; это свйоство слушается
и если true, то отображается что нет контента. */
export const SET_BAD_REQUEST = 'SET_BAD_REQUEST';
export const setBadRequest = create_action_creator(SET_BAD_REQUEST);

/* переключает флаг state (видимость текущих данных) */
export const SHOW_STATE = 'SHOW_STATE';
export const showState = create_action_creator(SHOW_STATE);

/* в случае неудачного запроса текущих данных всех цистерн, выствялет свойство curr_data в true; и если true, то отображается ошибка */
export const SET_NO_CURR_DATA = 'SET_NO_CURR_DATA';
export const setNoCurrData = create_action_creator(SET_NO_CURR_DATA);

/* переключает флаг info_window влияющий на отображение названия цистерны над самой цистерной на карте */
export const SHOW_INFO_WINDOW = 'SHOW_INFO_WINDOW';
export const showInfoWindow = create_action_creator(SHOW_INFO_WINDOW);

/* сохранение координат текущей выбранной цистерны (нужно для фокуса на карте) */
export const SET_CURR_LOCATION = 'SET_CURR_LOCATION';
export const setCurrLocation = create_action_creator(SET_CURR_LOCATION);

/* переключает флаг tanks (видиомость цистерн на карте) */
export const SHOW_TANKS = 'SHOW_TANKS';
export const showTanks = create_action_creator(SHOW_TANKS);

/* переключает флаг track (видимость маршрута перевозки на карте) */
export const SHOW_TRACK = 'SHOW_TRACK';
export const showTrack = create_action_creator(SHOW_TRACK);

/* записывает запрошенный маршрут перевозки в свойство track */
export const GET_TRACK = 'GET_TRACK';
export const getTrack = create_action_creator(GET_TRACK);

/* переключает флаг full_track - признак завершенной перевозки (нужен "флажок" в конце маршрута или нет) */
export const SET_FULL_TRACK = 'SET_FULL_TRACK';
export const setFullTrack = create_action_creator(SET_FULL_TRACK);

/* переключает тип карты (спутник или дорожная) */
export const SET_MAP_TYPE = 'SET_MAP_TYPE';
export const setMapType = create_action_creator(SET_MAP_TYPE);

/* переключает флаг movings (видимость перемещений на карте) */
export const SHOW_MOVINGS = 'SHOW_MOVINGS';
export const showMovings = create_action_creator(SHOW_MOVINGS);

/* записывает запрошенные перемещения в свойство movings */
export const GET_MOVINGS = 'GET_MOVINGS';
export const getMovings = create_action_creator(GET_MOVINGS);

/* записывает данные по отсекам, запрошенные для всего периода времени перевозки или перемещений, запрошенных
ранее, в свойство capsule; из этого свойства потом при наведении на точку маршрута отображается инфа по отсекам
привязанная к этой точке. */
export const GET_CAPSULE = 'GET_CAPSULE';
export const getCapsule = create_action_creator(GET_CAPSULE);

/* переключает флаг info_box (видимость инфы по отсекам для точки) */
export const TOGGLE_INFOBOX = 'TOGGLE_INFOBOX';
export const toggleInfoBox = create_action_creator(TOGGLE_INFOBOX);

/* записывает запрошенный справочник продуктов егаис в свойство tmpproduct */
export const GET_TMPPRODUCT = 'GET_TMPPRODUCT';
export const getTmpproduct = create_action_creator(GET_TMPPRODUCT);

/* переключает флаг noticeOpen, влияющий на ширину модального окна при открытии/закрытии формы заполнения уведомелния о перевозке */
export const SET_NOTICE_OPEN = 'SET_NOTICE_OPEN';
export const setNoticeOpen = create_action_creator(SET_NOTICE_OPEN);

/* переключает флаг parking_info (видимость инфы о стоянке цистерны при наведении на "якорь") */
export const TOGGLE_PARKING_INFO_OPEN = 'TOGGLE_PARKING_INFO_OPEN';
export const toggleParkingInfoOpen = create_action_creator(
    TOGGLE_PARKING_INFO_OPEN
);

/* записывает запрошенный список всех цистерн всех перевозчиков в свойство all_tanks (только для admin) */
export const GET_ALL_TANKS = 'GET_ALL_TANKS';
export const getAllTanks = create_action_creator(GET_ALL_TANKS);

/* записывает запрошенный список всех пользователей в свойство users (только для admin) */
export const GET_USERS_LIST = 'GET_USERS_LIST';
export const getUsersList = create_action_creator(GET_USERS_LIST);
