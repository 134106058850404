import React, { Component } from 'react';
import { Tab, Segment } from 'semantic-ui-react';

// import Apologies from './Apologies'; //  компонент-заглушка на период доработки или неисправности

/* Компоненты с основным контентом => в menuitems */
import FiveMinFiles from '../FiveMinFiles';
import NavFiles from '../NavFiles';
import DayFiles from '../DayFiles';
import Trips from '../Trips';
import EgaisData from '../EgaisData';
import Movings from '../Movings';
import Info from '../Info';
import NotificationsContainer from './NotificationsContainer';
import ProductsContainer from './ProductsContainer';
import DenstablesContainer from './DenstablesContainer';
import SyncCommandsContainer from './SyncCommandsContainer';
import ContractorsContainer from './ContractorsContainer';
/* ---------------------------------------------- */

/* Данный компонент хранит всю конфигурацию размещения вкладок с контентом для разных типов цистерн. */
const Data = ({
    t,
    allDataHeight,
    isFetching,
    fiveMinFetching,
    NavFetching,
    dayFetching,
    tripsFetching,
    egaisFetching
}) => {
    //  экспериментально подобранное значения высот, чтобы не было скроллинга; не удалось сделать нормальную автоматическую верстку, поэтому так.
    var blockHeight = 'calc(' + allDataHeight + ' - 10px - 6.6vh - 10px)';
    var blockHeightForTwo =
        'calc(' + allDataHeight + ' - 10px - 6.6vh - 10px - 6.6vh - 10px)';

    /* Заворачивание наших компонентов в готовые блоки (data-block можно посмотреть в style.css) */
    const blockFiveMins = (
        <Segment
            basic
            className='data-block'
            style={{ height: blockHeightForTwo }}
        >
            <FiveMinFiles
                id={t.id}
                isFetching={isFetching}
                fiveMinFetching={fiveMinFetching}
            />
        </Segment>
    );
    const blockNav = (
        <Segment
            basic
            className='data-block'
            style={{ height: blockHeightForTwo }}
        >
            <NavFiles
                id={t.id}
                isFetching={isFetching}
                NavFetching={NavFetching}
            />
        </Segment>
    );
    const blockSyncs = (
        <Segment
            basic
            className='data-block'
            style={{ height: blockHeightForTwo }}
        >
            <SyncCommandsContainer id={t.id} />
        </Segment>
    );
    const blockDay = (
        <Segment
            basic
            className='data-block'
            style={{ height: blockHeightForTwo }}
        >
            <DayFiles
                id={t.id}
                isFetching={isFetching}
                dayFetching={dayFetching}
            />
        </Segment>
    );
    const blockNotifications = (
        <Segment
            basic
            className='data-block'
            style={{ height: blockHeightForTwo }}
        >
            <NotificationsContainer id={t.id} />
        </Segment>
    );
    const blockProducts = (
        <Segment
            basic
            className='data-block'
            style={{ height: blockHeightForTwo }}
        >
            <ProductsContainer id={t.id} tank_type={t.tank_type} />
        </Segment>
    );
    const blockDenstables = (
        <Segment
            basic
            className='data-block'
            style={{ height: blockHeightForTwo }}
        >
            <DenstablesContainer id={t.id} />
        </Segment>
    );
    const blockContractors = (
        <Segment
            basic
            className='data-block'
            style={{ height: blockHeightForTwo }}
        >
            <ContractorsContainer id={t.id} />
        </Segment>
    );

    const blockTrips = (
        <Segment basic className='data-block' style={{ height: blockHeight }}>
            <Trips
                id={t.id}
                isFetching={isFetching}
                tripsFetching={tripsFetching}
            />
        </Segment>
    );
    const blockEgais = (
        <Segment basic className='data-block' style={{ height: blockHeight }}>
            <EgaisData
                id={t.id}
                uspd_number={t.uspd_number}
                tank_type={t.tank_type}
                isFetching={isFetching}
                egaisFetching={egaisFetching}
            />
        </Segment>
    );
    const blockMovings = (
        <Segment basic className='data-block' style={{ height: blockHeight }}>
            <Movings id={t.id} />
        </Segment>
    );
    const blockInfo = (
        <Segment basic className='data-block' style={{ height: blockHeight }}>
            <Info {...t} {...t.certs_info} />
        </Segment>
    );
    /* ----------------------------------------------- */

    const filePanes = [
        {
            menuItem: 'Суточные файлы',
            render: () => <Tab.Pane className='daily'>{blockDay}</Tab.Pane>
        },
        {
            menuItem: 'Пятиминутные слепки',
            render: () => (
                <Tab.Pane className='fivemins'>{blockFiveMins}</Tab.Pane>
            )
        },
        {
            menuItem: 'Навигационные данные',
            render: () => <Tab.Pane className='nav'>{blockNav}</Tab.Pane>
        },
        {
            menuItem: 'Команды синхронизации',
            render: () => <Tab.Pane className='sync'>{blockSyncs}</Tab.Pane>
        }
    ];

    const filePanesForContainer = [
        {
            menuItem: 'Команды синхронизации',
            render: () => <Tab.Pane className='sync'>{blockSyncs}</Tab.Pane>
        },
        {
            menuItem: 'Навигационные данные',
            render: () => <Tab.Pane className='nav'>{blockNav}</Tab.Pane>
        }
    ];

    const funcPanes = [
        {
            menuItem: 'Оповещения',
            render: () => (
                <Tab.Pane className='users-tab'>{blockNotifications}</Tab.Pane>
            )
        },
        {
            menuItem: 'Таблица продуктов',
            render: () => (
                <Tab.Pane className='products-tab'>{blockProducts}</Tab.Pane>
            )
        },
        {
            menuItem: 'Таблицы плотностей',
            render: () => (
                <Tab.Pane className='products-tab'>{blockDenstables}</Tab.Pane>
            )
        }, //  classname -> dens-tab
        {
            menuItem: 'Контрагенты',
            render: () => (
                <Tab.Pane className='contractors-tab'>
                    {blockContractors}
                </Tab.Pane>
            )
        }
    ];

    const funcPanesForContainer = [
        {
            menuItem: 'Оповещения',
            render: () => (
                <Tab.Pane className='users-tab'>{blockNotifications}</Tab.Pane>
            )
        },
        {
            menuItem: 'Таблица продуктов',
            render: () => (
                <Tab.Pane className='products-tab'>{blockProducts}</Tab.Pane>
            )
        },
        {
            menuItem: 'Контрагенты',
            render: () => (
                <Tab.Pane className='contractors-tab'>
                    {blockContractors}
                </Tab.Pane>
            )
        }
    ];

    const contents = [
        {
            menuItem: 'Перевозки',
            render: () => (
                <Tab.Pane className='trips-tab'>{blockTrips}</Tab.Pane>
            )
        },
        {
            menuItem: 'Перемещения',
            render: () => (
                <Tab.Pane className='movings-tab'>{blockMovings}</Tab.Pane>
            )
        },
        {
            menuItem: 'Данные ЕГАИС',
            render: () => (
                <Tab.Pane className='egais-tab'>{blockEgais}</Tab.Pane>
            )
        },
        {
            menuItem: 'Статусы отправки данных',
            render: () => (
                <Tab.Pane className='files-tab'>
                    <Tab panes={filePanes} />
                </Tab.Pane>
            )
        },
        {
            menuItem: 'Информация',
            render: () => <Tab.Pane className='info-tab'>{blockInfo}</Tab.Pane>
        },
        {
            menuItem: 'Функционал',
            render: () => (
                <Tab.Pane className='func-tab'>
                    <Tab panes={funcPanes} />
                </Tab.Pane>
            )
        }
    ];

    /* и для контейнеров и для ЖД */
    const contentsForContainers = [
        {
            menuItem: 'Перемещения',
            render: () => (
                <Tab.Pane className='movings-tab'>{blockMovings}</Tab.Pane>
            )
        },
        {
            menuItem: 'Статусы отправки данных',
            render: () => (
                <Tab.Pane className='files-tab'>
                    <Tab panes={filePanesForContainer} />
                </Tab.Pane>
            )
        },
        {
            menuItem: 'Информация',
            render: () => <Tab.Pane className='info-tab'>{blockInfo}</Tab.Pane>
        },
        {
            menuItem: 'Функционал',
            render: () => (
                <Tab.Pane className='func-tab'>
                    <Tab panes={funcPanesForContainer} />
                </Tab.Pane>
            )
        }
    ];

    var panes =
        t.tank_type !== 0 && t.tank_type !== 3
            ? contents
            : contentsForContainers;

    return <Tab panes={panes} />;
};

class AllData extends Component {
    /* была какая то проблема с монтированием компонентов. Где то вычитал и это помогло. Ошибка пропала. Лучше это не удалять. */
    _isMounted = false;

    /* переменные флаги для отображения загрузки данных */
    state = {
        fiveMinFetching: false,
        NavFetching: false,
        dayFetching: false,
        tripsFetching: false,
        egaisFetching: false
    };

    isFetching = fetching => {
        if (this._isMounted) {
            this.setState(fetching);
        }
    };

    componentDidMount() {
        this._isMounted = true;
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    render() {
        const { t, showMap, showState } = this.props;
        const {
            fiveMinFetching,
            NavFetching,
            dayFetching,
            tripsFetching,
            egaisFetching
        } = this.state;

        //  экспериментально подобранное значения высот, чтобы не было скроллинга; не удалось сделать нормальную автоматическую верстку, поэтому так.
        var allDataHeight =
            t.tank_type !== 0 || t.tank_type !== 3 ? '84vh' : '88.5vh';

        return (
            <div className='all-data' style={{ height: allDataHeight }}>
                <Data
                    t={t}
                    certs={t.certs}
                    allDataHeight={allDataHeight}
                    isFetching={this.isFetching}
                    fiveMinFetching={fiveMinFetching}
                    NavFetching={NavFetching}
                    dayFetching={dayFetching}
                    tripsFetching={tripsFetching}
                    egaisFetching={egaisFetching}
                    showMap={showMap}
                    showState={showState}
                />
            </div>
        );
    }
}

export default AllData;
