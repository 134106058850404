/**
 * проверка разницы между "Принятый объем, дал" и "Отгруженный объем, дал"
 * false: если любое из значений === 0
 * false: если статус приемки не "Завершена"
 * false: если разница меньше чем в 0.5% между значениями
 * true: если разница больше чем в 0.5% между значениями
 * @param receivedVolume - принятый объем далл
 * @param shippedVolume - отгруженный объем далл
 * @param status - статус приемки
 */
 export default function dallDifferenceCheck(receivedVolume, shippedVolume, status) {
     const max = Math.max(receivedVolume, shippedVolume)
     const min = Math.min(receivedVolume, shippedVolume)
     const result = (max / min) - 1
     // const result = (max + min)/(2*min) -1
     if(!receivedVolume || !shippedVolume || status !== 'Завершена') return false
    return result.toFixed(3) > 0.050 // 0.51 = 0.6
}
