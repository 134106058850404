import { defaultTanks } from '../defaultTanks';

import {
    GET_TANKS,
    UPDATE_TANKS_WITH_FILTER,
    GET_CURR_DATA,
    GET_TANK_STATE,
    GET_CERTS_INFO,
    RESET_TANKS,
    GET_CURR_CAPSULE
} from '../actions/tanks';

import {
    reduceTanks,
    reduceUpdateCurrData,
    reduceUpdateBoth,
    deleteOldAndInsertNew
} from '../defaultReducers';

export const tanks = (state = defaultTanks, action) => {
    switch (action.type) {
        case GET_TANKS:
        case UPDATE_TANKS_WITH_FILTER:
            return reduceTanks(state, action.payload);
        case GET_CURR_DATA:
            return reduceUpdateCurrData(state, action.payload);
        case GET_TANK_STATE:
        case GET_CERTS_INFO:
        case GET_CURR_CAPSULE:
            return reduceUpdateBoth(state, action.payload);
        case RESET_TANKS:
            return deleteOldAndInsertNew(state, action.payload);
        default:
            return state;
    }
};
