import React, { Component } from 'react';
import { Marker, Popup } from 'react-leaflet';
import { connect } from 'react-redux';
import { toggleInfoBox, toggleParkingInfoOpen } from '../actions/flags';
import L from 'leaflet';
import "leaflet/dist/leaflet.css";
import "leaflet-defaulticon-compatibility";
import "leaflet-defaulticon-compatibility/dist/leaflet-defaulticon-compatibility.css";

const getCapsuleInfoByTs = (capsule, ts) => {
    var arr = [];
    if (capsule && capsule.length > 0) {
        var k = 0;
        for (let i = 0; i < capsule.length; i++) {
            if (capsule[i].time_stamp === ts) {
                arr.push(capsule[i]);
                k++;
            }
            if (k === 3) break;
        }
    }

    return arr;
};

class PointNew extends Component {
    render() {
        let {
            i,
            t,
            url,
            title,
            capsule,
            onToggleInfoBox,
            onToggleParkingInfoOpen
        } = this.props;
        return (
            <Marker 
                key={i} 
                draggable={false} 
                position={{lat: t.lat?t.lat:0,lng: t.lng?t.lng:0}} 
                icon={L.icon({ iconUrl: url })}
                eventHandlers={{
                    mouseover: () => {
                        if (t.type !== 2) {
                            onToggleInfoBox(
                                true,
                                t.time_stamp,
                                t.lat,
                                t.lng,
                                getCapsuleInfoByTs(capsule, t.time_stamp)
                            );
                        } else {
                            onToggleParkingInfoOpen(
                                true,
                                t.lat,
                                t.lng,
                                t.start,
                                t.end
                            );
                        }
                    },
                    mouseout: () => {
                        if (t.type !== 2) {
                            onToggleInfoBox(false, t.time_stamp, t.lat, t.lng, []);
                        } else {
                            onToggleParkingInfoOpen(
                                false,
                                t.lat,
                                t.lng,
                                t.start,
                                t.end
                            );
                        }
                    },
                }}
            >
              {title === 'Начало\n' ? (
                    <Popup>
                        <span>{title}</span>
                    </Popup>
                ) : (
                    <div></div>
                )}
                {title === 'Конец\n' ? (
                    <Popup>
                        <span>{title}</span>
                    </Popup>
                ) : (
                    <div></div>
                )}
            </Marker>
        );
    }
}

const mapStateToProps = state => {
    return {
        capsule: state.flags.capsule
    };
};

const mapDispatchToProps = dispatch => {
    return {
        onToggleInfoBox: (flag, ts, lat, lng, data) => {
            dispatch(
                toggleInfoBox({
                    info_box: flag,
                    time_stamp: ts,
                    lat_info: lat,
                    lng_info: lng,
                    capsule: data
                })
            );
        },
        onToggleParkingInfoOpen: (flag, lat, lng, start, end) => {
            dispatch(
                toggleParkingInfoOpen({
                    parking_info: flag,
                    lat_info: lat,
                    lng_info: lng,
                    start: start,
                    end: end
                })
            );
        }
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(PointNew);