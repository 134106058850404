import React, { Component } from 'react';
import { connect } from 'react-redux';

import NavBar from './NavBar';
//import GMap from './Map';
import NewMap from './NewMap'
import TankFilter from './TankFilter';
import TankList from './TankList';
import ResponseModal from '../components/CarrierPanel/ResponseModal';
import API from './API';

import {
    getRole,
    getCarriers,
    initCarrierID,
    initTankID,
    setTankID,
    setBadRequest,
    setNoCurrData,
    setCurrLocation,
    getTmpproduct,
    getAllTanks
} from './actions/flags';

import { getTanks, updateTanksWithFilter, getCurrData } from './actions/tanks';

import { getProducts } from './actions/carrier';

/* без этой строчки стили не применятся к разметке!!! */
import styles from '../styles.css';

import { ERR_BAD_REQUEST } from './errors';

import {
    badReqHandler,
    toggleClickOnTankItem,
    indexObjects
} from './functions';

import { ADMIN } from './constants';

//  критерии по которым происходит фильтр списка цистерн
const searchFields = [{ key: 'name' }];

class Content extends Component {
    constructor() {
        super();
        var nextFilter;
        nextFilter = new RegExp('.*', 'g');
        this.state = {
            filter: nextFilter,
            filterValue: ''
        };
    }

    resetFilter = () => {
        this.setState({ filterValue: '' });
    };

    handleChangeSearch = e => {
        var newValue = e.target.value;
        var newFilter = this.state.filter;
        newFilter = new RegExp('.*' + newValue + '.*', 'gi');
        this.setState({ filter: newFilter, filterValue: newValue }, () => {
            var arr = this.getFilteredTankList();
            indexObjects(arr);
            this.props.onUpdateTanksWithFilter(arr);
            //  на случай если пользователь решит отфильтровать несуществующие цистерны
            if (!arr) return;
            if (arr.length > 0) {
                this.props.onSetTankID(arr[0].id, arr[0].name);
                /* При фильтрации цистерн фильтруется только список цистерн, но не их контент. Поэтому при
                фильтре, допустим, первая цистерна в списке пропадет и вторая станет первой, НО контент первой,
                пропавшей цистерны останется! Поэтому и нужен этот toggleClickOnTankItem(0). Это костыль. */
                setTimeout(() => {
                    toggleClickOnTankItem(0);
                }, 50);
            } else {
                this.props.onSetTankID(0, '');
            }
        });
    };

    getFilteredTankList = () => {
        //  проверка на случай если на запрос списка цистерн пришел null
        if (!this.props.tanks.initial_tanks) return;
        return this.props.tanks.initial_tanks.filter(row => {
            var result = searchFields.reduce((prev, field) => {
                var re = this.state.filter;
                if (typeof row[field.key] === 'string') {
                    return prev && row[field.key].search(re) !== -1;
                } else {
                    return prev && ('' + row[field.key]).search(re) !== -1;
                }
            }, true);
            return result;
        });
    };

    componentDidMount() {
        API.GET(
            '/role',
            null,
            res => {
                this.props.onGetRole(res.data);
                API.GET(
                    '/carriers',
                    null,
                    res => {
                        this.props.onGetCarriers(res.data);
                        this.props.onInitCarrierID(res.data[0].carrier_id);
                        API.GET(
                            '/tanks/init',
                            { carrier_id: res.data[0].carrier_id },
                            res => {
                                /* Это (indexObjects) нужно для того чтобы связать маркеры на карте и кнопки в меню. Чтобы при нажатии на
                    цистерну на карте, выделялась соответствующая цистерна в Меню. */
                                if (res.data != null) {
                                    indexObjects(res.data);
                                }
                                this.props.onGetTanks(res.data);
                                this.props.onInitTankID(
                                    res.data[0].id,
                                    res.data[0].name
                                );
                                //  на данном этапе мы:
                                //  1. запросили роль - права доступа (админ или клиент), номер перевозчика;
                                //  2. в зависимости от данных о пользователе запрашиваем список перевозчиков
                                //      (если пользователь = клиент, то список состоит из одного, его, номера перевозчика)
                                //  3. устанавливаем текущего выбранного перевозчика.
                                //  4. для текущего выбранного перевозчика запрашиваем список цистерн.
                                //  5. устанавливаем текущую выбранную цистерну, для которой будут выполнены все следующие запросы.
                                /* ------------------------------------------------------------------------------------------ */
                                //  6. далее запрашиваем для каждой цистерны данные отображенные на кнопках меню.
                                //      здесь запрос выполняется один раз, а в TankList запускается таймер выполнения этого запроса.
                                API.GET(
                                    '/tanks/curr_data',
                                    {
                                        carrier_id: this.props.flags
                                            .curr_carrier_id
                                    },
                                    res => {
                                        if (res.data) {
                                            this.props.onSetNoCurrData(false);
                                            this.props.onGetCurrData(res.data);
                                            this.props.onSetCurrLocation(
                                                res.data[0].lat,
                                                res.data[0].lng
                                            );
                                        } else {
                                            this.props.onSetNoCurrData(true);
                                        }
                                        /* Для административных целей запрашиваем список ВСЕХ цистерн */
                                        if (this.props.flags.role === ADMIN) {
                                            API.GET(
                                                '/tanks/all_tanks',
                                                null,
                                                res =>
                                                    this.props.onGetAllTanks(
                                                        res.data
                                                    )
                                            );
                                        }
                                    },
                                    err =>
                                        badReqHandler(
                                            err,
                                            ERR_BAD_REQUEST,
                                            this.props.onSetBadRequest
                                        )
                                );
                            },
                            err =>
                                badReqHandler(
                                    err,
                                    ERR_BAD_REQUEST,
                                    this.props.onSetBadRequest
                                )
                        );
                    },
                    err =>
                        badReqHandler(
                            err,
                            ERR_BAD_REQUEST,
                            this.props.onSetBadRequest
                        )
                );
            },
            err =>
                badReqHandler(err, ERR_BAD_REQUEST, this.props.onSetBadRequest)
        );
        API.GET('/tanks/tmpproduct', null, res =>
            this.props.onGetTmpproduct(res.data)
        );
        API.GET(
            '/tanks/products',
            { carrier_id: this.props.flags.curr_carrier_id },
            res => this.props.onGetProducts(res.data)
        );
    }

    componentWillUnmount() {}

    render() {
        return (
            <div className='main-container'>
                <div className='sticky' style={{zIndex:3}}>
                    <NavBar history={this.props.history} />
                </div>
                <NewMap tanks={this.props.tanks.filtered_tanks} />
                <TankFilter
                    handleChangeSearch={this.handleChangeSearch}
                    filterValue={this.state.filterValue}
                    resetFilter={this.resetFilter}
                />
                <TankList />
                <ResponseModal />
            </div>
        );
    }
}

const mapStateToProps = state => state;

const mapDispatchToProps = dispatch => {
    return {
        //  flags
        onGetRole: data => {
            dispatch(getRole(data));
        },
        onGetCarriers: data => {
            dispatch(getCarriers({ carriers: data }));
        },
        onInitCarrierID: id => {
            dispatch(initCarrierID({ curr_carrier_id: id }));
        },
        onInitTankID: (id, name) => {
            dispatch(initTankID({ curr_tank_id: id, curr_tank_name: name }));
        },
        onSetTankID: (id, name) => {
            dispatch(setTankID({ curr_tank_id: id, curr_tank_name: name }));
        },
        onSetBadRequest: flag => {
            dispatch(setBadRequest({ bad_req: flag }));
        },
        onSetNoCurrData: flag => {
            dispatch(setNoCurrData({ curr_data: flag }));
        },
        onSetCurrLocation: (lat, lng) => {
            dispatch(setCurrLocation({ lat: lat, lng: lng }));
        },
        onGetTmpproduct: data => {
            dispatch(getTmpproduct({ tmpproduct: data }));
        },
        onGetAllTanks: data => {
            dispatch(getAllTanks({ all_tanks: data }));
        },

        //  tanks
        onGetTanks: data => {
            dispatch(getTanks({ initial_tanks: data, filtered_tanks: data }));
        },
        onUpdateTanksWithFilter: data => {
            dispatch(updateTanksWithFilter({ filtered_tanks: data }));
        },
        onGetCurrData: data => {
            dispatch(getCurrData({ curr_data: data }));
        },

        //  carrier
        onGetProducts: data => {
            dispatch(getProducts({ products: data }));
        }
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Content);
