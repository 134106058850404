import React from 'react';
import { Table } from 'semantic-ui-react';
import NoData from './NoData';

const TableDayColumns = {
    dateinsert: 'Время создания',
    savedate: 'Отчетная дата',
    pointofcontrol: 'Точка учета',
    conclusion: 'Заключение',
    description_status: 'Статус',
    description_error: 'Ошибка',
    conclusionDate: 'Время ответа'
};

const TableAsiiuTasks = ({ data }) => {
    const isAllDataReady = data && data.length > 0;
    return (
        <div>
            {!isAllDataReady && <NoData />}
            {isAllDataReady && (
                <div>
                    <Table collapsing compact celled textAlign='center'>
                        <Table.Header>
                            <Table.Row>
                                <Table.HeaderCell key={1}>
                                    {TableDayColumns['dateinsert']}
                                </Table.HeaderCell>
                                <Table.HeaderCell key={2}>
                                    {TableDayColumns['savedate']}
                                </Table.HeaderCell>
                                <Table.HeaderCell key={3}>
                                    {TableDayColumns['pointofcontrol']}
                                </Table.HeaderCell>
                                <Table.HeaderCell key={4}>
                                    {TableDayColumns['conclusion']}
                                </Table.HeaderCell>
                                <Table.HeaderCell key={5}>
                                    {TableDayColumns['description_status']}
                                </Table.HeaderCell>
                                <Table.HeaderCell key={6}>
                                    {TableDayColumns['description_error']}
                                </Table.HeaderCell>
                                <Table.HeaderCell key={7}>
                                    {TableDayColumns['conclusionDate']}
                                </Table.HeaderCell>
                            </Table.Row>
                        </Table.Header>
                        <Table.Body>
                            {data.map((row, i) => {
                                return (
                                    <Table.Row key={i}>
                                        <Table.Cell key={1}>
                                            {row['dateinsert']
                                                .replace('T', ' ')
                                                .replace('Z', '')}
                                        </Table.Cell>
                                        <Table.Cell key={2}>
                                            {row['savedate'].replace(
                                                /T[\s\S]+/,
                                                ''
                                            )}
                                        </Table.Cell>
                                        <Table.Cell key={3}>
                                            {row['pointofcontrol']}
                                        </Table.Cell>
                                        <Table.Cell key={4}>
                                            {row['conclusion'].Valid
                                                ? row['conclusion'].String ===
                                                  'Rejected'
                                                    ? 'Отказано'
                                                    : 'Accepted'
                                                    ? 'Принято'
                                                    : ''
                                                : ''}
                                        </Table.Cell>
                                        <Table.Cell key={5}>
                                            {row['description_status'].Valid
                                                ? row['description_status']
                                                      .String
                                                : ''}
                                        </Table.Cell>
                                        <Table.Cell key={6}>
                                            {row['description_error'].Valid
                                                ? row['description_error']
                                                      .String
                                                : ''}
                                        </Table.Cell>
                                        <Table.Cell key={7}>
                                            {row['conclusionDate'].Valid
                                                ? row[
                                                      'conclusionDate'
                                                  ].String.replace(
                                                      'T',
                                                      ' '
                                                  ).substring(
                                                      0,
                                                      row[
                                                          'conclusionDate'
                                                      ].String.indexOf('.')
                                                  )
                                                : ''}
                                        </Table.Cell>
                                    </Table.Row>
                                );
                            })}
                        </Table.Body>
                    </Table>
                </div>
            )}
        </div>
    );
};

export default TableAsiiuTasks;
