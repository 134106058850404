import { defaultCarrier } from '../defaultCarrier';

import {
    GET_UTM_SERVERS,
    GET_CARRIER_INFO,
    GET_SHIPPER_INFO,
    GET_CONSIGNEE_INFO,
    GET_SYNC_HISTORY,
    ADD_ORG_ID_LOADING,
    DEL_ORG_ID_LOADING,
    WRITE_RESPONSE,
    CLOSE_RESPONSE,
    CHANGE_ORG_WAITING,
    CHANGE_FIRST_QUERY,
    CHANGE_UTM_TRACK,
    SET_SSH_PASS,
    RESET_CARRIER,
    GET_PRODUCTS,
    GET_NOTICE_HISTORY,
    SET_NOTICE_PROCESSING,
    UNSET_NOTICE_PROCESSING,
    ADD_TANK_ID_SYNC_LOADING,
    DEL_TANK_ID_SYNC_LOADING,
    GET_PRODUCER_INFO
} from '../actions/carrier';

import {
    reduceCarrier,
    reduceAddId,
    reduceDelId,
    reduceCarrierShow,
    deleteOldAndInsertNew
} from '../defaultReducers';

export const carrier = (state = defaultCarrier, action) => {
    switch (action.type) {
        case GET_UTM_SERVERS:
        case GET_CARRIER_INFO:
        case GET_SHIPPER_INFO:
        case GET_CONSIGNEE_INFO:
        case GET_SYNC_HISTORY:
        case GET_PRODUCTS:
        case CHANGE_UTM_TRACK:
        case SET_SSH_PASS:
        case GET_NOTICE_HISTORY:
        case GET_PRODUCER_INFO:
            return reduceCarrier(state, action.payload);
        case ADD_ORG_ID_LOADING:
        case SET_NOTICE_PROCESSING:
        case ADD_TANK_ID_SYNC_LOADING:
            return reduceAddId(state, action.payload);
        case DEL_ORG_ID_LOADING:
        case UNSET_NOTICE_PROCESSING:
        case DEL_TANK_ID_SYNC_LOADING:
            return reduceDelId(state, action.payload);
        case WRITE_RESPONSE:
        case CLOSE_RESPONSE:
        case CHANGE_ORG_WAITING:
        case CHANGE_FIRST_QUERY:
            return reduceCarrierShow(state, action.payload);
        case RESET_CARRIER:
            return deleteOldAndInsertNew(state, action.payload);
        default:
            return state;
    }
};
