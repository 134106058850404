import React, { Component } from 'react';
import redPoint from '../../images/bullet_red.png';
import yellowPoint from '../../images/bullet_yellow.png';
import greenPoint from '../../images/bullet_green.png';
import redFlag from '../../images/flag_red.png';
import yellowFlag from '../../images/flag_yellow.png';
import greenFlag from '../../images/flag_green.png';
import parking from '../../images/parking_purple.png';
import PointNew from './Point';

import { freqMarker } from '../constants';

/* компонент представляющий собой маршрут перевозки или перемещения, состоящие из массива точек или других icon. */
class TransitNew extends Component {
    state = {
        renderedPoints: [], //  текущие отрендеренные точки
        pointsRendered: 0 //  текущее количество отрендеренных точек
    };

    updateRenderedThings() {
        const pointsRendered = this.state.pointsRendered;
        const updatedState = {
            renderedPoints: this.state.renderedPoints.concat(
                this.props.data[this.state.pointsRendered]
            ),
            pointsRendered: pointsRendered + 1
        };
        this.setState(updatedState);
        if (updatedState.pointsRendered < this.props.data.length) {
            this.scheduleNextUpdate();
        }
    }

    /* данная функция обеспечивает постепенный рендеринг точек маршрута, запуская таймер и добавляя точки, которые
    нужно отобразить на карте с частотой freqMarker. По сути она запускает себя рекурсивно через вызов функции
    updateRenderedThings(). И вызов продолжается до тех пор пока все точки не будут отображены (подробности в
    функции updateRenderedThings). */
    scheduleNextUpdate() {
        this.timer = setTimeout(() => {
            this.updateRenderedThings();
        }, freqMarker);
    }

    componentDidMount() {
        this.scheduleNextUpdate();
    }

    componentWillUnmount() {
        clearTimeout(this.timer);
    }

    render() {
        const { data, flag, flagEnd } = this.props;
        let i = 0;
        return (
            <div>
                {data &&
                    this.state.renderedPoints.map(t => {
                        i = i + 1;
                        /* свойство data это массив координат по сути, но еще с дополнительной информацией в виде
                    статусов, влияющих на цвет точки, а также типа элемента этого массива координат (type = 1 - это обычная
                    точка, type = 2 - это "якорь" стоянка), влияющего на icon.
                    **Все эти координаты из массива привязаны к навигационным xml файлам, которые цистерны отправляют
                    в АСКП. Вышеуказанные статусы это статусы отправки этих файлов.
                    **Первую и последнюю точку массива отображаем как "флаги". Также важен flagEnd - признак, завершившейся
                    перевозки. Если не завршена, то последняя точка в виде флага не отображается.
                    **Также есть переменная flag (tracks или movings). Зависит от того, что нажимает пользователь. */
                        if (flag === 'tracks') {
                            if (i === 1) {
                                switch (
                                    t.status //  статусы: 10 - отправлено, 9 - в процессе, 8 - не отправлено
                                ) {
                                    case 8:
                                        return (
                                            <PointNew
                                                key={i}
                                                i={i}
                                                t={t}
                                                url={redFlag}
                                                title={'Начало\n'}
                                            />
                                        );
                                    case 10:
                                        return (
                                            <PointNew
                                                key={i}
                                                i={i}
                                                t={t}
                                                url={greenFlag}
                                                title={'Начало\n'}
                                            />
                                        );
                                    case 9:
                                        return (
                                            <PointNew
                                                key={i}
                                                i={i}
                                                t={t}
                                                url={yellowFlag}
                                                title={'Начало\n'}
                                            />
                                        );
                                    default:
                                        return (
                                            <PointNew
                                                key={i}
                                                i={i}
                                                t={t}
                                                url={redFlag}
                                                title={'Начало\n'}
                                            />
                                        );
                                }
                            } else if (i === data.length && flagEnd) {
                                switch (t.status) {
                                    case 8:
                                        return (
                                            <PointNew
                                                key={i}
                                                i={i}
                                                t={t}
                                                url={redFlag}
                                                title={'Конец\n'}
                                            />
                                        );
                                    case 10:
                                        return (
                                            <PointNew
                                                key={i}
                                                i={i}
                                                t={t}
                                                url={greenFlag}
                                                title={'Конец\n'}
                                            />
                                        );
                                    case 9:
                                        return (
                                            <PointNew
                                                key={i}
                                                i={i}
                                                t={t}
                                                url={yellowFlag}
                                                title={'Конец\n'}
                                            />
                                        );
                                    default:
                                        return (
                                            <PointNew
                                                key={i}
                                                i={i}
                                                t={t}
                                                url={redFlag}
                                                title={'Конец\n'}
                                            />
                                        );
                                }
                            } else if (t.type === 2) {
                                return (
                                    <PointNew
                                        key={i}
                                        i={i}
                                        t={t}
                                        url={parking}
                                        title={''}
                                    />
                                );
                            } else {
                                switch (t.status) {
                                    case 8:
                                        return (
                                            <PointNew
                                                key={i}
                                                i={i}
                                                t={t}
                                                url={redPoint}
                                                title={''}
                                            />
                                        );
                                    case 10:
                                        return (
                                            <PointNew
                                                key={i}
                                                i={i}
                                                t={t}
                                                url={greenPoint}
                                                title={''}
                                            />
                                        );
                                    case 9:
                                        return (
                                            <PointNew
                                                key={i}
                                                i={i}
                                                t={t}
                                                url={yellowPoint}
                                                title={''}
                                            />
                                        );
                                    default:
                                        return (
                                            <PointNew
                                                key={i}
                                                i={i}
                                                t={t}
                                                url={redPoint}
                                                title={''}
                                            />
                                        );
                                }
                            }
                        }
                        if (flag === 'movings') {
                            if (t.type === 2) {
                                return (
                                    <PointNew
                                        key={i}
                                        i={i}
                                        t={t}
                                        url={parking}
                                        title={''}
                                    />
                                );
                            } else {
                                switch (t.status) {
                                    case 8:
                                        return (
                                            <PointNew
                                                i={i}
                                                t={t}
                                                url={redPoint}
                                                title={''}
                                            />
                                        );
                                    case 10:
                                        return (
                                            <PointNew
                                                i={i}
                                                t={t}
                                                url={greenPoint}
                                                title={''}
                                            />
                                        );
                                    case 9:
                                        return (
                                            <PointNew
                                                i={i}
                                                t={t}
                                                url={yellowPoint}
                                                title={''}
                                            />
                                        );
                                    default:
                                        return (
                                            <PointNew
                                                i={i}
                                                t={t}
                                                url={redPoint}
                                                title={''}
                                            />
                                        );
                                }
                            }
                        }
                    })}
            </div>
        );
    }
}

export default TransitNew;
