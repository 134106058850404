import React, { Component } from 'react';
import DatePicker from 'material-ui/DatePicker';
import { Menu, Button } from 'semantic-ui-react';

import { date } from '../constants';

import { parseMoment } from '../functions';

/* Данный компонент используется в нескольких компонентах.
И в зависимости от места использования может меняться. */
class RangeDateSelector extends Component {
    state = {
        fromDate: parseMoment(date.defaultFrom, 'from'),
        toDate: parseMoment(date.defaultTo, 'to')
    };

    handleChangeFromDate = (e, date) => {
        this.setState({ fromDate: parseMoment(date, 'from') });
    };
    handleChangeToDate = (e, date) => {
        this.setState({ toDate: parseMoment(date, 'to') });
    };

    render() {
        const {
            textFrom,
            textTo,
            submitRangeDateSelector,
            disabled,
            exportTxt
        } = this.props;
        return (
            <div className='range-date-selector'>
                <Menu>
                    <Menu.Item header>Выберите интервал:</Menu.Item>
                    <Menu.Item style={{ height: '50px', width: '150px' }}>
                        <DatePicker
                            hintText={textFrom}
                            onChange={this.handleChangeFromDate}
                            defaultDate={date.defaultFrom.toDate()}
                            underlineStyle={{ display: 'none' }}
                        />
                    </Menu.Item>
                    <Menu.Item style={{ height: '50px', width: '150px' }}>
                        <DatePicker
                            hintText={textTo}
                            onChange={this.handleChangeToDate}
                            defaultDate={date.defaultTo.toDate()}
                            underlineStyle={{ display: 'none' }}
                        />
                    </Menu.Item>
                    <Menu.Item>
                        <Button
                            color='blue'
                            disabled={disabled}
                            onClick={() => {
                                submitRangeDateSelector({
                                    from: this.state.fromDate,
                                    to: this.state.toDate
                                });
                            }}
                        >
                            Подтвердить
                        </Button>
                    </Menu.Item>
                    <Menu.Item>
                        <Button
                            icon='sync alternate'
                            disabled={disabled}
                            color='green'
                            basic
                            onClick={() => {
                                submitRangeDateSelector({
                                    from: this.state.fromDate,
                                    to: this.state.toDate
                                });
                            }}
                        />
                    </Menu.Item>
                    {textFrom === 'egais-from' && (
                        <Menu.Menu position='right'>
                            <Menu.Item>
                                <Button
                                    color='yellow'
                                    icon='download'
                                    content='Выгрузить файл'
                                    onClick={() =>
                                        exportTxt(
                                            this.state.fromDate,
                                            this.state.toDate
                                        )
                                    }
                                />
                            </Menu.Item>
                        </Menu.Menu>
                    )}
                </Menu>
            </div>
        );
    }
}

export default RangeDateSelector;
