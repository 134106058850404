export const INVALID_TOKEN = 'Невалидный или истекший токен';
export const ACCESS_DENIED = 'Доступ к личному кабинету заблокирован';
export const INVALID_REFRESH_TOKEN =
    'Невалидный токен обновления. Есть вероятность кражи токена, пройдите повторную аутентификацию.';
export const SOMETHING_WRONG = 'Что-то пошло не так';
export const SUSPECT_ACTIONS = 'Подозрительные действия..';
export const SRV_NOT_AVAILABLE = 'Сервер недоступен';
export const EMPTY_MSG = '';
export const TANK_NOT_FOUND = 'Цистерны не найдены';
export const BAD_REQUEST = 'Произошла ошибка. Обновите страницу';
export const NO_CURR_DATA = 'Данные по цистернам не обновляются';