import React, { Component } from 'react';
import { Menu, Icon } from 'semantic-ui-react';

const LEFT_PAGE = 'LEFT';
const RIGHT_PAGE = 'RIGHT';

const range = (from, to, step = 1) => {
    let i = from;
    const range = [];

    while (i <= to) {
        range.push(i);
        i += step;
    }

    return range;
};

/* Если посидеть некоторое время, то можно разобраться как работают методы этого компонента.
Я когда это писал, знал как это работает. Сейчас забыл. Но главное, в данный момент копонент работает корректно. */
class Pagination extends Component {
    constructor(props) {
        super(props);

        this.pageLimit = this.props.pageLimit;
        this.pageNeighbours = this.props.pageNeighbours;

        this.state = {
            currentPage: 1
        };
    }

    componentDidMount() {
        this.gotoPage(1);
    }

    gotoPage = page => {
        const currentPage = Math.max(
            0,
            Math.min(page, Math.ceil(this.props.totalRecords / this.pageLimit))
        );

        const paginationData = {
            currentPage,
            totalPages: Math.ceil(this.props.totalRecords / this.pageLimit),
            pageLimit: this.pageLimit,
            totalRecords: this.props.totalRecords
        };

        this.setState({ currentPage }, () =>
            this.props.onPageChanged(paginationData)
        );
    };

    handleClick = (page, evt) => {
        evt.preventDefault();
        this.gotoPage(page);
    };

    handleMoveLeft = evt => {
        evt.preventDefault();
        this.gotoPage(this.state.currentPage - this.pageNeighbours * 2 - 1);
    };

    handleMoveRight = evt => {
        evt.preventDefault();
        this.gotoPage(this.state.currentPage + this.pageNeighbours * 2 + 1);
    };

    fetchPageNumbers = () => {
        const totalPages = Math.ceil(this.props.totalRecords / this.pageLimit);
        const currentPage = this.state.currentPage;
        const pageNeighbours = this.pageNeighbours;

        const totalNumbers = this.pageNeighbours * 2 + 3;
        const totalBlocks = totalNumbers + 2;

        if (totalPages > totalBlocks) {
            let pages = [];

            const leftBound = currentPage - pageNeighbours;
            const rightBound = currentPage + pageNeighbours;
            const beforeLastPage = totalPages - 1;

            const startPage = leftBound > 2 ? leftBound : 2;
            const endPage =
                rightBound < beforeLastPage ? rightBound : beforeLastPage;

            pages = range(startPage, endPage);

            const pagesCount = pages.length;
            const singleSpillOffset = totalNumbers - pagesCount - 1;

            const leftSpill = startPage > 2;
            const rightSpill = endPage < beforeLastPage;

            const leftSpillPage = LEFT_PAGE;
            const rightSpillPage = RIGHT_PAGE;

            if (leftSpill && !rightSpill) {
                const extraPages = range(
                    startPage - singleSpillOffset,
                    startPage - 1
                );
                pages = [leftSpillPage, ...extraPages, ...pages];
            } else if (!leftSpill && rightSpill) {
                const extraPages = range(
                    endPage + 1,
                    endPage + singleSpillOffset
                );
                pages = [...pages, ...extraPages, rightSpillPage];
            } else if (leftSpill && rightSpill) {
                pages = [leftSpillPage, ...pages, rightSpillPage];
            }

            return [1, ...pages, totalPages];
        }

        return range(1, totalPages);
    };

    render() {
        if (!this.props.totalRecords) return null;

        if (Math.ceil(this.props.totalRecords / this.pageLimit) === 1)
            return null;

        const { currentPage } = this.state;
        const pages = this.fetchPageNumbers();
        var i = 0;
        return (
            <div>
                <Menu pagination style={{ float: 'right' }}>
                    {pages.map((page, index) => {
                        if (page === LEFT_PAGE) {
                            return (
                                <Menu.Item
                                    key={index}
                                    icon
                                    onClick={this.handleMoveLeft}
                                >
                                    <Icon name='chevron left' />
                                </Menu.Item>
                            );
                        }
                        if (page === RIGHT_PAGE) {
                            return (
                                <Menu.Item
                                    key={index}
                                    icon
                                    onClick={this.handleMoveRight}
                                >
                                    <Icon name='chevron right' />
                                </Menu.Item>
                            );
                        }
                        return (
                            <Menu.Item
                                key={index}
                                id={'page-menu-item' + i++}
                                active={currentPage === page ? true : false}
                                onClick={e => this.handleClick(page, e)}
                            >
                                {page}
                            </Menu.Item>
                        );
                    })}
                </Menu>
            </div>
        );
    }
}

export default Pagination;
