import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
    Segment,
    Header,
    Divider,
    Icon,
    Table,
    Button,
    Modal,
    Form
} from 'semantic-ui-react';

import { ADMIN, ResTypesOnChange, CHECK_UTM_CONN } from '../constants';

import { validateNumsOrDots, validateNums, validateUtmIP } from '../functions';

import API from '../API';
import NoData from './NoData';

import { changeUtmTrack, setSSHPass, writeResponse } from '../actions/carrier';

const ERR_CONFIRM_PASS = 'ERR_CONFIRM_PASS';
const SUCCESS_CHANGE_PASS = 'SUCCESS_CHANGE_PASS';
const PASSWORD_OK = 'PASSWORD_OK';
const ERR_UTM_WORKER = 'ERR_UTM_WORKER';
const SUCCESS_CHANGE_UTM_DATA = 'SUCCESS_CHANGE_UTM_DATA';

const TableColumns = {
    carrier_name: 'Перевозчик',
    external_ip: 'Внешний IP адрес',
    external_port: 'Порт',
    utm_ip: 'IP адрес УТМ',
    utm_port: 'Порт',
    ssh_username: 'Пользователь',
    status: 'Статус'
};

/* компонент представляющий собой одну из настроек подключения к УТМ при их редактировании */
const UtmField = ({ fieldName, name, value, handleChange }) => {
    return (
        <Segment basic style={{ padding: '0px', height: '25px' }}>
            <Header floated='right'>
                <div className='ui input'>
                    <input
                        id='utm-field'
                        type='text'
                        name={name}
                        value={value}
                        onChange={handleChange}
                    />
                </div>
            </Header>
            <Header as='h4' floated='left'>
                {fieldName}
            </Header>
        </Segment>
    );
};

/* компонент представляющий собой вкладку "Информация об УТМ" в панеле управления. */
class InfoUtm extends Component {
    state = {
        chpass: false,
        oldpass: '',
        newpass: '',
        confirmpass: '',
        oldErr: false,
        newErr: false,
        confirmErr: false,
        openModal: false,
        msgNeg: false,
        msgPos: false,
        response: '',
        chtrack: false,
        pass: '',
        passErr: false,
        editInfo: false,
        external_ip: '',
        external_port: '',
        utm_ip: '',
        utm_port: '',
        username: ''
    };

    /* открывает/закрывает окно для смены пароля доступа к УТМ */
    open = () => this.setState({ chpass: true });
    close = () =>
        this.setState({
            chpass: false,
            oldpass: '',
            newpass: '',
            confirmpass: ''
        });

    /* открывает/закрывает окно для редактирования данных об УТМ, при этом подхватываются и отображаются текущие настройки */
    openEdit = () =>
        this.setState({
            editInfo: true,
            external_ip: this.props.servers[0].external_ip,
            external_port: this.props.servers[0].external_port,
            utm_ip: this.props.servers[0].utm_ip,
            utm_port: this.props.servers[0].utm_port,
            username: this.props.servers[0].ssh_username.String
        });
    closeEdit = () =>
        this.setState({
            editInfo: false,
            external_ip: '',
            external_port: '',
            utm_ip: '',
            utm_port: '',
            username: ''
        });

    /* обрабатывает ввод пользователя (например записывает введенный пароль в state.pass) */
    handleChange = (e, { name, value }) => this.setState({ [name]: value });

    /* обрабатывает ввод пользователя при редактировании настроек подключения к УТМ, при этом происходит проверка ввода. */
    handleChangeUtmInfo = e => {
        const { name, value } = e.target;
        switch (name) {
            case 'external_ip':
                if (validateNumsOrDots(value)) {
                    this.setState({ [name]: value });
                }
                break;
            case 'utm_ip':
                if (validateUtmIP(value)) {
                    this.setState({ [name]: value });
                }
                break;
            case 'external_port':
            case 'utm_port':
                if (validateNums(value)) {
                    this.setState({ [name]: value });
                }
                break;
            default:
                this.setState({ [name]: value });
        }
    };

    /* закрывает окно с результатом выполненной операции */
    closeModal = () =>
        this.setState({
            openModal: false,
            msgNeg: false,
            msgPos: false,
            response: '',
            oldpass: '',
            newpass: '',
            confirmpass: ''
        });

    /* открывает/закрывает окно для ввода пароля доступа к УТМ */
    enableTrack = () => this.setState({ chtrack: true });
    closeTrack = () => this.setState({ chtrack: false, pass: '' });

    /* отключает отслеживание связи с УТМ */
    disableTrack = () => {
        this.props.onChangeUtmTrack(!this.props.utm_tracking);
        this.props.onSetSSHPass('');
    };

    checkPasswordIsEmpty = (pass, key) => {
        if (pass === '') {
            this.setState({ [key]: true });
            setTimeout(() => this.setState({ [key]: false }), 1000);
            return false;
        }
        return true;
    };

    checkConfirmPassword = (pass, confirm) => {
        if (pass !== confirm) {
            return false;
        }
        return true;
    };

    startCheckingUtmConn = () => {
        var data = {
            new_pass: this.state.pass
        };
        /* отправляем запрос на проверку пароля доступа к УТМ */
        API.POST('/carrier/check_sshpass', data, res => {
            if (res.data !== PASSWORD_OK) {
                this.setState({
                    openModal: true,
                    response: res.data,
                    pass: ''
                });
                return;
            }
            /* Если пароль валидный, то устанавливаем флаг отслеживания связи с УТМ и сохраняем пароль
            (все в переменные redux store). Это нужно, т.к. они используются в другом компоненте (TankList). */
            this.props.onChangeUtmTrack(!this.props.utm_tracking);
            this.props.onSetSSHPass(this.state.pass);
            /* первоначальная проверка связи с УТМ. Таймер проверки запускается в компоненте TankList при флаге utm_tracking -> true */
            API.POST(
                '/carrier/' + CHECK_UTM_CONN + '/check_utmconn',
                { new_pass: this.state.pass },
                res => {
                    /* если эта ошибка возвращается, значит не запущен сервис utm-worker */
                    if (res.data === ERR_UTM_WORKER) {
                        this.props.onWriteResponse(res.data);
                        this.props.onChangeUtmTrack(!this.props.utm_tracking);
                        this.props.onSetSSHPass('');
                    }
                }
            );
            this.setState({ chtrack: false, pass: '' });
        });
    };

    /* посылает запрос на изменение настроек подключения к УТМ */
    changeUtmInfo = () => {
        var data = {
            new_pass: this.state.pass,
            external_ip: this.state.external_ip,
            external_port: Number(this.state.external_port),
            utm_ip: this.state.utm_ip,
            utm_port: Number(this.state.utm_port),
            ssh_username: this.state.username
        };
        API.POST('/carrier/change_utm_data', data, res => {
            this.setState({ openModal: true, response: res.data });
            if (res.data === SUCCESS_CHANGE_UTM_DATA) {
                this.setState({ msgPos: true, editInfo: false });
            } else {
                this.setState({ msgNeg: true });
            }
        });
    };

    /* проверяет ввели ли пароль и если ввели, то выполняет соответствующую функцию (startCheckingUtmConn или changeUtmInfo) */
    getAccess = () => {
        if (!this.checkPasswordIsEmpty(this.state.pass, 'passErr')) {
            return;
        }
        this.startCheckingUtmConn();
    };

    changePassword = () => {
        /* проверка заполнили ли все поля */
        if (
            !this.checkPasswordIsEmpty(this.state.oldpass, 'oldErr') ||
            !this.checkPasswordIsEmpty(this.state.newpass, 'newErr') ||
            !this.checkPasswordIsEmpty(this.state.confirmpass, 'confirmErr')
        ) {
            return;
        }
        /* проверка подтверждения нового пароля */
        if (
            !this.checkConfirmPassword(
                this.state.newpass,
                this.state.confirmpass
            )
        ) {
            this.setState({
                openModal: true,
                msgNeg: true,
                response: ERR_CONFIRM_PASS
            });
            return;
        }
        var data = {
            old_pass: this.state.oldpass,
            new_pass: this.state.newpass
        };
        API.POST('/carrier/change_pass', data, res => {
            this.setState({ openModal: true, response: res.data });
            if (res.data === SUCCESS_CHANGE_PASS) {
                this.setState({ msgPos: true, chpass: false });
            } else {
                this.setState({ msgNeg: true });
            }
        });
    };

    render() {
        /* servers это массив информаций об УТМ. Если пользователь user, то servers состоит из одного элемента.
        utm_tracking это флаг отслеживания связи с УТМ. */
        const { servers, role, utm_tracking } = this.props;
        const {
            chpass,
            oldpass,
            newpass,
            confirmpass,
            oldErr,
            newErr,
            confirmErr,
            openModal,
            msgNeg,
            msgPos,
            response,
            chtrack,
            pass,
            passErr,
            editInfo,
            external_ip,
            external_port,
            utm_ip,
            utm_port,
            username
        } = this.state;
        const isAllDataReady = servers && servers.length > 0;
        var utm_text = utm_tracking
            ? 'Связь отслеживается'
            : 'Связь не отслеживается';
        return (
            <div>
                {!isAllDataReady ? (
                    <NoData text='Данные об УТМ грузоперевозчика пока не добавлены' />
                ) : /* если пользователь admin, ему отображается просто таблица с данными по всем УТМ перевозчиков. */
                role === ADMIN ? (
                    <Segment basic style={{ padding: '0px' }}>
                        <Table collapsing celled textAlign='center'>
                            <Table.Header>
                                <Table.Row>
                                    <Table.HeaderCell key={1}>
                                        {TableColumns['carrier_name']}
                                    </Table.HeaderCell>
                                    <Table.HeaderCell key={2}>
                                        {TableColumns['external_ip']}
                                    </Table.HeaderCell>
                                    <Table.HeaderCell key={3}>
                                        {TableColumns['external_port']}
                                    </Table.HeaderCell>
                                    <Table.HeaderCell key={4}>
                                        {TableColumns['utm_ip']}
                                    </Table.HeaderCell>
                                    <Table.HeaderCell key={5}>
                                        {TableColumns['utm_port']}
                                    </Table.HeaderCell>
                                    <Table.HeaderCell key={6}>
                                        {TableColumns['ssh_username']}
                                    </Table.HeaderCell>
                                </Table.Row>
                            </Table.Header>
                            <Table.Body>
                                {servers.map((row, i) => {
                                    return (
                                        <Table.Row key={i}>
                                            <Table.Cell key={1}>
                                                {row['carrier_name']}
                                            </Table.Cell>
                                            <Table.Cell key={2}>
                                                {row['external_ip']}
                                            </Table.Cell>
                                            <Table.Cell key={3}>
                                                {row['external_port']}
                                            </Table.Cell>
                                            <Table.Cell key={4}>
                                                {row['utm_ip']}
                                            </Table.Cell>
                                            <Table.Cell key={5}>
                                                {row['utm_port']}
                                            </Table.Cell>
                                            <Table.Cell key={6}>
                                                {row['ssh_username'].String}
                                            </Table.Cell>
                                        </Table.Row>
                                    );
                                })}
                            </Table.Body>
                        </Table>
                    </Segment>
                ) : (
                    /* если user, то отображаются данные по его УТМ и возможности редактирования. */
                    <Segment style={{ height: 'auto', width: '650px' }}>
                        <Segment
                            basic
                            style={{ padding: '0px', height: '25px' }}
                        >
                            <Header as='h4' floated='right'>
                                {servers[0].external_ip}
                            </Header>
                            <Header as='h4' floated='left'>
                                Внешний IP адрес:
                            </Header>
                        </Segment>
                        <Segment
                            basic
                            style={{ padding: '0px', height: '25px' }}
                        >
                            <Header as='h4' floated='right'>
                                {servers[0].external_port === 0
                                    ? ''
                                    : servers[0].external_port}
                            </Header>
                            <Header as='h4' floated='left'>
                                Порт:
                            </Header>
                        </Segment>
                        <Divider
                            section
                            horizontal
                            style={{ marginTop: '10px', marginBottom: '10px' }}
                        />
                        <Segment
                            basic
                            style={{ padding: '0px', height: '25px' }}
                        >
                            <Header as='h4' floated='right'>
                                {servers[0].utm_ip}
                            </Header>
                            <Header as='h4' floated='left'>
                                IP адрес УТМ:
                            </Header>
                        </Segment>
                        <Segment
                            basic
                            style={{ padding: '0px', height: '25px' }}
                        >
                            <Header as='h4' floated='right'>
                                {servers[0].utm_port === 0
                                    ? ''
                                    : servers[0].utm_port}
                            </Header>
                            <Header as='h4' floated='left'>
                                Порт:
                            </Header>
                        </Segment>
                        <Divider
                            section
                            horizontal
                            style={{ marginTop: '10px', marginBottom: '10px' }}
                        />
                        <Segment
                            basic
                            style={{ padding: '0px', height: '25px' }}
                        >
                            <Header as='h4' floated='right'>
                                {servers[0].ssh_username.String}
                            </Header>
                            <Header as='h4' floated='left'>
                                Пользователь:
                            </Header>
                        </Segment>
                        <Segment
                            basic
                            style={{ padding: '0px', height: '25px' }}
                        >
                            <Header as='h4' floated='right'>
                                <Icon
                                    id='utm-conn'
                                    name='circle'
                                    color={
                                        !utm_tracking
                                            ? 'grey'
                                            : servers[0].status === 1
                                            ? 'red'
                                            : 'green'
                                    }
                                    size='small'
                                />
                            </Header>
                            <Header as='h4' floated='left'>
                                <Icon
                                    disabled={servers[0].external_ip === ''}
                                    link={servers[0].external_ip !== ''}
                                    size='large'
                                    name={
                                        utm_tracking
                                            ? 'toggle on'
                                            : 'toggle off'
                                    }
                                    color='blue'
                                    /* отслеживать УТМ можно только если установлен внешний IP */
                                    onClick={
                                        servers[0].external_ip !== '' &&
                                        (utm_tracking
                                            ? this.disableTrack
                                            : this.enableTrack)
                                    }
                                />
                                {utm_text}
                            </Header>
                        </Segment>
                        <Divider
                            section
                            horizontal
                            style={{ marginTop: '10px', marginBottom: '10px' }}
                        />
                        <Segment
                            basic
                            style={{
                                padding: '0px',
                                height: '25px',
                                marginBottom: '14px'
                            }}
                        >
                            <Header as='h4' floated='right'>
                                <Button
                                    disabled={servers[0].external_ip === ''}
                                    primary
                                    size='small'
                                    onClick={this.open}
                                >
                                    Сменить пароль
                                </Button>
                            </Header>
                            <Header as='h4' floated='left'>
                                <Button
                                    color='yellow'
                                    size='small'
                                    onClick={this.openEdit}
                                >
                                    Редактировать
                                </Button>
                            </Header>
                        </Segment>
                    </Segment>
                )}
                {/* окно для смены пароля доступа к УТМ */}
                <Modal
                    size='tiny'
                    open={chpass}
                    closeOnEscape={false}
                    closeOnDimmerClick={false}
                >
                    <Modal.Header>Смена пароля доступа к УТМ</Modal.Header>
                    <Modal.Content>
                        <Form>
                            <Form.Group>
                                <Form.Input
                                    type='password'
                                    placeholder='Старый пароль'
                                    name='oldpass'
                                    value={oldpass}
                                    onChange={this.handleChange}
                                    error={oldErr}
                                />
                            </Form.Group>
                            <Form.Group>
                                <Form.Input
                                    type='password'
                                    placeholder='Новый пароль'
                                    name='newpass'
                                    value={newpass}
                                    onChange={this.handleChange}
                                    error={newErr}
                                />
                            </Form.Group>
                            <Form.Group>
                                <Form.Input
                                    type='password'
                                    placeholder='Подтвердите пароль'
                                    name='confirmpass'
                                    value={confirmpass}
                                    onChange={this.handleChange}
                                    error={confirmErr}
                                />
                            </Form.Group>
                        </Form>
                    </Modal.Content>
                    <Modal.Actions>
                        <Button
                            positive
                            content='Подтвердить'
                            onClick={this.changePassword}
                        />
                        <Button negative onClick={this.close}>
                            Закрыть
                        </Button>
                    </Modal.Actions>
                </Modal>
                {/* окно для редактирования настроек подключения к УТМ */}
                <Modal
                    size='tiny'
                    open={editInfo}
                    closeOnEscape={false}
                    closeOnDimmerClick={false}
                >
                    <Modal.Header>
                        Редактирование параметров подключения к УТМ
                    </Modal.Header>
                    <Modal.Content style={{ padding: '0px' }}>
                        <p style={{ margin: '10px 0 0 14px' }}>
                            Если УТМ и SSH сервер работают на одной и той же
                            машине, то в поле "IP адрес УТМ" следует прописать
                            "localhost".
                        </p>
                        <Segment basic style={{ height: 'auto' }}>
                            <UtmField
                                fieldName='Внешний IP адрес:'
                                name='external_ip'
                                value={external_ip}
                                handleChange={this.handleChangeUtmInfo}
                            />
                            <UtmField
                                fieldName='Внешний порт:'
                                name='external_port'
                                value={external_port}
                                handleChange={this.handleChangeUtmInfo}
                            />
                            <UtmField
                                fieldName='IP адрес УТМ:'
                                name='utm_ip'
                                value={utm_ip}
                                handleChange={this.handleChangeUtmInfo}
                            />
                            <UtmField
                                fieldName='Порт УТМ:'
                                name='utm_port'
                                value={utm_port}
                                handleChange={this.handleChangeUtmInfo}
                            />
                            <UtmField
                                fieldName='Пользователь:'
                                name='username'
                                value={username}
                                handleChange={this.handleChangeUtmInfo}
                            />
                        </Segment>
                    </Modal.Content>
                    <Modal.Actions>
                        <Button
                            positive
                            content='Подтвердить'
                            onClick={this.changeUtmInfo}
                        />
                        <Button negative onClick={this.closeEdit}>
                            Закрыть
                        </Button>
                    </Modal.Actions>
                </Modal>
                {/* окно для ввода пароля доступа к УТМ для отслеживания связи */}
                <Modal
                    size='tiny'
                    open={chtrack}
                    closeOnEscape={false}
                    closeOnDimmerClick={false}
                >
                    <Modal.Header>Введите пароль доступа к УТМ</Modal.Header>
                    <Modal.Content>
                        <Form>
                            <Form.Group>
                                <Form.Input
                                    type='password'
                                    placeholder='Введите пароль'
                                    name='pass'
                                    value={pass}
                                    onChange={this.handleChange}
                                    error={passErr}
                                />
                            </Form.Group>
                        </Form>
                    </Modal.Content>
                    <Modal.Actions>
                        <Button
                            positive
                            content='Подтвердить'
                            onClick={this.getAccess}
                        />
                        <Button negative onClick={this.closeTrack}>
                            Закрыть
                        </Button>
                    </Modal.Actions>
                </Modal>
                {/* окно для отображения результата выполненной операции */}
                <Modal
                    size='tiny'
                    open={openModal}
                    onClose={this.closeModal}
                    dimmer='blurring'
                >
                    <Header
                        icon={
                            <Icon
                                color={
                                    msgNeg ? 'red' : msgPos ? 'green' : 'red'
                                }
                                name={
                                    msgNeg
                                        ? 'close'
                                        : msgPos
                                        ? 'check'
                                        : 'close'
                                }
                            />
                        }
                        content={ResTypesOnChange[response]}
                    />
                    <Modal.Actions>
                        <Button negative onClick={this.closeModal}>
                            Закрыть
                        </Button>
                    </Modal.Actions>
                </Modal>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        utm_tracking: state.carrier.utm_tracking,
        ssh_pass: state.carrier.ssh_pass
    };
};

const mapDispatchToProps = dispatch => {
    return {
        //  carrier
        onChangeUtmTrack: flag => {
            dispatch(changeUtmTrack({ utm_tracking: flag }));
        },
        onSetSSHPass: pass => {
            dispatch(setSSHPass({ ssh_pass: pass }));
        },
        onWriteResponse: res => {
            dispatch(writeResponse({ open: true, response: res }));
        }
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(InfoUtm);
