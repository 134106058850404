import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import {
    Form,
    Card,
    Grid,
    Modal,
    Button,
    Dimmer,
    Loader
} from 'semantic-ui-react';

import API from '../API';
import { AuthMessage } from './AuthMessage';

import {
    ERR_WRONG_LOGIN,
    ERR_WRONG_PSWD,
    ERR_UNEXPECTED,
    ERR_BUSY_ACOOUNT,
    SUCCESS_AUTH_DATA,
    ERR_NETWORK,
    ERR_NO_MONEY
} from '../errors';

class Login extends Component {
    state = {
        username: '',
        password: '',
        redirectToReferrer: false,
        user_id: 0,
        error: '',
        message: '',
        open: false,
        active: false,
        openModal: false
    };

    /* В данном методе в первый раз отправляем на сервер логин и пароль и flag = false, что говорит о том, что
    никого пока выкидывать из сеанса не нужно, просто делаем попытку залогиниться. При первой попытке user_id = 0.
    В ответ можем получить различные ошибки. Также если учетка занята, то с ответом приходит и user_id клиента,
    который занял. И после подтверждения повторного входа мы отправляем flag = true и user_id для завершения чужой сессии.
    Если все хорошо, учетка свободна, то redirectToReferrer -> true и в функции render делаем редирект.
    
    Аутентификация работает на основе JWT. Есть access и refresh токены. Токены и срок жизни access токена на клиенте хранятся
    в sessionStorage. Refresh токен храниться в БД на сервере. При каждом запросе отправляется access токен для его валидации.
    При истечении срока жизни access токена он продлевается и создается заново с помощью refresh токена, при этом refresh токен
    также создается заново и его срок продлевается. Значения сроков жизни токенов хранятся на сервере. Срок жизни refresh токена
    ограничивается датой оплаты доступа к ЛК.
    
    **Механизм работы функции выкидывания другого пользователя, логику аутентификации, авторизации, отслеживания срока действия
    доступа к ЛК нужно смотреть в компоненте API, NavBar, классе Authenticate и коде сервера - logout, auth. */
    login = () => {
        var data;
        if (this.state.user_id === 0) {
            data = {
                username: this.state.username,
                password: this.state.password,
                flag: false
            };
        } else {
            data = {
                username: this.state.username,
                password: this.state.password,
                flag: true,
                user_id: this.state.user_id
            };
        }
        API.Login(
            data,
            res => {
                this.setState({
                    user_id: res.user_id,
                    error: res.error,
                    message: res.message
                });
                if (this.state.error === ERR_BUSY_ACOOUNT) {
                    this.setState({ open: true });
                }
                if (
                    this.state.error === ERR_WRONG_LOGIN ||
                    this.state.error === ERR_WRONG_PSWD ||
                    this.state.error === ERR_UNEXPECTED ||
                    this.state.error === ERR_NO_MONEY
                ) {
                    this.setState({ openModal: true });
                }
                if (this.state.error === SUCCESS_AUTH_DATA) {
                    this.setState({ redirectToReferrer: true });
                }
            },
            error => {
                if (!error.response) {
                    this.setState({ openModal: true, message: ERR_NETWORK });
                } else {
                    this.setState({
                        openModal: true,
                        message: error.response.data.message
                    });
                }
            }
        );
    };

    handleSubmit = () => {
        this.login();
    };

    handleChange = (e, { name, value }) => this.setState({ [name]: value });

    close = () =>
        this.setState({
            open: false,
            error: '',
            message: '',
            user_id: 0,
            redirectToReferrer: false
        });

    handleShow = () => this.setState({ active: true });

    closeModal = () =>
        this.setState({
            openModal: false,
            error: '',
            message: '',
            user_id: 0,
            redirectToReferrer: false,
            username: '',
            password: '',
            active: false
        });

    render() {
        const { from } = this.props.location.state || {
            from: { pathname: '/load' }
        };
        /* Если полностью корректно выполнили вход, т.е. redirectToReferrer истина, то переходим сначала на
        страницу загрузки PageLoader, а потом в корень Content */
        if (this.state.redirectToReferrer === true) {
            return <Redirect to={from} />;
        }
        return (
            <div>
                <Grid centered container verticalAlign='middle'>
                    <Grid.Row centered>
                        <Grid.Column></Grid.Column>
                        <Grid.Column mobile={16} tablet={8} computer={6}>
                            <Card>
                                <Card.Content
                                    style={{ backgroundColor: '#2185d0' }}
                                >
                                    <Card.Header style={{ color: '#fff' }}>
                                        Выполните вход
                                    </Card.Header>
                                </Card.Content>
                                <Card.Content>
                                    <Form onSubmit={this.handleSubmit}>
                                        <Form.Input
                                            type='text'
                                            label='Имя'
                                            placeholder='Имя пользователя'
                                            name='username'
                                            value={this.state.username}
                                            onChange={this.handleChange}
                                        />
                                        <Form.Input
                                            type='password'
                                            label='Пароль'
                                            placeholder='Пароль пользователя'
                                            name='password'
                                            value={this.state.password}
                                            onChange={this.handleChange}
                                        />
                                        <Form.Button
                                            color='blue'
                                            content='Войти'
                                        />
                                    </Form>
                                </Card.Content>
                            </Card>
                        </Grid.Column>
                        <Grid.Column></Grid.Column>
                    </Grid.Row>
                </Grid>
                <Modal
                    basic
                    className='scrolling'
                    size='mini'
                    open={this.state.open}
                >
                    <Modal.Header>Учетная запись занята</Modal.Header>
                    <Modal.Content>
                        <p>Все равно зайти (текущая сессия будет прервана)?</p>
                    </Modal.Content>
                    <Modal.Actions>
                        <Button onClick={this.close} negative>
                            Нет
                        </Button>
                        <Button
                            onClick={() => {
                                this.handleShow();
                                this.login();
                                this.close();
                            }}
                            positive
                        >
                            Да
                        </Button>
                    </Modal.Actions>
                </Modal>
                <AuthMessage
                    message={this.state.message}
                    openModal={this.state.openModal}
                    closeModal={this.closeModal}
                />
                <Dimmer active={this.state.active}>
                    <Loader>Подождите...</Loader>
                </Dimmer>
            </div>
        );
    }
}

export default Login;
