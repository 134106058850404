import React from 'react';
import { Table } from 'semantic-ui-react';

import NoData from './NoData';

const TableColumns = {
    start_date: 'Время начала сессии',
    end_date: 'Время окончания сессии',
    mode: 'Режим',
    product_id: 'Код АП',
    vbs_start: 'Объем нач. безвод. спирта при 20°C, дал',
    vbs_end: 'Объем кон. безвод. спирта при 20°C, дал',
    a_start: 'Общий нач. объем при 20°C, дал',
    a_end: 'Общий кон. объем при 20°C, дал',
    aver_alko: 'Крепость, %'
};

const EgaisTableData = ({ data }) => {
    const isAllDataReady = data && data.length > 0;
    return !isAllDataReady ? (
        <NoData />
    ) : (
        <Table collapsing compact celled textAlign='center'>
            <Table.Header>
                <Table.Row>
                    {Object.keys(data[0]).map((k, i) => {
                        if (i !== 9) {
                            return (
                                <Table.HeaderCell key={i}>
                                    {TableColumns[k]}
                                </Table.HeaderCell>
                            );
                        }
                    })}
                </Table.Row>
            </Table.Header>
            <Table.Body>
                {data.map((row, i) => {
                    return (
                        <Table.Row key={i}>
                            {Object.keys(row).map((k, i) => {
                                if (i !== 9) {
                                    return (
                                        <Table.Cell key={i}>
                                            {i === 0 || i === 1
                                                ? row[k]
                                                      .replace('T', ' ')
                                                      .replace('Z', '')
                                                : row[k]}
                                        </Table.Cell>
                                    );
                                }
                            })}
                        </Table.Row>
                    );
                })}
            </Table.Body>
        </Table>
    );
};

export default EgaisTableData;
