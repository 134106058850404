import React from 'react';
import { Segment, Header, Icon } from 'semantic-ui-react';

import { EMPTY_MSG, TANK_NOT_FOUND, BAD_REQUEST } from '../messages';

const NoContent = ({ showMap, message }) => {
    if (message === EMPTY_MSG) {
        return (
            <Segment
                loading
                size='massive'
                className='no-content'
                style={{ height: showMap ? '55vh' : '90vh' }}
            />
        );
    }

    var iconName;
    if (message === TANK_NOT_FOUND) iconName = 'search';
    if (message === BAD_REQUEST) iconName = 'frown outline';
    return (
        <Segment
            className='no-content'
            placeholder
            style={{ height: showMap ? '55vh' : '90vh' }}
        >
            <Header icon>
                <Icon name={iconName} />
                {message}
            </Header>
        </Segment>
    );
};

export default NoContent;
