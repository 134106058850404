import { mapTypes } from './constants';

/* первоначальное состояние редьюсера flags по умолчанию */
export const defaultFlags = {
    logout: false, //  это свойство говорит о том, нужно ли выполнять метод Logout?
    show: {
        map: false, //  видимость карты
        state: false, //  видимость текущих данных (длинная синяя кнопка "Текущие данные" в интерфейсе)
        curr_data: false, //  флаг успешности запроса текущих данных всех цистерн
        info_window: true, //  видимость названия цистерны на карте
        track: false, //  видимость маршрута перевозки на карте
        tanks: true, //  видимость цистерн на карте
        movings: false, //  видимость перемещений на карте
        full_track: true, //  если true, то последняя точка маршрута перевозки в виде "флажка"
        map_type: mapTypes[0].value, //  типа карты
        info_box: false, //  видимость данных по отсекам для точки маршрута
        noticeOpen: false, //  влияет на ширину окна формы заполнения уведомления о перевозке
        parking_info: false //  видимость инфы по стоянке цистерны
    },
    bad_req: false //  признак неуспешного запроса -> отображение сообещния об ошибке
};
