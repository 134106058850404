import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Segment, Button } from 'semantic-ui-react';

import TankState from './TankState';
import AllData from './AllData';
import API from '../API';

import { showState } from '../actions/flags';

import { getCertsInfo } from '../actions/tanks';

/* Данный компонент каждый раз при переключении между цистернами перемонтируется. т.е. допустим
метод componentDidMount срабатывает каждый раз при монтировании. Соответственно и все компоненты
внутри этого также перемонтируются. Это полезно для управления работой таймеров обновления данных. */
class TankContent extends Component {
    componentDidMount() {
        API.GET(
            '/tanks/' + this.props.flags.curr_tank_id + '/info',
            null,
            this.props.onGetCertsInfo(this.props.flags.curr_tank_id),
            null
        );
    }

    componentWillUnmount() {}

    render() {
        const { showMap, showState, t } = this.props;

        var buttonText = showState
            ? 'Текущие данные (свернуть)'
            : 'Текущие данные (развернуть)';
        var stateDisplay = !showState ? 'none' : '';

        var contentTop = showMap ? '45vh' : '10vh';
        var contentHeight = showMap ? 'calc(55vh - 14px)' : 'calc(90vh - 14px)';

        return (
            <Segment
                basic
                className='segment-tank-content'
                style={{ top: contentTop, height: contentHeight }}
            >
                <div className='tank-content'>
                    {/* для контейнеров и ЖД TankState не отображаем */
                    t.tank_type !== 0 && t.tank_type !== 3 ? (
                        <div>
                            <Button
                                attached='top'
                                color='blue'
                                onClick={() =>
                                    this.props.onShowState(!showState)
                                }
                            >
                                {buttonText}
                            </Button>
                            <TankState t={t} stateDisplay={stateDisplay} />
                        </div>
                    ) : null}
                    <AllData t={t} showMap={showMap} showState={showState} />
                </div>
            </Segment>
        );
    }
}

const mapStateToProps = state => state;

const mapDispatchToProps = dispatch => {
    return {
        onShowState: flag => {
            dispatch(showState({ state: flag }));
        },

        onGetCertsInfo: id => res => {
            if (res.status === 200)
                dispatch(getCertsInfo({ id: id, certs_info: res.data }));
        }
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(TankContent);
