import { create_action_creator } from '../defaultAction';

export const CHANGE_FORM_FIELD = 'CHANGE_FORM_FIELD';
export const changeFormField = create_action_creator(CHANGE_FORM_FIELD);

export const CHANGE_POSITIONS_NUMBER = 'CHANGE_POSITIONS_NUMBER';
export const changePositionsNumber = create_action_creator(
    CHANGE_POSITIONS_NUMBER
);

export const CHANGE_FORM_FIELD_POSITION = 'CHANGE_FORM_FIELD_POSITION';
export const changeFormFieldPos = create_action_creator(
    CHANGE_FORM_FIELD_POSITION
);

export const RESET_NOTICE = 'RESET_NOTICE';
export const resetNotice = create_action_creator(RESET_NOTICE);

export const SHOW_FILE = 'SHOW_FILE';
export const showFile = create_action_creator(SHOW_FILE);

export const CLOSE_FILE = 'CLOSE_FILE';
export const closeFile = create_action_creator(CLOSE_FILE);

export const SAVE_FILE_IDX = 'SAVE_FILE_IDX';
export const saveFileIdx = create_action_creator(SAVE_FILE_IDX);
