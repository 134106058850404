import React from 'react';

const NoData = () => {
    return (
        <div>
            <h4 style={{ color: 'grey', paddingTop: '20px' }}>
                Нет данных за выбранный интервал
            </h4>
        </div>
    );
};

export default NoData;
