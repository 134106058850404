import React from 'react';
import { Form } from 'semantic-ui-react';

const XmlFileView = ({ file_string }) => {
    return (
        <Form id='file-viewer' size='large'>
            <Form.TextArea id='file-viewer-area' value={file_string} readOnly />
        </Form>
    );
};

export default XmlFileView;
