import React, { Component } from 'react';
import DatePicker from 'material-ui/DatePicker';
import { Menu, Button } from 'semantic-ui-react';

import { date } from '../constants';

import { parseMoment } from '../functions';

class DateSelector extends Component {
    state = {
        currDate: parseMoment(date.defaultCurrDate, 'date')
    };

    handleChangeDate = (e, date) => {
        this.setState({ currDate: parseMoment(date, 'date') });
    };

    render() {
        const { text, submitDateSelector, disabled } = this.props;
        return (
            <div className='date-selector'>
                <Menu>
                    <Menu.Item header>Выберите день:</Menu.Item>
                    <Menu.Item style={{ height: '50px', width: '150px' }}>
                        <DatePicker
                            hintText={text}
                            onChange={this.handleChangeDate}
                            defaultDate={date.defaultCurrDate.toDate()}
                            underlineStyle={{ display: 'none' }}
                        />
                    </Menu.Item>
                    <Menu.Item>
                        <Button
                            color='blue'
                            disabled={disabled}
                            onClick={() => {
                                submitDateSelector(this.state.currDate);
                            }}
                        >
                            Подтвердить
                        </Button>
                    </Menu.Item>
                    <Menu.Item>
                        <Button
                            icon='sync alternate'
                            disabled={disabled}
                            color='green'
                            basic
                            onClick={() => {
                                submitDateSelector(this.state.currDate);
                            }}
                        />
                    </Menu.Item>
                </Menu>
            </div>
        );
    }
}

export default DateSelector;
