import React, { Component } from 'react';
import {
    Segment,
    Button,
    Table,
    Icon,
    Modal,
    Form,
    Confirm
} from 'semantic-ui-react';

import API from '../API';

import { isBeforeToday } from '../functions';

const TableColumns = {
    username: 'Имя пользователя',
    carrier_name: 'Владелец учетки',
    role: 'Права',
    online: 'Онлайн',
    pay_date: 'Дата окончания доступа',
    feature: 'Доступность отправки уведомлений о перевозке'
};

const accessOptions = [
    { key: 1, text: 'Клиент', value: 'user' },
    { key: 2, text: 'Администратор', value: 'admin' }
];

class Users extends Component {
    state = {
        chpaydate: false,
        currPayDate: '',
        newPayDate: '',
        currUserID: 0,
        err: false,
        featureConfirm: false,
        currFeature: 0,
        currUserName: '',
        openAddUser: false,
        username: '',
        password: '',
        carrier: '',
        access: ''
    };

    handleChange = (e, { name, value }) => this.setState({ [name]: value });

    openChPayDate = (pay_date, user_id) =>
        this.setState({
            chpaydate: true,
            currPayDate: pay_date,
            currUserID: user_id
        });
    closeChPayDate = () =>
        this.setState({ chpaydate: false, currPayDate: '', currUserID: 0 });

    openFeatureConfirmation = (user_id, feature, username) =>
        this.setState({
            currUserID: user_id,
            featureConfirm: true,
            currFeature: feature,
            currUserName: username
        });
    closeFeatureConfirmation = () =>
        this.setState({
            featureConfirm: false,
            currFeature: 0,
            currUserName: '',
            currUserID: 0
        });

    openAddUser = () => this.setState({ openAddUser: true });
    closeAddUser = () =>
        this.setState({
            openAddUser: false,
            username: '',
            password: '',
            carrier: 0,
            access: ''
        });

    isValidDate = date => {
        var regEx = /^\d{4}-\d{2}-\d{2}$/;
        if (!date.match(regEx)) return false;
        var d = new Date(date);
        var dNum = d.getTime();
        if (!dNum && dNum !== 0) return false; // Invalid date
        return d.toISOString().slice(0, 10) === date;
    };

    changePayDate = () => {
        if (!this.isValidDate(this.state.newPayDate)) {
            this.setState({ err: true });
            setTimeout(() => this.setState({ err: false }), 1000);
            return;
        }
        var disable = false;
        if (isBeforeToday(this.state.newPayDate)) {
            disable = true;
        }
        var data = {
            pay_date: this.state.newPayDate,
            user_id: this.state.currUserID,
            operation: 'pay_date',
            disable: disable
        };
        API.POST('/change_users', data, res => {
            alert(res.data);
            this.closeChPayDate();
        });
    };

    confirmFeature = () => {
        var disable = this.state.currFeature === 0 ? false : true;
        var data = {
            user_id: this.state.currUserID,
            operation: 'feature',
            disable: disable
        };
        API.POST('/change_users', data, res => {
            alert(res.data);
            this.closeFeatureConfirmation();
        });
    };

    addNewUser = () => {
        if (
            this.state.username === '' ||
            this.state.password === '' ||
            this.state.carrier === '' ||
            this.state.access === ''
        ) {
            alert('Заполните все поля!');
            return;
        }
        var data = {
            operation: 'add_user',
            username: this.state.username,
            password: this.state.password,
            carrier: this.state.carrier,
            access: this.state.access
        };
        API.POST('/change_users', data, res => {
            alert(res.data);
            this.closeAddUser();
        });
    };

    render() {
        const { users, carriers } = this.props;
        const {
            chpaydate,
            currPayDate,
            newPayDate,
            err,
            featureConfirm,
            currFeature,
            currUserName,
            openAddUser,
            username,
            password,
            carrier,
            access
        } = this.state;
        const isAllDataReady = users && users.length > 0;
        var confirmContent =
            currFeature === 0
                ? 'Подтвердите активацию функции для пользователя ' +
                  currUserName
                : 'Подтвердите деактивацию функции для пользователя ' +
                  currUserName;
        return (
            <div style={{ height: '100%' }}>
                <Segment basic className='users' style={{ padding: '0px' }}>
                    <Segment
                        basic
                        style={{
                            height: '5%',
                            marginBottom: '10px',
                            padding: '0'
                        }}
                    >
                        <Button primary onClick={() => this.openAddUser()}>
                            Добавить пользователя
                        </Button>
                    </Segment>
                    {!isAllDataReady ? (
                        <Segment
                            loading
                            style={{
                                height: 'calc(95% - 10px)',
                                margin: '0',
                                padding: '0'
                            }}
                        ></Segment>
                    ) : (
                        <Segment
                            className='users-table'
                            basic
                            style={{
                                height: 'calc(95% - 10px)',
                                margin: '0',
                                padding: '0'
                            }}
                        >
                            <Table celled textAlign='center'>
                                <Table.Header>
                                    <Table.Row>
                                        <Table.HeaderCell key={1}>
                                            {TableColumns['username']}
                                        </Table.HeaderCell>
                                        <Table.HeaderCell key={2}>
                                            {TableColumns['carrier_name']}
                                        </Table.HeaderCell>
                                        <Table.HeaderCell key={3}>
                                            {TableColumns['role']}
                                        </Table.HeaderCell>
                                        <Table.HeaderCell key={4}>
                                            {TableColumns['pay_date']}
                                        </Table.HeaderCell>
                                        <Table.HeaderCell key={5}>
                                            {TableColumns['feature']}
                                        </Table.HeaderCell>
                                        <Table.HeaderCell key={6}>
                                            {TableColumns['online']}
                                        </Table.HeaderCell>
                                    </Table.Row>
                                </Table.Header>
                                <Table.Body>
                                    {users.map((row, i) => {
                                        return (
                                            <Table.Row key={i}>
                                                <Table.Cell key={1}>
                                                    {row['username']}
                                                </Table.Cell>
                                                <Table.Cell key={2}>
                                                    {row['carrier_name']}
                                                </Table.Cell>
                                                <Table.Cell key={3}>
                                                    {row['role']}
                                                </Table.Cell>
                                                <Table.Cell
                                                    key={4}
                                                    selectable
                                                    onClick={() =>
                                                        this.openChPayDate(
                                                            row[
                                                                'pay_date'
                                                            ].substring(
                                                                0,
                                                                row[
                                                                    'pay_date'
                                                                ].indexOf('T')
                                                            ),
                                                            row['user_id']
                                                        )
                                                    }
                                                >
                                                    {row['pay_date'].substring(
                                                        0,
                                                        row['pay_date'].indexOf(
                                                            'T'
                                                        )
                                                    )}
                                                    &nbsp;&nbsp;&nbsp;
                                                    {!isBeforeToday(
                                                        row[
                                                            'pay_date'
                                                        ].substring(
                                                            0,
                                                            row[
                                                                'pay_date'
                                                            ].indexOf('T')
                                                        )
                                                    ) ? (
                                                        <Icon
                                                            color='green'
                                                            name='unlock'
                                                            size='large'
                                                        ></Icon>
                                                    ) : (
                                                        <Icon
                                                            color='red'
                                                            name='lock'
                                                            size='large'
                                                        ></Icon>
                                                    )}
                                                </Table.Cell>
                                                <Table.Cell
                                                    key={5}
                                                    selectable
                                                    onClick={() =>
                                                        this.openFeatureConfirmation(
                                                            row.user_id,
                                                            row.feature,
                                                            row.username
                                                        )
                                                    }
                                                >
                                                    {row['feature'] === 1 ? (
                                                        <Icon
                                                            color='green'
                                                            name='unlock'
                                                            size='large'
                                                        ></Icon>
                                                    ) : (
                                                        <Icon
                                                            color='red'
                                                            name='lock'
                                                            size='large'
                                                        ></Icon>
                                                    )}
                                                </Table.Cell>
                                                <Table.Cell key={6}>
                                                    {row['online'] ? (
                                                        <Icon
                                                            color='blue'
                                                            name='user'
                                                            size='large'
                                                        ></Icon>
                                                    ) : (
                                                        <Icon
                                                            color='blue'
                                                            name='user outline'
                                                            size='large'
                                                        ></Icon>
                                                    )}
                                                </Table.Cell>
                                            </Table.Row>
                                        );
                                    })}
                                </Table.Body>
                            </Table>
                        </Segment>
                    )}
                </Segment>
                {/*  */}
                <Modal
                    size='tiny'
                    open={chpaydate}
                    closeOnEscape={false}
                    closeOnDimmerClick={false}
                >
                    <Modal.Header>
                        Управление доступом к личному кабинету
                    </Modal.Header>
                    <Modal.Content>
                        <p style={{ margin: '0 0 14px 0' }}>
                            Введите новую дату окончания срока доступа к ЛК в
                            формате ГГГГ-ММ-ДД. Для отключения доступа, можете
                            ввести любую дату раньше сегодняшней.
                        </p>
                        <Form>
                            <Form.Group>
                                <Form.Input
                                    placeholder={currPayDate}
                                    name='newPayDate'
                                    value={newPayDate}
                                    onChange={this.handleChange}
                                    error={err}
                                />
                            </Form.Group>
                        </Form>
                    </Modal.Content>
                    <Modal.Actions>
                        <Button
                            positive
                            content='Подтвердить'
                            onClick={() => this.changePayDate()}
                        />
                        <Button negative onClick={this.closeChPayDate}>
                            Закрыть
                        </Button>
                    </Modal.Actions>
                </Modal>
                {/*  */}
                <Confirm
                    open={featureConfirm}
                    content={confirmContent}
                    cancelButton='Закрыть'
                    confirmButton='Подтвердить'
                    onCancel={this.closeFeatureConfirmation}
                    onConfirm={this.confirmFeature}
                />
                {/*  */}
                <Modal
                    size='tiny'
                    open={openAddUser}
                    closeOnEscape={false}
                    closeOnDimmerClick={false}
                >
                    <Modal.Header>Добавление нового пользователя</Modal.Header>
                    <Modal.Content style={{ padding: '0px' }}>
                        <Segment basic style={{ height: 'auto' }}>
                            <Form>
                                <Form.Group>
                                    <Form.Input
                                        label='Имя пользователя:'
                                        name='username'
                                        placeholder='Имя пользователя'
                                        text={username}
                                        value={username ? username : ''}
                                        onChange={this.handleChange}
                                        width={8}
                                    />
                                    <Form.Input
                                        label='Пароль:'
                                        name='password'
                                        placeholder='Пароль'
                                        text={password}
                                        value={password ? password : ''}
                                        onChange={this.handleChange}
                                        width={8}
                                    />
                                </Form.Group>
                                <Form.Select
                                    label='Перевозчик:'
                                    name='carrier'
                                    placeholder='Перевозчик'
                                    text={carrier}
                                    value={carrier ? carrier : ''}
                                    options={carriers != null && carriers}
                                    onChange={this.handleChange}
                                    width={16}
                                />
                                <Form.Select
                                    label='Права:'
                                    name='access'
                                    placeholder='Права'
                                    text={
                                        access === 'user'
                                            ? 'Клиент'
                                            : access === 'admin'
                                            ? 'Администратор'
                                            : ''
                                    }
                                    value={access ? access : ''}
                                    options={accessOptions}
                                    onChange={this.handleChange}
                                    width={16}
                                />
                            </Form>
                        </Segment>
                    </Modal.Content>
                    <Modal.Actions>
                        <Button
                            positive
                            content='Подтвердить'
                            onClick={this.addNewUser}
                        />
                        <Button negative onClick={this.closeAddUser}>
                            Закрыть
                        </Button>
                    </Modal.Actions>
                </Modal>
            </div>
        );
    }
}

export default Users;
