import React, { Component } from 'react';
import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider';
import getMuiTheme from '../../../node_modules/material-ui/styles/getMuiTheme';

/* Данный враппер нужен для корректного применения стилей к компонентам material UI. */
export default class MaterialUIWrapper extends Component {
    render() {
        const { children, ...props } = this.props;
        const muiTheme = getMuiTheme({
            palette: {
                primary1Color: '#3B83C0',
                primary2Color: '#3B83C0',
                pickerHeaderColor: '#3B83C0'
            }
        });
        return (
            <MuiThemeProvider muiTheme={muiTheme} {...props}>
                {children}
            </MuiThemeProvider>
        );
    }
}
